import { TooltipPosition } from "@angular/material/tooltip";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { IconType } from "../../../../../../../core/src/lib/components/icon/icon.enum";
import { INavItem, INavLink } from "./default-navigation.interface";

export class NavItem implements INavItem {
  constructor(
    public tooltip: string,
    public tooltipPosition: TooltipPosition,
    public icon: IconName | string,
    public iconType: IconType
  ) {}
}

export class NavLink extends NavItem implements INavLink {
  constructor(
    public tooltip: string,
    public tooltipPosition: TooltipPosition,
    public icon: IconName | string,
    public iconType: IconType,
    public routerLinkActive: string,
    public routerLink: string
  ) {
    super(tooltip, tooltipPosition, icon, iconType);
  }
}

// export class NavButton extends NavItem implements INavButton {
//   constructor(
//     public tooltip: string,
//     public tooltipPosition: TooltipPosition,
//     public icon: IconName | string,
//     public iconType: IconType,
//     public clickEvent: () => void
//   ) {
//     super(tooltip, tooltipPosition, icon, iconType);
//   }
// }
