import { DocumentItemMediaType } from "../enums/document.enum";

export interface DocumentItemMedia {
  media_link: string;
  media_source: string;
  media_type: DocumentItemMediaType;
}

export interface DocumentItem {
  id: string;
  emitDate: number;
  emitDocumentDate: number;
  emitSource: string;
  emitChannel: string;
  emitActor: string;
  emitHeadline: string;
  emitBodyText: string;
  emitCommunity: string;
  emitParentId: string;
  emitTopLevelId: string;
  emitType: string;
  emitMedia: Array<DocumentItemMedia>;
  emitURL: string;
  emitBookmarks: Array<string>;
  emitReplyTo: string;
  emitExtensionFields: Array<any>;
  emitReplyToId: string;
}
export interface IMessage {
  itemId: string;
  destination: string;
  message: string;
}

export interface IData {
  count: number;
  result: Array<DocumentItem>;
  page?: number;
}

export interface ISearchResults {
  items: Array<DocumentItem>;
  page: number;
  pageSize: number;
  totalHits: number;
}

export interface IExtensionField {
  name: string;
  data: { [key: string]: string };
}

export interface IContext {
  document: DocumentItem;
  before?: number;
  after?: number;
}

export interface IContextSearchResults {
  items: Array<DocumentItem>;
  page?: number;
  totalHits?: number;
}
