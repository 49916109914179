import { BoardType } from "../enums/board.enum";
import { IBoard, IPosition } from "../interfaces/board.interface";
import { IPresetQuery } from "../interfaces/query-dtos";

export class Board implements IBoard {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public defaultBoard: boolean,
    public filter: {
      date: IPresetQuery;
    },
    public widgetIds: Array<string>,
    public type: BoardType,
    public widgetPositions: Array<IPosition>
  ) {}
}
