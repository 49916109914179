export enum TimelineStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NO_RESULTS = "NO_RESULTS",
}

export enum ScrollScope {
  CHART = "CHART",
  SEARCH_RESULTS = "SEARCH_RESULTS",
  TIMELINE_EXPLORE_DASHBOARD = "TIMELINE_EXPLORE_DASHBOARD",
}
