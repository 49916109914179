import { Pipe, PipeTransform } from "@angular/core";
import {
  IConnectionsData,
  Profile,
  ProfileConnection,
  ProfileService,
} from "@intorqa-ui/shared";
import { Utils } from "utils/utils";

@Pipe({
  name: "getNetworkGraphData",
})
export class GetNetworkGraphDataPipe implements PipeTransform {
  constructor(private profileService: ProfileService) {}

  transform(data: IConnectionsData, profile: Profile): any {
    if (!data) return data;
    let nodes = [
      {
        id: profile.profileId,
        profileId: profile.profileId,
        name: profile.name,
        symbolSize: 100,
        x: 0,
        y: 0,
        value: 0,
        category: 0,
        profileType: this.profileService.getProfileTypeById(
          profile.profileTypeId
        )?.name,
      },
    ];
    nodes = [
      ...nodes,
      ...(data?.items?.map((item: ProfileConnection) => ({
        id: `${item.targetProfileId}_${item.typeId}`,
        profileId: item.targetProfileId,
        name: item.targetProfileName,
        symbolSize: 30,
        x: undefined,
        y: undefined,
        value: 0,
        category: 0,
        profileType: item.targetProfileTypeName,
      })) || []),
    ];
    const links = data?.items?.map((item: ProfileConnection) => ({
      source: `${item.targetProfileId}_${item.typeId}`,
      target: profile.profileId,
      connectionType: item.typeName,
      name: item.targetProfileName,
    }));
    const graph_data = {
      nodes: nodes,
      links: links,
      categories: [{ name: "A" }],
    };

    return {
      series: [
        {
          data: [Utils.calculateCoordinates(graph_data)],
        },
      ],
      totalHits: data.totalCount,
    };
  }
}
