import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "itq-dropdown-item",
  templateUrl: "./dropdown-item.component.html",
  styleUrls: ["./dropdown-item.component.scss"],
})
export class DropdownItemComponent implements OnInit {
  @Input() dataFields: { name: string; value: string };
  @Input() item: any;
  @Input() httpBinding = false;

  @Output() changeValue = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public onClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.httpBinding) {
      this.changeValue.emit( this.item);
    } else {
      this.changeValue.emit(this.item[this.dataFields.value] );

    }
  }
}
