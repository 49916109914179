import { TemplatePortal } from "@angular/cdk/portal";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { Subscription } from "rxjs";
import { IconTypes } from "../../enums/icon.enum";
import { DropdownService } from "../../services/dropdown.service";
import { PortalBridgeService } from "../../services/portal-bridge.service";
import { Align, Sizes } from "../../enums/shared.enum";

import { IOpenDirection } from "./nav-button.interface";

@Component({
  selector: "itq-nav-button",
  templateUrl: "./nav-button.component.html",
  styleUrls: ["./nav-button.component.scss"],
})
export class NavButtonComponent implements OnInit, OnDestroy {
  @ViewChild("dropdownButton") dropdownButton: ElementRef;
  @ViewChild("container") container: ElementRef;
  @ViewChild("portalContent") portalContent: TemplateRef<unknown>;

  @Input() icon: IconProp;
  @Input() iconSize: SizeProp;
  @Input() width = 150;
  @Input() text = "";
  @Input() size: Sizes;
  @Input() template: TemplateRef<unknown>;
  @Input() templateData: any;
  @Input() disabled = false;
  @Input() color = "";
  @Input() svgIcon: string;
  @Input() openDirection: IOpenDirection;
  @Input() openPosition: "inside" | "outside";
  @Input() materialStyle = "basic";
  @Input() textTransform: string;
  @Input() align = Align.START;
  @Input() padding = Sizes["X-SM"];

  @Output() opened = new EventEmitter();

  public isOpen = false;
  private dropdownSubscription: Subscription;
  public Sizes = Sizes;
  public IconTypes = IconTypes;
  public clicked = false;

  @HostListener("document:click", ["$event"])
  click(): void {
    this.isOpen = false;
    this.clicked = false;
  }

  constructor(
    private portalBridgeService: PortalBridgeService,
    private viewContainerRef: ViewContainerRef,
    private dropdownService: DropdownService
  ) {}

  ngOnInit(): void {
    this.dropdownSubscription =
      this.dropdownService.dropdownObservable.subscribe(() => {
        this.isOpen = false;
      });
  }

  ngOnDestroy(): void {
    this.isOpen = false;
    this.dropdownSubscription?.unsubscribe();
  }

  public onMouseDown(event: MouseEvent): void {
    this.isOpen = !this.isOpen;
    this.clicked = this.isOpen;

    this.toggle(event);
  }

  private toggle(event: MouseEvent): void {
    event?.stopImmediatePropagation();
    if (this.isOpen) {
      if (this.opened) {
        this.opened.emit();
      }
      const position = this.getContainerPosition();
      this.loadPortal();
      this.portalBridgeService.toggle(this.isOpen ? "open" : "close", {
        left: position.x,
        top: position.y,
      });
    }
  }

  loadPortal(): void {
    const portal = new TemplatePortal(
      this.portalContent,
      this.viewContainerRef
    );
    this.portalBridgeService.portal = portal;
  }

  private checkBoundaries(): void {
    const dropdownBoundingRect =
      this.dropdownButton.nativeElement.getBoundingClientRect();
    if (dropdownBoundingRect.right + this.width > window.innerWidth) {
      this.openDirection.horizontal = "left";
      this.openPosition = "inside";
      if (dropdownBoundingRect.top - this.width < 0) {
        this.openDirection.vertical = "down";
      }
    }
    if (dropdownBoundingRect.top + this.width > window.innerHeight) {
      this.openDirection.vertical = "up";
    }
  }

  getContainerPosition(): { x: number; y: number } {
    this.checkBoundaries();
    const dropdownBoundingRect =
      this.dropdownButton.nativeElement.getBoundingClientRect();
    let coordinates: { x: number; y: number };
    const margin = 15;

    if (this.openDirection.horizontal === "right") {
      if (this.openPosition === "outside") {
        coordinates = {
          x: dropdownBoundingRect.right,
          y:
            this.openDirection.vertical === "down"
              ? dropdownBoundingRect.top
              : dropdownBoundingRect.bottom,
        };
      } else {
        coordinates = {
          x: dropdownBoundingRect.left + dropdownBoundingRect.width,
          y:
            this.openDirection.vertical === "down"
              ? dropdownBoundingRect.top + dropdownBoundingRect.height + 20
              : dropdownBoundingRect.top,
        };
      }
    }
    if (this.openDirection.horizontal === "left") {
      if (this.openPosition === "outside") {
        coordinates = {
          x: dropdownBoundingRect.left - this.width,
          y:
            this.openDirection.vertical === "up"
              ? dropdownBoundingRect.top + 90
              : dropdownBoundingRect.bottom,
        };
      } else {
        coordinates = {
          x: dropdownBoundingRect.right - this.width - margin,
          y:
            this.openDirection.vertical === "up"
              ? dropdownBoundingRect.top
              : dropdownBoundingRect.bottom + margin,
        };
      }
    }

    return coordinates;
  }
}
