import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownItem } from "@intorqa-ui/core";
import {
  GetMetadataFieldPipe,
  MetadataFields,
  Profile,
  ProfileEditAction,
  ProfileMetadata,
  ProfileService,
  ProfileTypeMetadata,
  ProfilesNavigationItem,
  WidgetActions,
} from "@intorqa-ui/shared";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-profile-wizard-details",
  templateUrl: "./profile-wizard-details.component.html",
  styleUrls: ["./profile-wizard-details.component.scss"],
})
export class ProfileWizardDetailsComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() action: ProfileEditAction;
  @Input() navigationItem: ProfilesNavigationItem;

  readonly WidgetActions = WidgetActions;

  public priorityDataSource: Array<IDropdownItem> = [];
  public cheatStatusDataSource: Array<IDropdownItem> = [];
  private metadataSubscription: Subscription;
  public priority: ProfileTypeMetadata;
  public cheatStatus: ProfileTypeMetadata;

  constructor(
    private profileService: ProfileService,
    private getMetadataField: GetMetadataFieldPipe
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.metadataSubscription = this.profileService.metadata$.subscribe(
      (response: Array<ProfileTypeMetadata>) => {
        const metadataForm = this.form.get("metadata") as FormGroup;
        if (metadataForm) {
          this.priority = response.find((item: ProfileTypeMetadata) => {
            return item.name === MetadataFields.PRIORITY;
          });
          if (this.priority) {
            this.priorityDataSource = this.priority.values.map(
              (value: string) => ({ name: value, value })
            );
          }
          this.cheatStatus = response.find((item: ProfileTypeMetadata) => {
            return item.name === MetadataFields.CHEAT_STATUS;
          });
          if (this.cheatStatus) {
            this.cheatStatusDataSource = this.cheatStatus.values.map(
              (value: string) => ({ name: value, value })
            );
          }
        } else {
          this.form.controls.metadata = new FormGroup({});
        }
        let priority: ProfileMetadata;
        let cheatStatus: ProfileMetadata;

        if (this.profile.metadata) {
          priority = this.getMetadataField.transform(
            this.profile.metadata,
            MetadataFields.PRIORITY
          );
          cheatStatus = this.getMetadataField.transform(
            this.profile.metadata,
            MetadataFields.CHEAT_STATUS
          );
        }
        priority = priority?.values ? JSON.parse(priority?.values) : undefined;
        cheatStatus = cheatStatus?.values
          ? JSON.parse(cheatStatus?.values)
          : undefined;
        metadataForm.addControl(this.priority.id, new FormControl(priority));
        if (this.cheatStatus) {
          metadataForm.addControl(
            this.cheatStatus.id,
            new FormControl(cheatStatus)
          );
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.metadataSubscription.unsubscribe();
  }

  private createForm(): void {
    this.form.controls.details = new FormGroup({});
    (this.form.get("details") as FormGroup).addControl(
      "name",
      new FormControl(
        this.profile?.name,
        [Validators.required],
        [
          this.profileService.validateName(
            this.profile,
            this.navigationItem.linkTag
          ),
        ]
      )
    );
    (this.form.get("details") as FormGroup).addControl(
      "description",
      new FormControl(this.profile?.description)
    );
  }
}
