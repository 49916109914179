import { Tag } from "@intorqa-ui/shared";
import { Category } from "../models/category";
import { Sections, TagCategory } from "./../enums/category.enum";

export interface ICategory {
  id: string;
  name: TagCategory;
  description: string;
  section: Sections;
  order: number;
  ecosystemId: string;
}

export interface IResults {
  items: Array<Category>;
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface ITagsResults {
  items: Array<Tag>;
  page: number;
  pageSize: number;
  totalCount: number;
}
