import { TagCategory } from "@intorqa-ui/core";
import { IDisplayType } from "../interfaces/widget/widget.interface";
import { FilterType } from "../enums/tag.enum";

export const TagTypes = [
  "User",
  "Global",
  "Shared",
  "Document Attributes",
  "Collection Source",
  "System Tag",
  "User Tag",
  "My Tags",
  "Bookmarks",
];
export const FilterTypes = [
  "Actor",
  "Channel",
  "Source",
  "EmitType",
  "Post Type",
  "Community",
];

export const TagFilters: Array<IDisplayType> = [
  {
    id: FilterType.ALL,
    tooltip: TagCategory.ALL,
  },
  {
    id: FilterType.USER,
    tooltip: "My tags",
  },
  {
    id: FilterType.SHARED,
    tooltip: TagCategory.Shared,
  },
  {
    id: FilterType.COMMUNITIES,
    tooltip: "System tag",
  },
  {
    id: FilterType.ALERTS,
    tooltip: "Tags with alerts",
  },
];
