import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DomainDetailsNote, Profile } from "@intorqa-ui/shared";

@Component({
  selector: "itq-view-domain-details",
  templateUrl: "./view-domain-details.component.html",
  styleUrls: ["./view-domain-details.component.scss"],
})
export class ViewDomainDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: DomainDetailsNote;

  constructor() {}

  ngOnInit() {}
}
