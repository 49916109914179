import { EChartsOption } from "echarts";
import { ChartType, PieMode, WidgetActions } from "../../enums/widget.enum";
import {
  IDataPoint,
  IDisplayType,
  ISerie,
} from "../../interfaces/widget/widget.interface";
import { Widget } from "../widgets/widget";
import { Chart } from "./chart";

export class DoughnutChart extends Chart {
  constructor(public name: ChartType) {
    super(name);
  }

  public getOptions(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any }
  ): EChartsOption {
    return {
      tooltip: {
        trigger: "item",
        extraCssText: "overflow: auto; max-height: 250px; border: 0;",
        enterable: true,
      },
      legend: {
        type: "scroll",
        icon: "rect",
        top:
          settings?.width === 1 ||
          settings?.widgetAction !== WidgetActions.RENDER
            ? "top"
            : "center",
        orient:
          settings?.width === 1 ||
          settings?.widgetAction !== WidgetActions.RENDER
            ? "horizontal"
            : "vertical",
        left:
          settings?.width === 1 ||
          settings?.widgetAction !== WidgetActions.RENDER
            ? "center"
            : "left",
        formatter: (value: string) => {
          return value.trim().replace("\n", "").length > 30
            ? value.trim().replace("\n", "").substring(0, 30) + "..."
            : value.trim().replace("\n", "");
        },
      },
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      series: data.map((serie: ISerie, index: number) => {
        return {
          name: data[0].name,
          type: "pie",
          label: {
            show: true,
            position: "inner",
            formatter: (params: any) => {
              return params.value === 0 ? "" : params.value;
            },
          },
          data: serie.data.map((item: IDataPoint) => {
            return this.getDataPoint(item, segment, serie.tagId, index);
          }),
          center: settings?.width === 1 ? ["50%", "50%"] : ["50%", "50%"],
          radius:
            settings?.widgetAction === WidgetActions.EXPLORE
              ? ["50%", "35%"]
              : settings?.widgetAction === WidgetActions.CLONE ||
                settings?.widgetAction === WidgetActions.SETTINGS
              ? ["60%", "45%"]
              : settings?.width === 1
              ? ["75%", "50%"]
              : ["70%", "45%"],

          avoidLabelOverlap: true,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        };
      }),
    };
  }

  public getDisplayTypes(
    displayTypes: Array<IDisplayType>,
    widget: Widget
  ): Array<IDisplayType> {
    const selectedDisplay = this.getSelectedType(displayTypes, widget);
    const types = displayTypes?.filter((item: IDisplayType) => {
      return item.options?.mode !== selectedDisplay.options.mode;
    });

    return types;
  }

  public getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: Widget
  ): IDisplayType {
    return displayTypes.find((item: IDisplayType) => {
      return (
        item.type === ChartType.PIE && item.options?.mode === PieMode.DOUGHTNUT
      );
    });
  }
}
