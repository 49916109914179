import { Routes } from "@angular/router";
import { ErrorComponent, ErrorResolver } from "@intorqa-ui/core";
import { ProfilesResolver } from "./modules/profiles/profiles.resolver";
import { AuthGuardService } from "@intorqa-ui/shared";
import { PageDocumentComponent } from "./modules/document/page-document.component";
import { NotificationsResolver } from "./modules/notifications/notifications.resolver";

export const APP_ROUTES: Routes = [
  { path: "", redirectTo: "boards", pathMatch: "full" },
  {
    path: "boards",
    resolve: {
      error: ErrorResolver,
      loadNotificationsCount: NotificationsResolver,
    },
    loadChildren: () =>
      import("./modules/boards/boards.module").then((m) => m.BoardsModule),
  },
  {
    path: "document/:id",
    resolve: {
      error: ErrorResolver,
      loadNotificationsCount: NotificationsResolver,
    },
    component: PageDocumentComponent,
  },
  {
    path: "tags",
    resolve: {
      error: ErrorResolver,
      loadNotificationsCount: NotificationsResolver,
    },
    loadChildren: () =>
      import("./modules/tags/tags.module").then((m) => m.TagsModule),
  },
  {
    path: "profiles",
    resolve: {
      error: ErrorResolver,
      loadPreferences: ProfilesResolver,
      loadNotificationsCount: NotificationsResolver,
    },
    canActivate: [AuthGuardService],
    data: { roles: ["saas-profiles"] },
    loadChildren: () =>
      import("./modules/profiles/profiles.module").then(
        (m) => m.ProfilesModule
      ),
  },
  {
    path: "**",
    resolve: {
      error: ErrorResolver,
      loadNotificationsCount: NotificationsResolver,
    },
    component: ErrorComponent,
  },
];
