import { TagCategory } from "@intorqa-ui/core";
import { ChartType } from "../enums/widget.enum";
import { IDisplayType } from "../interfaces/widget/widget.interface";

export const TimelineFeedDisplayTypes: Array<IDisplayType> = [
  {
    id: "Timeline",
    type: ChartType.TIMELINE,
    svgIcon: "board",
    tooltip: "Timeline",
  },
  {
    id: TagCategory.Actor,
    type: ChartType.TABLE,
    icon: ["far", "user"],
    tooltip: "Top Actors",
  },
  {
    id: TagCategory.Channel,
    type: ChartType.TABLE,
    icon: ["far", "hashtag"],
    tooltip: "Top Channels",
  },
  {
    id: TagCategory.Source,
    type: ChartType.TABLE,
    icon: ["far", "tower-broadcast"],
    tooltip: "Sources",
  },
  {
    id: TagCategory.Community,
    type: ChartType.TABLE,
    icon: ["far", "users"],
    tooltip: "Communities",
  },
];
