import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Category, QueryFilters, TagCategory } from "@intorqa-ui/core";
import {
  CategoryService,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  ICustomTag,
  ITagMetadata,
  Query,
  QueryRule,
  TagComparison,
  TagService,
  TimeSeries,
  Timeline,
  WidgetActions,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-specific-tag-datasource",
  templateUrl: "./specific-tag-datasource.component.html",
  styleUrls: ["./specific-tag-datasource.component.scss"],
})
export class SpecificTagDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() widget: TimeSeries | TagComparison;
  @Input() initialState: QueryFilters;
  @Input() ecosystemId: string;
  @Input() action: WidgetActions;

  @Output() changeDataValue = new EventEmitter<Array<string>>();
  @Output() changeDataSource = new EventEmitter<Array<string>>();
  @Output() changeDataType = new EventEmitter<string>();
  @Output() showLoader = new EventEmitter<boolean>();

  public dataPointsDataSource: Array<ICustomTag>;
  public tagsDataSource: Array<ICustomTag> = [];
  public categoriesDataSource: Array<Category>;
  public dataValues: Array<any> = [];
  public tag: Timeline;

  readonly TagCategory = TagCategory;

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.showLoader.emit(true);
    let promises: Array<Promise<unknown>> = [];
    promises.push(
      this.onGetTags(this.initialState, TagCategory["My Tags"]).then(
        (response: Array<ICustomTag>) => {
          this.tagsDataSource = response;
          this.form.patchValue({
            dataSource: this.widget.dataSource[0],
          });
          this.showLoader.emit(false);
        }
      )
    );
    if (
      this.action === WidgetActions.SETTINGS ||
      this.action === WidgetActions.CLONE
    ) {
      let tagIds = this.widget.getTagIds();
      let fieldFilterIds = this.widget.getFieldFilterTagIds();
      promises.push(
        this.tagService
          .getSelections(
            tagIds,
            fieldFilterIds,
            undefined,
            this.categoryService.categories
          )
          .then((response: Array<ITagMetadata>) => {
            this.dataValues = response.map((item: ITagMetadata) => ({
              name: item.tagName,
              id: item.tagId,
            }));
            promises.push(this.getCategories());
            promises.push(
              this.onGetTags(this.initialState, this.widget.dataType).then(
                (response: Array<ICustomTag>) => {
                  this.dataPointsDataSource = response;
                  if (this.categoriesDataSource?.length > 0) {
                    this.form.controls.dataPoints.enable();
                  }
                  if (this.widget.dataValues?.length > 0) {
                    this.form.patchValue({
                      dataPoints: this.dataValues || [],
                    });
                  }
                  const tagId = this.widget.dataValues[0];
                  if (
                    this.action === WidgetActions.SETTINGS ||
                    this.action === WidgetActions.CLONE
                  ) {
                    this.tagService.getTagById(tagId).then((tag: Timeline) => {
                      this.tag = tag;
                    });
                  }
                }
              )
            );
          })
      );
    }
    Promise.all(promises).then(() => {
      this.showLoader.emit(false);
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl("dataSource");
    this.form.removeControl("dataType");
    this.form.removeControl("dataPoints");
  }

  public onDataBoundDataSource(params: QueryFilters): void {
    this.onGetTags(params, TagCategory["My Tags"]).then(
      (response: Array<ICustomTag>) => {
        this.tagsDataSource = response;
      }
    );
  }

  public onDataBoundDataValues(params: QueryFilters): void {
    this.initialState.page = params.page;
    this.initialState.pageSize = params.pageSize;
    this.initialState.query = params.query;
    this.onGetTags(this.initialState, this.form.controls.category.value).then(
      (response: Array<ICustomTag>) => {
        if (this.initialState?.page === 1) {
          this.dataPointsDataSource = response;
        } else {
          this.dataPointsDataSource = [
            ...this.dataPointsDataSource,
            ...response,
          ];
        }
      }
    );
  }

  public onGetTags(
    params: QueryFilters,
    category: TagCategory
  ): Promise<Array<ICustomTag>> {
    const queryModel = this.getQueryModel();
    return new Promise((resolve) => {
      this.categoryService
        .getTags(
          params?.query,
          params,
          queryModel.modelToDTO(),
          category,
          this.ecosystemId,
          params.page > 1
            ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1]
                .name
            : undefined
        )
        .then((response: Array<ICustomTag>) => {
          resolve(response);
        });
    });
  }

  private getCategories(): Promise<Array<Category>> {
    return new Promise((resolve) => {
      this.categoryService
        .getCategories(this.ecosystemId)
        .then((response: Array<Category>) => {
          this.categoriesDataSource = response;
          this.form.controls.category.setValue(this.widget.dataType);
          if (this.dataPointsDataSource?.length > 0) {
            this.form.controls.dataPoints.enable();
          }
          resolve(response);
        });
    });
  }

  private createForm(): void {
    this.form.addControl(
      "dataSource",
      new FormControl(undefined, Validators.required)
    );
    this.form.addControl(
      "category",
      new FormControl(
        {
          value: undefined,
          disabled: this.widget?.dataSource?.length === 0 ? true : false,
        },
        Validators.required
      )
    );
    this.form.addControl(
      "dataPoints",
      new FormControl(
        {
          value: [],
          disabled: this.widget.dataType ? false : true,
        },
        Validators.required
      )
    );
  }

  public onChangeDataSources(): void {
    this.widget.dataSource = [this.form.controls.dataSource.value?.id];
    this.form.controls.dataPoints.setValue([]);
    this.form.controls.dataPoints.disable();
    this.widget.dataType = undefined;
    this.dataPointsDataSource = [];
    this.form.controls.category.setValue(undefined);
    this.getCategories().then((response: Array<Category>) => {
      this.populateDataType(response);
      this.showLoader.emit(false);
    });
    this.changeDataSource.emit(this.widget.dataSource);
  }

  public onDataBound(): void {
    this.changeDataValue.emit(
      this.form.controls.dataPoints.value.map((item: ICustomTag) => item.id)
    );
  }

  public onChangeDataType(): void {
    const dataType = this.form.controls.category.value;
    this.changeDataType.emit(dataType);
    this.form.controls.dataPoints.setValue(this.widget.dataValues);
    this.widget.dataType = dataType;
    this.showLoader.emit(true);
    this.onGetTags(this.initialState, this.form.controls.category.value).then(
      (response: Array<ICustomTag>) => {
        this.dataPointsDataSource = response;
        if (this.categoriesDataSource?.length > 0) {
          this.form.controls.dataPoints.enable();
        }
        if (this.widget.dataValues?.length > 0) {
          this.form.patchValue({
            dataPoints: this.widget.dataValues,
          });
        }
        this.showLoader.emit(false);
      }
    );
  }

  private getQueryModel(): Query {
    const queryModel = new Query();
    if (this.tagsDataSource?.length > 0) {
      this.widget?.dataSource.forEach((item: string) => {
        queryModel.addRule(
          new QueryRule(DTOQueryFieldType.tag, DTOQueryConditionOperator.in, [
            item,
          ])
        );
      });
    }
    return queryModel;
  }

  private populateDataType(response: Array<Category>): void {
    this.categoriesDataSource = response;

    if (this.tagsDataSource.length > 0) {
      this.form.controls.category.enable();
    }
    if (this.widget.dataType) {
      this.form.patchValue({
        category: this.widget.dataType,
      });
    } else {
      this.form.patchValue({
        category: undefined,
      });
    }
  }
}
