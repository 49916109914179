import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FoamTree } from "@carrotsearch/foamtree";
import {
  CustomOverlayService,
  CustomOverlayType,
  IRiplResults,
  IconType,
  QueryFilters,
  TableColumn,
  TagCategory,
  ViewMode,
  VirtualScrollService,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  ChartType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DocumentItem,
  IAddConnection,
  IAddNote,
  IData,
  IDataPoint,
  ISegment,
  IWidgetData,
  LinkTag,
  NavigationHistoryItem,
  Profile,
  ProfileDrildownScope,
  ProfileDrilldown,
  ProfilesNavigationItem,
  Query,
  SegmentScope,
  TagAnalysis,
  Timeline,
  TimelineStatus,
  Widget,
  WidgetActions,
  WidgetService,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { AddConnectionsComponent } from "../add-connections/add-connections.component";
import { PostNotesWizardComponent } from "../post-notes-wizard/post-notes-wizard.component";
import { ShareEmailComponent } from "../share-email/share-email.component";

@Component({
  selector: "itq-search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
})
export class SearchResultsComponent implements OnInit {
  @Input() viewMode: ViewMode;
  @Input() widgetData: IWidgetData;
  @Input() timelineData: IData;
  @Input() foamtreeData: IRiplResults;
  @Input() initialState: QueryFilters;
  @Input() query: Query;
  @Input() tagAnalysisWidget: TagAnalysis;
  @Input() widget: Timeline;
  @Input() segment: any;
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() allowScrollingUp: boolean;

  @Output() drilldownFoamtree = new EventEmitter<any>();
  @Output() drilldown = new EventEmitter<{ segment: any; widget: Widget }>();
  @Output() updateFoamtreeRef = new EventEmitter<ElementRef>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  @ViewChild("template")
  template: TemplateRef<unknown>;

  @ViewChild("countTemplate") countTemplate: TemplateRef<unknown>;

  public foamtree: FoamTree;
  public widgetActions = [WidgetActions.EXPORT];
  public tableColumns: TableColumn[] = [];

  readonly WidgetActions = WidgetActions;
  readonly TimelineStatus = TimelineStatus;
  readonly ViewMode = ViewMode;
  readonly ChartType = ChartType;

  constructor(
    public widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    readonly virtualScrollService: VirtualScrollService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.widgetData?.previousValue !== changes?.widgetData?.currentValue
    ) {
      this.tableColumns = this.tagAnalysisWidget.getTableColumns(
        this.countTemplate
      );
    }

    if (
      changes?.timelineData?.previousValue !==
      changes?.timelineData?.currentValue
    ) {
      this.tableColumns = this.widget.getTableColumns(this.template);
    }
  }

  onDrilldownFoamtree(cluster: any): void {
    this.drilldownFoamtree.emit(cluster);
  }

  onUpdateRef(foamtreeRef: ElementRef): void {
    this.updateFoamtreeRef.emit(foamtreeRef);
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.openArticleDetail(segment);
        break;
      default:
        break;
    }
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "hashtag",
      IconType.FONT_AWESOME,
      this.navigationItem.selectedDisplayType,
      undefined,
      this.navigationItem.boardId
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "user",
      IconType.FONT_AWESOME,
      this.navigationItem.selectedDisplayType,
      undefined,
      this.navigationItem.boardId
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private openArticleDetail(segment: ISegment): void {
    this.widgetService.updateSegmentObservable.next(segment);
  }

  public onShare(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        item: document,
      },
      type: CustomOverlayType["slide-right"],
      component: ShareEmailComponent,
      disposeOnNavigation: true,
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.scope === ProfileDrildownScope.TAG
        ? this.widget.name
        : profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      this.widget.ecosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "plus",
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory["My Tags"]
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.Actor
          : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`
      ),
      undefined,
      undefined,
      0
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onLoadCount(row: IDataPoint): void {
    const timeline = new Timeline(
      this.widget.widgetId,
      this.widget.username,
      AnalysisTypes.TIMELINE,
      `${this.widget.name}: ${row.category}`,
      undefined,
      ChartType.TIMELINE,
      this.tagAnalysisWidget.dataSource[0],
      undefined,
      undefined,
      undefined,
      this.widget.categoryId,
      this.widget.lastTaggingTime,
      this.widget.ecosystemId,
      this.widget.updatedDate,
      this.widget.alertTypeId
    );
    const navigationItem = new NavigationHistoryItem(
      `${timeline.widgetId}_${WidgetActions.DRILLDOWN}_${row.tagId}`,
      cloneDeep(timeline),
      WidgetActions.DRILLDOWN,
      [
        {
          field:
            row.tagId.search("Field Filter") > -1
              ? DTOQueryFieldType.filter
              : DTOQueryFieldType.tag,
          operator: DTOQueryConditionOperator.in,
          value: [row.tagId],
        },
        {
          field: DTOQueryFieldType.tag,
          operator: DTOQueryConditionOperator.in,
          value: [timeline.tagId],
        },
      ],
      this.initialState,
      new FormGroup({}),
      "search",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      this.navigationItem.boardId
    );

    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onAddConnection(params: IAddConnection): void {
    this.customOverlayService.open({
      data: params,
      type: CustomOverlayType["slide-right"],
      component: AddConnectionsComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddNote(params: IAddNote): void {
    this.customOverlayService.open({
      data: params,
      size: "lg",
      type: CustomOverlayType["slide-right"],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });
  }

  public onTimelineScroll(params: QueryFilters): void {
    this.virtualScrollService.scroll$.next({
      query: params,
      direction: "down",
    });
  }
}
