import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { cloneDeep } from "lodash";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { QueryFilters, Sizes, TagCategory } from "@intorqa-ui/core";
import {
  BarMode,
  ChartOrientation,
  ChartType,
  DateInterval,
  IDisplayType,
  IWidget,
  LineMode,
  PieMode,
  Tag,
  TimeSeries,
  TimeSeriesDisplayTypes,
  Widget,
  WidgetActions,
  WidgetIntervals,
  WidgetOption,
  WidgetService,
} from "@intorqa-ui/shared";
import { DataSourceType } from "../../../../../../../../../../shared/src/lib/enums/widget-settings.enum";

@Component({
  selector: "itq-ws-time-series",
  templateUrl: "./ws-time-series.component.html",
  styleUrls: ["./ws-time-series.component.scss"],
})
export class WSTimeSeriesComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.timeSeriesWidget = widget as TimeSeries;
    if (!this.timeSeriesWidget.dataSource) {
      this.timeSeriesWidget.dataSource = [];
    }
    if (!this.timeSeriesWidget.dataType) {
      this.timeSeriesWidget.dataType = TagCategory["My Tags"];
    }
  }
  @Input() form: FormGroup;
  @Input() action: WidgetActions;
  @Input() initialState: QueryFilters;
  @Input() ecosystemId: string;

  @Output() update = new EventEmitter<IWidget>();
  @Output() showLoader = new EventEmitter<boolean>();

  public dataPointsDataSource: Array<Tag>;
  public options: echarts.EChartsOption;
  public timeSeriesWidget: TimeSeries;
  public intervalDataSource: Array<{ name: string; value: DateInterval }>;
  public displayTypes = TimeSeriesDisplayTypes;

  readonly DataSourceType = DataSourceType;
  readonly TagCategory = TagCategory;
  readonly BarMode = BarMode;
  readonly Sizes = Sizes;
  readonly ChartType = ChartType;
  readonly ChartOrientation = ChartOrientation;
  readonly LineMode = LineMode;
  readonly WidgetOption = WidgetOption;
  readonly PieMode = PieMode;
  readonly WidgetActions = WidgetActions;

  constructor(public widgetService: WidgetService) {
    this.intervalDataSource = WidgetIntervals;
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.removeControl("typeDataSource");
    this.form.removeControl("searchType");
    this.form.removeControl("interval");
  }

  private createForm(): void {
    this.form.addControl(
      "typeDataSource",
      new FormControl(
        this.timeSeriesWidget.dataSource.length === 0
          ? DataSourceType.ALL
          : DataSourceType.SET,
        Validators.required
      )
    );

    this.form.addControl(
      "interval",
      new FormControl(
        this.timeSeriesWidget.interval || DateInterval.DAY,
        Validators.required
      )
    );
    this.form.addControl(
      "searchType",
      new FormControl(this.displayTypes[0].id, Validators.required)
    );
  }

  public onDataBound(params: { prop: string; value: any }): void {
    this.timeSeriesWidget[params.prop] = params.value;
    this.update.emit(cloneDeep(this.timeSeriesWidget));
  }

  public onChangeDataValues(value: Array<string>): void {
    this.onDataBound({
      prop: "dataValues",
      value,
    });
  }

  public onChangeDataType(value: string): void {
    this.timeSeriesWidget.dataValues = [];
    this.onDataBound({
      prop: "dataType",
      value,
    });
  }

  public onChangeDataSources(value: Array<string>): void {
    this.timeSeriesWidget.dataValues = [];
    this.timeSeriesWidget.dataType = undefined;
    this.onDataBound({
      prop: "dataSource",
      value,
    });
  }

  public onChangeInterval(): void {
    this.onDataBound({
      prop: "interval",
      value: this.form.controls.interval.value,
    });
  }

  public onChangeDataSourceType(event: MatRadioChange): void {
    if (event.value === DataSourceType.ALL) {
      this.timeSeriesWidget.dataSource = [];
      this.timeSeriesWidget.dataType = TagCategory["My Tags"];
      this.timeSeriesWidget.dataValues = [];
    } else {
      this.timeSeriesWidget.dataType = undefined;
      this.timeSeriesWidget.dataValues = [];
    }
    this.update.emit(cloneDeep(this.timeSeriesWidget));
  }

  public onChangeDisplayType(params: IDisplayType): void {
    this.timeSeriesWidget.chartType = params.type;
    this.timeSeriesWidget.options = params.options;
    this.update.emit(cloneDeep(this.timeSeriesWidget));
  }

  public getSelectedType(): IDisplayType {
    return this.timeSeriesWidget.getSelectedType();
  }

  public onShowLoader(showLoader: boolean): void {
    this.showLoader.emit(showLoader);
  }
}
