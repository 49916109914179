import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
} from "@intorqa-ui/core";
import {
  ConnectionsService,
  ISegment,
  NavigationHistoryItem,
  ProfileNote,
  WidgetActions,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-post-notes",
  templateUrl: "./post-notes.component.html",
  styleUrls: ["./post-notes.component.scss"],
})
export class PostNotesComponent implements OnInit {
  @Input() segment: ISegment;
  @Input() note: ProfileNote;
  @Input() navigationItem: NavigationHistoryItem;

  readonly WidgetActions = WidgetActions;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    readonly connectionsService: ConnectionsService,
    readonly customOverlayRef: CustomOverlayRef
  ) {}

  ngOnInit() {}
}
