import { ChartType } from "../enums/widget.enum";
import { DateInterval } from "./../enums/widget.enum";

export const ColorPalette = [
  "#6ca3ef",
  "#f0a1f7",
  "#ef9881",
  "#fcaa73",
  "#ffd470",
  "#a79d8b",
  "#08d9cb",
  "#da6cb4",
  "#99e3ea",
];

export const WIDGET_DIMENSIONS = {
  maxWidth: 2,
  maxHeight: 2,
  minWidth: 1,
  minHeight: 1,
  width: 275,
  height: 275,
  gutter: 10,
  maxWidthPx: 1130,
  maxHeightPx: 845,
};

export const TopOptions: Array<{ name: string; value: number }> = [
  {
    name: "5",
    value: 5,
  },
  {
    name: "10",
    value: 10,
  },
  {
    name: "15",
    value: 15,
  },
  {
    name: "25",
    value: 25,
  },
  {
    name: "50",
    value: 50,
  },
];

export const WidgetIntervals: Array<{ name: string; value: DateInterval }> = [
  {
    name: "Day",
    value: DateInterval.DAY,
  },
  {
    name: "Week",
    value: DateInterval.WEEK,
  },
  {
    name: "Month",
    value: DateInterval.MONTH,
  },
  {
    name: "Quarter",
    value: DateInterval.QUARTER,
  },
  {
    name: "Year",
    value: DateInterval.YEAR,
  },
];

export const ChartTypes = {
  ECHARTS: [ChartType.BAR, ChartType.LINE, ChartType.PIE, ChartType.NETWORK],
  COMPONENT: [ChartType.TABLE, ChartType.FOAMTREE, ChartType.TIMELINE],
};
