import { Pipe, PipeTransform } from "@angular/core";
import { INode } from "../interfaces/accordion";
import { ListItem } from "../models/list-item";
@Pipe({
  name: "getChildren",
})
export class GetChildrenPipe implements PipeTransform {
  constructor() {}

  transform(data: INode): Array<ListItem> {
    return data.children.map((item: INode) => new ListItem(item.id, item.name));
  }
}
