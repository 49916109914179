import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { QueryFilters } from "@intorqa-ui/core";
import { NotificationsService } from "@intorqa-ui/shared";

@Injectable({
  providedIn: "root",
})
export class NotificationsResolver implements Resolve<void> {
  constructor(readonly notificationsService: NotificationsService) {}

  resolve(): void {
    this.notificationsService
      .getUnreadNotificationsCount(
        new QueryFilters(30, 1, undefined, undefined, undefined)
      )
      .subscribe();
  }
}
