import { Type } from "@angular/core";
import { AnalysisTypes, IWidget } from "@intorqa-ui/shared";
import { WSTagAnalysisComponent } from "./modules/widget-settings-chart/chart-wizard/components/ws-tag-analysis/ws-tag-analysis.component";
import { WSTagComparisonComponent } from "./modules/widget-settings-chart/chart-wizard/components/ws-tag-comparison/ws-tag-comparison.component";
import { WSTimeSeriesComponent } from "./modules/widget-settings-chart/chart-wizard/components/ws-time-series/ws-time-series.component";

export class WidgetSettingsFactory {
  static getComponent(
    widget: IWidget
  ): Type<
    WSTimeSeriesComponent | WSTagAnalysisComponent | WSTagComparisonComponent
  > {
    if (widget.type === AnalysisTypes.TAG_ANALYSIS) {
      return WSTagAnalysisComponent;
    } else if (widget.type === AnalysisTypes.TAG_COMPARISON) {
      return WSTagComparisonComponent;
    } else if (widget.type === AnalysisTypes.TIME_SERIES) {
      return WSTimeSeriesComponent;
    }
  }
}
