import { FormGroup } from "@angular/forms";
import { IconType, QueryFilters } from "@intorqa-ui/core";
import {
  IDisplayType,
  ISegment,
  QueryRule,
  Widget,
  WidgetActions,
} from "@intorqa-ui/shared";

export interface INavigationHistoryItem {
  id: string;
  item: Widget;
  action: WidgetActions;
  rules: Array<QueryRule>;
  initialState: QueryFilters;
  form: FormGroup;
  icon: string;
  iconType: IconType;
  selectedDisplayType: IDisplayType;
  segment: ISegment;
  boardId: string;
}
