import {
  Component,
  ComponentRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Sizes } from "../../enums/shared.enum";
import { InputComponent } from "../input/input.component";

@Component({
  selector: "itq-input-toggle",
  templateUrl: "./input-toggle.component.html",
  styleUrls: ["./input-toggle.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputToggleComponent),
    },
  ],
})
export class InputToggleComponent implements OnInit {
  @Input() expanded = false;
  @Input() disabled: boolean;
  @Input() submit: boolean;
  @Input() padding: Sizes;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() autoFocus: boolean;
  @Input() showIcon = true;

  @Output() search = new EventEmitter<void>();
  @Output() toggle = new EventEmitter<void>();

  @ViewChild("input") input: ComponentRef<InputComponent>;

  public searchTerm = "";

  constructor() {}

  @HostListener("document:click", ["$event"])
  click(): void {
    if (this.expanded) {
      this.expanded = this.searchTerm && this.searchTerm !== "" ? true : false;
    }
  }

  ngOnInit() {}

  public onToggle(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.expanded = true;
    this.toggle.emit();
  }

  onChange = (text: string) => {};

  onTouched = () => {};

  writeValue(value: string): void {
    this.searchTerm = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public onModelChange(value: string): void {
    this.onChange(value);
  }

  public onSearch(): void {
    this.search.emit();
  }
}
