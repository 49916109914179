import { Component, OnInit } from "@angular/core";

@Component({
  selector: "itq-toolbar-action",
  templateUrl: "./toolbar-action.component.html",
  styleUrls: ["./toolbar-action.component.scss"],
})
export class ToolbarActionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
