export enum ChartType {
  BAR = "BAR",
  LINE = "LINE",
  PIE = "PIE",
  TABLE = "TABLE",
  FOAMTREE = "FOAMTREE",
  TIMELINE = "TIMELINE",
  PROFILE = "PROFILE",
  NETWORK = "NETWORK",
  NOTIFICATION = "NOTIFICATION",
}

export enum ChartOrientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export enum WidgetActions {
  CREATE = "create",
  EXPLORE = "explore",
  SETTINGS = "settings",
  DELETE = "delete",
  EXPORT = "export",
  DRILLDOWN = "drilldown",
  CLONE = "clone",
  RENDER = "render",
  REFRESH = "refresh",
}

export enum AnalysisTypes {
  TAG_ANALYSIS = "TAG_ANALYSIS",
  TIME_SERIES = "TIME_SERIES",
  TAG_COMPARISON = "TAG_COMPARISON",
  TIMELINE = "TIMELINE",
  PROFILE = "PROFILE",
  NOTIFICATION = "NOTIFICATION",
}

export enum AnalysisDataPointsType {
  TOP_CHANNELS = "TOP_CHANNELS",
  TOP_ACTORS = "TOP_ACTORS",
  SOURCES = "SOURCES",
  COMMUNITIES = "COMMUNITIES",
}

export enum WidgetOption {
  ORIENTATION = "orientation",
  MODE = "mode",
  X_AXIS = "xAxis",
  Y_AXIS = "yAxis",
  DATA_ZOOM = "dataZoom",
  SERIES = "series",
  TOOLTIP = "tooltip",
}

export enum PieMode {
  PIE = "pie",
  DOUGHTNUT = "doughtnut",
}

export enum LineMode {
  LINE = "line",
  AREA = "area",
}

export enum BarMode {
  STACK = "stack",
  GROUP = "group",
}

export enum DateInterval {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}
