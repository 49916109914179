import { Pipe, PipeTransform } from "@angular/core";
import { IDropdownItem } from "./dropdown.interface";

@Pipe({
  name: "setActiveItemClass",
})
export class SetActiveItemClassPipe implements PipeTransform {
  transform(
    selection: string,
    item: string
  ): {
    [klass: string]: any;
  } {
    return {
      active: selection === item ? true : false,
    };
  }
}

@Pipe({
  name: "getLabel",
})
export class GetLabelPipe implements PipeTransform {
  transform(
    data: Array<any>,
    dataFields: IDropdownItem,
    selection: string,
    placeholder: string
  ): string {
    if (!selection || data?.length === 0) return placeholder;
    const dataItem = data?.find(
      (item: any) => item[dataFields?.value] === selection
    );
    return (dataItem && dataItem[dataFields.name]) || placeholder;
  }
}
