import { ElementRef } from "@angular/core";
import { WIDGET_DIMENSIONS } from "../../const/widget.const";
import { IResizer } from "../../interfaces/widget/resizer.interface";
import { Placeholder } from "./placeholder";

export class Resizer implements IResizer {
  private pageX: number;
  private pageY: number;

  constructor(
    public width: number,
    public height: number,
    public placeholder: Placeholder
  ) {}

  public resizeStart(event: MouseEvent): void {
    this.pageX = event.pageX;
    this.pageY = event.pageY;
  }

  public resize(
    widget: ElementRef<any>,
    event: MouseEvent
  ): { width: number; height: number } {
    const result = { width: undefined, height: undefined };
    const containerRect = widget.nativeElement.getBoundingClientRect();
    const offsetX = event.clientX - containerRect.right;
    const offsetY = event.clientY - containerRect.bottom;
    const newWidth = this.width + offsetX;
    const newHeight = this.height + offsetY;

    if (newWidth > WIDGET_DIMENSIONS.width) {
      result.width = newWidth;
      this.width = newWidth;
    }
    if (newHeight > WIDGET_DIMENSIONS.height) {
      result.height = newHeight;
      this.height = newHeight;
    }
    const dashboardElement = document
      .querySelector(".dashboard__container")
      .getBoundingClientRect();
    if (event.pageX >= this.pageX) {
      if (event.x < dashboardElement.right) {
        if (
          this.width >
          this.placeholder.width.end + WIDGET_DIMENSIONS.width * 0.5
        ) {
          this.placeholder.increaseWidth();
        }
      }
    } else {
      if (
        this.width <
        this.placeholder.width.end - WIDGET_DIMENSIONS.width / 2
      ) {
        this.placeholder.descreaseWidth();
      }
    }
    if (event.pageY >= this.pageY) {
      if (event.y < dashboardElement.right) {
        if (
          this.height >
          this.placeholder.height.end + WIDGET_DIMENSIONS.height * 0.5
        ) {
          this.placeholder.increaseHeight();
        }
      }
    } else {
      if (
        this.height <
        this.placeholder.height.end - WIDGET_DIMENSIONS.height / 2
      ) {
        this.placeholder.decreaseHeight();
      }
    }
    this.pageX = event.pageX;
    this.pageY = event.pageY;
    return result;
  }
}
