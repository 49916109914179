import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { CurrencyService } from "@intorqa-ui/core";
import { UserService } from "@intorqa-ui/shared";

@Injectable({
  providedIn: "root",
})
export class ProfilesResolver implements Resolve<void> {
  constructor(
    private userService: UserService,
    private currencyService: CurrencyService
  ) {}

  resolve(): void {
    this.userService.getUserPreferences().subscribe();
    this.currencyService.getExchangeRates().subscribe();
  }
}
