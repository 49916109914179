import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Align,
  IDropdownItem,
  IconType,
  QueryFilters,
  Sizes,
  TagCategory,
} from "@intorqa-ui/core";
import {
  CategoryService,
  ICustomTag,
  ILinksData,
  LinkTag,
  LinkTagsService,
  NavigationHistoryItem,
  Profile,
  Query,
  Utils,
  WidgetActions,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";

@Component({
  selector: "itq-link-tags",
  templateUrl: "./link-tags.component.html",
  styleUrls: ["./link-tags.component.scss"],
})
export class LinkTagsComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() action = WidgetActions.SETTINGS;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() link: LinkTag;
  @Input() showTitle = true;

  @Output() close = new EventEmitter<void>();

  public linkTypeDataSource: Array<IDropdownItem> = [
    { name: TagCategory["My Tags"], value: TagCategory["My Tags"] },
    { name: TagCategory.Actor, value: TagCategory.Actor },
    { name: TagCategory.Channel, value: TagCategory.Channel },
  ];
  public tagsDataSource: Array<ICustomTag>;
  private links: ILinksData;

  readonly WidgetActions = WidgetActions;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly IconType = IconType;

  constructor(
    private categoryService: CategoryService,
    private linkTagsService: LinkTagsService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.links = cloneDeep(this.linkTagsService.links);
  }

  ngOnDestroy(): void {
    this.form.removeControl("addTags");
  }

  private createForm(): void {
    this.form.addControl(
      "addTags",
      new FormGroup({
        linkType: new FormControl(this.link?.type, [Validators.required]),
        tag: new FormControl(
          {
            value: { name: this.link?.tagName, value: this.link?.tagName },
            disabled: this.link?.tagName ? false : true,
          },
          [Validators.required]
        ),
        description: new FormControl(this.link?.description),
        default: new FormControl({
          value: this.link?.isDefault,
          disabled:
            this.linkTagsService.links?.items?.length === 0
              ? true
              : this.action === WidgetActions.SETTINGS && this.link?.isDefault
              ? true
              : false,
        }),
      })
    );
  }

  public onGetTags(params: QueryFilters): void {
    const queryModel = new Query();
    this.categoryService
      .getTags(
        params.query,
        params,
        queryModel.modelToDTO(),
        this.form.get("addTags.linkType").value,
        this.profile.ecosystemId,
        params.page > 1
          ? this.tagsDataSource[this.tagsDataSource.length - 1].name
          : undefined
      )
      .then((response: Array<ICustomTag>) => {
        if (params.page === 1) {
          this.tagsDataSource = response;
        } else {
          this.tagsDataSource = [...this.tagsDataSource, ...response];
        }
      });
  }

  public onChangeValue(tag: ICustomTag): void {
    this.link.tagName = tag.name;
    if (this.link.type === TagCategory["My Tags"]) {
      this.link.tagId = tag.id;
    }
  }

  public onChangeLinkType(): void {
    this.form.get("addTags.tag").enable();
    this.form.get("addTags.tag").setValue(undefined);
    this.form.get("addTags.description").setValue(undefined);
    this.link.type = this.form.get("addTags.linkType").value;
  }

  public onChangeDescription(): void {
    this.link.description = this.form.get("addTags.description").value;
  }

  public onCancel(): void {
    this.close.emit();
    this.form.get("addTags").reset();
  }

  public onAdd(): void {
    this.addLink(false);
    this.close.next();
    this.form.controls.links?.markAsTouched();
  }

  public onUpdate() {
    if (this.navigationItem.action === WidgetActions.EXPLORE) {
      this.linkTagsService
        .updateLink(this.profile.profileId, this.link)
        .subscribe(() => {
          this.close.emit();
        });
    } else {
      this.linkTagsService.links.items = this.linkTagsService.links?.items?.map(
        (item: LinkTag) => {
          return item.id === this.link.id ? this.link : item;
        }
      );
      this.linkTagsService.links$.next({
        link: undefined,
        data: this.linkTagsService.links,
      });
      this.close.emit();
    }
    this.form.get("addTags").reset();
    this.form.controls.links?.markAsTouched();
    this.link = undefined;
  }

  public onCreate(): void {
    this.addLink(true);
    this.form.controls.links?.markAsTouched();
  }

  private addLink(createNew: boolean): void {
    if (this.link.isDefault) {
      this.linkTagsService.links.items = this.linkTagsService.links.items.map(
        (item: LinkTag) => {
          return {
            ...item,
            ...{ isDefault: false },
          };
        }
      );
    }
    if (this.navigationItem.action === WidgetActions.EXPLORE) {
      this.linkTagsService
        .linkTags(this.profile.profileId, [this.link])
        .subscribe(() => {
          this.linkTagsService.links$.next({
            link: this.link,
            data: this.linkTagsService.links,
          });
          if (createNew) {
            this.link = new LinkTag(
              Utils.generateUUID(),
              undefined,
              undefined,
              false,
              undefined,
              undefined,
              undefined,
              undefined
            );
          } else {
            this.close.next();
          }
          this.links = cloneDeep(this.linkTagsService.links);
          this.form.get("addTags").reset();
          this.form.get("addTags.default").enable();
        });
    } else {
      this.linkTagsService.links.items.push(cloneDeep(this.link));
      this.linkTagsService.links$.next({
        link: undefined,
        data: this.linkTagsService.links,
      });
      this.links = cloneDeep(this.linkTagsService.links);
      this.form.get("addTags").reset();
      this.form.get("addTags.default").enable();
    }
  }

  public onToggleChange(): void {
    this.link.isDefault = this.form.get("addTags.default").value;
  }
}
