import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IDropdownItem {
  name: string;
  value: string;
  icon?: IconProp;
}
export interface DropdownPosition {
  left: number;
  top: number;
}
export interface DropdownRequest {
  action: string;
  position: DropdownPosition;
}
