import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { GetUsernameInitialsPipe, INoteDataSource, NotesService, ProfileNote } from "@intorqa-ui/shared";

@Pipe({
  name: "transformNotes",
  standalone: true
})
export class TransformNotesPipe implements PipeTransform {
  constructor(
    private notesService: NotesService,
    private datePipe: DatePipe,
    private getUserNameInitialsPipe: GetUsernameInitialsPipe
  ) {}

  transform(data: Array<ProfileNote>): Array<INoteDataSource> {
    if (!data || data?.length === 0) return [];
    return data?.map((item: any) => {
      const type = this.notesService.getTypeById(item.typeId);
      return {
        id: item.id,
        type: type?.name,
        subType: item.subType,
        value: item.value,
        rawValue: item.rawValue,
        documentId: item.documentId,
        rawTextNote: item.rawTextNote,
        textNote: item.textNote,
        updatedDate: this.datePipe.transform(item.updatedDate, "yyyy MMM dd"),
        updatedBy: this.getUserNameInitialsPipe.transform(item.updatedBy),
      };
    });
  }
}
