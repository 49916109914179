import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";

import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from "@angular/material/slide-toggle";
import { CoreModule, IDropdownItem } from "@intorqa-ui/core";
import {
  AlertsService,
  AnalysisTypes,
  ChartOrientation,
  ChartType,
  Condition_DataSource,
  DateInterval,
  Delays_DataSource,
  IAlertsTrendData,
  LineMode,
  NavigationHistoryItem,
  Priority_DataSource,
  TagService,
  TagThresholdAlert,
  TimeSeries,
  Timeline,
  WidgetActions,
} from "@intorqa-ui/shared";
import { SharedModule } from "projects/portal/src/app/modules/shared/shared.module";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-tag-threshold-alert",
  templateUrl: "./tag-threshold-alert.component.html",
  styleUrls: ["./tag-threshold-alert.component.scss"],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CommonModule,
    MatFormFieldModule,
    SharedModule,
  ],
})
export class TagThresholdAlertComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() form: FormGroup;
  @Input() widget: Timeline;

  public conditionDataSource: Array<IDropdownItem>;
  public periodDataSource: Array<{ name: string; value: number }>;
  private loadAlertSubscription: Subscription;
  public priorityDataSource: Array<IDropdownItem>;
  public trendDataSource: IAlertsTrendData;
  private trendSubscription: Subscription;
  public trendWidget: TimeSeries;

  readonly WidgetActions = WidgetActions;

  constructor(
    readonly alertsService: AlertsService,
    readonly tagService: TagService
  ) {
    this.conditionDataSource = Condition_DataSource;
    this.periodDataSource = Delays_DataSource;
    this.priorityDataSource = Priority_DataSource;
  }

  ngOnInit() {
    this.trendWidget = new TimeSeries(
      undefined,
      undefined,
      AnalysisTypes.TIME_SERIES,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        orientation: ChartOrientation.VERTICAL,
        mode: LineMode.LINE,
        tooltip: {
          show: false,
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          show:true,
        },
        series: {
          showSymbol: false,
          label: {
            show: false,
          },
          lineStyle: {
            width: 2,
          },
        },
      },
      [],
      [],
      undefined,
      ChartType.LINE,
      DateInterval.DAY,
      this.widget.ecosystemId
    );
    this.trendSubscription = this.alertsService.trend$.subscribe(
      (response: IAlertsTrendData) => {
        this.trendDataSource = response;
      }
    );
    this.loadAlertSubscription = this.alertsService.loadAlert$.subscribe(
      (response: TagThresholdAlert) => {
        this.form.get("alerts.dailyAverage").setValue(response.dailyAverage);
        this.form.get("alerts.active").setValue(response.active);
        this.form.get("alerts.priority").setValue(response.priority);
        this.form.get("alerts.condition").setValue(response.condition);
        this.form.get("alerts.count").setValue(response.count);
        this.form.get("alerts.period").setValue(response.period);
        if (response.dailyAverage) {
          this.form.get("alerts.count").disable();
          this.form.get("alerts.period").disable();
        }
      }
    );
    this.createForm();
    const { ecosystemId, _extras } = this.widget;
    this.alertsService
      .getTrend({
        ecosystemId,
        query: _extras.modelToDTO(),
      })
      .subscribe();
  }

  ngOnDestroy(): void {
    this.trendSubscription.unsubscribe();
    this.loadAlertSubscription.unsubscribe();
    const alertControls = (this.form?.get("alerts") as FormGroup)?.controls;
    for (const name in alertControls) {
      if (alertControls.hasOwnProperty(name)) {
        (this.form?.get("alerts") as FormGroup).removeControl(name);
      }
    }
  }

  private createForm(): void {
    if (this.navigationItem?.action !== WidgetActions.CREATE) {
      (this.form.get("alerts") as FormGroup).addControl(
        "active",
        new FormControl(true)
      );
    }
    (this.form.get("alerts") as FormGroup).addControl(
      "dailyAverage",
      new FormControl(undefined)
    );
    (this.form.get("alerts") as FormGroup).addControl(
      "priority",
      new FormControl(undefined, [Validators.required])
    );
    (this.form.get("alerts") as FormGroup).addControl(
      "condition",
      new FormControl(undefined, [Validators.required])
    );
    (this.form.get("alerts") as FormGroup).addControl(
      "count",
      new FormControl(undefined, [Validators.required])
    );
    (this.form.get("alerts") as FormGroup).addControl(
      "period",
      new FormControl(undefined, [Validators.required])
    );
  }

  public onChangeDailyAverage(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.form?.get("alerts.count")?.disable();
      this.form?.get("alerts.period")?.disable();
      this.form
        ?.get("alerts.count")
        ?.setValue(this.trendDataSource?.dailyAverage);
      const hour = Delays_DataSource.find(
        (item: { name: string; value: number }) => item.name === "Day"
      ).value;
      this.form?.get("alerts.period")?.setValue(hour);
    } else {
      this.form?.get("alerts.count")?.enable();
      this.form?.get("alerts.period")?.enable();
      this.form?.get("alerts.period")?.setValue(undefined);
      this.form?.get("alerts.count")?.setValue(undefined);
    }
  }
}
