import { Pipe, PipeTransform } from "@angular/core";
import {
  Cheat_Reputation_Map,
  MetadataFields
} from "@intorqa-ui/shared";

@Pipe({
  name: "renderSingleMetadata",
})
export class RenderSingleMetadataPipe implements PipeTransform {
  transform(value: string, field: string): string {
    if (field === MetadataFields.CHEAT_REPUTATION_SCORE) {
      const map = Cheat_Reputation_Map.find(
        (item: { name: string; value: number }) =>
          item.value === parseInt(value)
      );
      return `${value} - ${map.name}`;
    } else {
      return value;
    }
  }
}
