import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { QuillModule } from "ngx-quill";
import { Subscription } from "rxjs";
import { TagMatchAlertComponent } from "../tag-match-alert/tag-match-alert.component";
import { TagThresholdAlertComponent } from "../tag-threshold-alert/tag-threshold-alert.component";
import { CoreModule } from "@intorqa-ui/core";
import {
  NavigationHistoryItem,
  AlertTypes,
  TagService,
  AlertsService,
  Alert,
  Timeline,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-tag-alerts",
  templateUrl: "./tag-alerts.component.html",
  styleUrls: ["./tag-alerts.component.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    QuillModule,
    MatCheckboxModule,
    TagMatchAlertComponent,
    TagThresholdAlertComponent,
    CommonModule,
    MatFormFieldModule,
  ],
})
export class TagAlertsComponent implements OnInit {
  @Input() metadataForm: FormGroup;
  @Input() form: FormGroup;
  @Input() alertType: string;
  @Input() alert: Alert;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() widget: Timeline;

  private loadAlertSubscription: Subscription;
  private toggleSharedTagSubscription: Subscription;

  readonly AlertTypes = AlertTypes;

  constructor(
    readonly tagService: TagService,
    readonly alertsService: AlertsService
  ) {}

  ngOnInit() {
    this.toggleSharedTagSubscription =
      this.tagService.toggleSharedTag$.subscribe((response: boolean) => {
        const alertsFormGroup = this.form.get("alerts") as FormGroup;
        if (response) {
          alertsFormGroup.addControl(
            "postSlack",
            new FormControl(this.alert?.postSlack)
          );
        } else {
          alertsFormGroup.removeControl("postSlack");
        }
      });
    this.loadAlertSubscription = this.alertsService.loadAlert$.subscribe(
      (response: Alert) => {
        this.form.get("alerts.message").setValue(response.message);
        if (this.metadataForm?.get("sharedTag")?.value) {
          this.form.get("alerts.postSlack").setValue(response.postSlack);
        }
        // this.form.get("alerts.emailMe").setValue(response.emailMe);
      }
    );
    this.createForm();
  }

  ngOnDestroy(): void {
    this.loadAlertSubscription.unsubscribe();
    this.toggleSharedTagSubscription.unsubscribe();
  }

  private createForm(): void {
    (this.form.get("alerts") as FormGroup).addControl(
      "message",
      new FormControl(this.alert?.message)
    );
    if (this.metadataForm?.get("sharedTag")?.value) {
      (this.form.get("alerts") as FormGroup).addControl(
        "postSlack",
        new FormControl(this.alert?.postSlack)
      );
    }
    // (this.form.get("alerts") as FormGroup).addControl(
    //   "emailMe",
    //   new FormControl(this.alert?.emailMe)
    // );
  }
}
