import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownItem } from "@intorqa-ui/core";
import {
  DomainDetailsNote,
  NotesService,
  Profile,
  ProfileNote,
  Utils,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-type-domain-details",
  templateUrl: "./type-domain-details.component.html",
  styleUrls: ["./type-domain-details.component.scss"],
})
export class TypeDomainDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() note: DomainDetailsNote;
  @Input() profile: Profile;

  public subTypesDataSource: Array<IDropdownItem> = [];
  public editorModules = {
    toolbar: [
      ["bold", "italic", "underline"], // Basic formatting options
      [{ header: [1, 2, 3, 4, 5, 6] }], // Header formatting option
      [{ list: "ordered" }, { list: "bullet" }], // List formatting options
      ["link", "image", "video"], // Insert link, image, and video options
      ["clean"], // Remove formatting option
    ],
  };
  constructor(private notesService: NotesService) {}

  ngOnInit() {
    const type = this.notesService.getTypeById(this.note.typeId);
    this.subTypesDataSource = type.subTypes.map((item: string) => ({
      name: item,
      value: item,
    }));
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.note?.previousValue?.type !== changes?.note?.currentValue?.type
    ) {
      this.form.get("addNote.subType")?.setValue(undefined);
      this.subTypesDataSource = this.notesService
        .getTypeById(this.note.typeId)
        .subTypes.map((item: string) => ({ name: item, value: item }));
    }
  }

  ngOnDestroy(): void {
    const formGroup = this.form.get("addNote") as FormGroup;
    formGroup?.removeControl("subType");
    formGroup?.removeControl("value");
    formGroup?.removeControl("description");
  }

  private createForm(): void {
    const formGroup = this.form.get("addNote") as FormGroup;
    formGroup.addControl(
      "subType",
      new FormControl(this.note?.subType, [Validators.required])
    );
    formGroup.addControl(
      "value",
      new FormControl(this.note?.rawValue, [Validators.required])
    );
    formGroup.addControl("description", new FormControl(this.note?.textNote));
  }

  public onContentChanged(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id
    );
    this.note.textNote = this.form.get("addNote.description").value;
    this.note.rawTextNote = Utils.removeHtmlTags(this.note.textNote);

    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          }
        );
      }
    }
  }

  public onValueChanged(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id
    );
    this.note.rawValue = this.form.get("addNote.value").value;
    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          }
        );
      }
    }
  }

  public onChangeSubType(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id
    );
    this.note.subType = this.form.get("addNote.subType").value;
    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          }
        );
      }
    }
  }
}
