import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  Align,
  ComponentType,
  CustomOverlayService,
  IDropdownItem,
  PillType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TableColumn,
} from "@intorqa-ui/core";
import {
  DocumentItem,
  DocumentItemService,
  INoteDataSource,
  INotesData,
  ISegment,
  NoteType,
  NotesService,
  Profile,
  ProfileNote,
  ProfilesNavigationItem,
  SegmentScope,
  Utils,
  WidgetActions,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-profiles-notes",
  templateUrl: "./profiles-notes.component.html",
  styleUrls: ["./profiles-notes.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesNotesComponent,
    },
  ],
})
export class ProfilesNotesComponent implements OnInit, AfterViewInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() action: WidgetActions;
  @Input() showTitle = true;

  public dataSource: INotesData;
  public tableColumns: Array<TableColumn> = [];
  public notesSubscription: Subscription;
  private selection: Array<ProfileNote>;
  private touched = false;
  private disabled = false;
  public note: ProfileNote;
  private showAddNotesSubscription: Subscription;
  public initialState = new QueryFilters(30, 1, undefined, undefined, {
    direction: "desc",
    active: "updatedDate",
  });
  private resetSubscription: Subscription;
  private showViewNotesSubscription: Subscription;
  public isFilterable = false;
  public segment: ISegment;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly WidgetActions = WidgetActions;
  readonly Align = Align;

  @ViewChild("actionsButtonTemplate")
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild("notesTemplate")
  notesTemplate: TemplateRef<unknown>;
  @ViewChild("valueTemplate")
  valueTemplate: TemplateRef<unknown>;
  @ViewChild("ownerTemplate") ownerTemplate: TemplateRef<unknown>;

  constructor(
    private notesService: NotesService,
    readonly documentService: DocumentItemService,
    readonly customOverlayService: CustomOverlayService
  ) {}

  ngOnInit(): void {
    this.showAddNotesSubscription = this.notesService.showAddNotes$.subscribe(
      (response: WidgetActions) => {
        if (!response) {
          this.note = undefined;
        }
      }
    );

    this.showViewNotesSubscription = this.notesService.showViewNotes$.subscribe(
      (response: boolean) => {
        if (!response) {
          this.note = undefined;
          this.segment = undefined;
        }
      }
    );

    this.resetSubscription = this.notesService.reset$.subscribe(() => {
      this.dataSource = { items: [], totalCount: undefined };
    });
    this.notesSubscription = this.notesService.notes$.subscribe(
      (response: INotesData) => {
        this.dataSource = cloneDeep(response);
        this.note = undefined;
      }
    );
    this.onLoadTypes();
    if (this.navigationItem.notes?.length > 0) {
      this.notesService.notes = {
        items: this.navigationItem.notes,
        totalCount: this.navigationItem.notes.length,
      };
      this.dataSource = cloneDeep(this.notesService.notes);
    } else {
      if (this.navigationItem.action === WidgetActions.CREATE) {
        this.notesService.notes = {
          items: [],
          totalCount: 0,
        };
        this.dataSource = cloneDeep(this.notesService.notes);
      }
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.notesSubscription.unsubscribe();
    this.showAddNotesSubscription.unsubscribe();
    this.resetSubscription.unsubscribe();
    this.showViewNotesSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ProfileNote>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onLoadTypes(): void {
    this.notesService.getNoteTypes(this.profile.profileTypeId).subscribe(() => {
      this.getSubTypes();
      this.getNoteTypes();
      if (
        this.navigationItem.action === WidgetActions.SETTINGS ||
        this.navigationItem.action === WidgetActions.EXPLORE
      ) {
        this.onDataBound(this.initialState, false);
      }
    });
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: "Type",
        dataKey: "type",
        searchField: "typeId",
        isSortable: true,
        width: "150px",
        isFilterable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: undefined,
      },
      {
        name: "Sub type",
        dataKey: "subType",
        searchField: "subType",
        isSortable: true,
        width: "150px",
        isFilterable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: undefined,
        dataBound: () => this.getSubTypes(),
      },
      {
        name: "Value",
        dataKey: "rawValue",
        searchField: "rawValue",
        isSortable: true,
        width: "fit",
        isFilterable: true,
        customRender: true,
        template: this.valueTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: "Notes",
        dataKey: "rawTextNote",
        searchField: "textNote",
        isSortable: true,
        width: "fit",
        isFilterable: true,
        customRender: true,
        template: this.notesTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: undefined,
        dataKey: "actions",
        position: "center",
        customRender: true,
        template: this.actionsButtonTemplate,
        width: "68px",
      },
    ];

    if (
      this.navigationItem.action === WidgetActions.EXPLORE ||
      this.navigationItem.action === WidgetActions.SETTINGS
    ) {
      this.tableColumns = [
        {
          name: "Updated date",
          dataKey: "updatedDate",
          searchField: "updatedDate",
          isSortable: true,
          width: "150px",
          sticky: true,
          isFilterable: true,
          searchFieldType: SearchFieldType.DATE,
          searchComponent: ComponentType.DATE,
        },
        {
          name: "Updated by",
          dataKey: "updatedBy",
          searchField: "updatedBy",
          isSortable: true,
          position: "center",
          customRender: true,
          template: this.ownerTemplate,
          width: "120px",
          httpBinding: true,
          sticky: true,
          isFilterable: true,
          searchFieldType: SearchFieldType.TEXT,
          searchComponent: ComponentType.DROPDOWN,
          dataSource: undefined,
          dataBound: () => {
            this.onGetUsers();
          },
        },
        {
          name: "DocumentId",
          dataKey: "documentId",
          width: "150px",
          show: false,
        },
        ...this.tableColumns,
      ];
    }
  }

  private onGetUsers(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === "updatedBy") {
        this.notesService
          .getUsers(this.profile.profileId)
          .subscribe((response: Array<string>) => {
            column.dataSource = response.map((item: string) => ({
              name: item,
              value: item,
            }));
          });
      }
      return column;
    });
  }

  private getNoteTypes(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === "typeId") {
        column.dataSource = this.notesService.noteTypes.map(
          (item: NoteType) => ({ name: item.name, value: item.id })
        );
      }
      return column;
    });
  }

  private getSubTypes(): void {
    let result: Array<IDropdownItem> = [];
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === "subType") {
        this.notesService.noteTypes.forEach((item: NoteType) => {
          if (item.subTypes?.length > 0) {
            result = [
              ...result,
              ...item.subTypes.map((subItem: string) => ({
                name: subItem,
                value: subItem,
              })),
            ];
          }
        });
        column.dataSource = result;
      }
      return column;
    });
  }

  public onDeleteNote(note: ProfileNote): void {
    if (
      this.navigationItem.action === WidgetActions.EXPLORE ||
      this.navigationItem.action === WidgetActions.SETTINGS
    ) {
      this.notesService
        .removeNotes(this.profile.profileId, [note.id])
        .subscribe();
    }
    this.form.controls.notes?.markAsTouched();
  }

  public onAddNote(): void {
    this.note = new ProfileNote(
      Utils.generateUUID(),
      this.profile.profileId,
      undefined,
      undefined,
      undefined,
      undefined
    );
    this.notesService.showAddNotes$.next(WidgetActions.CREATE);
  }

  public onEditNote(note: INoteDataSource): void {
    this.notesService.showAddNotes$.next(WidgetActions.SETTINGS);
    this.note = this.notesService.getNoteById(note.id);
  }

  public onDataBound(params: QueryFilters, showLoader = true): void {
    this.notesService
      .getNotes(this.profile.profileId, params, showLoader)
      .subscribe();
  }

  public onViewNote(note: ProfileNote): void {
    this.note = this.notesService.getNoteById(note.id);
    this.segment = undefined;
    if (this.segment?.value?.id !== note.documentId) {
      this.documentService
        .getDocument(note.documentId)
        .then((response: DocumentItem) => {
          this.segment = {
            scope: SegmentScope.ARTICLE_DETAIL,
            value: response,
          };
        });
    }
  }
}
