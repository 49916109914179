import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { IDisplayType, IPresetQuery } from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import moment from "moment";
import { Align, Sizes } from "../../enums/shared.enum";
import { QueryFilters } from "../../models/query-model/query-filters";
import { SharedService } from "../../services/shared.service";
import { VirtualScrollService } from "../../services/virtual-scrolling.service";
import { DateQueryType } from "../date-range/date-range.enum";
import { DateRange } from "../date-range/date-range.model";
import { IPreset } from "./../date-range/date-range.interface";
import { ToolbarActions, ViewMode } from "./toolbar.enum";
import { IToolbarAction } from "./toolbar.interface";
import { FindActionPipe } from "./toolbar.pipe";

@Component({
  selector: "itq-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit, OnChanges {
  @Output() toggleView = new EventEmitter<any>();
  @Output() search = new EventEmitter<QueryFilters>();
  @Output() refresh = new EventEmitter<void>();
  @Output() save = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() showFilters = new EventEmitter();
  @Output() resetFilters = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() showApi = new EventEmitter();
  @Output() changeDisplayType = new EventEmitter<any>();

  @Input() actions: Array<IToolbarAction>;
  @Input() widget: any;
  @Input() filtersApplied: boolean;
  @Input() viewMode: ViewMode;
  @Input() query: string;
  @Input() count: number;
  @Input() dateRangeEnabled = true;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;
  @Input() selectedDisplayType: any;
  @Input() displayTypes: Array<IDisplayType>;
  @Input() showTooltip = false;
  @Input() foamtreeActive = false;

  readonly ViewMode = ViewMode;
  readonly Sizes = Sizes;
  readonly ToolbarActions = ToolbarActions;
  readonly Align = Align;

  public dates: IPreset;
  public originalDates: IPreset;
  public reloadDates = true;
  public foamtreeDisabled = false;

  constructor(
    public sharedService: SharedService,
    private findActionPipe: FindActionPipe,
    private virtualScrollService: VirtualScrollService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.actions?.previousValue !== changes?.actions?.currentValue) {
      this.getDatesData();
      const searchAction = this.findActionPipe.transform(
        this.actions,
        ToolbarActions.SEARCH
      );
      if (searchAction) {
        this.form?.controls?.searchTerm?.setValue(searchAction?.data);
      }
    }
  }

  private getDatesData(): void {
    const dateAction = this.findActionPipe.transform(
      this.actions,
      ToolbarActions.DATE
    );
    if (dateAction && dateAction.data?.date?.start) {
      const dates = {
        label: DateQueryType[dateAction?.data?.date?.label],
        start: moment(
          DateRange.convertFromEpochSeconds(dateAction?.data?.date?.start)
        ),
        end: dateAction.data?.date?.end
          ? moment(
              DateRange.convertFromEpochSeconds(dateAction.data?.date?.end)
            )
          : moment(),
      };
      if (dateAction?.data?.date?.label === DateQueryType.Custom) {
        this.dates = dates;
      } else {
        const dateRange = new DateRange(dates);
        this.dates = dateRange.getPresetByLabel(dates.label);
      }
    } else {
      this.dates = undefined;
    }
    this.reloadDates = true;
    this.originalDates = cloneDeep(this.dates);
  }

  private createForm(): void {
    const searchAction = this.findActionPipe.transform(
      this.actions,
      ToolbarActions.SEARCH
    );
    if (searchAction) {
      this.form.addControl("searchTerm", new FormControl(searchAction.data));
    }
  }

  public onToggleView(view: ViewMode): void {
    this.toggleView.emit(view);
  }

  public onExport(): void {
    this.export.emit();
  }

  public onSearchQuery(): void {
    const initialState = this.initialState.cloneDeep();
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    initialState.query = this.form?.controls?.searchTerm?.value;
    this.search.emit(initialState);
  }

  public onSearch(dates: IPreset): void {
    const initialState = this.initialState.cloneDeep();
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    initialState.where = this.getPayload(dates);
    this.search.emit(initialState);
  }

  public onRefresh(): void {
    this.refresh.emit();
  }

  private getPayload(dates: IPreset): IPresetQuery {
    this.reloadDates = false;
    let payload: IPresetQuery;
    if (!dates) {
      dates = this.dates;
    } else {
      return {
        label: DateRange.getDateQueryText(dates.label),
        start: DateRange.convertToEpochSec(
          moment(cloneDeep(dates).start).toDate()
        ),
        end:
          dates.label === DateQueryType.Custom
            ? DateRange.convertToEpochSec(moment(cloneDeep(dates).end).toDate())
            : undefined,
      };
    }
    return payload;
  }

  public onDateRangeChange(args: IPreset): void {
    if (args?.start && args?.end) {
      this.dates = args;
    } else {
      this.dates = undefined;
    }
    this.filtersApplied = this.originalDates?.label !== this.dates?.label;
    this.onSearch(this.dates);
  }

  public onResetFilters(): void {
    this.filtersApplied = false;
    this.dates = undefined;
    this.form.controls.searchTerm.setValue(undefined);

    const searchAction = this.findActionPipe.transform(
      this.actions,
      ToolbarActions.SEARCH
    );
    if (searchAction) {
      searchAction.expanded = false;
      this.query = undefined;
      this.form.controls.searchTerm.setValue(this.query);
    }
    this.resetFilters.emit();
  }

  public onAdd(): void {
    this.add.emit();
  }

  public onShowApi(): void {
    this.showApi.emit();
  }

  public onChangeDisplay(params: IDisplayType): void {
    this.foamtreeActive = false;
    this.foamtreeDisabled = true;
    this.changeDisplayType.emit(params);
  }
}
