export enum ErrorMessages {
  "DUPLICATE_NAME" = "Name already in use for user",
  "NOT_FOUND_TITLE" = "Oops! Page not found",
  "NOT_FOUND_DESCRIPTION" = "WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND",
  "NO_TAG_PERMISSIONS_TITLE" = "Oops! No Permissions",
  "NO_TAG_PERMISSIONS_DESCRIPTION" = "Sorry, you do not have permission to view this tag",
  "NO_TAG_FOUND_TITLE" = "Oops! No tag found",
  "NO_TAG_FOUND" = "Sorry, the tag you trying to load does not exist",
  "NO_DELETE_TAG_PERMISSION_DESCRIPTION" = "User does not have permission to delete this tag.",
  "NO_DELETE_TAG_PERMISSION_TITLE" = "Oops! No permissions",
  "INTERNAL_SERVER_ERROR_TITLE" = "Oops! Something went wrong",
  "INTERNAL_SERVER_ERROR_DESCRIPTION" = "There was an internal server error. Please contact your system admin or try again later",
  "NO_DELETE_TAG_BOARD_TITLE" = "Oops! Something went wrong",
  "NO_DELETE_TAG_BOARD_DESCRIPTION" = "The tag is in use on one or more boards",
  "TAG_NAME_CLASH_DESCRIPTION" = "This name clashes with another tag name",
  BOARD_NOT_FOUND = "BOARD_NOT_FOUND",
  UPDATE_TREE_ERROR = "TREE_VERSION_CONFLICT",
  PAGE_NOT_FOUND = "PAGE_NOT_FOUND",
  CANNOT_DELETE_DEFAULT_BOARD = "CANNOT_DELETE_DEFAULT_BOARD",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  TAG_MANDATORY_TITLE = "Tag name is mandatory",
  TAG_MANDATORY_DESCRIPTION = "You must set a name for this tag",
  DELETE_TAG_DEPENDENCIES = "Tag cannot be deleted because it has dependants",
  ERROR_CREATE_CATEGORY = "Please fill all fields to create category",
  DUPLICATE_TAG_NAME = "DUPLICATE_TAG_NAME",
  TAG_API_PREFIX_NOT_FOUND = "TAG_API_PREFIX_NOT_FOUND",
  USER_PREFERENCES_NOT_FOUND = "USER_PREFERENCES_NOT_FOUND",
  PARENT_DOCUMENT_NOT_FOUND = "PARENT_DOCUMENT_NOT_FOUND",
  INVALID_DEPENDENCIES_UPDATE = "INVALID_DEPENDENCIES_UPDATE",
  CYCLIC_TAG_DEPENDENCY = "CYCLIC_TAG_DEPENDENCY",
  TAG_NOT_FOUND = "TAG_NOT_FOUND",
  PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND",
}
