import { EChartsOption } from "echarts";
import { ProfilePathSvg } from "../../enums/profile.enum";
import { ChartType } from "../../enums/widget.enum";
import { IDisplayType } from "../../interfaces/widget/widget.interface";
import { Profile } from "../widgets/profile";
import { Chart } from "./chart";

interface INetworkChart {
  chartName: string;
  name: ChartType;
}

export class NetworkChart extends Chart implements INetworkChart{
  constructor(public chartName: string, public name: ChartType) {
    super(name);
  }

  public getOptions(
    data: any,
    segment: any,
    settings: { [key: string]: any }
  ): EChartsOption {
    let options: EChartsOption;
    data = data[0].data[0];
    options = {
      animationDurationUpdate: 1500,
      tooltip: {},
      animationEasingUpdate: "cubicOut",
      series: [
        {
          name: "Connections",
          type: "graph",
          layout: "none",
          data: data.nodes.map((node) => {
            return {
              ...node,
              symbol: `path://${ProfilePathSvg[node.profileType]}` || "circle", // Use the SVG path for the node type, or fallback to 'circle'
            };
          }),
          categories: data.categories,
          edges: data.links,
          force: {
            edgeLength: 5,
            repulsion: 20,
            gravity: 0.2,
          },
          edgeLabel: {
            show: true,
            position: "middle",
            formatter: (value: any) => {
              return value.data.connectionType;
            },
            fontSize: 12,
          },
          roam: true,
          label: {
            show: true,
            position: "right",
            formatter: "{b}",
          },
          scaleLimit: {
            min: 0.4,
            max: 2,
          },
        },
      ],
    };

    return options;
  }

  public getDisplayTypes(
    displayTypes: Array<IDisplayType>,
    widget: Profile
  ): Array<IDisplayType> {
    const selectedDisplay = this.getSelectedType(displayTypes, widget);
    const types = displayTypes?.filter((item: IDisplayType) => {
      const mode = selectedDisplay.options.mode;
      if (mode) {
        return item.options?.mode !== selectedDisplay.options.mode;
      } else {
        return (
          item.options?.orientation !== selectedDisplay.options.orientation
        );
      }
    });

    return types;
  }

  public getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: Profile
  ): IDisplayType {
    return undefined;
  }
}
