import { ChartOrientation, ChartType, PieMode } from "../enums/widget.enum";
import { IDisplayType } from "../interfaces/widget/widget.interface";

export const TagAnalysisDisplayTypes: Array<IDisplayType> = [
  {
    id: "VerticalBar",
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
    },
    svgIcon: "vertical-bar",
    tooltip: "Vertical Bar",
  },
  {
    id: "HorizontalBar",
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.HORIZONTAL,
    },
    svgIcon: "horizontal-bar",
    tooltip: "Horizontal Bar",
  },
  {
    id: "Pie",
    type: ChartType.PIE,
    options: {
      mode: PieMode.PIE,
    },
    svgIcon: "pie",
    tooltip: "Pie",
  },
  {
    id: "Doughnut",
    type: ChartType.PIE,
    options: {
      mode: PieMode.DOUGHTNUT,
    },
    svgIcon: "doughnut",
    tooltip: "Doughnut",
  },
  {
    id: "Table",
    type: ChartType.TABLE,
    icon: ["far", "table"],
    tooltip: "Table",
  },
  // {
  //   id: "Foamtree",
  //   type: ChartType.FOAMTREE,
  //   svgIcon: "ripl",
  //   tooltip: "Foamtree",
  // },
];
