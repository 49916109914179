import { Pipe, PipeTransform } from "@angular/core";
import { TagCategory } from "@intorqa-ui/core";
import { ICustomTag, LinkTag, LinkTagsService } from "@intorqa-ui/shared";

@Pipe({
  name: "filterTags",
})
export class FilterTagsPipe implements PipeTransform {
  constructor(private linkTagsService: LinkTagsService) {}

  transform(data: Array<ICustomTag>, type: TagCategory): Array<ICustomTag> {
    if (!type || !data || data.length === 0) return data;
    let tagNames = [];
    const tags = this.linkTagsService.getTagsByType(type);
    if (tags?.length > 0) {
      tagNames = tags.map((item: LinkTag) => item.tagName);
    }
    return data.filter((item: ICustomTag) => {
      return !tagNames.includes(item.name);
    });
  }
}
