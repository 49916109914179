import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CodeSnippetNote, Profile } from "@intorqa-ui/shared";

@Component({
  selector: "itq-view-code-snippet",
  templateUrl: "./view-code-snippet.component.html",
  styleUrls: ["./view-code-snippet.component.scss"],
})
export class ViewCodeSnippetComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: CodeSnippetNote;

  constructor() {}

  ngOnInit() {}
}
