import { CommonModule } from "@angular/common";
import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  QueryFilters,
  SharedService,
  TagCategory,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  ChartType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  GroupByDatePipe,
  GroupNotification,
  Notification,
  ISegment,
  LinkTag,
  NavigationHistoryItem,
  NotificationNavigationItem,
  NotificationWidget,
  NotificationsService,
  Profile,
  ProfileDrildownScope,
  ProfileDrilldown,
  ProfilesNavigationItem,
  SegmentScope,
  Timeline,
  WidgetActions,
  ResearchNotification,
} from "@intorqa-ui/shared";
import { Subscription, forkJoin } from "rxjs";
import { WidgetSettingsComponent } from "../../widget-settings/widget-settings.component";
import { NotificationsTimelineComponent } from "../notifications-timeline/notifications-timeline.component";

@Component({
  selector: "itq-notifications-hub",
  templateUrl: "./notifications-hub.component.html",
  styleUrls: ["./notifications-hub.component.scss"],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    CommonModule,
    FAwesomeModule,
    GroupByDatePipe,
    NotificationsTimelineComponent,
  ],
  providers: [GroupByDatePipe, SharedService],
})
export class NotificationsHubComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined
  );

  @Output() drilldown = new EventEmitter<{
    segment?: ISegment;
    notification: Notification;
  }>();

  @ViewChildren("dynamicComponentContainer", { read: ViewContainerRef })
  dynamicComponentContainers: QueryList<ViewContainerRef>;

  public loadOtherMatchesSubscription: Subscription;
  private loadNotificationsSubscription: Subscription;
  public notificationsDataSource: {
    items: Array<GroupNotification>;
    totalCount: number;
  };
  private createProfileSubscription: Subscription;
  public loadNotificationDetailSubscription: Subscription;

  constructor(
    readonly componentFactoryResolver: ComponentFactoryResolver,
    readonly notificationsService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly groupByDate: GroupByDatePipe
  ) {}

  ngOnInit() {
    this.createProfileSubscription =
      this.notificationsService.createProfile$.subscribe(
        (params: {
          profileDrilldown: ProfileDrilldown;
          notification: Notification;
        }) => {
          this.createProfile(params);
        }
      );
    this.loadNotificationsSubscription =
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          this.notificationsDataSource = {
            items: this.groupByDate.transform(response.items),
            totalCount:
              response.totalCount !== undefined
                ? response.totalCount
                : this.notificationsDataSource.totalCount,
          };
        }
      );
    this.loadOtherMatchesSubscription =
      this.notificationsService.loadOtherMatches$.subscribe(
        (params: { filters: QueryFilters; raisedAlertId: string }) => {
          this.loadOtherMatches(params);
        }
      );

    this.loadNotificationDetailSubscription =
      this.notificationsService.loadNotificationDetail$.subscribe(
        (response: ResearchNotification) => {
          this.loadResearchAlert(response);
        }
      );
    this.createForm();

    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.loadOtherMatchesSubscription.unsubscribe();
    this.loadNotificationsSubscription.unsubscribe();
    this.createProfileSubscription.unsubscribe();
    this.loadNotificationDetailSubscription.unsubscribe();
  }

  private loadResearchAlert(notification: ResearchNotification): void {
    const widget = new NotificationWidget(
      undefined,
      undefined,
      AnalysisTypes.NOTIFICATION,
      "Notifications Hub",
      undefined,
      ChartType.NOTIFICATION,
      undefined
    );
    const navigationItem = new NotificationNavigationItem(
      `${WidgetActions.EXPLORE}_notifications`,
      widget,
      WidgetActions.EXPLORE,
      undefined,
      this.initialState,
      new FormGroup({}),
      "bell",
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      undefined,
      notification.raisedAlertId
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: "basic",
      type: CustomOverlayType["almost-full"],
      size: "lg",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private loadOtherMatches(params: {
    filters?: QueryFilters;
    raisedAlertId?: string;
  }): void {
    const widget = new NotificationWidget(
      undefined,
      undefined,
      AnalysisTypes.NOTIFICATION,
      "Notifications Hub",
      undefined,
      ChartType.NOTIFICATION,
      undefined
    );
    const navigationItem = new NotificationNavigationItem(
      `${WidgetActions.EXPLORE}_notifications`,
      widget,
      WidgetActions.EXPLORE,
      undefined,
      params?.filters || this.initialState,
      new FormGroup({}),
      "bell",
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      params.raisedAlertId,
      undefined
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: "basic",
      type: CustomOverlayType["almost-full"],
      size: "lg",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe(() => {
      this.notificationsService
        .getUnreadNotificationsCount(this.initialState)
        .subscribe();
    });
  }

  private createProfile(params: {
    profileDrilldown: ProfileDrilldown;
    notification: Notification;
  }): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      params.profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      params.notification.ecosystemId,
      params.profileDrilldown.profileType.id,
      params.profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "plus",
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory["My Tags"]
          : params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.Actor
          : TagCategory.Channel,
        true,
        params.profileDrilldown.value,
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? params.profileDrilldown.value
          : `Field field:${params.profileDrilldown.value}:${
              params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`
      ),
      undefined,
      undefined,
      0
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: "basic",
      type: CustomOverlayType["almost-full"],
      size: "md",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private createForm(): void {
    this.form.addControl("query", new FormControl());
  }

  public onSearch(): void {
    this.initialState.query = this.form.get("query").value;
    this.onDataBound(this.initialState);
  }

  public onDataBound(params: QueryFilters): void {
    const getNotificationsObservable =
      this.notificationsService.getNotifications(
        params,
        this.form.get("showAll").value === "unread" ? false : undefined
      );
    const getUnreadCountObservable =
      this.notificationsService.getUnreadNotificationsCount(this.initialState);
    forkJoin([
      getNotificationsObservable,
      getUnreadCountObservable,
    ]).subscribe();
  }

  public onDrilldown(params: {
    segment?: ISegment;
    notification?: Notification;
  }): void {
    switch (params.segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(params);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(params);
        break;
      default:
        break;
    }
  }

  private drilldownChannel(params: {
    segment?: ISegment;
    notification?: Notification;
  }): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      params.segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      params.notification?.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${params.segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "hashtag",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: "basic",
      type: CustomOverlayType["almost-full"],
      size: "lg",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(params: {
    segment?: ISegment;
    notification?: Notification;
  }): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      params.segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      params.notification?.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${params.segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "user",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: "basic",
      type: CustomOverlayType["almost-full"],
      size: "lg",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}
