import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { IPreset } from "../components/date-range/date-range.interface";
import { DateQueryType } from "../enums/date-range.enum";
import { Moment } from "moment";
import { DateRange } from "../components/date-range/date-range.model";

@Injectable({
  providedIn: "root",
})
export class DateRangeService {
  changeDateObservable: Subject<IPreset> = new Subject();
  public preset: IPreset;

  constructor() {}

  getPresetLabel(startDate: Moment, endDate: Moment): DateQueryType {
    const presets = DateRange.getDatePresets();
    if (this.preset) {
      const selectedPreset = presets.find(
        (preset: IPreset) =>
          preset?.start?.isSame(startDate, "day") &&
          preset?.end?.isSame(endDate, "day")
      );
      if (selectedPreset) {
        return selectedPreset.label;
      }
    }
    return DateQueryType.Custom;
  }
}
