import { Pipe, PipeTransform } from "@angular/core";
import { ICustomTag, ITagMetadata } from "@intorqa-ui/shared";

@Pipe({
  name: "filterTags",
})
export class FilterTagsPipe implements PipeTransform {
  constructor() {}

  transform(
    data: Array<ICustomTag>,
    selections: Array<ITagMetadata>
  ): Array<ICustomTag> {
    if (!selections) return data;
    const ids = selections.map((item: ITagMetadata) => item.tagId);
    return data?.filter((item: ICustomTag) => !ids.includes(item.id));
  }
}
