export interface IRiplResults {
  clusters: Array<ICluster>;
  source_documents: Array<[key: string]>;
}

export interface ICluster {
  clusters: Array<ICluster>;
  documents: Array<number>;
  labels: Array<string>;
  score: number;
}
