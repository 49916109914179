import { DateQueryType } from "./date-range.enum";

export interface IPreset {
  label: DateQueryType;
  start: moment.Moment;
  end: moment.Moment;
}

export interface IDateRange {
  preset: IPreset;
}
