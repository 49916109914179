import { Pipe, PipeTransform } from "@angular/core";
import { NotesService, ProfileNote } from "@intorqa-ui/shared";

@Pipe({
  name: "getNoteValue",
})
export class GetNoteValuePipe implements PipeTransform {
  constructor(readonly notesService: NotesService) {}

  transform(note: ProfileNote): any {
    return this.notesService.getTypeById(note?.typeId)?.name;
  }
}
