import { TagCategory } from "@intorqa-ui/core";
import { ILinkTag } from "../../interfaces/profiles/profile-tags.interface";

export class LinkTag implements ILinkTag {
  constructor(
    public id: string,
    public description: string,
    public type: TagCategory,
    public isDefault: boolean,
    public tagName: string,
    public updatedDate: number,
    public updatedBy: string,
    public tagId?: string
  ) {}
}
