import { DateQueryType, QueryFilters } from "@intorqa-ui/core";
import { RuleSet } from "angular2-query-builder";
import {
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DTOQueryOperator,
} from "../enums/query.enum";

export interface DTOQueryCondition {
  field: DTOQueryFieldType;
  operator: DTOQueryConditionOperator;
  value: Array<string | QueryFilters> | string;
}

export interface DTOQuery {
  operator: DTOQueryOperator;
  conditions?: Array<DTOQueryCondition>;
  queries?: Array<DTOQuery>;
}

export interface QueryModel {
  query?: RuleSet | any;
  advanced?: boolean;
}
export interface IPresetQuery {
  label?: DateQueryType;
  start?: number;
  end?: number;
}
