import { AlertTypes } from "../../enums/alerts.enum";
import { DocumentItem } from "../../interfaces/document-dtos";
import {
  IGroupNotification,
  INotification,
  IResearchNotification,
  ISlackNotification,
  ITagMatchNotification,
  IThresholdHubNotification,
} from "../../interfaces/notification.interface";
import { ITransactionalNotification } from "./../../interfaces/notification.interface";

export class SlackNotification implements ISlackNotification {
  constructor(public attachment: string) {}
}

export class Notification implements INotification {
  constructor(
    public raisedAlertId: string,
    public createdDate: number,
    public alertTypeName: AlertTypes,
    public message: string,
    public read: boolean,
    public ecosystemId: string
  ) {}
}

export class TagMatchNotification
  extends Notification
  implements ITagMatchNotification
{
  constructor(
    public raisedAlertId: string,
    public createdDate: number,
    public alertTypeName: AlertTypes,
    public tagName: string,
    public priority: string,
    public message: string,
    public read: boolean,
    public ecosystemId: string,
    public document: DocumentItem,
    public matches: number,
    public tagEdited: boolean
  ) {
    super(
      raisedAlertId,
      createdDate,
      alertTypeName,
      message,
      read,
      ecosystemId
    );
  }
}

export class ThresholdNotification
  extends Notification
  implements IThresholdHubNotification
{
  constructor(
    public raisedAlertId: string,
    public createdDate: number,
    public alertTypeName: AlertTypes,
    public tagName: string,
    public priority: string,
    public message: string,
    public read: boolean,
    public ecosystemId: string,
    public period: number,
    public condition: string,
    public count: number,
    public tagId: string,
    public tagEdited: boolean
  ) {
    super(
      raisedAlertId,
      createdDate,
      alertTypeName,
      message,
      read,
      ecosystemId
    );
  }
}

export class ResearchNotification
  extends Notification
  implements IResearchNotification
{
  constructor(
    public raisedAlertId: string,
    public createdDate: number,
    public alertTypeName: AlertTypes,
    public priority: string,
    public message: string,
    public read: boolean,
    public ecosystemId: string,
    public headline: string,
    public rawMessage: string
  ) {
    super(
      raisedAlertId,
      createdDate,
      alertTypeName,
      message,
      read,
      ecosystemId
    );
  }
}

export class TransactionalNotification
  extends Notification
  implements ITransactionalNotification
{
  constructor(
    public raisedAlertId: string,
    public createdDate: number,
    public alertTypeName: AlertTypes,
    public message: string,
    public read: boolean,
    public ecosystemId: string,
    public headline: string,
    public triggerMetadata: { [key: string]: any }
  ) {
    super(
      raisedAlertId,
      createdDate,
      alertTypeName,
      message,
      read,
      ecosystemId
    );
  }
}

export class GroupNotification implements IGroupNotification {
  constructor(public date: string, public notifications: Array<Notification>) {}
}
