import { GlobalPositionStrategy } from "@angular/cdk/overlay";
import { TemplateRef } from "@angular/core";
import { DialogTypes } from "../dialog/dialog.enum";
import { CustomOverlayType } from "./custom-overlay.type";

export interface CustomOverlayConfig {
  type: CustomOverlayType;
  panelClass?: Array<string>;
  hasBackdrop?: boolean;
  backdropClass?: Array<string>;
  component?: any;
  data?: any;
  cssClass?: string;
  positionStrategy?: GlobalPositionStrategy;
  disposeOnNavigation?: boolean;
  size?: string;
  dialog?: {
    type: DialogTypes;
    footerTemplate?: TemplateRef<any>;
  };
  closeBtnStyle?: string;
  closeBtnClass?: string;
  closeOnBackdropClick?: boolean;
}
