import { PortalModule } from "@angular/cdk/portal";
import { DatePipe } from "@angular/common";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  PreloadAllModules,
  RouteReuseStrategy,
  RouterModule,
} from "@angular/router";

import { ApiModule, HttpCancelService } from "@intorqa-ui/api";
import { CoreModule } from "@intorqa-ui/core";

import { environment } from "../environments/environment";
import { APP_ROUTES } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  AuthGuardService,
  ErrorCatchingInterceptor,
  ManageHttpInterceptor,
} from "@intorqa-ui/shared";
import {
  KeycloakAngularModule,
  KeycloakOptions,
  KeycloakService,
} from "keycloak-angular";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { NotificationsComponent } from "./modules/notifications/notifications.component";
import { DefaultNavigationComponent } from "./modules/shared/components/default-navigation/default-navigation.component";
import { WidgetSettingsModule } from "./modules/widget-settings/widget-settings.module";
import { CacheRouteReuseStrategy } from "./strategies/cache-route-reuse.strategy";

function initializeKeycloak(keycloakService: KeycloakService) {
  const config = environment.authConfig as KeycloakOptions;
  return () => keycloakService.init(config);
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    KeycloakAngularModule,
    ApiModule.forRoot(environment),
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules,
    }),
    PortalModule,
    CoreModule,
    IconSpriteModule,
    WidgetSettingsModule,
    NotificationsComponent,
    DefaultNavigationComponent,
  ],
  declarations: [AppComponent],
  providers: [
    DatePipe,
    HttpCancelService,
    KeycloakService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    AuthGuardService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {}
}
