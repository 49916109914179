import { NoteTypes } from "../../enums/profile.enum";
import { SegmentScope } from "../../enums/widget-settings.enum";
import { ProfileNote } from "../../models/profiles/profile-note";
import { Profile } from "../../models/widgets/profile";
import { DocumentItem } from "../document-dtos";

export interface IProfileNote {
  id: string;
  profileId: string;
  typeId: string;
  documentId?: string;
  updatedDate?: number;
  updatedBy?: string;
}

export interface INotesData {
  items: Array<ProfileNote>;
  totalCount: number;
}

export interface IProfileNotesResults {
  items: Array<IProfileNote>;
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface INoteDataSource {
  id: string;
  type: NoteTypes;
  subType: string;
  value: string;
  rawValue: string;
  textNote: string;
  rawTextNote: string;
  updatedDate: string;
  updatedBy: string;
}

export interface IAddNote {
  segment: {
    segment: SegmentScope;
    value: DocumentItem;
  };
  ecosystemId: string;
  profile: Profile;
}
