import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPrefixDescription } from "@intorqa-ui/core";
import {
  GetMetadataFieldPipe,
  GroupMetadata,
  MetadataFields,
  ProfileMetadata,
  ProfileMetadataGroups,
  ProfileService,
  ProfileType,
  ProfileTypeMetadata,
} from "@intorqa-ui/shared";

@Pipe({
  name: "getProfileTypeLabel",
})
export class GetProfileTypeLabelPipe implements PipeTransform {
  constructor(private profileService: ProfileService) {}

  transform(profileTypeId: string): string {
    return this.profileService.types.find(
      (item: ProfileType) => (item.id = profileTypeId)
    )?.name;
  }
}

@Pipe({
  name: "groupMetadata",
})
export class GroupMetadataPipe implements PipeTransform {
  transform(
    metadata: Array<ProfileTypeMetadata | ProfileMetadata>
  ): Array<GroupMetadata> {
    if (!metadata || metadata.length === 0) return [];
    let groupedMetadata = metadata.reduce((groups, item) => {
      const group = groups[item.groupName] || [];
      group.push(item);
      groups[item.groupName] = group;
      return groups;
    }, {});

    const cheatFeaturesGroup =
      groupedMetadata[ProfileMetadataGroups.CHEAT_DETAILS];
    delete groupedMetadata[ProfileMetadataGroups.INFO];
    delete groupedMetadata[ProfileMetadataGroups.CHEAT_DETAILS];
    const sortedGroupedMetadata = Object.keys(groupedMetadata).map(
      (item: string) => new GroupMetadata(item, groupedMetadata[item])
    );

    if (cheatFeaturesGroup) {
      sortedGroupedMetadata.push(
        new GroupMetadata(
          ProfileMetadataGroups.CHEAT_DETAILS,
          cheatFeaturesGroup
        )
      );
    }

    return sortedGroupedMetadata;
  }
}

@Pipe({
  name: "getMetadataItemByGroupName",
})
export class GetMetadataItemByGroupNamePipe implements PipeTransform {
  transform(
    metadata: Array<ProfileMetadata>,
    groupName: string
  ): Array<ProfileMetadata> {
    return metadata?.filter(
      (item: ProfileMetadata) => item.groupName === groupName
    );
  }
}

@Pipe({
  name: "parseMetadataLabel",
})
export class ParseMetadataLabelPipe implements PipeTransform {
  constructor(private getMetadataField: GetMetadataFieldPipe) {}

  transform(label: string, metadata: Array<ProfileMetadata>): string {
    if (label === MetadataFields.PRICE_PER_MONTH) {
      const currencyMetadataValue = this.getMetadataField.transform(
        metadata,
        MetadataFields.PRICING_CURRENCY
      )?.values;
      if (currencyMetadataValue) {
        const parsedCurrencyMetadataValue = JSON.parse(currencyMetadataValue);
        return `${label} (${CurrencyPrefixDescription[parsedCurrencyMetadataValue]})`;
      }
    }
    return label;
  }
}
