import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { QueryFilters, TagCategory } from "@intorqa-ui/core";
import {
  CategoryService,
  ICustomTag,
  ITagMetadata,
  Query,
  TagComparison,
  TagService,
  TimeSeries,
  WidgetActions,
  WidgetService,
} from "@intorqa-ui/shared";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-all-tags-datasource",
  templateUrl: "./all-tags-datasource.component.html",
  styleUrls: ["./all-tags-datasource.component.scss"],
})
export class AllTagsDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;
  @Input() widget: TimeSeries | TagComparison;
  @Input() ecosystemId: string;
  @Input() action: WidgetActions;

  @Output() changeDataValue = new EventEmitter<Array<string>>();

  public dataPointsDataSource: Array<ICustomTag>;
  public dataValues: Array<any> = [];
  private changeEcosystemSubscription: Subscription;

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService,
    public widgetService: WidgetService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.changeEcosystemSubscription =
      this.widgetService.changeEcosystemObservable.subscribe(() => {
        this.onGetTags(this.initialState);
      });
    if (
      this.action === WidgetActions.SETTINGS ||
      this.action === WidgetActions.CLONE
    ) {
      this.loadMetadata();
    }
  }

  ngOnDestroy(): void {
    this.changeEcosystemSubscription.unsubscribe();
    this.form.removeControl("dataPoints");
  }

  private loadMetadata(): void {
    let tagIds = this.widget.getTagIds();
    let fieldFilterIds = this.widget.getFieldFilterTagIds();
    this.tagService
      .getSelections(
        tagIds,
        fieldFilterIds,
        undefined,
        this.categoryService.categories
      )
      .then((response: Array<ITagMetadata>) => {
        this.dataValues = response.map((item: ITagMetadata) => ({
          name: item.tagName,
          id: item.tagId,
        }));
        this.form.controls.dataPoints.setValue(this.dataValues || []);

        this.onGetTags(this.initialState);
      });
  }

  public createForm(): void {
    this.form.addControl(
      "dataPoints",
      new FormControl([], Validators.required)
    );
  }

  public onGetTags(params: QueryFilters): void {
    this.categoryService
      .getTags(
        params?.query,
        params,
        new Query().modelToDTO(),
        TagCategory["My Tags"],
        this.ecosystemId,
        params.page > 1
          ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1].name
          : undefined
      )
      .then((response: Array<ICustomTag>) => {
        this.dataPointsDataSource =
          params.page > 1
            ? [...this.dataPointsDataSource, ...response]
            : response;
      });
  }

  public onDataBound(): void {
    this.changeDataValue.emit(
      this.form.controls.dataPoints.value.map((item: ICustomTag) => item.id)
    );
  }
}
