import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
  IconType,
  Sizes,
} from "@intorqa-ui/core";
import {
  DocumentItem,
  NotificationsService,
  WidgetActions,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-share-email",
  templateUrl: "./share-email.component.html",
  styleUrls: ["./share-email.component.scss"],
})
export class ShareEmailComponent implements OnInit {
  public form: FormGroup;
  public showLoader = false;
  public IconType = IconType;
  public Sizes = Sizes;

  @Input() item: DocumentItem;
  @Input() action: WidgetActions;

  @Output() closeApi = new EventEmitter();

  @ViewChild("documentItemTemplate") documentItemTemplate: TemplateRef<unknown>;
  @ViewChild("Editor", { static: false })
  editorElementRef: any;

  public document: DocumentItem;

  constructor(
    private customOverlayRef: CustomOverlayRef,
    private snackBar: MatSnackBar,
    private notificationService: NotificationsService,
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig
  ) {}

  ngOnInit(): void {
    this.document = this.config.data?.item || this.item;
    this.form = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      content: new FormControl(""),
    });
  }

  public onSubmit(): void {
    this.showLoader = true;
    const message = {
      itemId: this.document.id,
      destination: this.form.controls.email.value,
      message: this.form.controls.content.value,
    };
    this.notificationService.shareEmail(message).then(() => {
      this.snackBar.open("Document has been shared succssefully", "Close", {
        horizontalPosition: "right",
        duration: 5000,
        verticalPosition: "top",
      });
      if (this.item) {
        this.closeApi.emit();
      } else {
        this.customOverlayRef.close();
      }
    });
  }

  public onClose(): void {
    if (this.config.data?.action === WidgetActions.RENDER) {
      this.customOverlayRef.close();
    } else {
      this.closeApi.emit();
    }
  }
}
