import { Sort, SortDirection } from "@angular/material/sort";
import { DTOQueryCondition, QueryRule } from "@intorqa-ui/shared";
import { DateQueryType } from "../../enums/date-range.enum";
import { SearchFieldType } from "../../enums/query-filters.enum";

export interface IQueryFilter {
  pageSize: number;
  page: number;
  where: IPresetQuery;
  query: string | Array<IQueryColumn>;
  dataValue?: string;
  sort: Sort;
}

export interface IQueryColumn {
  searchField: string;
  searchValues: Array<string>;
  searchFieldType: SearchFieldType;
  isMetadata?: boolean;
}

export interface IPresetQuery {
  label?: DateQueryType;
  start?: number;
  end?: number;
}
export interface ISort {
  active: string;
  direction: SortDirection;
  isMetadata?: boolean;
}
export interface IQueryDTO {
  condition: string;
  rules: Array<IQueryDTO | QueryRule | DTOQueryCondition>;
}
