import { IProfileConnectionDataSource } from "../../interfaces/profiles/profile-connection-datasource.interface";

export class ProfileConnectionDataSource implements IProfileConnectionDataSource {
  constructor(
    public typeName: string,
    public typeId: string,
    public profiles: Array<{ name: string; profileId: string }>,
    public targetProfileName: string,
    public updatedDate: string,
    public updatedBy: string
  ) {}
}
