import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PaymentDetailsNote, Profile } from "@intorqa-ui/shared";

@Component({
  selector: "itq-view-payment-details",
  templateUrl: "./view-payment-details.component.html",
  styleUrls: ["./view-payment-details.component.scss"],
})
export class ViewPaymentDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: PaymentDetailsNote;

  constructor() {}

  ngOnInit() {}
}
