import { Sections, TagCategory } from "@intorqa-ui/core";
import { DTOQuery } from "./query-dtos";
import { TagNodeType } from "../enums/tag.enum";

export interface Tag {
  tagId: string;
  description: string;
  createdDate: number;
  updatedDate: number;
  _extras: any;
  name: string;
  query: DTOQuery;
  sharedTag: boolean;
  username: string;
  categoryId: string;
  ecosystemId: string;
  lastTaggingTime: number;
  alertTypeId: string;
}

export interface ITimeline {
  tagId: string;
  createdDate: number;
  sharedTag: boolean;
  _extras: any;
  categoryId: string;
  lastTaggingTime: number;
  updatedDate: number;
  alertTypeId: string;
}

export interface ITimelineTag {
  tag: Tag;
  timelineId: string;
}

export interface IExtTag {
  id?: string;
  value: string;
  count: number;
}

export interface ITagResults {
  items: Array<IExtTag>;
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface ICustomTag {
  name: string;
  id: string;
  type: string;
  count?: number;
}

export interface TimelineDTO {
  id: string;
  lastTaggingTime: number;
  creationDate: number;
  name: string;
  tagKey: string;
}

export interface ITagMetadata {
  section: Sections;
  categoryName: TagCategory;
  tagName: string;
  tagId: string;
  included: boolean;
  excluded: boolean;
}

export interface ITagTreeNode {
  id: string;
  name: string;
  type: TagNodeType;
  organisation: string;
  children: Array<ITagTreeNode>;
}
