export enum TagCategory {
  content = "Content",
  "EmitType" = "EmitType",
  "Post Type" = "Post Type",
  User = "My Tags",
  Global = "System Tags",
  Source = "Source",
  Community = "Community",
  Shared = "Tags shared by my team",
  "My Tags" = "My Tags",
  "User Tag" = "Tags shared by my team",
  "System Tag" = "Communities",
  "Collection Source" = "Source",
  "Document Type" = "Post Type",
  "Document Attributes" = "Attribute",
  Actor = "Actor",
  Channel = "Channel",
  "Actor Activity" = "Actor Activity",
  ALL = "All",
}

export enum TagCategoryId {
  content = "Content",
  "EmitType" = "Post Type",
  "Post Type" = "EmitType",
  User = "My Tags",
  Global = "Communities",
  Source = "Source",
  Community = "Community",
  Shared = "Tags Shared By My Team",
  "My Tags" = "My Tags",
  "User Tag" = "Tags Shared By My Team",
  "System Tag" = "Communities",
  "Collection Source" = "Source",
  "Document Type" = "Post Type",
  "Document Attributes" = "Attribute",
  Actor = "Actor",
  Channel = "Channel",
  Bookmarks = "Bookmarks",
  ALL = "All",
}

export enum Sections {
  "My Tags" = "My Tags",
  "Communities and Sources" = "Communities and Sources",
  "Classifiers" = "Classifiers",
}

export enum SectionIcons {
  "My Tags" = "tag",
  "Communities and Sources" = "users",
  "Classifiers" = "sparkles",
}

export enum CategorySections {
  "My Tags" = "tag",
  "Communities and Sources" = "users",
  "Classifiers" = "sparkles",
}
