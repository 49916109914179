import { Pipe, PipeTransform } from "@angular/core";
import {
  AnalysisTypes,
  ANALYSIS_TYPES,
  IDisplayType,
} from "@intorqa-ui/shared";

@Pipe({
  name: "getAnalysisLabel",
})
export class GetAnalysisLabelPipe implements PipeTransform {
  transform(type: AnalysisTypes): string {
    return ANALYSIS_TYPES.find((item: IDisplayType) => item.id === type)
      .tooltip;
  }
}
