export const Colors = [
  "#1DE62A",
  "#00CB52",
  "#00AAF6",
  "#0074E5",
  "#2B5BD6",
  "#3A4FD3",
  "#8741CE",
  "#FF1F39",
  "#E50059",
  "#FF5D00",
];

export const ColorsDark = [
  "#00A208",
  "#008E34",
  "#0075AD",
  "#004EA1",
  "#1B3C97",
  "#273395",
  "#5F2891",
  "#B41122",
  "#A1003D",
  "#B44000",
];
