import {
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "striphtml",
  pure: false,
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    return value && value.replace("↵", "'\n'"); // replace tags
  }
}

@Pipe({
  name: "stripImg",
})
export class StripeImgPipe implements PipeTransform {
  transform(value: string): any {
    return value?.replace(new RegExp('<img.*?src="(.*?)"[^>]+>', "g"), "");
  }
}

@Pipe({
  name: "millisToDate",
  standalone: true,
})
export class MillisToDate implements PipeTransform {
  transform(value: number): Date {
    return new Date(value);
  }
}

@Pipe({
  name: "timeAgo",
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnDestroy(): void {
    this.removeTimer();
  }

  transform(d: Date): any {
    this.removeTimer();
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds)
      ? 1000
      : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== "undefined") {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return "";
    } else if (seconds <= 45) {
      return "a few seconds ago";
    } else if (seconds <= 90) {
      return "a minute ago";
    } else if (minutes <= 45) {
      return minutes + " minutes ago";
    } else if (minutes <= 90) {
      return "an hour ago";
    } else if (hours <= 22) {
      return hours + " hours ago";
    } else if (hours <= 36) {
      return "a day ago";
    } else if (days <= 25) {
      return days + " days ago";
    } else if (days <= 45) {
      return "a month ago";
    } else if (days <= 345) {
      return months + " months ago";
    } else if (days <= 545) {
      return "a year ago";
    } else {
      // (days > 545)
      return years + " years ago";
    }
  }
  private removeTimer(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }
  private getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}

@Pipe({ name: "safe", pure: true })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: "detecturl",
  pure: false,
})
export class DetectUrlPipe implements PipeTransform {
  transform(value: string): any {
    return value.replace(new RegExp("\n", "g"), "<br />");
  }
}

@Pipe({
  name: "getUsernameInitials",
  standalone: true,
})
export class GetUsernameInitialsPipe implements PipeTransform {
  constructor() {}
  transform(username: string): string {
    if (!username) return "";
    const splittedUsername = username.split(" ");
    return (
      splittedUsername[0]
        ?.substring(0, 1)
        ?.toUpperCase()
        ?.concat(splittedUsername[1]?.substring(0, 1)?.toUpperCase() || "") ||
      ""
    );
  }
}

@Pipe({
  name: "urlify",
})
export class UrlifyPipe implements PipeTransform {
  transform(text: string): any {
    var urlRegex =
      /(\b(https?|http|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text?.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  }
}
