import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IconType, PillType, QueryFilters, Sizes } from "@intorqa-ui/core";
import {
  ChartType,
  ConnectionsService,
  IConnectionsData,
  NetworkChart,
  Profile,
  ProfileService,
  ProfilesNavigationItem,
  WidgetActions,
  WidgetService,
} from "@intorqa-ui/shared";
import { ECharts } from "echarts";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-connections-network",
  templateUrl: "./connections-network.component.html",
  styleUrls: ["./connections-network.component.scss"],
})
export class ConnectionsNetworkComponent implements OnInit {
  @Input() profile: Profile;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() form: FormGroup;
  @Input() action: WidgetActions;
  @Input() showConnectionSettings: boolean;

  public widget: NetworkChart;
  public data: IConnectionsData;
  private connectionsSubscription: Subscription;
  public initialState = new QueryFilters(30, 1, undefined, undefined, {
    direction: "desc",
    active: "updatedDate",
  });
  public chartInstance: ECharts;

  readonly WidgetActions = WidgetActions;
  readonly Sizes = Sizes;
  readonly PillType = PillType;

  constructor(
    private connectionsService: ConnectionsService,
    private widgetService: WidgetService,
    readonly profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.connectionsSubscription =
      this.connectionsService.connections$.subscribe(
        (response: IConnectionsData) => {
          this.data = response;
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.navigationItem?.previousValue !==
      changes?.navigationItem?.currentValue
    ) {
      this.widget = new NetworkChart(this.profile.name, ChartType.NETWORK);
      this.connectionsService
        .getConnections(this.profile.profileId, this.initialState, true)
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.connectionsSubscription.unsubscribe();
  }

  public onToggleSettings(): void {
    this.connectionsService.showConnectionsSettings$.next(true);
  }

  public onExport(): void {
    this.widgetService.exportChart(
      `${this.profile.name}_connections`,
      this.chartInstance
    );
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  public onDrilldown(params: any): void {
    this.profileService
      .getProfileById(params.data.profileId)
      .subscribe((response: Profile) => {
        this.navigationItem.selectedTabIndex = 1;
        const navigationItem = new ProfilesNavigationItem(
          `${WidgetActions.EXPLORE}_${response.profileId}`,
          response,
          WidgetActions.EXPLORE,
          undefined,
          new QueryFilters(30, 1, undefined, undefined, {
            direction: "desc",
            active: "updatedDate",
          }),
          new FormGroup({}),
          "file-invoice",
          IconType.FONT_AWESOME,
          {
            id: "Timeline",
            type: ChartType.TIMELINE,
            svgIcon: "board",
            tooltip: "Timeline",
          },
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          0
        );
        this.widgetService.drilldownObservable.next(navigationItem);
      });
  }
}
