import {
  AnalysisTypes,
  BarMode,
  ChartOrientation,
  ChartType,
  DateInterval,
} from "../enums/widget.enum";
import {
  ITagAnalysis,
  ITagComparison,
  ITimeSeries,
  IWidget,
} from "../interfaces/widget/widget.interface";
import { TagAnalysis } from "../models/widgets/tag-analysis";
import { TagComparison } from "../models/widgets/tag-comparison";
import { TimeSeries } from "../models/widgets/time-series";

export class WidgetFactory {
  static createObject(
    widget: IWidget
  ): TagAnalysis | TagComparison | TimeSeries {
    if (widget.type === AnalysisTypes.TAG_ANALYSIS) {
      const tagAnalysis = widget as ITagAnalysis;
      return new TagAnalysis(
        tagAnalysis.widgetId,
        tagAnalysis.username,
        tagAnalysis.type,
        tagAnalysis.name,
        tagAnalysis.description,
        tagAnalysis.width || 30,
        tagAnalysis.height || 1,
        tagAnalysis.x || 0,
        tagAnalysis.y || 0,
        tagAnalysis.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        tagAnalysis.dataSource,
        tagAnalysis.dataPoints,
        tagAnalysis.top || 10,
        tagAnalysis.chartType || ChartType.BAR,
        tagAnalysis.ecosystemId
      );
    } else if (widget.type === AnalysisTypes.TAG_COMPARISON) {
      const tagComparison = widget as ITagComparison;
      return new TagComparison(
        tagComparison.widgetId,
        tagComparison.username,
        tagComparison.type,
        tagComparison.name,
        tagComparison.description,
        tagComparison.width || 30,
        tagComparison.height || 1,
        tagComparison.x || 0,
        tagComparison.y || 0,
        tagComparison.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        tagComparison.dataSource,
        tagComparison.dataValues,
        tagComparison.dataType,
        tagComparison.chartType || ChartType.BAR,
        tagComparison.ecosystemId
      );
    } else if (widget.type === AnalysisTypes.TIME_SERIES) {
      const timeSeries = widget as ITimeSeries;
      return new TimeSeries(
        timeSeries.widgetId,
        timeSeries.username,
        timeSeries.type,
        timeSeries.name,
        timeSeries.description,
        timeSeries.width || 30,
        timeSeries.height || 1,
        timeSeries.x || 0,
        timeSeries.y || 0,
        timeSeries.options || {
          orientation: ChartOrientation.VERTICAL,
          mode: BarMode.STACK,
        },
        timeSeries.dataSource,
        timeSeries.dataValues,
        timeSeries.dataType,
        timeSeries.chartType || ChartType.BAR,
        timeSeries.interval || DateInterval.DAY,
        timeSeries.ecosystemId
      );
    }
  }
}
