import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
  ErrorMessages,
  Errors,
  IError,
} from "@intorqa-ui/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private customOverlayService: CustomOverlayService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let error: IError;
    return next.handle(request).pipe(
      map((res) => {
        return res;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.error === ErrorMessages.BOARD_NOT_FOUND) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.BOARD_NOT_FOUND;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: true,
            },
          };
        } else if (errorResponse.error === ErrorMessages.PROFILE_NOT_FOUND) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.PROFILE_NOT_FOUND;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: true,
            },
          };
        } else if (errorResponse.error === ErrorMessages.TAG_NOT_FOUND) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.TAG_NOT_FOUND;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: true,
            },
          };
        } else if (
          errorResponse.error === ErrorMessages.CYCLIC_TAG_DEPENDENCY
        ) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.CYCLIC_TAG_DEPENDENCY;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: true,
            },
          };
        } else if (
          errorResponse.error === ErrorMessages.INVALID_DEPENDENCIES_UPDATE
        ) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.INVALID_DEPENDENCIES_UPDATE;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: true,
            },
          };
        } else if (
          errorResponse.error === ErrorMessages.USER_PREFERENCES_NOT_FOUND
        ) {
          error = {
            ...error,
            ...{
              code: errorResponse.status,
              stopPropagation: false,
            },
          };
        } else if (
          errorResponse.error === ErrorMessages.CANNOT_DELETE_DEFAULT_BOARD
        ) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.CANNOT_DELETE_DEFAULT_BOARD;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: false,
            },
          };
        } else if (errorResponse.error === ErrorMessages.UPDATE_TREE_ERROR) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.UPDATE_TREE_ERROR;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: false,
            },
          };
        } else if (errorResponse.error === ErrorMessages.DUPLICATE_TAG_NAME) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.DUPLICATE_TAG_NAME;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: false,
            },
          };
        } else if (
          errorResponse.error === ErrorMessages.TAG_API_PREFIX_NOT_FOUND
        ) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.TAG_API_PREFIX_NOT_FOUND;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: false,
            },
          };
        } else if (
          errorResponse.error === ErrorMessages.PARENT_DOCUMENT_NOT_FOUND
        ) {
          const errorMessage = Errors.find((item: IError) => {
            return item.error === ErrorMessages.PARENT_DOCUMENT_NOT_FOUND;
          });
          error = {
            ...errorMessage,
            ...{
              code: errorResponse.status,
              stopPropagation: false,
            },
          };
        } else {
          switch (errorResponse?.error?.message) {
            case ErrorMessages.TAG_MANDATORY_DESCRIPTION:
              error = {
                error: ErrorMessages.TAG_MANDATORY_DESCRIPTION,
                code: errorResponse.status,
                title: ErrorMessages.TAG_MANDATORY_TITLE,
                description: ErrorMessages.TAG_MANDATORY_DESCRIPTION,
                stopPropagation: false,
                formControlName: "name",
              };
              break;
            case ErrorMessages.DUPLICATE_NAME:
              error = {
                error: ErrorMessages.DUPLICATE_NAME,
                code: errorResponse.status,
                title: ErrorMessages.DUPLICATE_NAME,
                description: ErrorMessages.DUPLICATE_NAME,
                stopPropagation: false,
                formControlName: "name",
              };
              break;
            case ErrorMessages.NO_TAG_PERMISSIONS_DESCRIPTION:
              error = {
                error: ErrorMessages.NO_TAG_PERMISSIONS_DESCRIPTION,
                code: errorResponse.status,
                title: ErrorMessages.NO_TAG_PERMISSIONS_TITLE,
                description: ErrorMessages.NO_TAG_PERMISSIONS_DESCRIPTION,
                stopPropagation: true,
              };
              this.showAlert(error);
              break;
            case ErrorMessages.NO_TAG_FOUND:
              error = {
                error: ErrorMessages.NO_TAG_FOUND,
                code: errorResponse.status,
                title: ErrorMessages.NO_TAG_FOUND_TITLE,
                description: ErrorMessages.NO_TAG_FOUND,
                stopPropagation: true,
              };
              this.showAlert(error);
              break;
            case ErrorMessages.NO_DELETE_TAG_PERMISSION_DESCRIPTION:
              error = {
                error: ErrorMessages.NO_DELETE_TAG_PERMISSION_DESCRIPTION,
                code: errorResponse.status,
                title: ErrorMessages.NO_DELETE_TAG_PERMISSION_TITLE,
                description: ErrorMessages.NO_DELETE_TAG_PERMISSION_DESCRIPTION,
                stopPropagation: false,
              };
              this.showAlert(error);
              break;
            case ErrorMessages.NO_DELETE_TAG_BOARD_DESCRIPTION:
              error = {
                error: ErrorMessages.NO_DELETE_TAG_BOARD_DESCRIPTION,
                code: errorResponse.status,
                title: ErrorMessages.NO_DELETE_TAG_BOARD_TITLE,
                description: ErrorMessages.NO_DELETE_TAG_BOARD_DESCRIPTION,
                stopPropagation: true,
              };
              break;
            case ErrorMessages.TAG_NAME_CLASH_DESCRIPTION:
              error = {
                error: ErrorMessages.TAG_NAME_CLASH_DESCRIPTION,
                code: errorResponse.status,
                title: ErrorMessages.TAG_NAME_CLASH_DESCRIPTION,
                description: ErrorMessages.TAG_NAME_CLASH_DESCRIPTION,
                stopPropagation: false,
                formControlName: "name",
              };
              break;
            case ErrorMessages.DELETE_TAG_DEPENDENCIES:
              error = {
                error: ErrorMessages.DELETE_TAG_DEPENDENCIES,
                code: errorResponse.status,
                title: ErrorMessages.DELETE_TAG_DEPENDENCIES,
                description: errorResponse.error.data,
                stopPropagation: false,
              };
              break;

            default:
              error = {
                error: ErrorMessages.INTERNAL_SERVER_ERROR,
                code: errorResponse.status,
                title: ErrorMessages.INTERNAL_SERVER_ERROR_TITLE,
                description: ErrorMessages.INTERNAL_SERVER_ERROR_DESCRIPTION,
                stopPropagation: true,
              };
              this.showAlert(error);
              break;
          }
        }

        return throwError(error);
      })
    );
  }

  private showAlert(error: IError): void {
    this.customOverlayService.openCustom(
      {
        title: error.title,
        message: error.description,
        icon: ["far", "exclamation-circle"],
        size: "4x",
        dialog: {
          type: DialogTypes.ALERT,
        },
      },
      DialogComponent
    );
  }
}
