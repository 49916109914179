import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { cloneDeep } from "lodash";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { IconTypes, QueryFilters, Sizes, TagCategory } from "@intorqa-ui/core";
import {
  BarMode,
  ChartOrientation,
  ChartType,
  DataSourceType,
  IDisplayType,
  IWidget,
  LineMode,
  PieMode,
  Tag,
  TagComparison,
  TagComparisonDisplayTypes,
  Widget,
  WidgetActions,
  WidgetOption,
  WidgetService,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-ws-tag-comparison",
  templateUrl: "./ws-tag-comparison.component.html",
  styleUrls: ["./ws-tag-comparison.component.scss"],
})
export class WSTagComparisonComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.tagComparisonWidget = widget as TagComparison;
    if (!this.tagComparisonWidget.dataSource) {
      this.tagComparisonWidget.dataSource = [];
    }
    if (!this.tagComparisonWidget.dataType) {
      this.tagComparisonWidget.dataType = TagCategory["My Tags"];
    }
  }
  @Input() form: FormGroup;
  @Input() action: WidgetActions;
  @Input() initialState: QueryFilters;
  @Input() ecosystemId: string;

  @Output() update = new EventEmitter<IWidget>();
  @Output() showLoader = new EventEmitter<boolean>();

  public dataPointsDataSource: Array<Tag>;
  public options: echarts.EChartsOption;
  public tagComparisonWidget: TagComparison;
  public displayTypes = TagComparisonDisplayTypes;

  readonly IconTypes = IconTypes;
  readonly Sizes = Sizes;
  readonly DataSourceType = DataSourceType;
  readonly TagCategory = TagCategory;
  readonly ChartType = ChartType;
  readonly ChartOrientation = ChartOrientation;
  readonly LineMode = LineMode;
  readonly WidgetOption = WidgetOption;
  readonly PieMode = PieMode;
  readonly WidgetActions = WidgetActions;
  readonly BarMode = BarMode;

  constructor(readonly widgetService: WidgetService) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.removeControl("typeDataSource");
    this.form.removeControl("searchType");
  }

  private createForm(): void {
    this.form.addControl(
      "typeDataSource",
      new FormControl(
        this.tagComparisonWidget.dataSource.length === 0
          ? DataSourceType.ALL
          : DataSourceType.SET,
        Validators.required
      )
    );
    this.form.addControl(
      "searchType",
      new FormControl(this.displayTypes[0].id, Validators.required)
    );
  }

  public onDataBound(params: { prop: string; value: any }): void {
    this.tagComparisonWidget[params.prop] = params.value;
    this.update.emit(cloneDeep(this.tagComparisonWidget));
  }

  public onChangeDataValues(value: Array<string>): void {
    this.onDataBound({
      prop: "dataValues",
      value,
    });
  }

  public onChangeDataType(value: string): void {
    this.tagComparisonWidget.dataValues = [];
    this.onDataBound({
      prop: "dataType",
      value,
    });
  }

  public onChangeDataSources(value: Array<string>): void {
    this.tagComparisonWidget.dataValues = [];
    this.tagComparisonWidget.dataType = undefined;
    this.onDataBound({
      prop: "dataSource",
      value,
    });
  }

  public onChangeDataSourceType(event: MatRadioChange): void {
    if (event.value === DataSourceType.ALL) {
      this.tagComparisonWidget.dataSource = [];
      this.tagComparisonWidget.dataType = TagCategory["My Tags"];
      this.tagComparisonWidget.dataValues = [];
    } else {
      this.tagComparisonWidget.dataType = undefined;
      this.tagComparisonWidget.dataValues = [];
    }
    this.update.emit(cloneDeep(this.tagComparisonWidget));
  }

  public onChangeDisplayType(params: IDisplayType): void {
    this.tagComparisonWidget.chartType = params.type;
    this.tagComparisonWidget.options = params.options;
    this.update.emit(cloneDeep(this.tagComparisonWidget));
  }

  public getSelectedType(): IDisplayType {
    return this.tagComparisonWidget.getSelectedType();
  }

  public onShowLoader(showLoader: boolean): void {
    this.showLoader.emit(showLoader);
  }
}
