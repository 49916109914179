import { Pipe, PipeTransform } from "@angular/core";
import { Sort } from "@angular/material/sort";

@Pipe({
  name: "sortTableData",
  pure: true,
})
export class SortTableDataPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<any>): Array<any> {
    return data.sort((a: any, b: any) => {
      return a.value > b.value ? 1 : -1;
    });
  }
}
