import { Pipe, PipeTransform } from "@angular/core";
import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";

@Pipe({
  name: "castIconName",
  pure: false,
})
export class CastIconNamePipe implements PipeTransform {
  transform(value: string): IconName {
    return value as IconName;
  }
}

@Pipe({
  name: "castIconSize",
  pure: false,
})
export class CastIconSizePipe implements PipeTransform {
  transform(value: string): SizeProp {
    return value as SizeProp;
  }
}
