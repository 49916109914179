import {
  IAlert,
  IResearchAlert,
  ITagMatchAlert,
  ITagThresholdAlert,
} from "../../interfaces/alerts.interface";

export class Alert implements IAlert {
  constructor(
    public id: string,
    public message: string,
    public priority: string,
    public postSlack: boolean,
    public emailMe: boolean
  ) {}
}
export class ResearchAlert extends Alert implements IResearchAlert {
  constructor(
    public id: string,
    public priority: string,
    public message: string,
    public postSlack: boolean,
    public emailMe: boolean,
    public headline: number,
    public createdDate: number,
    public rawMessage: string,
    public createdBy: string,
    public sentBy: string,
    public clientIds: { [key: string]: string },
    public ecosystemId: string
  ) {
    super(id, message, priority, postSlack, emailMe);
  }
}

export class TagMatchAlert extends Alert implements ITagMatchAlert {
  constructor(
    public id: string,
    public active: boolean,
    public priority: string,
    public message: string,
    public postSlack: boolean,
    public emailMe: boolean,
    public delay: number
  ) {
    super(id, message, priority, postSlack, emailMe);
  }
}

export class TagThresholdAlert extends Alert implements ITagThresholdAlert {
  constructor(
    public id: string,
    public active: boolean,
    public priority: string,
    public message: string,
    public postSlack: boolean,
    public emailMe: boolean,
    public dailyAverage: string,
    public condition: string,
    public count: number,
    public period: number
  ) {
    super(id, message, priority, postSlack, emailMe);
  }
}
