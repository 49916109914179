import { NgModule } from "@angular/core";
import { WidgetSettingsChartComponent } from "./widget-settings-chart.component";
import {
  AngularModule,
  CoreModule,
  FAwesomeModule,
  MaterialModule,
} from "@intorqa-ui/core";
import { ChartWizardComponent } from "./chart-wizard/chart-wizard.component";
import { AllTagsDatasourceComponent } from "./chart-wizard/components/all-tags-datasource/all-tags-datasource.component";
import { ChartWizardDetailsComponent } from "./chart-wizard/components/chart-wizard-details/chart-wizard-details.component";
import { WSTagAnalysisComponent } from "./chart-wizard/components/ws-tag-analysis/ws-tag-analysis.component";
import { WSTimeSeriesComponent } from "./chart-wizard/components/ws-time-series/ws-time-series.component";
import { WSTagComparisonComponent } from "./chart-wizard/components/ws-tag-comparison/ws-tag-comparison.component";
import { SpecificTagDatasourceComponent } from "./chart-wizard/components/specific-tag-datasource/specific-tag-datasource.component";
import { ChartExploreComponent } from "./chart-explore/chart-explore.component";
import { SharedModule } from "../../../shared/shared.module";
import { FilterTagsPipe } from "./pipes/chart-wizard";
import { GetDateRangePipe } from "./chart-wizard/chart-wizard.pipe";
import { GetAnalysisLabelPipe } from "./chart-wizard/components/chart-wizard-details/chart-wizard-details.pipe";

@NgModule({
  imports: [
    FAwesomeModule,
    MaterialModule,
    AngularModule,
    CoreModule,
    SharedModule,
  ],
  declarations: [
    WidgetSettingsChartComponent,
    ChartWizardComponent,
    AllTagsDatasourceComponent,
    ChartWizardDetailsComponent,
    WSTagAnalysisComponent,
    WSTimeSeriesComponent,
    WSTagComparisonComponent,
    SpecificTagDatasourceComponent,
    ChartExploreComponent,
    FilterTagsPipe,
    GetDateRangePipe,
    GetAnalysisLabelPipe,
  ],
  exports: [WidgetSettingsChartComponent],
})
export class WidgetSettingsChartModule {}
