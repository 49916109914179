import { FormGroup } from "@angular/forms";
import { IconType, QueryFilters } from "@intorqa-ui/core";
import {
  IDisplayType,
  ISegment,
  QueryRule,
  Widget,
  WidgetActions,
} from "@intorqa-ui/shared";
import { INavigationHistoryItem } from "../interfaces/navigation-history-item.interface";

export class NavigationHistoryItem implements INavigationHistoryItem {
  constructor(
    public id: string,
    public item: Widget,
    public action: WidgetActions,
    public rules: Array<QueryRule>,
    public initialState: QueryFilters,
    public form: FormGroup,
    public icon: string,
    public iconType: IconType,
    public selectedDisplayType: IDisplayType,
    public segment: ISegment,
    public boardId: string
  ) {}
}
