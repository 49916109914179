import { Pipe, PipeTransform } from "@angular/core";
import { Timeline } from "@intorqa-ui/shared";
import { KeycloakService } from "keycloak-angular";

@Pipe({
  name: "isTagEnabled",
  pure: false,
})
export class IsTagEnabledPipe implements PipeTransform {
  constructor(private keycloakService: KeycloakService) {}

  transform(tag: Timeline): boolean {
    const isSuperAdmin = this.keycloakService.isUserInRole("super-admin");
    if (tag.categoryId && isSuperAdmin) {
      return true;
    }
    if (
      tag.username ===
        this.keycloakService.getKeycloakInstance().profile.username ||
      isSuperAdmin
    ) {
      return true;
    }
    return false;
  }
}
