export enum DateQueryType {
  "Last15Minutes" = "Last 15 minutes",
  "LastHour" = "Last hour",
  "Last12Hours" = "Last 12 hours",
  "LastDay" = "Last day",
  "LastWeek" = "Last week",
  "LastFortnight" = "Last fortnight",
  "LastMonth" = "Last month",
  "LastQuarter" = "Last quarter",
  "LastYear" = "Last year",
  "LastThreeYears" = "Last three years",
  "Custom" = "Custom",
  "Clear" = "Clear",
}
