import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownItem } from "@intorqa-ui/core";
import {
  EcosystemsService,
  NavigationHistoryItem,
  Query,
  QueryType,
  SEARCH_TYPES,
  TagService,
  Timeline,
  WidgetActions,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-timeline-wizard-details",
  templateUrl: "./timeline-wizard-details.component.html",
  styleUrls: ["./timeline-wizard-details.component.scss"],
})
export class TimelineWizardDetailsComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() form: FormGroup;
  @Input() metadataForm: FormGroup;
  @Input() query: Query;
  @Input() navigationItem: NavigationHistoryItem;

  @Output() changeQueryType = new EventEmitter<void>();
  @Output() changeEcosystem = new EventEmitter<void>();

  readonly SearchTypes = SEARCH_TYPES;

  public ecosystemDataSource: Array<IDropdownItem> = [];

  constructor(
    private ecosystemsService: EcosystemsService,
    readonly tagService: TagService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getEcosystems();
  }

  private getEcosystems(): void {
    this.ecosystemsService
      .getEcosystems()
      .subscribe((response: Array<{ id: string; name: string }>) => {
        this.ecosystemDataSource = response.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        if (this.ecosystemDataSource?.length > 1) {
          this.form.addControl(
            "ecosystemId",
            new FormControl(
              {
                value: this.widget?.ecosystemId,
                disabled: this.metadataForm.controls.category?.value,
              },
              [Validators.required]
            )
          );
        } else {
          this.widget.ecosystemId = this.ecosystemDataSource[0]?.value;
          if (this.navigationItem.action === WidgetActions.CREATE) {
            this.tagService.getFilters$.next();
          }
        }
      });
  }

  private createForm(): void {
    this.form.addControl(
      "searchType",
      new FormControl(this.query.type || QueryType.QUICK_SEARCH, [
        Validators.required,
      ])
    );
    this.form.addControl(
      "name",
      new FormControl(this.widget?.name, [Validators.required])
    );
    this.form.addControl(
      "description",
      new FormControl(this.widget?.description)
    );
  }

  public onChangeDisplayType(): void {
    this.changeQueryType.emit();
  }

  public onChangeEcosystem(): void {
    this.changeEcosystem.emit();
  }
}
