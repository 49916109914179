import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Sizes } from "../../enums/shared.enum";

@Component({
  selector: "itq-horizontal-scroller",
  templateUrl: "./horizontal-scroller.component.html",
  styleUrls: ["./horizontal-scroller.component.scss"],
})
export class HorizontalScrollerComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild("container") mainContainer: ElementRef<HTMLDivElement>;
  @ViewChild("scrollerWrapper") scrollerWrapper: ElementRef<HTMLDivElement>;

  @Output() updateRef = new EventEmitter<ElementRef<HTMLDivElement>>();
  @Output() updateViewPort = new EventEmitter();

  @Input() containerWidth: number;
  @Input() render: string;
  @Input() ref: ElementRef<HTMLDivElement>;
  @Input() itemsCount: number;
  @Input() showToggle: boolean;

  @HostBinding("class") classes = "itq-horizontal-scroller";

  curLeftPosition = 0;
  dragSize: number;
  public Sizes = Sizes;

  constructor() {}

  ngOnInit(): void {
    this.classes = `itq-horizontal-scroller ${this.render}`;
  }

  ngOnChanges(): void {
    this.curLeftPosition = 0;
    if (this.mainContainer && this.scrollerWrapper) {
      this.mainContainer.nativeElement.style.transform = `translateX(${Math.ceil(
        this.curLeftPosition
      )}px)`;
      this.dragSize = this.scrollerWrapper.nativeElement.clientWidth / 2;
    }
  }

  ngAfterViewInit(): void {
    this.updateRef.emit(this.scrollerWrapper);
    this.dragSize = this.scrollerWrapper.nativeElement.clientWidth / 2;
  }

  scrollLeft(): void {
    this.scrollerWrapper.nativeElement.scrollLeft -= this.containerWidth + 16;
    this.curLeftPosition =
      this.scrollerWrapper.nativeElement.scrollLeft - this.containerWidth;
    this.updateViewPort.emit();
  }

  scrollRight(): void {
    this.scrollerWrapper.nativeElement.scrollLeft += this.containerWidth + 16;
    this.curLeftPosition =
      this.scrollerWrapper.nativeElement.scrollLeft - this.containerWidth;

    this.updateViewPort.emit();
  }

  isScrollStart(): boolean {
    return this.curLeftPosition >= 0;
  }

  isScrollEnd(): boolean {
    if (this.scrollerWrapper && this.mainContainer) {
      const wrapperWidth =
        Math.abs(this.curLeftPosition) +
        this.scrollerWrapper.nativeElement.clientWidth;
      const scrollWidth = this.mainContainer.nativeElement.clientWidth;
      return wrapperWidth >= scrollWidth;
    }
  }

  onScroll(event: Event): void {
    event.preventDefault();
    this.curLeftPosition = this.scrollerWrapper.nativeElement.scrollLeft;
    this.updateViewPort.emit();
  }
}
