import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { DateRange } from "../date-range/date-range.model";

@Component({
  selector: "itq-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent,
    },
  ],
})
export class DatePickerComponent implements OnInit {
  @HostBinding("class.focus") applyClass = false;

  @HostListener("click", ["$event"]) onClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.applyClass = true;
  }
  @HostListener("document:click", ["$event"])
  click(): void {
    this.applyClass = false;
  }

  @Input() disabled = false;

  @Output() changeValue = new EventEmitter<number>();

  private touched = false;
  public value: number;
  public dateValue = new Date();

  constructor() {}

  ngOnInit() {}

  onChange = (items: number) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(value: number): void {
    this.value = value;
    if (this.value) {
      this.dateValue = DateRange.convertFromEpochSeconds(value);
    } else {
      this.dateValue = undefined;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>) {
    const date = event.value;
    const utcEpochMillis = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
    this.onChange(utcEpochMillis);
    this.changeValue.emit(utcEpochMillis);
  }

  public onClear(): void {
    this.dateValue = undefined;
    this.onChange(undefined);
    this.changeValue.emit(undefined);
  }
}
