import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import {
  Align,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  IconType,
  QueryFilters,
  SharedService,
  Sizes,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  ChartType,
  ConnectionsService,
  ILinksData,
  INotesData,
  LinkTag,
  LinkTagsService,
  NavigationHistoryItem,
  NotesService,
  Profile,
  ProfileConnection,
  ProfileMetadata,
  ProfileNote,
  ProfileService,
  ProfileTypeIcon,
  ProfileTypeMetadata,
  ProfilesNavigationItem,
  WidgetActions,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { Observable, Subscription, forkJoin, of } from "rxjs";
import { WidgetSettingsComponent } from "../../../widget-settings.component";
import { CreateProfileActions } from "./profiles-wizard.enum";
@Component({
  selector: "itq-profiles-wizard",
  templateUrl: "./profiles-wizard.component.html",
  styleUrls: ["./profiles-wizard.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
  ],
})
export class ProfilesWizardComponent implements OnInit {
  @Input() initialState: QueryFilters;
  @Input() navigationItem: ProfilesNavigationItem;

  public form: FormGroup;
  public profile: Profile;
  public showLoader = false;
  private loaderSubscription: Subscription;
  private connectionsSubscription: Subscription;
  public showAddTags = true;
  private showAddNotesSubscription: Subscription;
  private showAddConnectionsSubscription: Subscription;
  private notesSubscription: Subscription;
  private showLinkTagsSubscription: Subscription;
  public showLinkTags: boolean;
  public action: WidgetActions;

  readonly WidgetActions = WidgetActions;
  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly CreateProfileActions = CreateProfileActions;

  @ViewChild("stepper")
  stepper: MatStepper;

  constructor(
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    public profileService: ProfileService,
    private customOverlayRef: CustomOverlayRef,
    private noteService: NotesService,
    public linkTagsService: LinkTagsService,
    public connectionsService: ConnectionsService,
    public notesService: NotesService,
    private customOverlayService: CustomOverlayService
  ) {}

  ngOnInit(): void {
    this.profile = cloneDeep(this.navigationItem.item) as Profile;
    this.notesService.getNoteTypes(this.profile.profileTypeId).subscribe();
    this.createForm();

    this.showLinkTagsSubscription = this.linkTagsService.showLinkTag$.subscribe(
      (response: boolean) => {
        if (!response) {
          this.form.removeControl("addTags");
        }
        this.showLinkTags = response;
      }
    );
    this.showAddConnectionsSubscription =
      this.connectionsService.showAddConnections$.subscribe(
        (response: WidgetActions) => {
          if (!response) {
            this.form.removeControl("addConnections");
          }
          this.action = response;
        }
      );
    this.showAddNotesSubscription = this.noteService.showAddNotes$.subscribe(
      (response: WidgetActions) => {
        if (!response) {
          this.form.removeControl("addNotes");
        }
        this.action = response;
      }
    );
    this.loaderSubscription = this.sharedService.loader$.subscribe(
      (response: boolean) => {
        this.showLoader = response;
      }
    );

    this.notesSubscription = this.noteService.notes$.subscribe(() => {
      this.notesSubscription.unsubscribe();
    });
    this.connectionsSubscription =
      this.connectionsService.connections$.subscribe(() => {
        this.connectionsSubscription.unsubscribe();
      });
  }

  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
    this.connectionsSubscription.unsubscribe();
    this.showAddNotesSubscription.unsubscribe();
    this.notesSubscription.unsubscribe();
    this.showAddConnectionsSubscription.unsubscribe();
    this.showLinkTagsSubscription.unsubscribe();
  }

  public markDetailsAsTouched(): void {
    this.form.get("details").markAllAsTouched();
    this.form.get("metadata").markAllAsTouched();
  }

  private createForm(): void {
    this.form = new FormGroup({
      links: new FormControl(undefined, undefined, [
        this.linkTagsService.validateLinks(),
      ]),
      connections: new FormControl(undefined),
      notes: new FormControl(undefined),
    });
  }

  private saveProfile(): Observable<Profile> {
    const detailsGroup = this.form.get("details") as FormGroup;
    const metadata = this.getMetadata();
    return this.profileService.save({
      ecosystemId: this.profile.ecosystemId,
      name: detailsGroup.controls.name.value,
      profileTypeId: this.profile.profileTypeId,
      description: detailsGroup.controls.description.value,
      metadata: metadata,
    });
  }

  private getMetadata(): Array<ProfileMetadata> {
    const metadataGroup = this.form.get("metadata") as FormGroup;
    let result: Array<ProfileMetadata> = [];
    Object.keys(metadataGroup.controls).forEach((item: string) => {
      if (metadataGroup.controls[item].value) {
        const typeMetadata = this.profileService.typeMetadata.find(
          (typeMetadata: ProfileTypeMetadata) => {
            return item === typeMetadata.id;
          }
        );
        result.push(
          new ProfileMetadata(
            typeMetadata.id,
            typeMetadata.name,
            JSON.stringify(
              typeof metadataGroup.controls[item].value === "number"
                ? metadataGroup.controls[item].value.toString()
                : metadataGroup.controls[item].value
            ),
            typeMetadata.component,
            typeMetadata.groupName
          )
        );
      }
    });
    return result;
  }

  private connectProfiles(
    profile: Profile,
    connections: Array<ProfileConnection>
  ): Observable<Array<ProfileConnection>> {
    if (this.connectionsService.connections?.items?.length === 0) {
      return of(undefined);
    } else {
      return this.connectionsService.addConnections(profile, connections);
    }
  }

  public onSubmit(action: CreateProfileActions): void {
    this.showLoader = true;
    this.saveProfile().subscribe((response: Profile) => {
      forkJoin([
        this.connectProfiles(
          response,
          this.connectionsService.connections?.items
        ),
        this.addNotes(response, this.noteService.notes.items),
        this.linkTags(response.profileId, this.linkTagsService.links.items),
      ]).subscribe(() => {
        this.snackBar.open(
          "Your profile has been created, and is available for immediate use!",
          "Close",
          {
            horizontalPosition: "right",
            duration: 5000,
            verticalPosition: "top",
          }
        );
        this.showLoader = false;
        if (action === CreateProfileActions.CREATE_NEW) {
          this.onReset();
          this.profileService.getProfiles$.next();
        } else if (action === CreateProfileActions.LOAD) {
          this.customOverlayRef.close({ refresh: true });
          this.onView(response);
        } else {
          this.customOverlayRef.close({ refresh: true });
        }
      });
    });
  }

  public onView(profile: Profile): void {
    if (profile) {
      const typeName = this.profileService.getProfileTypeById(
        profile.profileTypeId
      )?.name;
      const navigationItem = new NavigationHistoryItem(
        `${WidgetActions.EXPLORE}_${profile.profileId}`,
        profile,
        WidgetActions.EXPLORE,
        undefined,
        new QueryFilters(30, 1, undefined, undefined, {
          direction: "desc",
          active: "updatedDate",
        }),
        new FormGroup({}),
        ProfileTypeIcon[typeName],
        IconType.FONT_AWESOME,
        {
          id: "Timeline",
          type: ChartType.TIMELINE,
          svgIcon: "board",
          tooltip: "Timeline",
        },
        undefined,
        undefined
      );
      this.customOverlayService.open({
        data: {
          navigationItem,
        },
        closeBtnStyle: "basic",
        type: CustomOverlayType["almost-full"],
        size: "lg",
        component: WidgetSettingsComponent,
        disposeOnNavigation: true,
      });
    }
  }

  private onReset(): void {
    this.connectionsService.resetConnections();
    this.noteService.resetNotes();
    this.linkTagsService.resetLinks();
    this.profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      undefined,
      undefined,
      ChartType.PROFILE,
      this.profile.ecosystemId,
      this.profile.profileTypeId,
      this.profile.profileTypeName,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    this.stepper.reset();
    this.linkTagsService.showLinkTag$.next(false);
    this.connectionsService.showAddConnections$.next();
    this.noteService.showAddNotes$.next();
    this.linkTagsService.reset$.next();
    this.connectionsService.reset$.next();
    this.noteService.reset$.next();
    this.form.get("details").reset();
    this.form.get("metadata").reset();
    this.form.get("connections").reset();
    this.form.get("links").reset();
    this.form.get("notes").reset();
  }

  private addNotes(
    profile: Profile,
    notes: Array<ProfileNote>
  ): Observable<INotesData> {
    if (this.noteService.notes.items?.length === 0) {
      return of(undefined);
    } else {
      return this.noteService.addNotes(profile, profile, notes);
    }
  }

  private linkTags(
    profileId: string,
    tags: Array<LinkTag>
  ): Observable<ILinksData> {
    if (tags?.length === 0) {
      return of(undefined);
    } else {
      return this.linkTagsService.linkTags(profileId, tags);
    }
  }
}
