import { Injectable } from "@angular/core";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import { Ecosystem, IEcosystem } from "@intorqa-ui/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EcosystemsService {
  public ecosystems$ = new Subject<Array<Ecosystem>>();
  private _ecosystems: Array<Ecosystem>;

  get ecosystems(): Array<Ecosystem> {
    return this._ecosystems;
  }

  set ecosystems(value: Array<Ecosystem>) {
    this._ecosystems = value;
  }
  constructor(private apiRequestService: ApiRequestService) {}

  public getEcosystems(): Observable<Array<Ecosystem>> {
    return this.apiRequestService
      .getToObservable("/ecosystems", new DTOTypeConverter<Array<IEcosystem>>())
      .pipe(
        map((response: Array<IEcosystem>) => {
          this.ecosystems = response.map(
            (item: IEcosystem) =>
              new Ecosystem(
                item.id,
                item.name,
                item.description,
                item.communityIds
              )
          );
          this.ecosystems$.next(this.ecosystems);
          return this.ecosystems;
        })
      );
  }

  public getEcosystemById(id: string): Observable<Ecosystem> {
    return this.apiRequestService
      .getToObservable(`/ecosystems/${id}`, new DTOTypeConverter<IEcosystem>())
      .pipe(
        map((response: IEcosystem) => {
          return new Ecosystem(
            response.id,
            response.name,
            response.description,
            response.communityIds
          );
        })
      );
  }
}
