import { TemplatePortal } from "@angular/cdk/portal";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { OpenDirection, OpenStatus, Styles } from "../../enums/dropdown.enum";
import { Sizes } from "../../enums/shared.enum";
import { QueryFilters } from "../../models/query-model/query-filters";
import { PortalBridgeService } from "../../services/portal-bridge.service";
import { MatButtonComponent } from "../mat-button/mat-button.component";

@Component({
  selector: "itq-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent,
    },
  ],
})
export class DropdownComponent
  implements OnInit, ControlValueAccessor, OnChanges, OnDestroy
{
  @ViewChild("portalContent") portalContent: TemplateRef<unknown>;
  @ViewChild("button") button: MatButtonComponent;

  @Input() style = Styles.FIT;
  @Input() disabled = false;
  @Input() showHeader = true;
  @Input() template: TemplateRef<unknown>;
  @Input() direction = "down";
  @Input() width: string;
  @Input() emptyDataSourceMessage = "No results found";
  @Input() dataSource: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() placeholder = "Please select an item...";
  @Input() icon: IconName;
  @Input() text = "";
  @Input() svgIcon: string;
  @Input() textTransform: string;
  @Input() color: string;
  @Input() materialStyle = "stroked";
  @Input() padding = Sizes["X-SM"];
  @Input() label: string;
  @Input() formElement = false;
  @Input() invalid: boolean;
  @Input() clearSelection = true;
  @Input() httpBinding = false;

  @Output() changeValue = new EventEmitter<any>();
  @Output() dataBound = new EventEmitter<QueryFilters>();
  @Output() clear = new EventEmitter<void>();

  pageX = 0;
  pageY = 0;

  public dropdownWidth: number;
  private touched = false;
  public showLoader = false;
  public open = false;
  public selection: any;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined
  );

  readonly OpenDirection = OpenDirection;
  readonly Sizes = Sizes;

  @HostListener("document:click", ["$event"])
  click(): void {
    if (this.open) {
      this.toggleDropdown(false);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    if (this.open) {
      this.toggleDropdown(false);
    }
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private portalBridgeService: PortalBridgeService
  ) {}

  onChange = (items: string) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: string): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      if (this.initialState && this.showLoader) {
        this.showLoader = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.toggleDropdown(false);
  }

  loadPortal(): void {
    const portal = new TemplatePortal(
      this.portalContent,
      this.viewContainerRef
    );
    this.portalBridgeService.portal = portal;
  }

  onClick(): void {
    this.onTouched(true);
    this.toggleDropdown(!this.open);
  }

  toggleDropdown(action: boolean): void {
    if (action) {
      this.setDropdownWidth();
      this.setDropdownPosition();
      this.loadPortal();
    }
    this.open = action;
    if (this.open) {
      if (this.httpBinding) {
        this.dataBound.emit(this.initialState);
      }
      this.portalBridgeService.toggle(OpenStatus.OPEN, {
        left: this.pageX,
        top: this.pageY,
      });
    } else {
      this.portalBridgeService.toggle(OpenStatus.CLOSE);
    }
  }

  setDropdownWidth(): void {
    if (this.width === "fit") {
      this.dropdownWidth = undefined;
    } else {
      this.dropdownWidth =
        this.width ||
        this.button?.dropdownButton?._elementRef?.nativeElement?.clientWidth;
    }
  }

  setDropdownPosition(): void {
    const dropdownBoundingRect =
      this.button?.dropdownButton?._elementRef?.nativeElement?.getBoundingClientRect();
    if (window.innerHeight - dropdownBoundingRect?.y < 300) {
      this.direction = "up";
      this.pageY = dropdownBoundingRect?.bottom - dropdownBoundingRect.height;
    } else {
      this.pageY = dropdownBoundingRect?.bottom + 20;
    }
    this.pageX = dropdownBoundingRect?.left - 10;
  }

  public onDataBound(params: QueryFilters): void {
    if (this.httpBinding) {
      this.showLoader = true;
      this.initialState = params;
      this.dataBound.emit(this.initialState);
    }
  }

  public onChangeValue(value: any): void {
    this.toggleDropdown(false);
    this.selection = value;
    this.onChange(this.selection);
    this.changeValue.emit(value);
  }

  public onClear(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.selection = undefined;
    this.onChange(this.selection);
    this.initialState = new QueryFilters(
      30,
      1,
      undefined,
      undefined,
      undefined
    );
    this.clear.emit();
  }
}
