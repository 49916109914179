import { FormGroup } from "@angular/forms";
import { IconType, QueryFilters } from "@intorqa-ui/core";
import {
  IDisplayType,
  ISegment,
  QueryRule,
  Widget,
  WidgetActions
} from "@intorqa-ui/shared";
import { NavigationHistoryItem } from "./navigation-history-item.model";
import { ITimelineNavigationItem } from '../interfaces/timeline-navigation-item.interface';

export class TimelineNavigationItem
  extends NavigationHistoryItem
  implements ITimelineNavigationItem
{
  constructor(
    public id: string,
    public item: Widget,
    public action: WidgetActions,
    public rules: Array<QueryRule>,
    public initialState: QueryFilters,
    public form: FormGroup,
    public icon: string,
    public iconType: IconType,
    public selectedDisplayType: IDisplayType,
    public segment: ISegment,
    public boardId: string,
    public activeDocument: string
  ) {
    super(
      id,
      item,
      action,
      rules,
      initialState,
      form,
      icon,
      iconType,
      selectedDisplayType,
      segment,
      boardId
    );
  }
}
