import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BioLinkNote, Profile } from "@intorqa-ui/shared";

@Component({
  selector: "itq-view-bio-link",
  templateUrl: "./view-bio-link.component.html",
  styleUrls: ["./view-bio-link.component.scss"],
})
export class ViewBioLinkComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: BioLinkNote;

  constructor() {}

  ngOnInit() {}
}
