import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  ISegment,
  WidgetActions,
  NavigationHistoryItem,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-widget-settings-chart",
  templateUrl: "./widget-settings-chart.component.html",
  styleUrls: ["./widget-settings-chart.component.scss"],
})
export class WidgetSettingsChartComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() articleDetail: ISegment;
  @Input() segment: any;
  @Input() form: FormGroup;

  public WidgetActions = WidgetActions;

  constructor() {}

  ngOnInit(): void {}
}
