import { BoardType, NodeType } from "../enums/board.enum";
import { Board } from "../models/board";
import { IGroup } from "./group.interface";
import { IPresetQuery } from "./query-dtos";

export interface ITree {
  tree: Array<ITreeNode>;
  boards: Array<Board>;
  groups: Array<IGroup>;
  version: number;
}

export interface ITreeNode {
  readonly id: string;
  readonly type: NodeType;
  children: ITreeNode[];
  name: string;
  readonly icon?: string;
  boardType: BoardType;
  defaultBoard: boolean;
}

export interface IBoard {
  readonly id: string;
  name: string;
  description: string;
  defaultBoard: boolean;
  filter?: {
    date: IPresetQuery;
  };
  widgetIds: Array<string>;
  readonly type: BoardType;
  widgetPositions: Array<IPosition>;
}

export interface IPosition {
  widgetId: string;
  attr: string;
  x: number;
}
