import { ComponentType } from "@intorqa-ui/core";
import { IProfileMetadata } from "../../interfaces/profiles/profile-metadata.interface";

export class ProfileMetadata implements IProfileMetadata {
  constructor(
    public id: string,
    public name: string,
    public values: string,
    public component: ComponentType,
    public groupName: string
  ) {}
}
