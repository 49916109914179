import { Injectable } from "@angular/core";
import {
  ApiRequestService,
  DTOCreation,
  DTOMessage,
  DTOTypeConverter,
} from "@intorqa-ui/api";
import { IError } from "@intorqa-ui/core";
import { cloneDeep } from "lodash";
import { Observable, Subject } from "rxjs";
import { BoardType, NodeType } from "../enums/board.enum";
import { AnalysisTypes, ChartType } from "../enums/widget.enum";
import { WidgetFactory } from "../factories/widget";
import { IBoard, ITree, ITreeNode } from "../interfaces/board.interface";
import { IGroup } from "../interfaces/group.interface";
import { IPresetQuery } from "../interfaces/query-dtos";
import { ITagTreeNode, ITimelineTag } from "../interfaces/tags-dtos";
import { ISyncFusionTreeNode } from "../interfaces/tree.interface";
import { IWidget } from "../interfaces/widget/widget.interface";
import { Board } from "../models/board";
import { TagAnalysis } from "../models/widgets/tag-analysis";
import { TagComparison } from "../models/widgets/tag-comparison";
import { TimeSeries } from "../models/widgets/time-series";
import { Timeline } from "../models/widgets/timeline";
import { Widget } from "../models/widgets/widget";
import { Query } from "../models/query-model/query-model";

@Injectable({
  providedIn: "root",
})
export class BoardService {
  public loadNode$ = new Subject<string>();
  public removeTimelines$ = new Subject<Timeline>();
  public addTimeline$ = new Subject<Timeline>();
  public toggleSideBarObservable = new Subject<string>();
  public updateViewObservable = new Subject<Board>();
  public boards: Array<Board> = [];

  public board: Board;
  public tree: ITree;
  public preventUpdate = false;
  public groups: Array<IGroup> = [];
  public timelineWidgets: Array<Timeline> = [];
  public widgets: Array<TagAnalysis | TagComparison | TimeSeries> = [];
  public treeVersion: number;

  constructor(public apiRequestService: ApiRequestService) {}

  public getBoards(): Promise<Array<IBoard>> {
    return new Promise((resolve) => {
      if (this.boards?.length > 0) {
        resolve(this.boards);
      }
      this.apiRequestService
        .get(
          "/boards",
          {},
          new DTOTypeConverter<Array<IBoard>>(),
          undefined,
          "v2.0"
        )
        .then((response: Array<Board>) => {
          this.boards = response;
          resolve(response);
        });
    });
  }

  public getBoardById(boardId: string): Promise<Board> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .get(
          "/boards/" + boardId,
          {},
          new DTOTypeConverter<IBoard>(),
          undefined,
          "v2.0"
        )
        .then((response: IBoard) => {
          this.board = new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.type,
            response.widgetPositions
          );
          resolve(this.board);
        })
        .catch(() => reject());
    });
  }

  public createBoard(board: {
    name: string;
    type: BoardType;
    filter?: {
      date: IPresetQuery;
    };
  }): Promise<Board> {
    return new Promise((resolve) => {
      const payload = {
        ...board,
        treeVersion: this.treeVersion,
      };
      this.apiRequestService
        .post(
          "/boards",
          new DTOTypeConverter<IBoard>(),
          JSON.stringify(payload),
          undefined,
          "v2.0"
        )
        .then((response: IBoard) => {
          const board = new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.type,
            response.widgetPositions
          );
          this.boards.push(board);
          resolve(board);
        });
    });
  }

  public getDashboardWidgets(
    board: IBoard
  ): Promise<Array<TagAnalysis | TagComparison | TimeSeries>> {
    return new Promise((resolve) => {
      this.apiRequestService
        .get(
          `/boards/${board?.id}/widgets`,
          {},
          new DTOTypeConverter<Array<IWidget>>(),
          undefined,
          "v2.0"
        )
        .then((response: Array<IWidget>) => {
          this.widgets = [];
          response.forEach((item: IWidget) => {
            this.widgets.push(WidgetFactory.createObject(item));
          });
          resolve(this.widgets);
        });
    });
  }

  public updateBoard(
    id: string,
    params: {
      type: BoardType;
      [key: string]: any;
    }
  ): Promise<IBoard> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .put(
          "/boards/" + id,
          new DTOTypeConverter<IBoard>(),
          JSON.stringify(params),
          undefined,
          "v2.0"
        )
        .then((response: IBoard) => {
          this.board = new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.type,
            response.widgetPositions
          );
          if (params.widgetIds) {
            params.widgetIds.forEach((widgetId: string) => {
              if (params.type === BoardType.WIDGET) {
                if (!this.findAnalyticsWidgetById(widgetId)) {
                  this.widgets = this.widgets.filter((item: Widget) => {
                    return item.widgetId !== widgetId;
                  });
                }
              } else {
                if (!this.findWidgetById(widgetId)) {
                  this.timelineWidgets = this.timelineWidgets.filter(
                    (item: Timeline) => {
                      return item.widgetId !== widgetId;
                    }
                  );
                }
              }
            });
          }
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  public deleteBoard(boardId: string): Promise<void> {
    return new Promise((resolve) => {
      this.apiRequestService
        .delete(
          "/boards/" + boardId,
          { body: JSON.stringify({ treeVersion: this.treeVersion }) },
          "v2.0"
        )
        .then(() => {
          resolve();
        });
    });
  }

  public findBoardById(id: string): Board {
    return this.boards.find((board: Board) => {
      return board.id === id;
    });
  }

  public getTree(): Promise<ITree> {
    return new Promise((resolve) => {
      this.apiRequestService
        .get(
          "/boards/tree",
          {},
          new DTOTypeConverter<ITree>(),
          undefined,
          "v2.0"
        )
        .then((response: ITree) => {
          this.treeVersion = response.version;
          this.updateTreeData(response);
          resolve(this.tree);
        });
    });
  }

  public transformTree(
    tree: Array<ITreeNode>,
    boards: Array<IBoard>,
    groups: Array<IGroup>
  ): Array<ITreeNode> {
    const findBoardById = (list: Array<IBoard>, id: string) => {
      return list.find((board: IBoard) => {
        return board.id === id;
      });
    };
    const findGroupById = (list: Array<IGroup>, id: string) => {
      return list.find((group: IGroup) => {
        return group.uuid === id;
      });
    };
    return tree.map((item: ITreeNode) => {
      let board: IBoard;
      let childBoard: IBoard;
      let group: IGroup;

      if (item.type === NodeType.BOARD) {
        board = findBoardById(boards, item.id);
      } else {
        group = findGroupById(groups, item.id);
      }
      if (item.children?.length > 0) {
        item.children = item.children.map((node: ITreeNode) => {
          childBoard = findBoardById(boards, node.id);
          return {
            ...node,
            ...{
              name: childBoard?.name,
              icon: childBoard?.type,
            },
          };
        });
      }
      return {
        ...item,
        ...{
          name: board?.name || group?.name,
          icon:
            item.type === NodeType.BOARD
              ? board?.type || NodeType.BOARD
              : NodeType.GROUP,
        },
      };
    });
  }

  public updateTreeData(response: ITree): void {
    this.tree = {
      tree: this.transformTree(response.tree, response.boards, response.groups),
      boards: response.boards,
      groups: response.groups,
      version: response.version,
    };
    this.boards = response.boards;
    this.groups = response.groups;
  }

  public updateTree(tree: Array<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      const apiTree = tree.map((node: ISyncFusionTreeNode) => {
        return {
          type: node.isGroup ? NodeType.GROUP : NodeType.BOARD,
          id: node.id,
          children:
            node.child?.length > 0
              ? node.child.map((child: any) => {
                  return {
                    id: child.id,
                    type: NodeType.BOARD,
                    children: [],
                  };
                })
              : [],
        };
      });
      this.apiRequestService
        .put(
          "/boards/tree",
          new DTOTypeConverter<DTOMessage>(),
          JSON.stringify({ tree: apiTree, version: this.treeVersion }),
          undefined,
          "v2.0"
        )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: IError) => {
          reject(error);
        });
    });
  }

  public getTreeShape(
    tree: ITree,
    selectedBoardId: string
  ): Array<ISyncFusionTreeNode> {
    const getChildren = (node: ITreeNode) => {
      return node.children?.map((child: ITreeNode) => {
        const board = this.findBoardById(child.id);
        return {
          id: child.id,
          name: child.name,
          icon: child.icon,
          isGroup: false,
          child: [],
          hasChildren: false,
          type: child.type,
          isSelected: selectedBoardId === child?.id,
          boardType: board.type,
          defaultBoard: board.defaultBoard,
        };
      });
    };
    const isExpanded = (node: ITreeNode) => {
      let foundNode = false;
      node.children?.forEach((child: ITreeNode) => {
        if (child.id === selectedBoardId) {
          foundNode = true;
        }
      });

      return foundNode;
    };
    let result: ISyncFusionTreeNode;
    return tree?.tree?.map((node: ITreeNode) => {
      const board = this.findBoardById(node.id);
      result = {
        id: node.id,
        name: node.name,
        hasChildren: node.children?.length > 0,
        expanded: isExpanded(node),
        child: getChildren(node) || [],
        isGroup: node.type === NodeType.GROUP,
        icon: node.type === NodeType.GROUP ? undefined : node.icon,
        isSelected: selectedBoardId === node?.id,
        defaultBoard: node.defaultBoard,
      };
      if (node.type === NodeType.BOARD) {
        result = { ...result, ...{ boardType: board.type } };
      }
      return result;
    });
  }

  public findTreeIndexById(id: string): number {
    let index: number;
    this.tree.tree.forEach((item: ITreeNode, i: number) => {
      if (item.id === id) {
        index = i;
      }
    });

    return index;
  }

  public updateGroup(group: IGroup): Promise<void> {
    return new Promise((resolve) => {
      this.apiRequestService
        .put(
          "/boards/group/" + group.uuid,
          new DTOTypeConverter<DTOMessage>(),
          JSON.stringify(group),
          undefined,
          "v2.0"
        )
        .then(() => {
          resolve();
        });
    });
  }

  public deleteGroup(groupId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .delete(
          "/boards/group/" + groupId,
          { body: JSON.stringify({ treeVersion: this.treeVersion }) },
          "v2.0"
        )
        .then(() => {
          resolve();
        })
        .catch((error: IError) => reject(error));
    });
  }

  public createGroup(name: string): Promise<IGroup> {
    return new Promise((resolve) => {
      const payload = { name, treeVersion: this.treeVersion };
      this.apiRequestService
        .post(
          "/boards/group",
          new DTOTypeConverter<DTOCreation>(),
          JSON.stringify(payload),
          undefined,
          "v2.0"
        )
        .then((response: any) => {
          resolve(response);
        });
    });
  }

  public getTimelineWidgets(board: IBoard): Promise<Array<Timeline>> {
    return new Promise((resolve) => {
      this.apiRequestService
        .get(
          `/boards/${board?.id}/widgets`,
          {},
          new DTOTypeConverter<Array<ITimelineTag>>(),
          undefined,
          "v2.0"
        )
        .then((response: Array<ITimelineTag>) => {
          this.timelineWidgets = [];
          response = response?.sort((a: ITimelineTag, b: ITimelineTag) =>
            a.tag.name.trim().localeCompare(b.tag.name.trim())
          );
          response?.forEach((item: ITimelineTag) => {
            const extras = new Query();
            extras.query = extras.dtoToModel(item.tag.query);
            extras.type = JSON.parse(item.tag._extras)?.type;
            this.timelineWidgets.push(
              new Timeline(
                item.timelineId,
                item.tag.username,
                AnalysisTypes.TIMELINE,
                item.tag.name,
                item.tag.description,
                ChartType.TIMELINE,
                item.tag.tagId,
                item.tag.createdDate,
                item.tag.sharedTag,
                extras,
                item.tag.categoryId,
                item.tag.lastTaggingTime,
                item.tag.ecosystemId,
                item.tag.updatedDate,
                item.tag.alertTypeId
              )
            );
          });
          this.board.widgetIds = this.timelineWidgets.map(
            (item: Timeline) => item.widgetId
          );
          resolve(cloneDeep(this.timelineWidgets));
        });
    });
  }

  public findBoardByProp(key: string, value: any): IBoard {
    return this.boards.find((item: IBoard) => {
      return item[key] === value;
    });
  }

  public findWidgetById(id: string): Widget {
    return this.timelineWidgets.find((widget: Widget) => {
      return widget.widgetId === id;
    });
  }

  public findAnalyticsWidgetById(
    id: string
  ): TagAnalysis | TagComparison | TimeSeries {
    return this.widgets.find(
      (widget: TagAnalysis | TagComparison | TimeSeries) => {
        return widget.widgetId === id;
      }
    );
  }

  public updateWidgets(
    boardId: string,
    payload: { add?: Array<string>; delete?: Array<string> }
  ): Observable<IBoard> {
    return this.apiRequestService.putToObservable(
      `/boards/${boardId}/widgets`,
      JSON.stringify(payload),
      undefined,
      "v2.0"
    );
  }

  public getDefault(): Board {
    return this.boards.find((item: Board) => item.defaultBoard);
  }

  public getTags(id: string): Promise<Array<string>> {
    return this.apiRequestService.get(
      `/tags/${id}/boards`,
      {},
      new DTOTypeConverter<Array<string>>(),
      undefined,
      "v1.0"
    );
  }

  /**
   * Searches the tree dataset for nodes that match the given query.
   * If the query is empty, returns the entire dataset.
   *
   * @param dataset - The array of tree nodes to search.
   * @param query - The query string to match against node names.
   * @returns An array of tree nodes that match the query.
   */
  public searchTree({
    dataset,
    query,
  }: {
    dataset: Array<ISyncFusionTreeNode>;
    query: string;
  }): Array<ISyncFusionTreeNode> {
    if (!query) {
      return dataset;
    }
    const result: Array<ISyncFusionTreeNode> = [];
    dataset.forEach((node: ISyncFusionTreeNode) => {
      const children: Array<ISyncFusionTreeNode> = node.child?.filter(
        (childNode: ISyncFusionTreeNode) =>
          childNode.name
            .toLowerCase()
            .trim()
            .includes(query.toLowerCase().trim())
      );
      if (node.name.toLowerCase().trim().includes(query.toLowerCase().trim())) {
        result.push({ ...node, ...{ child: node.child } });
      } else if (children.length > 0) {
        result.push({ ...node, ...{ child: children } });
      }
    });
    return result;
  }

  public updateTimeline(timeline: Timeline): void {
    this.timelineWidgets = this.timelineWidgets.map((item: Timeline) =>
      timeline.widgetId === item.widgetId ? timeline : item
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/boards/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      "v2.0"
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/boards/${unlinkId}/unlink/tag/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      "v2.0"
    );
  }

  public unlinkWidget(
    unlinkId: string,
    dependencyId: string
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/boards/${unlinkId}/unlink/widget/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      "v2.0"
    );
  }
}
