import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  CustomOverlayConfig,
  CUSTOM_OVERLAY_DATA,
  DialogComponent,
  DialogTypes,
  CustomOverlayService,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  DiscordNavigationItem,
  ISegment,
  NavigationHistory,
  NavigationHistoryItem,
  ResponsivePanels,
  WidgetService,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-widget-settings",
  templateUrl: "./widget-settings.component.html",
  styleUrls: ["./widget-settings.component.scss"],
})
export class WidgetSettingsComponent implements OnInit, OnDestroy {
  public AnalysisTypes = AnalysisTypes;
  public selectedNavigationHistory: NavigationHistoryItem;
  private drilldownSubscription: Subscription;
  public expandedNavigation = true;
  public navigationHistory: NavigationHistory;
  public segment: any;
  public articleDetail: ISegment;
  public updateSegmentSubscription: Subscription;
  public form: FormGroup;
  private updateNavigationItemSubscription: Subscription;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService
  ) {}

  ngOnInit(): void {
    this.selectedNavigationHistory = this.config.data
      ?.navigationItem as NavigationHistoryItem;
    this.navigationHistory = new NavigationHistory([
      this.selectedNavigationHistory,
    ]);
    this.form = this.selectedNavigationHistory.form;
    this.articleDetail = this.config.data?.articleDetail;
    this.segment = this.config?.data?.segment;

    this.widgetService.responsivePanels = new ResponsivePanels(
      false,
      true,
      this.articleDetail ? true : false,
      false
    );

    this.updateNavigationItemSubscription =
      this.widgetService.updateNavigationItem$.subscribe(
        (response: NavigationHistoryItem) => {
          this.navigationHistory.items = this.navigationHistory?.items?.map(
            (item: NavigationHistoryItem) => {
              return item.id === response.id ? response : item;
            }
          );
        }
      );

    this.drilldownSubscription =
      this.widgetService.drilldownObservable.subscribe(
        (response: NavigationHistoryItem) => {
          const navHitoryItem = this.navigationHistory.find(response);
          if (navHitoryItem) {
            this.onLoadHistoryItem(navHitoryItem);
          } else {
            this.addNavigationHistoryItem(response);
            this.articleDetail = undefined;
            this.form = response.form;
          }
        }
      );

    this.updateSegmentSubscription =
      this.widgetService.updateSegmentObservable.subscribe(
        (articleDetail: ISegment) => {
          this.articleDetail = articleDetail;
          this.widgetService.responsivePanels.articleDetail = this.articleDetail
            ? true
            : false;
          this.widgetService.responsivePanels$.next();
        }
      );
  }

  ngOnDestroy(): void {
    this.drilldownSubscription.unsubscribe();
    this.updateSegmentSubscription.unsubscribe();
    this.updateNavigationItemSubscription.unsubscribe();
  }

  private addNavigationHistoryItem(item: NavigationHistoryItem): void {
    if (!(item instanceof DiscordNavigationItem)) {
      item.segment = undefined;
    }
    if (!this.navigationHistory.includes(item)) {
      this.navigationHistory.add(item);
    }
    this.selectedNavigationHistory = item;
    this.widgetService.responsivePanels.navigationHistory =
      this.navigationHistory.items.length > 1;
  }

  public onDeleteHistoryItem(item: NavigationHistoryItem): void {
    this.navigationHistory.items = this.navigationHistory.items.filter(
      (elem: NavigationHistoryItem) => {
        return elem.item.name !== item.item.name;
      }
    );
    this.selectedNavigationHistory =
      this.navigationHistory.items[this.navigationHistory.items.length - 1];
    this.articleDetail = undefined;
    this.form.reset();
    this.widgetService.responsivePanels.navigationHistory =
      this.navigationHistory.items.length > 1;
  }

  public onLoadHistoryItem(item: NavigationHistoryItem): void {
    if (this.selectedNavigationHistory.id === "create_drilldown") {
      this.customOverlayService.openCustom(
        {
          title: "Cancel your tag?",
          message: `If  you leave this screen, the tag you are creating will be lost. <br /><br /> Are you sure you wish to load a previous step?`,
          icon: ["far", "question-circle"],
          size: "4x",
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.navigationHistory.items = this.navigationHistory.items.filter(
              (elem: NavigationHistoryItem) => {
                return elem.id !== item.id;
              }
            );
            this.articleDetail = item.segment;
            this.form = cloneDeep(item.form);
            this.selectedNavigationHistory = item;
          }
        }
      );
    } else {
      this.form = cloneDeep(item.form);
      this.selectedNavigationHistory = item;
    }
  }

  public onToggle(): void {
    this.expandedNavigation = !this.expandedNavigation;
    this.widgetService.responsivePanels.navigationHistory =
      this.expandedNavigation;
    this.widgetService.responsivePanels$.next();
  }
}
