import { Injectable } from "@angular/core";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import { Observable } from "rxjs";
import { IProfileMetric } from "../interfaces/profiles/profile.interface";
import { map } from "rxjs/operators";
import { IWidgetData } from "../interfaces/widget/widget.interface";

@Injectable({
  providedIn: "root",
})
export class ProfileMetricsService {
  constructor(private apiRequestService: ApiRequestService) {}

  public getActivityData(
    profileIds: Array<string>
  ): Observable<Array<IWidgetData>> {
    return this.apiRequestService
      .postToObservable(
        "/profiles/metrics/activity",
        new DTOTypeConverter<Array<IProfileMetric>>(),
        profileIds
      )
      .pipe(
        map((response: Array<IProfileMetric>) => {
          return response.map((item: IProfileMetric) => ({
            series: [
              {
                data: item.data,
              },
            ],
            widgetId: item.profileId,
            totalHits: item.totalHits,
          }));
        })
      );
  }
}
