import { Subscription } from "rxjs";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { cloneDeep } from "lodash";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import {
  Category,
  IconTypes,
  QueryFilters,
  Sizes,
  TagCategory,
} from "@intorqa-ui/core";
import {
  CategoryService,
  ChartOrientation,
  ChartType,
  ICustomTag,
  IDisplayType,
  PieMode,
  Query,
  TagAnalysis,
  TagAnalysisDisplayTypes,
  TagService,
  Timeline,
  TopOptions,
  Widget,
  WidgetActions,
  WidgetOption,
  WidgetService,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-ws-tag-analysis",
  templateUrl: "./ws-tag-analysis.component.html",
  styleUrls: ["./ws-tag-analysis.component.scss"],
})
export class WSTagAnalysisComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.tagAnalysisWidget = widget as TagAnalysis;
  }
  @Input() form: FormGroup;
  @Input() action: WidgetActions;
  @Input() ecosystemId: string;

  @Output() dataBound = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<Widget>();
  @Output() showLoader = new EventEmitter<boolean>();

  public dataPointsDataSource: Array<Category> = [];
  public tagsDataSource: Array<ICustomTag>;
  public topDataSource: Array<{ name: string; value: number }> = [];
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined
  );
  public options: echarts.EChartsOption;
  private changeEcosystemSubscription: Subscription;
  public tagAnalysisWidget: TagAnalysis;
  public tag: Timeline;

  readonly displayTypes = TagAnalysisDisplayTypes;
  readonly IconTypes = IconTypes;
  readonly Sizes = Sizes;
  readonly ChartType = ChartType;
  readonly ChartOrientation = ChartOrientation;
  readonly WidgetOption = WidgetOption;
  readonly PieMode = PieMode;
  readonly WidgetActions = WidgetActions;

  constructor(
    private categoryService: CategoryService,
    public widgetService: WidgetService,
    readonly tagService: TagService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.changeEcosystemSubscription =
      this.widgetService.changeEcosystemObservable.subscribe(() => {
        this.onGetTags(this.initialState);
      });
    this.showLoader.emit(true);
    let promises: Array<Promise<unknown>> = [this.onGetTags(this.initialState)];
    if (
      this.action === WidgetActions.SETTINGS ||
      this.action === WidgetActions.CLONE
    ) {
      promises.push(this.getCategories());
    }
    Promise.all(promises).then(() => {
      this.showLoader.emit(false);
    });
    this.topDataSource = TopOptions;
  }

  ngOnDestroy(): void {
    this.changeEcosystemSubscription.unsubscribe();
    this.form.removeControl("dataSource");
    this.form.removeControl("dataPoints");
    this.form.removeControl("top");
    this.form.removeControl("searchType");
  }

  /**
   * Retrieves tags based on the provided query filters.
   * @param params - The query filters.
   * @returns A promise that resolves to an array of custom tags.
   */
  public onGetTags(params: QueryFilters): Promise<Array<ICustomTag>> {
    return new Promise((resolve) => {
      this.categoryService
        .getTags(
          params.query,
          params,
          new Query().modelToDTO(),
          TagCategory["My Tags"],
          this.ecosystemId,
          params.page > 1
            ? this.tagsDataSource[this.tagsDataSource.length - 1].name
            : undefined
        )
        .then((response: Array<ICustomTag>) => {
          const tagId = this.tagAnalysisWidget.dataSource[0];
          if (
            this.action === WidgetActions.SETTINGS ||
            this.action === WidgetActions.CLONE
          ) {
            this.tagService.getTagById(tagId).then((tag: Timeline) => {
              this.tag = tag;
            });
          }
          this.tagsDataSource =
            params.page > 1 ? [...this.tagsDataSource, ...response] : response;
          if (this.tagAnalysisWidget.dataSource?.length > 0) {
            this.form.patchValue({
              dataSource: tagId,
            });
          }
          resolve(response);
        });
    });
  }

  public onChangeDisplayType(params: IDisplayType): void {
    this.tagAnalysisWidget.chartType = params.type;
    this.tagAnalysisWidget.options = params.options;
    this.update.emit(cloneDeep(this.tagAnalysisWidget));
  }

  private getCategories(): Promise<Array<Category>> {
    this.showLoader.emit(true);
    return new Promise((resolve) => {
      this.categoryService
        .getCategories(this.ecosystemId)
        .then((response: Array<Category>) => {
          this.dataPointsDataSource = response;
          if (this.dataPointsDataSource.length > 0) {
            this.form.controls.dataPoints.enable();
          }
          if (this.tagAnalysisWidget.dataPoints?.length > 0) {
            this.form.patchValue({
              dataPoints: this.tagAnalysisWidget.dataPoints[0],
            });
          }
          resolve(response);
        });
    });
  }

  private createForm(): void {
    this.form = new FormGroup({
      dataSource: new FormControl(undefined, Validators.required),
      dataPoints: new FormControl(
        {
          value: undefined,
          disabled:
            this.tagAnalysisWidget?.dataSource?.length > 0 ? false : true,
        },
        Validators.required
      ),
      top: new FormControl(
        this.tagAnalysisWidget.top || 10,
        Validators.required
      ),
      searchType: new FormControl(this.displayTypes[0].id, Validators.required),
    });
  }

  public onDataBound(params: { prop: string; value: any }): void {
    this.tagAnalysisWidget[params.prop] = params.value;
    this.update.emit(cloneDeep(this.tagAnalysisWidget));
  }

  public onChangeDataSources(): void {
    this.onDataBound({
      prop: "dataSource",
      value: [this.form.controls.dataSource.value?.id],
    });

    this.form.controls.dataPoints.setValue(undefined);
    this.onDataBound({
      prop: "dataPoints",
      value: this.form.controls.dataPoints.value,
    });

    if (this.form.controls.dataSource.value.length > 0) {
      this.form.controls.dataPoints.enable();
    }

    this.getCategories().then(() => {
      if (this.tagAnalysisWidget?.dataPoints?.length > 0) {
        this.form.controls.dataPoints.setValue(
          this.tagAnalysisWidget.dataPoints[0]
        );
      }
      this.showLoader.emit(false);
    });
  }

  public onChangeDatePoints(): void {
    this.onDataBound({
      prop: "dataPoints",
      value: [this.form.controls.dataPoints.value],
    });
    this.getCategories();
  }

  public getSelectedType(): IDisplayType {
    return this.tagAnalysisWidget.getSelectedType();
  }

  public getDisplayIcon(displayType: string): IconName {
    return displayType as IconName;
  }
}
