export interface INode {
  id: string;
  name: string;
  children?: INode[];
}

export interface IFlatNode {
  expandable: boolean;
  level: number;
}
