import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  Align,
  ComponentType,
  PillType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TableColumn,
} from "@intorqa-ui/core";
import {
  ConnectionType,
  ConnectionsService,
  IConnectionsData,
  Profile,
  ProfileConnection,
  ProfileService,
  ProfilesNavigationItem,
  Utils,
  WidgetActions,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-profiles-connections",
  templateUrl: "./profiles-connections.component.html",
  styleUrls: ["./profiles-connections.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesConnectionsComponent,
    },
  ],
})
export class ProfilesConnectionsComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() action: WidgetActions;
  @Input() showTitle = true;

  public tableColumns: Array<TableColumn> = [];
  public dataSource: IConnectionsData = { items: [], totalCount: 0 };
  private selection: Array<ProfileConnection>;
  private touched = false;
  private disabled = false;
  private connectionsSubscription: Subscription;
  public initialState = new QueryFilters(30, 1, undefined, undefined, {
    direction: "desc",
    active: "updatedDate",
  });
  public totalCount: number;
  public connection: ProfileConnection;
  private resetSubscription: Subscription;
  public isFilterable: boolean;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly WidgetActions = WidgetActions;
  readonly Align = Align;

  @ViewChild("actionsButtonTemplate")
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild("ownerTemplate") ownerTemplate: TemplateRef<unknown>;
  @ViewChild("dateTemplate") dateTemplate: TemplateRef<unknown>;

  constructor(
    public profileService: ProfileService,
    public connectionsService: ConnectionsService
  ) {}

  ngOnInit(): void {
    if (this.navigationItem.connections?.length > 0) {
      this.connectionsService.connections = {
        items: this.navigationItem.connections,
        totalCount: this.navigationItem.connections.length,
      };
      this.dataSource = cloneDeep(this.connectionsService.connections);
    }
    this.connectionsSubscription =
      this.connectionsService.connections$.subscribe(
        (response: IConnectionsData) => {
          this.form.controls.connections?.setValue(response);
          this.dataSource = cloneDeep(response);
        }
      );
    this.resetSubscription = this.connectionsService.reset$.subscribe(() => {
      this.dataSource = { items: [], totalCount: undefined };
    });
    this.connectionsService.getConnectionTypes(this.profile).subscribe(() => {
      this.onGetTypes();
    });
    if (
      this.navigationItem.action === WidgetActions.SETTINGS ||
      this.navigationItem.action === WidgetActions.EXPLORE
    ) {
      this.onDataBound(this.initialState, false);
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.connectionsSubscription.unsubscribe();
    this.resetSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ProfileConnection>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: "Connection type",
        dataKey: "typeName",
        searchField: "typeId",
        width: "200px",
        isFilterable: true,
        isSortable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: undefined,
      },
      {
        name: "Connected profile",
        dataKey: "targetProfileName",
        searchField: "targetProfileName",
        width: "fit",
        isFilterable: true,
        httpBinding: true,
        isSortable: true,
        searchFieldType: SearchFieldType.TEXT,
        dataSource: undefined,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: "Description",
        dataKey: "description",
        searchField: "description",
        width: "fit",
        isFilterable: true,
        isSortable: true,
        searchFieldType: SearchFieldType.TEXT,
        dataSource: undefined,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: undefined,
        dataKey: "actions",
        position: "center",
        customRender: true,
        template: this.actionsButtonTemplate,
        width: "68px",
      },
    ];
    if (this.navigationItem.action === WidgetActions.EXPLORE) {
      this.tableColumns = [
        {
          name: "Updated date",
          dataKey: "updatedDate",
          searchField: "updatedDate",
          isSortable: true,
          width: "180px",
          sticky: true,
          customRender: true,
          template: this.dateTemplate,
          isFilterable: true,
          searchFieldType: SearchFieldType.DATE,
          searchComponent: ComponentType.DATE,
        },
        {
          name: "Updated by",
          dataKey: "updatedBy",
          searchField: "updatedBy",
          isSortable: true,
          position: "center",
          customRender: true,
          template: this.ownerTemplate,
          width: "180px",
          sticky: true,
          httpBinding: true,
          isFilterable: true,
          searchFieldType: SearchFieldType.TEXT,
          searchComponent: ComponentType.DROPDOWN,
          dataSource: undefined,
          dataBound: () => {
            this.onGetUsers();
          },
        },
        ...this.tableColumns,
      ];
    }
  }

  private onGetTypes(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === "typeId") {
        column.dataSource = this.connectionsService.connectionTypes.map(
          (item: ConnectionType) => ({ name: item.name, value: item.id })
        );
      }
      return column;
    });
  }

  private onGetUsers(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === "updatedBy") {
        this.connectionsService
          .getUsers(this.profile.profileId)
          .subscribe((response: Array<string>) => {
            column.dataSource = response.map((item: string) => ({
              name: item,
              value: item,
            }));
          });
      }
      return column;
    });
  }

  public onDeleteConnection(connection: ProfileConnection): void {
    if (this.navigationItem.action === WidgetActions.EXPLORE) {
      this.connectionsService
        .removeConnections(this.profile.profileId, [connection])
        .subscribe();
    } else {
      this.dataSource.items = this.dataSource.items.filter(
        (item: ProfileConnection) => item.id !== connection.id
      );
      this.connectionsService.connections.items =
        this.connectionsService.connections.items.filter(
          (item: ProfileConnection) => item.id !== connection.id
        );
    }
    this.form.controls.notes?.markAsTouched();
  }

  public onEditConnection(connection: ProfileConnection): void {
    this.connectionsService.showAddConnections$.next(WidgetActions.SETTINGS);
    this.connection = connection;
  }

  public onAddConnection(): void {
    this.connectionsService.showAddConnections$.next(WidgetActions.CREATE);
    this.connection = new ProfileConnection(
      Utils.generateUUID(),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  public onDataBound(params: QueryFilters, showLoader = true): void {
    this.connectionsService
      .getConnections(this.profile.profileId, params, showLoader)
      .subscribe();
  }

  public onExit(): void {
    this.connectionsService.showAddConnections$.next(undefined);
    this.connectionsService.showConnectionsSettings$.next(false);
  }
}
