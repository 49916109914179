import { Component, Input, OnInit } from "@angular/core";
import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { IconType } from "../icon/icon.enum";

@Component({
  selector: "itq-headline-simple",
  templateUrl: "./headline-simple.component.html",
  styleUrls: ["./headline-simple.component.scss"],
})
export class HeadLineSimpleComponent implements OnInit {
  @Input() iconName: IconName | string;
  @Input() iconSize: SizeProp | string;
  @Input() title: string;
  @Input() description: string;
  @Input() iconType: IconType = IconType.FONT_AWESOME;

  constructor() {}

  ngOnInit(): void {}
}
