import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTabGroup } from "@angular/material/tabs";
import {
  Align,
  CurrencyPrefix,
  Ecosystem,
  IconType,
  PillType,
  SharedService,
  Sizes,
  TagCategory,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  ChartOrientation,
  ChartType,
  ConnectionsService,
  DateInterval,
  EcosystemsService,
  GroupMetadata,
  ILinksData,
  ILoadLink,
  ISegment,
  IWidgetData,
  LineMode,
  LinkTag,
  LinkTagsService,
  MetadataFields,
  MetadataIcons,
  NavigationHistoryItem,
  NotesService,
  Profile,
  ProfileEditAction,
  ProfileMetadataGroups,
  ProfileMetricsService,
  ProfileService,
  ProfileTypeMetadata,
  ProfilesNavigationItem,
  TagService,
  TimeSeries,
  Timeline,
  WidgetActions,
  WidgetService,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { GroupMetadataPipe } from "./profiles-explore.pipe";

@Component({
  selector: "itq-profiles-explore",
  templateUrl: "./profiles-explore.component.html",
  styleUrls: ["./profiles-explore.component.scss"],
})
export class ProfilesExploreComponent implements OnInit {
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() form: FormGroup;
  @Input() articleDetail: ISegment;

  public showLoader = false;
  public expandedFilters = true;
  public profile: Profile;
  public ecosystemName: string;
  private showAddConnectionsSubscription: Subscription;
  private showAddNotesSubscription: Subscription;
  public profileTypeName: string;
  private loaderSubscription: Subscription;
  public tagsDataSource: ILinksData;
  public selectedTag: LinkTag;
  private getLinkedTagsSubscription: Subscription;
  private showLinkTagsSubscription: Subscription;
  public showLinkTags = false;
  public editAction = ProfileEditAction.DETAILS;
  public action: WidgetActions;
  public closeOnUpdate = true;
  public showConnectionSettings = false;
  private showConnectionsSettingsSubscription: Subscription;
  private metadataSubscription: Subscription;
  public metadata: Array<GroupMetadata>;
  public editMetadataAction: ProfileMetadataGroups;
  private profilesSubscription: Subscription;
  public selectedTabIndex = 0;
  public activityWidget: TimeSeries;
  public activityData: IWidgetData;

  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Align = Align;
  readonly WidgetActions = WidgetActions;
  readonly ProfileEditAction = ProfileEditAction;
  readonly TagCategory = TagCategory;
  readonly MetadataFields = MetadataFields;
  readonly MetadataIcons = MetadataIcons;
  readonly CurrencyPrefix = CurrencyPrefix;

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    readonly widgetService: WidgetService,
    readonly tagService: TagService,
    private ecosystemService: EcosystemsService,
    private profileService: ProfileService,
    private notesService: NotesService,
    private connectionsService: ConnectionsService,
    private sharedService: SharedService,
    private linkTagsService: LinkTagsService,
    private groupMetadataPipe: GroupMetadataPipe,
    readonly profileMetricsService: ProfileMetricsService
  ) {}

  ngOnInit(): void {
    if (this.profile.activity > 0) {
      this.activityWidget = new TimeSeries(
        undefined,
        undefined,
        AnalysisTypes.TIME_SERIES,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          orientation: ChartOrientation.VERTICAL,
          mode: LineMode.LINE,
          tooltip: {
            show: false,
          },
          xAxis: {
            show: false,
          },
          yAxis: {
            show: false,
          },
          series: {
            showSymbol: false,
            label: {
              show: false,
            },
            lineStyle: {
              width: 2,
            },
          },
        },
        [],
        [],
        undefined,
        ChartType.LINE,
        DateInterval.DAY,
        this.profile.ecosystemId
      );
      this.profileMetricsService
        .getActivityData([this.profile.profileId])
        .subscribe((response: Array<IWidgetData>) => {
          this.activityData = response[0];
        });
    }

    this.profilesSubscription = this.profileService.profiles$.subscribe(() => {
      this.profile = this.profileService.findProfileById(
        this.profile.profileId
      );
    });

    this.metadataSubscription = this.profileService.metadata$.subscribe(
      (response: Array<ProfileTypeMetadata>) => {
        this.metadata = this.groupMetadataPipe.transform(response);
      }
    );
    this.loaderSubscription = this.sharedService.loader$.subscribe(
      (response: boolean) => {
        this.showLoader = response;
      }
    );
    this.showAddNotesSubscription = this.notesService.showAddNotes$.subscribe(
      (response: WidgetActions) => {
        if (!response) {
          this.form.removeControl("addNote");
        }
        this.action = response;
      }
    );
    this.showLinkTagsSubscription = this.linkTagsService.showLinkTag$.subscribe(
      (response: boolean) => {
        if (!response) {
          this.form.removeControl("addTag");
          this.action = undefined;
        }
        this.showLinkTags = response;
      }
    );
    this.showConnectionsSettingsSubscription =
      this.connectionsService.showConnectionsSettings$.subscribe(
        (response: boolean) => {
          if (!response) {
            this.form.removeControl("addConnections");
          }
          this.showConnectionSettings = response;
        }
      );
    this.showAddConnectionsSubscription =
      this.connectionsService.showAddConnections$.subscribe(
        (response: WidgetActions) => {
          if (!response) {
            this.form.removeControl("addConnections");
          }
          this.action = response;
        }
      );
    this.getLinkedTagsSubscription = this.linkTagsService.links$.subscribe(
      (response: ILoadLink) => {
        this.tabGroup.selectedIndex = 0;
        this.tagsDataSource = response.data;
        if (response.link) {
          this.selectedTag = response.link;
        } else {
          this.selectedTag = this.tagsDataSource.items.find(
            (item: LinkTag) => item.isDefault
          );
        }
        if (this.selectedTag) {
          this.onChangeTag(this.selectedTag);
        }
        if (!this.selectedTag) {
          this.showLinkTags = true;
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.navigationItem?.previousValue !==
      changes?.navigationItem?.currentValue
    ) {
      this.selectedTabIndex = this.navigationItem.selectedTabIndex || 0;
      this.profile = cloneDeep(this.navigationItem.item) as Profile;
      this.getEcosystemName();
      this.createForm();
      this.profileService.getMetadata(this.profile.profileTypeId).subscribe();
      this.profileService
        .getProfileTypes(this.profile.ecosystemId)
        .subscribe(() => {
          this.profileTypeName = this.profileService.getProfileTypeById(
            this.profile.profileTypeId
          )?.name;
        });
    }
  }

  ngOnDestroy(): void {
    this.showLinkTagsSubscription.unsubscribe();
    this.loaderSubscription.unsubscribe();
    this.showAddConnectionsSubscription.unsubscribe();
    this.showAddNotesSubscription.unsubscribe();
    this.getLinkedTagsSubscription.unsubscribe();
    this.showLinkTagsSubscription.unsubscribe();
    this.metadataSubscription.unsubscribe();
    this.showConnectionsSettingsSubscription.unsubscribe();
    this.profilesSubscription.unsubscribe();
  }

  private createForm(): void {
    this.form.addControl("tags", new FormControl(1));
  }

  private getEcosystemName(): void {
    this.ecosystemService
      .getEcosystemById(this.profile.ecosystemId)
      .subscribe((response: Ecosystem) => {
        this.ecosystemName = response.name;
      });
  }

  public onEditTag(value: LinkTag): void {
    this.tagService.getTagById(value.tagId).then((response: Timeline) => {
      const widget = response;
      const navHitoryItem = new NavigationHistoryItem(
        `${WidgetActions.SETTINGS}_${widget.widgetId}`,
        widget,
        WidgetActions.SETTINGS,
        undefined,
        this.navigationItem.initialState,
        new FormGroup({}),
        "pencil",
        IconType.FONT_AWESOME,
        undefined,
        undefined,
        undefined
      );
      this.widgetService.drilldownObservable.next(navHitoryItem);
    });
  }

  public onChangeTag(value: LinkTag): void {
    this.selectedTag = value;
    this.navigationItem = new ProfilesNavigationItem(
      this.navigationItem.id,
      this.navigationItem.item,
      this.navigationItem.action,
      this.navigationItem.rules,
      this.navigationItem.initialState,
      this.navigationItem.form,
      this.navigationItem.icon,
      this.navigationItem.iconType,
      this.navigationItem.selectedDisplayType,
      this.navigationItem.segment,
      this.navigationItem.boardId,
      value,
      undefined,
      undefined,
      0
    );
  }

  public onEdit(
    editAction: ProfileEditAction,
    editMetadataAction: string
  ): void {
    this.closeOnUpdate = false;
    this.navigationItem.action = WidgetActions.SETTINGS;
    this.editAction = editAction;
    this.editMetadataAction = editMetadataAction as ProfileMetadataGroups;
  }

  public onToggleSettings(): void {
    if (this.showLinkTags) {
      this.action = WidgetActions.SETTINGS;
    } else {
      this.action = WidgetActions.CREATE;
    }
    this.showLinkTags = !this.showLinkTags;
  }

  public onTabChange(): void {
    this.navigationItem.selectedTabIndex = this.selectedTabIndex;
    this.widgetService.updateNavigationItem$.next(this.navigationItem);
    this.connectionsService.showAddConnections$.next(undefined);
    this.notesService.showAddNotes$.next(undefined);
    this.action = undefined;
  }
}
