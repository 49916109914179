import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Profile, StoreFrontNote } from "@intorqa-ui/shared";

@Component({
  selector: "itq-view-store-front",
  templateUrl: "./view-store-front.component.html",
  styleUrls: ["./view-store-front.component.scss"],
})
export class ViewStoreFrontComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: StoreFrontNote;

  constructor() {}

  ngOnInit() {}
}
