import { Pipe, PipeTransform } from "@angular/core";
import { IPill } from "@intorqa-ui/core";
import {
  ProfileConnection,
  ProfileConnectionDataSource,
} from "@intorqa-ui/shared";

@Pipe({
  name: "getDistinctTypesCount",
})
export class GetDistinctTypesCountPipe implements PipeTransform {
  transform(connections: Array<ProfileConnection>): number {
    return new Set(connections.map((item: ProfileConnection) => item.typeId))
      .size;
  }
}

@Pipe({
  name: "transformProfileValues",
})
export class TransformProfileValuesPipe implements PipeTransform {
  transform(data: ProfileConnectionDataSource): Array<IPill> {
    return data?.profiles?.map((item: { name: string; profileId: string }) => ({
      name: item.name,
      tooltip: data.targetProfileName,
    }));
  }
}
