import { TagCategory } from "@intorqa-ui/core";
import { Pipe, PipeTransform } from "@angular/core";
import { Category } from "@intorqa-ui/core";

@Pipe({
  name: "filterActorCategories",
  pure: true,
})
export class FilterActorCategoriesPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<Category>): Array<Category> {
    const includedCategories = [
      TagCategory.Actor,
      TagCategory.Channel,
      TagCategory.Source,
    ];
    return data.filter((item: Category) =>
      includedCategories.includes(item.name)
    );
  }
}
