import { NoteTypes } from '../../enums/profile.enum';
import { INoteType } from "../../interfaces/profiles/note-type.interface";

export class NoteType implements INoteType {
  constructor(
    public id: string,
    public name: NoteTypes,
    public subTypes: Array<string>
  ) {}
}
