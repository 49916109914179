import { CommonModule } from "@angular/common";
import { Component, HostBinding, Input, OnInit } from "@angular/core";

import { FormGroup } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  QueryFilters,
  Sizes,
} from "@intorqa-ui/core";
import {
  AlertTypesIcons,
  ChartType,
  Condition_Notification,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  IDisplayType,
  NavigationHistoryItem,
  NotificationsService,
  PriorityColor,
  PriorityLabel,
  QueryRule,
  TagService,
  ThresholdNotification,
  Timeline,
  TimelineFeedDisplayTypes,
  WidgetActions,
} from "@intorqa-ui/shared";
import { MillisToDate } from "../../../../../../shared/src/lib/pipes/shared.pipe";
import { SharedModule } from "../../shared/shared.module";
import { GetPeriodLabelPipe } from "./notifications-tag-threshold.pipe";
import { Subscription } from "rxjs";
import { WidgetSettingsComponent } from "../../widget-settings/widget-settings.component";

@Component({
  selector: "itq-notifications-tag-threshold",
  templateUrl: "./notifications-tag-threshold.component.html",
  styleUrls: ["./notifications-tag-threshold.component.scss"],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    MillisToDate,
    GetPeriodLabelPipe,
  ],
})
export class NotificationsTagThresholdComponent implements OnInit {
  @Input() notification: ThresholdNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly Condition_Notification = Condition_Notification;

  private markAsReadSubscription: Subscription;

  @HostBinding("style.borderRight") borderRight: string;
  @HostBinding("class") class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService
  ) {}

  ngOnInit() {
    this.markAsReadSubscription =
      this.notificationService.markAsRead$.subscribe(() => {
        this.class = "";
      });
    this.class = !this.notification.read ? "unread" : "";
    this.borderRight =
      "10px solid " + PriorityColor[this.notification.priority];
  }

  ngOnDestroy(): void {
    this.markAsReadSubscription?.unsubscribe();
  }

  public onViewOtherMatches(): void {
    this.notificationService.loadOtherMatches$.next({
      raisedAlertId: this.notification.raisedAlertId,
      filters: undefined,
    });
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: ThresholdNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? "unread" : "";
        this.notificationService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get("priority")?.value
          )
          .subscribe();
      });
  }

  public onExploreTag(): void {
    const { tagId } = this.notification;
    this.tagService.getTagById(tagId).then((timeline: Timeline) => {
      const navigationItem = new NavigationHistoryItem(
        `${timeline.widgetId}_${WidgetActions.EXPLORE}`,
        timeline,
        WidgetActions.EXPLORE,
        [
          new QueryRule(DTOQueryFieldType.tag, DTOQueryConditionOperator.in, [
            timeline.tagId,
          ]),
        ],
        new QueryFilters(30, 1, undefined, undefined, undefined),
        new FormGroup({}),
        "magnifying-glass",
        IconType.FONT_AWESOME,
        TimelineFeedDisplayTypes.find(
          (item: IDisplayType) => item.type === ChartType.TIMELINE
        ),
        undefined,
        undefined
      );
      this.openCustomOverlay({ navigationItem });
    });
  }

  private openCustomOverlay(data: any): void {
    this.customOverlayService.open({
      data,
      closeBtnStyle: "basic",
      type: CustomOverlayType["almost-full"],
      size: "lg",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}
