import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  ISegment,
  NetworkChart,
  TagAnalysis,
  TagComparison,
  TimeSeries,
  WidgetActions,
} from "@intorqa-ui/shared";
import { ECharts, EChartsOption } from "echarts";
import { defaultTheme } from "utils/echarts.theme";
import * as echarts from "echarts/core";

@Component({
  selector: "itq-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
})
export class ChartComponent {
  @Input() widget: TagAnalysis | TimeSeries | TagComparison | NetworkChart;
  @Input() action = WidgetActions.RENDER;
  @Input() data: any;
  @Input() segment: ISegment;
  @Input() noResultsTitle = "No results found!";
  @Input() noResultsMessage = "Please update your settings and try again...";

  @Output() drilldown = new EventEmitter<any>();
  @Output() updateRef = new EventEmitter<ECharts>();

  @ViewChild("chartContainer") chartContainer: ElementRef;

  public chartInstance: ECharts;
  public options: EChartsOption;
  public theme = defaultTheme.themeName;

  constructor() {
    echarts.registerTheme(defaultTheme.themeName, defaultTheme.theme);
  }

  public onChartInit(instance: ECharts): void {
    this.chartInstance = instance;
    this.onUpdateRef();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.data?.previousValue !== changes?.data?.currentValue ||
      changes?.widget?.previousValue !== changes?.widget?.currentValue ||
      changes?.segment?.previousValue !== changes?.segment?.currentValue
    ) {
      this.reloadOptions();
    }
  }

  ngOnDestroy(): void {
    this.chartInstance?.dispose();
  }

  onUpdateRef(): void {
    this.updateRef.emit(this.chartInstance);
  }

  private reloadOptions(): void {
    if (this.data?.series[0]?.data?.length > 0) {
      this.initEcharts();
    }
  }

  private initEcharts(): void {
    this.options = this.widget.getOptions(
      this.data?.series,
      this.action,
      this.segment
    );
  }

  public onChartClick(params: any): void {
    if (this.widget instanceof NetworkChart) {
      if (
        params.dataType === "node" &&
        params?.data?.name !== this.widget.chartName
      ) {
        this.drilldown.emit(params);
      }
    } else {
      this.drilldown.emit(params);
    }
  }
}
