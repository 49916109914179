import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  IconType,
  PillType,
  Sizes,
} from "@intorqa-ui/core";
import {
  ConnectionsService,
  DocumentItem,
  DocumentItemService,
  IExtensionField,
  ISegment,
  NavigationHistoryItem,
  NotesService,
  NotificationsService,
  Profile,
  ProfileConnection,
  ProfileNote,
  SegmentScope,
  SlackService,
  Utils,
  WidgetActions,
  dateFormats,
} from "@intorqa-ui/shared";
import { franc } from "franc";
import { KeycloakService } from "keycloak-angular";
import { AddConnectionsComponent } from "../add-connections/add-connections.component";
import { PostNotesWizardComponent } from "../post-notes-wizard/post-notes-wizard.component";
import { ShareEmailComponent } from "../share-email/share-email.component";
import {
  ExtensionDataFields,
  ExtensionDataFieldsText,
} from "./document-item-detail.enum";
import { Subscription } from "rxjs";

interface IContentLink {
  anchorText: string;
  href: string;
}

@Component({
  selector: "itq-document-item-detail",
  templateUrl: "./document-item-detail.component.html",
  styleUrls: ["./document-item-detail.component.scss"],
})
export class DocumentItemDetailComponent implements OnInit, OnChanges {
  @Input() segment: ISegment;
  @Input() action: WidgetActions;
  @Input() form: FormGroup;
  @Input() query: string;
  @Input() profile: Profile;
  @Input() ecosystemId: string;
  @Input() expanded = true;
  @Input() viewParent = false;
  @Input() allowDrilldown = true;
  @Input() navigationItem: NavigationHistoryItem;

  @ViewChild("itemdetailcontainer", { static: true }) container: ElementRef;

  @Output() drilldown = new EventEmitter<ISegment>();

  public customOverlay: CustomOverlayRef;
  public showLoader = false;
  public translatedContent: string;
  public translatedTitle: string;
  public contentLanguage: string;
  public code: string;
  public contentLinks: Array<IContentLink> = [];
  public extractedData: Array<any> = [];
  public repliesCount: number;
  public replyParent: DocumentItem;
  public showParent: boolean;
  public note: ProfileNote;
  private queryParamsSubscription: Subscription;

  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly SegmentScope = SegmentScope;
  readonly PillType = PillType;
  readonly dateFormats = dateFormats;
  readonly ExtensionDataFieldsText = ExtensionDataFieldsText;
  readonly ExtensionDataFields = ExtensionDataFields;

  constructor(
    public router: Router,
    public customOverlayService: CustomOverlayService,
    private docService: DocumentItemService,
    private route: ActivatedRoute,
    private documentService: DocumentItemService,
    private snackBar: MatSnackBar,
    readonly notesService: NotesService,
    readonly connectionsService: ConnectionsService,
    public keycloakService: KeycloakService,
    readonly slackService: SlackService,
    readonly notificationService: NotificationsService,
    readonly _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.container.nativeElement.addEventListener("click", (event: any) => {
      this.interceptHref(event, this);
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        this.code = params.code;
      }
    );
    this.extractedData = this.getExtractedData();
    this.contentLanguage = franc(this.segment.value.emitBodyText);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.segment?.previousValue !== changes?.segment?.currentValue) {
      this.translatedContent = undefined;
      this.translatedTitle = undefined;
      this.showParent = false;
      this.repliesCount = undefined;
      this.replyParent = undefined;
      this.contentLinks = this.getContentLinks();
      this.getRepliesCount();
      if (this.viewParent) {
        if (this.segment.value.emitReplyToId) {
          this.getDocument(this.segment.value).then(
            (response: DocumentItem) => {
              this.replyParent = response;
            }
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription?.unsubscribe();
  }

  private getDocument(document: DocumentItem): Promise<DocumentItem> {
    return new Promise((resolve) => {
      this.documentService
        .getParentDocument(document)
        .then((response: DocumentItem) => {
          resolve(response);
        })
        .catch(() => {
          this.viewParent = false;
        });
    });
  }

  public getRepliesCount(): void {
    this.documentService
      .getRepliesCount(this.segment.value)
      .then((response: number) => {
        this.repliesCount = response;
      });
  }

  interceptHref(event: Event | any, self: any): any {
    const tEvent = event || window?.event;
    const element = tEvent.target || tEvent.srcElement;

    if (element.tagName === "IMG") {
      event.stopPropagation();
      event.preventDefault();
    }

    if (
      element.tagName === "A" &&
      event.srcElement.href &&
      event.srcElement.href.indexOf(`${window.location.origin}`) === -1
    ) {
      event.stopPropagation();
      event.preventDefault();
      if (
        event.srcElement.href.indexOf("http") === -1 ||
        event.srcElement.href.indexOf(`${window.location.origin}`) !== -1
      ) {
        this.snackBar.open(
          "Sorry that url is malformed, we wont be trying to go there!",
          "Close",
          {
            horizontalPosition: "right",
            duration: 5000,
            verticalPosition: "top",
          }
        );
      } else {
        self.customOverlayService.openCustom(
          {
            title: "Open external url?",
            message: `Are you sure you want to open this url '<strong>${event.srcElement.href}</strong>'?`,
            icon: ["far", "question-circle"],
            size: "4x",
            dialog: {
              type: DialogTypes.CONFIRM,
            },
          },
          DialogComponent,
          (result: boolean) => {
            if (result === true) {
              window.open(event.srcElement.href, "_blank");
            }
          }
        );
      }
    }
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  public onShare(event: MouseEvent, document: DocumentItem): void {
    event.stopImmediatePropagation();
    this.customOverlayService.open({
      data: {
        item: document,
      },
      type: CustomOverlayType["slide-right"],
      component: ShareEmailComponent,
      disposeOnNavigation: true,
    });
  }

  public onTranslate(): void {
    this.showLoader = true;
    this.docService
      .detectLanguage(this.segment.value.emitBodyText)
      .then((response) => response.json())
      .then((result: any) => {
        if (result?.data?.detections?.length > 0) {
          this.translate(
            result.data.detections[0][0].language,
            this.segment.value.emitBodyText
          )
            .then((text: string) => {
              this.translatedContent = text;
              this.showLoader = false;
            })
            .catch(() => {
              this.showLoader = false;
            });
          this.translate(
            result.data.detections[0][0].language,
            this.segment.value.emitHeadline
          )
            .then((text: string) => {
              this.translatedTitle = text;
              this.showLoader = false;
            })
            .catch(() => {
              this.showLoader = false;
            });
        }
      })
      .catch((error) => console.log("error", error));
  }

  private translate(lang: string, text: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.docService
        .translate(text, lang)
        .then((response) => response.json())
        .then((response: any) => {
          resolve(response.data.translations[0].translatedText);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  shareSlack(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.customOverlayService.openCustom(
      {
        title: "Share to slack?",
        message: `Are you sure you want to share the document to slack?`,
        icon: ["far", "question-circle"],
        size: "4x",
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          window.localStorage.setItem(
            "document",
            JSON.stringify(this.segment.value)
          );
          const attachment = this.slackService.generateAttachment(
            this.segment.value
          );
          this.notificationService
            .postSlackMessage(attachment)
            .subscribe(() => {
              this.showLoader = false;
              this._snackBar.open("Document shared succssefully!", "Close", {
                horizontalPosition: "right",
                duration: 5000,
                verticalPosition: "top",
              });
            });
        }
      }
    );
  }

  public getContentLinks(): Array<IContentLink> {
    let contentLinks: any;
    if (this.segment?.value?.emitExtensionFields?.length > 0) {
      if (this.segment.value.emitSource === "Discord") {
        contentLinks = this.segment.value?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField.name === "contentLinks") {
              return extensionField;
            }
          }
        );
        return contentLinks?.data?.map((item: { url: string }) => ({
          href: item.url,
        }));
      } else {
        contentLinks = this.segment.value?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField["content links"]?.length > 0) {
              return extensionField;
            }
          }
        );
        if (contentLinks && contentLinks["content links"]) {
          let uniqueHrefs: Array<string> = [];
          return contentLinks["content links"].filter((link: IContentLink) => {
            if (link.href?.length > 0) {
              if (!uniqueHrefs.includes(link.href.trim())) {
                uniqueHrefs.push(link.href);
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      }
    }
    return [];
  }

  private getExtractedData(): Array<any> {
    if (this.segment?.value?.emitExtensionFields?.length > 0) {
      return this.segment?.value?.emitExtensionFields.filter(
        (item: any) =>
          item.name !== "contentLinks" && item.name !== "content links"
      );
    }
  }

  getDocumentDate(dateItem: any): Date {
    if (typeof dateItem === "number") {
      return new Date(dateItem);
    } else if (typeof dateItem === "string") {
      return new Date(dateItem);
    } else if (dateItem) {
      return dateItem;
    } else {
      return null;
    }
  }

  onDrilldown(scope: SegmentScope, extensionField?: any): void {
    if (scope === SegmentScope.DISCORD || scope === SegmentScope.CONTEXT) {
      if (extensionField)
        this.drilldown.emit({
          scope,
          value: extensionField,
          context: { document: this.segment.value },
        });
    } else if (scope === SegmentScope.REPLIES) {
      this.drilldown.emit({
        scope,
        value: extensionField || this.segment.value,
      });
    } else {
      this.drilldown.emit({ scope, value: this.segment.value });
    }
  }

  onReplayDrilldown(segment: ISegment): void {
    this.drilldown.emit(segment);
  }

  public onWheel(event: WheelEvent): void {
    if (event.deltaY < 0) {
      if (this.container.nativeElement.scrollTop == 0) {
        if (
          this.viewParent &&
          this.segment.value.emitReplyToId &&
          !this.showParent
        ) {
          this.showParent = true;
        }
      }
    } else {
      if (
        this.viewParent &&
        this.segment.value.emitReplyToId &&
        this.showParent
      ) {
        this.showParent = false;
      }
    }
  }

  public onShowReply(): void {
    this.showParent = !this.showParent;
  }

  public onAddConnection(): void {
    this.customOverlayService.open({
      data: {
        connection: new ProfileConnection(
          Utils.generateUUID(),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ),
        profile: this.profile,
        navigationItem: this.navigationItem,
        form: this.form,
        action: WidgetActions.CREATE,
      },
      type: CustomOverlayType["slide-right"],
      component: AddConnectionsComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddNote(): void {
    this.customOverlayService.open({
      data: {
        segment: {
          segment: SegmentScope.ARTICLE_DETAIL,
          value: this.segment?.value,
        },
        ecosystemId: this.ecosystemId,
        profile: this.profile,
      },
      size: "lg",
      type: CustomOverlayType["slide-right"],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });
  }

  public onViewContext(): void {
    const extensionField = this.segment.value?.emitExtensionFields?.find(
      (item: IExtensionField) => {
        return item.name === "channelDetails";
      }
    );
    this.onDrilldown(SegmentScope.CONTEXT, extensionField);
  }
}
