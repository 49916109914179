import { CommonModule } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  CoreModule,
  CustomOverlayService,
  DateQueryType,
  DateRange,
  IDropdownItem,
  IPreset,
  IPresetQuery,
  IconType,
  PillType,
  QueryFilters,
  SharedService,
  TableColumn,
  TagCategory,
  VirtualScrollService,
} from "@intorqa-ui/core";
import {
  AlertType,
  AlertsService,
  AnalysisTypes,
  ChartType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DiscordNavigationItem,
  GroupByDatePipe,
  GroupNotification,
  Notification,
  IDisplayType,
  ISearchResults,
  ISegment,
  LinkTag,
  NavigationHistoryItem,
  NotificationNavigationItem,
  NotificationsService,
  Priority_DataSource,
  Profile,
  ProfileDrildownScope,
  ProfileDrilldown,
  ProfilesNavigationItem,
  SegmentScope,
  Timeline,
  WidgetActions,
  WidgetService,
  ResearchNotification,
} from "@intorqa-ui/shared";
import { Subscription, forkJoin } from "rxjs";
import { SharedModule } from "../../shared/shared.module";
import { NotificationsTimelineComponent } from "../notifications-timeline/notifications-timeline.component";
import { NotificationResearchDetailComponent } from "../notification-research-detail/notification-research-detail.component";
import moment from "moment";

@Component({
  selector: "itq-notifications-explore",
  templateUrl: "./notifications-explore.component.html",
  styleUrls: ["./notifications-explore.component.scss"],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    NotificationsTimelineComponent,
    ReactiveFormsModule,
    SharedModule,
    MatTooltipModule,
    NotificationResearchDetailComponent,
  ],
  providers: [NotificationsService, GroupByDatePipe, SharedService],
})
export class NotificationsExploreComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() articleDetail: ISegment;
  @Input()
  set navigationItem(value: NavigationHistoryItem) {
    this._navigationItem = value as NotificationNavigationItem;
  }

  get navigationItem(): NotificationNavigationItem {
    return this._navigationItem;
  }

  public _navigationItem: NotificationNavigationItem;
  public expandedFilters = true;
  public priorityDataSource: Array<IDropdownItem> = [];
  public showLoader = false;

  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined
  );
  public buttonDataSource: Array<IDisplayType>;
  public unreadCount: number;
  public notificationsDataSource: {
    items: Array<GroupNotification>;
    totalCount: number;
  };
  private subscriptions = new Subscription();
  public raisedAlertId: string;
  public researchNotification: ResearchNotification;
  public tableColumns: TableColumn[] = [];
  public matchesDataSource: ISearchResults;
  public alertTypesDataSource: Array<AlertType> = [];

  @ViewChild("unreadTemplate")
  unreadTemplate: TemplateRef<unknown>;

  @ViewChild("template")
  template: TemplateRef<unknown>;

  readonly PillType = PillType;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly groupByDate: GroupByDatePipe,
    readonly widgetService: WidgetService,
    readonly alertsService: AlertsService,
    readonly virtualScrollService: VirtualScrollService
  ) {
    this.priorityDataSource = Priority_DataSource;
    localStorage.removeItem("researchAlertId");
    localStorage.removeItem("loadMatches");
    localStorage.removeItem("raisedAlertId");
  }

  ngOnInit() {
    this.initializeSubscriptions();
    this.raisedAlertId = this.navigationItem.raisedAlertId;
    this.createForm();
    this.onDataBound();
    this.loadMatches();
  }

  ngAfterViewInit(): void {
    this.tableColumns = [
      {
        name: undefined,
        dataKey: "result",
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
    this.buttonDataSource = [
      {
        id: "read",
        tooltip: "All",
      },
      {
        id: "unread",
        tooltip: "Unread",
        template: this.unreadTemplate,
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initializeSubscriptions(): void {
    this.subscriptions.add(
      this.notificationsService.loadNotificationDetail$.subscribe(
        (response: ResearchNotification) => {
          this.researchNotification = response;
          this.raisedAlertId = undefined;
        }
      )
    );
    this.subscriptions.add(
      this.notificationsService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      })
    );
    this.alertsService.getTypes().subscribe((response: Array<AlertType>) => {
      this.alertTypesDataSource = response;
    });
    this.subscriptions.add(
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount = response;
          this.buttonDataSource = this.buttonDataSource.map(
            (item: IDisplayType) => {
              if (item.id === "unread") {
                if (this.unreadCount === 0) {
                  item = { ...item, ...{ disabled: true } };
                } else {
                  delete item.disabled;
                }
                return item;
              } else {
                return item;
              }
            }
          );
        }
      )
    );
    this.subscriptions.add(
      this.notificationsService.createProfile$.subscribe(
        (params: {
          profileDrilldown: ProfileDrilldown;
          notification: Notification;
        }) => {
          this.createProfile(params);
        }
      )
    );
    this.subscriptions.add(
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          this.notificationsDataSource = {
            items: this.groupByDate.transform(response.items),
            totalCount: response.totalCount,
          };
          this.researchNotification =
            this.notificationsService.findNotificationBy(
              this.navigationItem.researchAlertId
            ) as ResearchNotification;
        }
      )
    );
    this.subscriptions.add(
      this.notificationsService.loadOtherMatches$.subscribe(
        (response: { filters: QueryFilters; raisedAlertId: string }) => {
          this.raisedAlertId = response.raisedAlertId;
          this.researchNotification = undefined;
          this.loadMatches();
        }
      )
    );
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const notification = this.notificationsService.findNotificationById(
      this.raisedAlertId
    );
    this.createProfile({ profileDrilldown, notification });
  }

  private createProfile(params: {
    profileDrilldown: ProfileDrilldown;
    notification: Notification;
  }): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      params.profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      params.notification.ecosystemId,
      params.profileDrilldown.profileType.id,
      params.profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "plus",
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory["My Tags"]
          : params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.Actor
          : TagCategory.Channel,
        true,
        params.profileDrilldown.value,
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? params.profileDrilldown.value
          : `Field field:${params.profileDrilldown.value}:${
              params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`
      ),
      undefined,
      undefined,
      0
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private loadMatches(): void {
    if (this.raisedAlertId) {
      this.notificationsService
        .getMatches(
          new QueryFilters(30, 1, undefined, undefined, undefined),
          this.raisedAlertId
        )
        .subscribe((matches: ISearchResults) => {
          this.matchesDataSource = matches;
        });
    }
  }

  public createForm(): void {
    this.form.addControl("alertType", new FormControl(undefined));
    this.form.addControl("priority", new FormControl(undefined));
    this.form.addControl("showAll", new FormControl("read"));
    this.form.addControl("query", new FormControl(undefined));
  }

  public onDataBound(params?: QueryFilters): void {
    if (params) {
      this.initialState = params;
    }
    this.initialState.query = this.form.get("query").value;

    const getNotificationsObservable =
      this.notificationsService.getNotifications(
        params || this.initialState,
        this.form.get("showAll").value === "unread" ? false : undefined,
        this.form.get("priority").value,
        this.form.get("alertType").value
      );
    const getUnreadCountObservable =
      this.notificationsService.getUnreadNotificationsCount(
        this.initialState,
        this.form.get("priority").value
      );

    forkJoin([getNotificationsObservable, getUnreadCountObservable]).subscribe(
      ([notifications, unreadCount]) => {
        this.unreadCount = unreadCount;
        this.buttonDataSource = this.buttonDataSource.map(
          (item: IDisplayType) => {
            if (item.id === "unread") {
              if (this.unreadCount === 0) {
                item = { ...item, ...{ disabled: true } };
              } else {
                delete item.disabled;
              }
            }
            return item;
          }
        );
      }
    );
  }

  public onDateRangeChange(args: IPreset): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
      let payload: IPresetQuery;
      if (args.start) {
        payload = this.getPayload(args);
      }
      this.initialState.where = payload;

      this.onSearch(this.initialState);
    });
  }

  private getPayload(dates: IPreset): IPresetQuery {
    return {
      label: DateRange.getDateQueryText(dates.label),
      start: DateRange.convertToEpochSec(moment(dates.start).toDate()),
      end:
        dates.label === DateQueryType.Custom
          ? DateRange.convertToEpochSec(moment(dates.end).toDate())
          : undefined,
    };
  }

  public onSearch(params?: QueryFilters): void {
    this.raisedAlertId = undefined;
    this.researchNotification = undefined;
    this.onDataBound(params);
  }

  public onDrilldownMatches(segment: ISegment): void {
    const notification = this.notificationsService.findNotificationById(
      this.raisedAlertId
    );
    this.onDrilldown(segment, notification);
  }

  public onDrilldown(segment?: ISegment, notification?: Notification): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment, notification);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment, notification);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.widgetService.updateSegmentObservable.next(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment, notification);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment, notification);
        break;
      case SegmentScope.REPLIES:
        this.drilldownReplies(segment, notification);
      default:
        break;
    }
  }

  private drilldownContext(
    segment: ISegment,
    notification: Notification
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      notification?.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      "discord",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      segment,
      undefined,
      segment.context
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownReplies(
    segment: ISegment,
    notification: Notification
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitType === "Comment"
        ? `${segment.value.emitType} by ${segment.value.emitActor} `
        : `Replies of ${segment.value.emitHeadline}`,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      notification.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.id}:ReplyTo`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "file",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownDiscord(
    segment: ISegment,
    notification: Notification
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      notification?.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "discord",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onDrilldownDocumentDetail(segment?: ISegment): void {
    const notification = this.notificationsService.findNotificationById(
      this.navigationItem.raisedAlertId
    );
    this.onDrilldown(segment, notification);
  }

  private drilldownChannel(
    segment: ISegment,
    notification: Notification
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      notification?.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "hashtag",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment, notification: Notification): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      notification?.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "user",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }
}
