import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  AngularModule,
  CoreModule,
  FAwesomeModule,
  MaterialModule,
} from "@intorqa-ui/core";
import { SharedModule } from "../shared/shared.module";
import { ApiSettingsComponent } from "./components/api-settings/api-settings.component";
import { NavigationHistoryComponent } from "./components/navigation-history/navigation-history.component";
import {
  GetFontAwesomeIconPipe,
  GetSVGIconPipe,
} from "./components/navigation-history/navigation-history.pipe";
import { WidgetSettingsChartModule } from "./modules/widget-settings-chart/widget-settings-chart.module";
import { WidgetSettingsProfilesModule } from "./modules/widget-settings-profiles/widget-settings-profiles.module";
import { WidgetSettingsTimelineModule } from "./modules/widget-settings-timeline/widget-settings-timeline.module";
import { WidgetSettingsComponent } from "./widget-settings.component";
import { NotificationsExploreComponent } from '../notifications/notifications-explore/notifications-explore.component';

@NgModule({
  imports: [
    AngularModule,
    CoreModule,
    SharedModule,
    MaterialModule,
    WidgetSettingsTimelineModule,
    WidgetSettingsProfilesModule,
    WidgetSettingsChartModule,
    FAwesomeModule,
    NotificationsExploreComponent,
  ],
  declarations: [
    WidgetSettingsComponent,
    ApiSettingsComponent,
    NavigationHistoryComponent,
    GetSVGIconPipe,
    GetFontAwesomeIconPipe,
  ],
  exports: [WidgetSettingsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WidgetSettingsModule {}
