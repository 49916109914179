import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import {
  Align,
  Category,
  CustomOverlayRef,
  CustomOverlayService,
  DateQueryType,
  DateRange,
  DialogComponent,
  DialogTypes,
  IToolbarAction,
  IconType,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
  ToolbarActions,
  VirtualScrollService,
} from "@intorqa-ui/core";
import {
  Alert,
  AlertTypes,
  AlertsService,
  AnalysisTypes,
  Board,
  BoardService,
  CategoryService,
  ChartType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  EcosystemsService,
  IBoard,
  ICreateTagMatchAlertDTO,
  ICreateTagThresholdAlertDTO,
  IData,
  ISegment,
  ITagMetadata,
  NavigationHistoryItem,
  Query,
  QueryRule,
  QueryType,
  Tag,
  TagMatchAlert,
  TagService,
  TagThresholdAlert,
  Timeline,
  TimelineStatus,
  WidgetActions,
} from "@intorqa-ui/shared";
import { KeycloakService } from "keycloak-angular";
import { cloneDeep } from "lodash";
import moment from "moment";
import {
  Observable,
  Subscription,
  concat,
  forkJoin,
  fromEvent,
  of,
} from "rxjs";

@Component({
  selector: "itq-timeline-wizard",
  templateUrl: "./timeline-wizard.component.html",
  styleUrls: ["./timeline-wizard.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class TimelineWizardComponent implements OnInit, AfterViewInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  public infoForm: FormGroup;
  public metadataForm: FormGroup;
  public contentForm: FormGroup;
  public WidgetActions = WidgetActions;
  public expandedFilters = true;
  public showLoader = false;
  public widget: Timeline;
  public timelineDataSource: IData;
  public query = new Query();
  public toolbarActions: Array<IToolbarAction>;
  public initialState: QueryFilters;
  public tableColumns: TableColumn[] = [];
  public timelines: Array<Timeline> = [];
  public categoriesDataSource: Array<Category>;
  private stepperClickSubscription: Subscription;
  public boardIds: Array<string>;
  public alertType: string;
  private loadTypesSubscription: Subscription;
  private loadAlertSubscription: Subscription;
  private alert: Alert;
  private changeAlertTypeSubscription: Subscription;
  private getFiltersSubscription: Subscription;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly IconType = IconType;
  readonly TimelineStatus = TimelineStatus;
  readonly Align = Align;

  @ViewChild("deleteButtonTemplate") deleteButtonTemplate: TemplateRef<unknown>;
  @ViewChild("ownerTemplate") ownerTemplate: TemplateRef<unknown>;
  @ViewChild("relationshipDropdownTemplate")
  relationshipDropdownTemplate: TemplateRef<unknown>;
  @ViewChild("typeTemplate")
  typeTemplate: TemplateRef<unknown>;
  @ViewChild("template")
  template: TemplateRef<unknown>;
  @ViewChild("stepper")
  stepper: MatStepper;

  constructor(
    private tagService: TagService,
    private snackBar: MatSnackBar,
    private boardService: BoardService,
    private customOverlayService: CustomOverlayService,
    private customOverlayRef: CustomOverlayRef,
    private virtualScrollService: VirtualScrollService,
    private categoryService: CategoryService,
    readonly alertService: AlertsService,
    readonly ecosystemService: EcosystemsService,
    readonly cdr: ChangeDetectorRef,
    readonly keycloakService: KeycloakService
  ) {}

  ngOnInit(): void {
    this.changeAlertTypeSubscription =
      this.tagService.changeAlertType$.subscribe((response: string) => {
        this.alertType = this.alertService.getTypeById(response)?.label;
        this.form.get("alerts").reset();
      });
    this.loadAlertSubscription = this.alertService.loadAlert$.subscribe(
      (response: Alert) => {
        this.alert = response;
      }
    );
    this.loadTypesSubscription = this.alertService.loadTypes$.subscribe(() => {
      this.alertType = this.alertService.getTypeById(
        this.metadataForm?.get("alertTypeId")?.value
      )?.label;
    });
    this.getFiltersSubscription = this.tagService.getFilters$.subscribe(() => {
      this.getFilters();
    });
    this.widget = cloneDeep(this.navigationItem.item);
    this.initialState = this.navigationItem.initialState;
    if (this.navigationItem.boardId) {
      this.boardIds = [this.navigationItem.boardId];
    }
    this.toolbarActions = [
      {
        action: ToolbarActions.DATE,
        expanded: true,
        data: {
          date: this.initialState?.where,
        },
      },
      {
        action: ToolbarActions.REFRESH,
      },
    ];
    this.createForm();
    this.loadQuery();
    if (
      this.navigationItem.action === WidgetActions.SETTINGS ||
      this.navigationItem.action === WidgetActions.CLONE ||
      this.navigationItem.rules?.length > 0 ||
      this.query.hasRules()
    ) {
      this.getFilters();
      this.getTimelineData();
    }
    if (
      this.navigationItem.action === WidgetActions.SETTINGS ||
      this.navigationItem.action === WidgetActions.CLONE
    ) {
      this.getBoardsForTag();
    }
  }

  private getBoardsForTag(): void {
    if (
      this.navigationItem?.id !== `tag_manager_${WidgetActions.CLONE}` &&
      this.navigationItem?.id !== `board_${WidgetActions.CLONE}`
    ) {
      this.boardService
        .getTags(this.widget.tagId)
        .then((response: Array<string>) => {
          this.boardIds = response;
        });
    }
  }

  ngAfterViewInit(): void {
    this.initColumns();
    const stepperClick = fromEvent(
      document.getElementsByTagName("mat-step-header"),
      "click"
    );
    this.stepperClickSubscription = stepperClick.subscribe(() => {
      this.infoForm.markAllAsTouched();
    });
  }

  ngOnDestroy(): void {
    this.stepperClickSubscription.unsubscribe();
    this.loadTypesSubscription.unsubscribe();
    this.loadAlertSubscription.unsubscribe();
    this.changeAlertTypeSubscription.unsubscribe();
    this.getFiltersSubscription.unsubscribe();
  }

  initColumns(): void {
    this.tableColumns = [
      {
        name: undefined,
        dataKey: "result",
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
  }

  private loadSelections(): void {
    let tagIds = this.query.getTagIds();
    let fieldFilterIds = this.query.getFieldFilterTagIds();
    let contentTag = this.query.getContentTag();
    this.tagService
      .getSelections(
        tagIds,
        fieldFilterIds,
        contentTag,
        this.categoryService.categories
      )
      .then((response: Array<ITagMetadata>) => {
        this.query.selections = response;
      });
  }

  private loadQuery(): void {
    this.contentForm.addControl(
      "query",
      new FormControl(undefined, [Validators.required])
    );
    if (this.widget._extras) {
      this.query = this.widget._extras;
      this.contentForm.controls.query.setValue(this.query.query);
    }
  }

  public getTimelineData(): void {
    if (this.query.hasRules()) {
      this.showLoader = true;
      this.tagService
        .execute(this.initialState, this.query, this.widget.ecosystemId)
        .then((response: IData) => {
          this.updateNavigationItem();
          this.timelineDataSource = {
            result:
              this.initialState.page > 1
                ? [...this.timelineDataSource.result, ...response.result]
                : response.result,
            count: response.count,
          };
          this.showLoader = false;
        });
    }
  }

  private updateNavigationItem(): void {
    const query = new Query();
    let rules = this.query.getRules();
    if (this.navigationItem?.rules?.length > 0) {
      rules = this.query.getRules().filter((rule: QueryRule) => {
        return rule.value !== this.navigationItem?.rules[0]?.value;
      });
    }
    rules.forEach((item: QueryRule) => {
      query.addRule(item);
    });
    this.onUpdateWidget({ prop: "_extras", value: query });
  }

  public onUpdateWidget(params: { prop: string; value: any }): void {
    this.widget[params.prop] = params.value;
    this.navigationItem.initialState = cloneDeep(this.initialState);
    this.navigationItem.item[params.prop] = params.value;
  }

  public onToggleFilters(): void {
    this.expandedFilters = !this.expandedFilters;
    this.articleDetail = undefined;
  }

  private createForm(): void {
    this.infoForm = new FormGroup({});
    this.contentForm = new FormGroup({
      query: new FormControl(undefined, [Validators.required]),
    });
    this.metadataForm = new FormGroup({});
    this.form.addControl("alerts", new FormGroup({}));
  }

  private createAlert(
    tagId: string
  ): Observable<TagThresholdAlert | TagMatchAlert> {
    const alertTypeName = this.alertService.getTypeById(
      this.metadataForm?.get("alertTypeId")?.value
    )?.label;
    let alertPayload: ICreateTagThresholdAlertDTO | ICreateTagMatchAlertDTO;
    if (alertTypeName === AlertTypes.THRESHOLD) {
      alertPayload = {
        tagId,
        typeId: this.metadataForm?.get("alertTypeId")?.value,
        priority: this.form?.get("alerts.priority")?.value,
        message: this.form?.get("alerts.message")?.value,
        postSlack: this.form?.get("alerts.postSlack")?.value || false,
        emailMe: false, //this.form?.get("alerts.emailMe")?.value || false,
        condition: this.form?.get("alerts.condition")?.value,
        period: this.form?.get("alerts.period")?.value,
        count: this.form?.get("alerts.count")?.value,
      } as ICreateTagThresholdAlertDTO;
    } else {
      alertPayload = {
        tagId,
        typeId: this.metadataForm?.get("alertTypeId")?.value,
        delay: this.form?.get("alerts.delay")?.value,
        priority: this.form?.get("alerts.priority")?.value,
        message: this.form?.get("alerts.message")?.value,
        emailMe: false, //this.form?.get("alerts.emailMe")?.value || false,
        postSlack: this.form?.get("alerts.postSlack")?.value || false,
      } as ICreateTagMatchAlertDTO;
    }
    return this.alertService.create(alertPayload);
  }

  public onSave(createNew?: boolean): void {
    this.showLoader = true;
    const boardIds = this.metadataForm.controls.boardIds.value?.map(
      (item: Board) => item.id
    );
    const alertTypeId = this.metadataForm?.get("alertTypeId")?.value;
    this.tagService
      .save({
        name: this.infoForm.controls.name.value,
        description: this.infoForm.controls.description.value,
        categoryId: this.metadataForm.controls.categoryId?.value,
        ecosystemId: this.widget.ecosystemId,
        query: this.query.modelToDTO(),
        sharedTag: this.metadataForm.controls.sharedTag.value,
        boardIds,
        _extras: JSON.stringify({ type: this.query.type }),
      })
      .subscribe(
        (response: Timeline) => {
          if (alertTypeId) {
            this.createAlert(response?.tagId).subscribe(() => {
              this.onCreateTagCallback(boardIds, response, createNew);
            });
          } else {
            this.onCreateTagCallback(boardIds, response, createNew);
          }
        },
        (error: any) => {
          this.customOverlayService.openCustom(
            {
              title: error.title,
              message: error.description,
              icon: ["far", "exclamation-circle"],
              size: "4x",
              dialog: {
                type: DialogTypes.ALERT,
              },
            },
            DialogComponent
          );
          this.showLoader = false;
        }
      );
  }

  private onCreateTagCallback(
    boardIds: Array<string>,
    timeline: Timeline,
    createNew: boolean
  ): void {
    this.snackBar.open(
      "Your tag has been created, and is available for immediate use!",
      "Close",
      {
        horizontalPosition: "right",
        duration: 5000,
        verticalPosition: "top",
      }
    );
    if (
      boardIds?.includes(this.navigationItem.boardId) &&
      !this.metadataForm.controls.categoryId?.value
    ) {
      timeline.alertTypeId = this.metadataForm?.get("alertTypeId")?.value;
      this.boardService.addTimeline$.next(timeline);
    }

    if (createNew) {
      this.onReset();
    } else {
      this.customOverlayRef.close();
    }
    this.showLoader = false;
  }

  private getChangedAlertsProperties(): { [key: string]: any } {
    let changedProperties = {};
    Object.keys((this.form.get("alerts") as FormGroup).controls).forEach(
      (name: string) => {
        const currentControl = (this.form.get("alerts") as FormGroup).controls[
          name
        ];

        if (!currentControl.pristine) {
          changedProperties = {
            ...changedProperties,
            [name]: currentControl.value,
          };
        }
      }
    );

    if (this.keycloakService.isUserInRole("saas-alerts")) {
      const currentControl = this.metadataForm.controls["alertTypeId"];

      if (!currentControl.pristine) {
        changedProperties = {
          ...changedProperties,
          typeId: currentControl.value,
        };
      }
    }
    return changedProperties;
  }

  private getChangedTagProperties(): { [key: string]: any } {
    let changedProperties = {};

    Object.keys(this.infoForm.controls).forEach((name: string) => {
      const currentControl = this.infoForm.controls[name];

      if (!currentControl.pristine) {
        if (name === "searchType") {
          changedProperties = {
            ...changedProperties,
            _extras: JSON.stringify({ type: currentControl.value }),
          };
        } else {
          changedProperties = {
            ...changedProperties,
            [name]: currentControl.value,
          };
        }
      }
    });

    Object.keys(this.metadataForm.controls).forEach((name: string) => {
      const currentControl = this.metadataForm.controls[name];

      if (
        !currentControl.pristine &&
        name !== "boardIds" &&
        name !== "alertTypeId"
      ) {
        changedProperties = {
          ...changedProperties,
          [name]: currentControl.value,
        };
      }
    });

    Object.keys(this.contentForm.controls).forEach((name: string) => {
      const currentControl = this.contentForm.controls[name];

      if (currentControl.touched) {
        delete currentControl.value.selections;
        changedProperties = {
          ...changedProperties,
          [name]: currentControl.value.modelToDTO(),
        };
      }
    });

    return changedProperties;
  }

  private updateTag(): Observable<Tag> {
    const changedProperties = this.getChangedTagProperties();
    if (Object.keys(changedProperties)?.length > 0) {
      changedProperties.tagId = this.widget.tagId;
      if (this.widget.categoryId) {
        changedProperties.systemTag = true;
        changedProperties.categoryId = this.widget.categoryId;
      } else {
        changedProperties.systemTag = false;
      }
      return this.tagService.update(changedProperties);
    }
    return undefined;
  }

  public onEdit(): void {
    const observables: Array<Observable<any>> = [
      this.updateTag(),
      ...this.updateBoards(),
      this.updateAlerts(),
    ].filter(Boolean);
    if (observables.length > 0) {
      forkJoin(observables).subscribe((values: Array<any>) => {
        const changedProperties = this.getChangedTagProperties();
        if (Object.keys(changedProperties)?.length > 0) {
          this.snackBar.open("Your tag has been updated!", "Close", {
            horizontalPosition: "right",
            duration: 5000,
            verticalPosition: "top",
          });
          const firstValue = values[0];
          if (firstValue) {
            const timeline = new Timeline(
              this.widget.widgetId,
              firstValue.username,
              this.widget.type,
              firstValue.name,
              firstValue.description,
              this.widget.chartType,
              firstValue.tagId,
              firstValue.createdDate,
              firstValue.sharedTag,
              undefined,
              firstValue.categoryId,
              firstValue.lastTaggingTime,
              firstValue.ecosystemId,
              firstValue.updatedDate,
              this.metadataForm.get("alertTypeId")?.value
            );
            if (firstValue?.query) {
              const extras = new Query();
              extras.query = extras.dtoToModel(firstValue.query);
              extras.type = JSON.parse(firstValue._extras).type;
              timeline._extras = extras;
              if (this.updateTag()) {
                this.boardService.updateTimeline(timeline);
              }
            }

            this.customOverlayRef.close({
              refresh: true,
              widget: timeline,
            });
          }
        } else {
          this.widget.alertTypeId = this.metadataForm.get("alertTypeId")?.value;
          this.customOverlayRef.close({
            refresh: true,
            widget: this.widget,
          });
        }
      });
    }
  }

  private updateAlerts(): Observable<any> {
    const changedAlertsProperties = this.getChangedAlertsProperties();
    const hasAlertTypeChanged = Object.keys(changedAlertsProperties).includes(
      "typeId"
    );
    let result: Observable<any>;
    if (hasAlertTypeChanged && changedAlertsProperties.typeId === undefined) {
      result = this.alertService.delete(this.alert.id);
    } else {
      if (Object.keys(changedAlertsProperties)?.length > 0) {
        if (this.alert?.id) {
          if (hasAlertTypeChanged) {
            result = concat(
              this.alertService.delete(this.alert.id),
              this.createAlert(this.widget.tagId)
            );
          } else {
            result = this.alertService.update(
              this.alert.id,
              changedAlertsProperties
            );
          }
        } else {
          result = this.createAlert(this.widget.tagId);
        }
      } else {
        result = of(undefined);
      }
    }
    return result;
  }

  /**
   * Updates the boards by adding or deleting the widget's tag ID.
   * @returns An array of observables representing the update operations.
   */
  private updateBoards(): Array<Observable<IBoard>> {
    let updateWidgetsPromises: Array<Observable<IBoard>> = [];
    const payload = this.getBoardIdsPayload();
    payload.add?.forEach((boardId: string) => {
      updateWidgetsPromises.push(
        this.boardService.updateWidgets(boardId, {
          add: [this.widget.tagId],
        })
      );
    });
    payload.delete?.forEach((boardId: string) => {
      updateWidgetsPromises.push(
        this.boardService.updateWidgets(boardId, {
          delete: [this.widget.tagId],
        })
      );
      this.boardService.removeTimelines$.next(this.widget);
    });
    return updateWidgetsPromises;
  }

  /**
   * Retrieves the payload containing the board IDs to be added and deleted.
   * @returns The payload object with the "add" and "delete" arrays.
   */
  private getBoardIdsPayload(): { add: string[]; delete: string[] } {
    const payload = { add: [], delete: [] };
    const boardIds = this.metadataForm.controls.boardIds.value?.map(
      (item: Board) => item.id
    );
    this.boardIds.forEach((boardId: string) => {
      if (!boardIds?.includes(boardId)) {
        payload.delete.push(boardId);
      }
    });

    boardIds?.forEach((boardId: string) => {
      if (!this.boardIds.includes(boardId)) {
        payload.add.push(boardId);
      }
    });

    return payload;
  }

  private onReset(): void {
    this.articleDetail = undefined;
    this.widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      undefined,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    this.timelineDataSource = undefined;
    this.stepper.reset();
    this.infoForm.reset();
    this.contentForm.reset();
    this.metadataForm.reset();
    this.contentForm.reset();
    this.query = new Query();
    this.tagService.resetQuery$.next();
    this.infoForm.controls.searchType.setValue(QueryType.QUICK_SEARCH);
    if (this.boardService?.board?.id) {
      const board = this.boardService?.findBoardById(
        this.boardService?.board?.id
      );
      this.metadataForm.controls.boardIds.setValue([board]);
    }
    this.toolbarActions = [
      {
        action: ToolbarActions.DATE,
        expanded: true,
        data: {
          date: {
            label: DateRange.getDateQueryText(DateQueryType.LastMonth),
            start: DateRange.convertToEpochSec(
              moment(new Date()).subtract(1, "month").toDate()
            ),
          },
        },
      },
      {
        action: ToolbarActions.REFRESH,
      },
    ];
  }

  public onDataBound(query: Query): void {
    this.articleDetail = undefined;
    this.query = query;
    this.cdr.detectChanges();
    this.tagService.reloadFilters$.next(this.initialState);
    if (this.query.hasRules()) {
      this.contentForm.controls.query.setValue(this.query);
    } else {
      this.contentForm.controls.query.setValue(undefined);
    }
    this.getTimelineData();
  }

  public onRefresh(): void {
    this.getTimelineData();
  }

  public onSearch(params: QueryFilters): void {
    this.initialState = params;
    this.getTimelineData();
  }

  public onChangeQueryType(): void {
    if (this.query.hasRules()) {
      this.customOverlayService.openCustom(
        {
          title: "Change search type",
          message:
            "In order to change your search type, you need to reset your query.<br><br>Do you wish to continue?",
          icon: ["far", "question-circle"],
          size: "4x",
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.clearQuery();
            this.query.type = this.infoForm.controls.searchType.value;
            this.getFilters();
          } else {
            this.infoForm.controls.searchType.setValue(this.query.type);
          }
        }
      );
    } else {
      this.clearQuery();
      this.query.type = this.infoForm.controls.searchType.value;
      this.getFilters();
    }
  }

  public onChangeEcosystem(): void {
    if (this.query.hasRules()) {
      this.customOverlayService.openCustom(
        {
          title: "Change ecosystem?",
          message: `In order to change ecosystem you need to reset your query.<br><br>Do you wish to continue?`,
          icon: ["far", "question-circle"],
          size: "4x",
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.initialState.resetPagination().then(() => {
              this.virtualScrollService.dataBoundObservable.next();
            });
            this.metadataForm.controls.categoryId.setValue(undefined);
            this.widget.ecosystemId = this.infoForm.controls.ecosystemId.value;
            this.clearQuery();
            this.getFilters();
          } else {
            this.infoForm.controls.ecosystemId.setValue(
              this.widget.ecosystemId
            );
          }
        }
      );
    } else {
      this.widget.ecosystemId = cloneDeep(
        this.infoForm.controls.ecosystemId.value
      );
      this.getFilters();
    }
  }

  private clearQuery(): void {
    if (this.infoForm.controls.searchType.value === QueryType.QUICK_SEARCH) {
      this.query = new Query([], this.infoForm.controls.searchType.value);
    }
    if (this.infoForm.controls.searchType.value === QueryType.QUERY_BUILDER) {
      this.query = new Query(
        [
          new QueryRule(
            DTOQueryFieldType.content,
            DTOQueryConditionOperator.contains,
            []
          ),
        ],
        this.infoForm.controls.searchType.value
      );
    } else {
      this.query = new Query([], this.infoForm.controls.searchType.value);
    }
    this.contentForm.controls.query.setValue(undefined);
  }

  private getFilters(): void {
    this.categoryService
      .getCategories(this.widget.ecosystemId)
      .then((response: Array<Category>) => {
        this.categoriesDataSource = response;
        this.loadSelections();
        this.cdr.detectChanges();
        this.tagService.getSearchResults$.next();
      });
  }
}
