import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SharedService } from "@intorqa-ui/core";
import {
  ConnectionsService,
  ISegment,
  LinkTagsService,
  NavigationHistoryItem,
  NotesService,
  ProfilesNavigationItem,
  WidgetActions,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-widget-settings-profiles",
  templateUrl: "./widget-settings-profiles.component.html",
  styleUrls: ["./widget-settings-profiles.component.scss"],
  providers: [SharedService, NotesService, ConnectionsService, LinkTagsService],
})
export class WidgetSettingsProfilesComponent implements OnInit {
  @Input()
  set navigationItem(value: NavigationHistoryItem) {
    this._navigationItem = value as ProfilesNavigationItem;
  }

  get navigationItem(): ProfilesNavigationItem {
    return this._navigationItem;
  }
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  public _navigationItem: ProfilesNavigationItem;

  readonly WidgetActions = WidgetActions;

  constructor() {}

  ngOnInit(): void {}
}
