import { Pipe, PipeTransform } from "@angular/core";
import {
  DTOQueryFieldType,
  DiscordNavigationItem,
  NavigationHistoryItem,
  Query,
} from "@intorqa-ui/shared";

@Pipe({
  name: "getSearchTerm",
})
export class GetSearchTermPipe implements PipeTransform {
  constructor() {}

  transform(query: Query): string | undefined {
    const searchTerm = query.getRuleValueByField(DTOQueryFieldType.content);
    return searchTerm && searchTerm.value?.length > 0
      ? searchTerm?.value[0]
      : undefined;
  }
}

@Pipe({
  name: "isDiscordNavigation",
})
export class IsDiscordNavigationPipe implements PipeTransform {
  constructor() {}

  transform(navigationItem: NavigationHistoryItem): boolean {
    return navigationItem instanceof DiscordNavigationItem;
  }
}
