import {
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Align,
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
  CustomOverlayService,
  IconType,
  QueryFilters,
  SearchFieldType,
  Sizes,
} from "@intorqa-ui/core";
import {
  ProfileService,
  ProfileConnection,
  Profile,
  NavigationHistoryItem,
  WidgetActions,
  ConnectionType,
  ConnectionsService,
  WidgetService,
  IProfileData,
  AnalysisTypes,
  ChartType,
  ProfilesNavigationItem,
} from "@intorqa-ui/shared";

import { cloneDeep } from "lodash";
import { Utils } from "utils/utils";

@Component({
  selector: "itq-add-connections",
  templateUrl: "./add-connections.component.html",
  styleUrls: ["./add-connections.component.scss"],
  providers: [ProfileService],
})
export class AddConnectionsComponent implements OnInit {
  @Input() connection: ProfileConnection;
  @Input() profile: Profile;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() form: FormGroup;
  @Input() action: WidgetActions;
  @Input() showTitle = true;

  public typesDataSource: Array<ConnectionType> = [];
  public initialState = new QueryFilters(30, 1, undefined, undefined, {
    active: "updatedDate",
    direction: "desc",
  });
  public profilesDataSource: Array<Profile>;
  public connections: Array<ProfileConnection> = [];

  readonly WidgetActions = WidgetActions;
  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly Validators = Validators;

  @ViewChild("valueTemplate")
  valueTemplate: TemplateRef<unknown>;
  @ViewChild("subTypeDropdownTemplate")
  subTypeDropdownTemplate: TemplateRef<unknown>;
  @ViewChild("typeTemplate")
  typeTemplate: TemplateRef<unknown>;
  @ViewChild("descriptionTemplate")
  descriptionTemplate: TemplateRef<unknown>;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    readonly customOverlayRef: CustomOverlayRef,
    private profileService: ProfileService,
    private connectionsService: ConnectionsService,
    readonly customOverlayService: CustomOverlayService,
    readonly widgetService: WidgetService
  ) {
    this.connections = cloneDeep(this.connectionsService.connections);
    if (this.config?.component?.name === "AddConnectionsComponent") {
      this.connection = this.config?.data?.connection;
      this.profile = this.config?.data?.profile;
      this.navigationItem = this.config?.data?.profile;
      this.form = this.config?.data?.form;
      this.action = this.config?.data?.action;
    }
  }

  ngOnInit() {
    this.getConnectionTypes();
    this.getProfileTypes();
    this.createForm();
    this.onGetProfiles();
  }

  ngOnDestroy(): void {
    this.form.removeControl("addConnections");
  }

  private getProfileTypes(): void {
    this.profileService.getProfileTypes(this.profile.ecosystemId).subscribe();
  }

  public onChangeConnectionType(): void {
    const typeId = this.form.get("addConnections.type").value;
    this.form.get("addConnections.profile").enable();
    this.form.get("addConnections.profile").reset();
    let id: string;
    if (this.action !== WidgetActions.SETTINGS) {
      this.connection = undefined;
      id = Utils.generateUUID();
    } else {
      id = this.connection.id;
    }
    const type = this.connectionsService.getConnectionTypeById(typeId);
    this.connection = new ProfileConnection(
      id,
      type.id,
      type.name,
      this.profile.profileId,
      this.profile.name,
      this.profileService.getProfileTypeById(this.profile.profileTypeId)?.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    this.onGetProfiles();
  }

  public onChangeProfile(): void {
    this.connection.targetProfileId = this.form.get(
      "addConnections.profile"
    ).value?.profileId;
    const profile = this.profilesDataSource.find(
      (item: Profile) => item.profileId === this.connection.targetProfileId
    );
    this.connection.targetProfileId = profile?.profileId;
    this.connection.targetProfileName = profile?.name;
    this.connection.targetProfileTypeName =
      this.profileService.getProfileTypeById(profile.profileTypeId)?.name;
    this.getConnectionTypes(profile.profileTypeId);
  }

  private getConnectionTypes(targetTypeId?: string): void {
    this.connectionsService
      .getConnectionTypes(this.profile, targetTypeId)
      .subscribe((response: Array<ConnectionType>) => {
        this.typesDataSource = response;
      });
  }

  public onChangeDescription(): void {
    this.connection.description = this.form.get(
      "addConnections.description"
    ).value;
  }

  private createForm(): void {
    this.form.addControl(
      "addConnections",
      new FormGroup({
        type: new FormControl(this.connection?.typeId, [Validators.required]),
        profile: new FormControl(
          {
            value: {
              name: this.connection?.targetProfileName,
              value: this.connection?.targetProfileId,
            },
            disabled: !this.connection?.targetProfileId,
          },
          [Validators.required]
        ),
        description: new FormControl(this.connection?.description),
      })
    );
    if (this.connection?.targetProfileId) {
      this.form.get("addConnections.profile").enable();
    }
  }

  public onClearProfiles(): void {
    this.initialState = new QueryFilters(30, 1, undefined, undefined, {
      active: "updatedDate",
      direction: "desc",
    });
    this.onGetProfiles();
    this.getConnectionTypes();
  }

  public onGetProfiles(params?: QueryFilters): void {
    const typeId = this.form.get("addConnections.type").value;
    const typeName =
      this.connectionsService.getConnectionTypeById(typeId)?.name;
    if (typeName) {
      const duplicateNames = this.connectionsService.connectionTypes.filter(
        (item: ConnectionType) => item.name === typeName
      );
      this.initialState.addQueryColumn({
        searchValues: duplicateNames.map(
          (item: ConnectionType) => item.targetProfileTypeId
        ),
        searchField: "typeId",
        searchFieldType: SearchFieldType.ID,
      });
      if (params?.query) {
        this.initialState.addQueryColumn({
          searchValues: [params.query.toString()],
          searchField: "name",
          searchFieldType: SearchFieldType.TEXT,
        });
      }

      this.profileService
        .getProfiles(this.initialState, this.profile.ecosystemId, false)
        .subscribe((response: IProfileData) => {
          if (response.items?.length === 0) {
            this.form
              .get("addConnections.profile")
              ?.removeValidators([Validators.required]);
          } else {
            this.form
              .get("addConnections.profile")
              ?.addValidators([Validators.required]);
          }

          const dataSource = response?.items?.filter(
            (item: Profile) => item.profileId !== this.profile.profileId
          );
          this.profilesDataSource = dataSource;
        });
    }
  }

  public onCreate(): void {
    this.addConnections();
    this.form.get("addConnections").reset();
    this.form.get("addConnections.type").setValue(this.connection?.typeId);
    this.form.get("addConnections.profile").setValue({
      name: this.connection?.targetProfileName,
      value: this.connection?.targetProfileId,
    });
    this.form.controls.connections?.markAsTouched();
  }

  public onCancel(): void {
    if (this.config?.component?.name === "AddConnectionsComponent") {
      this.customOverlayRef.close({ refresh: false });
    }

    this.connectionsService.showAddConnections$.next();
  }

  private addConnections(): void {
    if (
      this.navigationItem.action === WidgetActions.EXPLORE ||
      this.navigationItem.action === WidgetActions.SETTINGS
    ) {
      this.connectionsService
        .addConnections(this.profile, [this.connection])
        .subscribe();
    } else {
      this.connectionsService.connections.items.push(this.connection);
      this.connectionsService.connections.totalCount += 1;
      this.connectionsService.connections$.next(
        this.connectionsService.connections
      );
      this.connections = cloneDeep(this.connectionsService.connections);
    }
  }

  public onAdd(): void {
    this.addConnections();
    this.onCancel();
    this.form.controls.connections?.markAsTouched();
  }

  public onUpdate() {
    if (
      this.navigationItem.action === WidgetActions.EXPLORE ||
      this.navigationItem.action === WidgetActions.SETTINGS
    ) {
      if (this.connection?.id) {
        this.connectionsService
          .updateConnection(this.profile.profileId, this.connection)
          .subscribe(() => {
            this.onCancel();
          });
      }
    } else {
      this.connectionsService.connections.items =
        this.connectionsService.connections?.items?.map(
          (item: ProfileConnection) => {
            return item.id === this.connection.id ? this.connection : item;
          }
        );
      this.connectionsService.connections$.next(
        this.connectionsService.connections
      );
      this.onCancel();
    }
    this.form.controls.notes?.markAsTouched();
  }

  public onCreateProfile(): void {
    this.profileService
      .getProfileTypes(this.profile.ecosystemId)
      .subscribe(() => {
        const profileTypeName = this.profileService.getProfileTypeById(
          this.profile.profileTypeId
        )?.name;
        const typeId = this.form.get("addConnections.type").value;
        const connectionType =
          this.connectionsService.getConnectionTypeById(typeId);
        this.connection = new ProfileConnection(
          undefined,
          this.connection.typeId,
          this.connection.typeName,
          undefined,
          undefined,
          undefined,
          this.profile.profileId,
          this.profile.name,
          profileTypeName,
          this.connection.description,
          undefined,
          undefined
        );

        const profile = new Profile(
          undefined,
          undefined,
          AnalysisTypes.PROFILE,
          undefined,
          undefined,
          ChartType.PROFILE,
          this.profile?.ecosystemId,
          connectionType.targetProfileTypeId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        );
        const navigationItem = new ProfilesNavigationItem(
          `${WidgetActions.CREATE}_addconnection_profiles`,
          profile,
          WidgetActions.CREATE,
          undefined,
          undefined,
          this.form,
          "plus",
          IconType.FONT_AWESOME,
          undefined,
          undefined,
          undefined,
          undefined,
          [this.connection],
          undefined,
          0
        );
        this.onCancel();
        this.widgetService.drilldownObservable.next(navigationItem);
      });
  }
}
