import { EChartsOption } from "echarts";
import { defaultTheme } from "utils/echarts.theme";
import { Utils } from "utils/utils";
import {
  ChartOrientation,
  ChartType,
  WidgetOption,
} from "../../enums/widget.enum";
import {
  IDataPoint,
  IDisplayType,
  ISerie,
} from "../../interfaces/widget/widget.interface";
import { TagComparison } from "../widgets/tag-comparison";
import { TimeSeries } from "../widgets/time-series";
import { TagAnalysis } from "./../widgets/tag-analysis";
import { Chart } from "./chart";

export class VerticalBarChart extends Chart {
  constructor(public name: ChartType) {
    super(name);
  }

  public getOptions(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any }
  ): EChartsOption {
    let options: EChartsOption;
    options = {
      tooltip: {
        trigger: data?.length > 1 ? "item" : "axis",
        extraCssText: "overflow: auto; max-height: 250px; border: 0;",
        enterable: true,
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        data: data[0]?.data.map((item: IDataPoint) => {
          return item.category;
        }),
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: -320,
          formatter: (value: string) => {
            return value.trim().replace("\n", "").length > 10
              ? value.trim().replace("\n", "").substring(0, 10) + "..."
              : value.trim().replace("\n", "");
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value: number) => {
            return Utils.numFormatter(value);
          },
        },
      },
      series: data.map((serie: ISerie, index: number) => {
        return {
          name: serie.name,
          stack: settings?.mode === "stack" ? "stack" : undefined,
          legendHoverLink: true,
          data: serie.data.map((item: IDataPoint) => {
            return this.getDataPoint(item, segment, serie.tagId, index);
          }),
          emphasis: {
            focus: "series",
          },
          type: "bar",
          itemStyle: {
            borderRadius: data.length > 1 ? undefined : [5, 5, 0, 0],
          },
          label: {
            show: data?.length > 1 || serie.data?.length > 15 ? false : true,
            position: "top",
            labelLayout: {
              hideOverlap: true, // Automatically hide overlapping labels
            },
            formatter: (params: any) => {
              return params.value === 0 ? "" : params.value;
            },
          },
          tooltip: {
            confine: false,
            formatter: (value: any) => {
              let result = "<div>" + value.name + "</div>";
              let resultObj = data.map((item: ISerie, index: number) => {
                const overedSeries = item.name === value.seriesName;
                let tooltiptipItem = "";
                const dataPointValue = item.data.find(
                  (dataPoint: IDataPoint) => dataPoint.category === value.name
                );
                if (overedSeries) {
                  tooltiptipItem +=
                    "<span class='display: block;  width: 150px; height: 30px; padding-top: 5px;border: 1px solid black;'>";
                  tooltiptipItem +=
                    "<span style='padding-left: 5px;padding-right: 5px;display: inline-block;width: 10px; height: 10px; background-color: " +
                    defaultTheme.theme.color[index] +
                    "; border-radius: 100%;'></span>";
                  tooltiptipItem +=
                    "<div style='display: block;background-color: #8f8f90; border-radius: 5px; justify-content: space-between; padding-left: 5px;font-weight: bold; display: inline-block; color: " +
                    defaultTheme.theme.color[index] +
                    "'><span>" +
                    item.name +
                    "</span> - <span style='color: #333; padding-right: 5px;'>" +
                    dataPointValue.count +
                    "</span></div>";
                  tooltiptipItem += "</span>";
                } else {
                  tooltiptipItem +=
                    "<div class='display: flex; justify-content: space-between; width: 150px; height: 30px; padding-top: 5px;'>";
                  tooltiptipItem +=
                    "<span style='display: inline-block;width: 10px; height: 10px; background-color: " +
                    defaultTheme.theme.color[index] +
                    "; border-radius: 100%;'></span>";
                  tooltiptipItem +=
                    "<span style='padding-left: 5px; display: inline-block; color: " +
                    defaultTheme.theme.color[index] +
                    "'>" +
                    item.name +
                    "</span> - <span style='font-weight: bold; color: #333;'>" +
                    dataPointValue.count +
                    "</span>";
                  tooltiptipItem += "</div>";
                }
                return {
                  value: dataPointValue,
                  text: tooltiptipItem,
                };
              });
              resultObj = resultObj.sort((a, b) => {
                return b.value.count > a.value.count ? 1 : -1;
              });
              return result + resultObj.map((item) => item.text).join("");
            },
          },
        };
      }),
      grid: {
        left: 50,
        top: data.length > 1 ? 40 : 20,
        right: 10,
        bottom: data[0].data.length > 10 ? 90 : 70,
      },
      legend: {
        show: data.length > 1,
        type: "scroll",
        icon: "rect",
        top: "top",
        orient: "horizontal",
        left: "center",
        formatter: (value: string) => {
          return value.trim().replace("\n", "").length > 30
            ? value.trim().replace("\n", "").substring(0, 30) + "..."
            : value.trim().replace("\n", "");
        },
      },
      dataZoom: [
        {
          show: data[0].data.length > 10,
          realtime: true,
          start: 0,
          end: 100,
          height: 20,
          bottom: 10,
        },
      ],
    };

    return options;
  }

  public getDisplayTypes(
    displayTypes: Array<IDisplayType>,
    widget: TagAnalysis | TimeSeries | TagComparison
  ): Array<IDisplayType> {
    const selectedDisplay = this.getSelectedType(displayTypes, widget);
    const types = displayTypes?.filter((item: IDisplayType) => {
      const mode = selectedDisplay.options.mode;
      if (mode) {
        return item.options?.mode !== selectedDisplay.options.mode;
      } else {
        return (
          item.options?.orientation !== selectedDisplay.options.orientation
        );
      }
    });

    return types;
  }

  public getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: TagAnalysis | TimeSeries | TagComparison
  ): IDisplayType {
    return displayTypes.find((item: IDisplayType) => {
      const mode = widget.getOption(WidgetOption.MODE);
      if (mode) {
        return (
          item.type === ChartType.BAR &&
          item.options?.orientation === ChartOrientation.VERTICAL &&
          item.options?.mode === mode
        );
      } else {
        return (
          item.type === ChartType.BAR &&
          item.options?.orientation === ChartOrientation.VERTICAL
        );
      }
    });
  }
}
