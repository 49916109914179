import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Sizes, TableColumn, TagCategory } from "@intorqa-ui/core";
import { ITagMetadata, Query } from "@intorqa-ui/shared";

@Component({
  selector: "itq-timeline-selections",
  templateUrl: "./timeline-selections.component.html",
  styleUrls: ["./timeline-selections.component.scss"],
})
export class TimelineSelectionsComponent implements OnInit {
  @Input() query: Query;

  @Output() removeTag = new EventEmitter<ITagMetadata>();

  readonly Sizes = Sizes;
  readonly TagCategory = TagCategory;

  public selectionsTableColumns: Array<TableColumn> = [];

  @ViewChild("deleteButtonTemplate") deleteButtonTemplate: TemplateRef<unknown>;
  @ViewChild("includedTickTemplate") includedTickTemplate: TemplateRef<unknown>;
  @ViewChild("excludedTickTemplate") excludedTickTemplate: TemplateRef<unknown>;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.selectionsTableColumns = [
      {
        name: "Section",
        dataKey: "section",
        isSortable: true,
        width: "fit",
      },
      {
        name: "Category",
        dataKey: "categoryName",
        isSortable: true,
        width: "fit",
      },
      {
        name: "Tag",
        dataKey: "tagName",
        isSortable: true,
        width: "fit",
      },
      {
        name: "Included",
        dataKey: "included",
        position: "center",
        customRender: true,
        template: this.includedTickTemplate,
        width: "15%",
      },
      {
        name: "Excluded",
        dataKey: "excluded",
        position: "center",
        customRender: true,
        template: this.excludedTickTemplate,
        width: "15%",
      },
      {
        name: "Delete",
        dataKey: "delete",
        position: "center",
        customRender: true,
        template: this.deleteButtonTemplate,
        width: "100px",
      },
    ];
  }

  public onRemoveSelection(item: ITagMetadata): void {
    this.removeTag.emit(item);
  }
}
