import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Sizes } from "@intorqa-ui/core";
import {
  AlertType,
  AlertTypes,
  AlertsService,
  Board,
  BoardService,
  BoardType,
  CategoryService,
  NavigationHistoryItem,
  TagService,
  Timeline,
  WidgetActions,
} from "@intorqa-ui/shared";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: "itq-timeline-wizard-settings",
  templateUrl: "./timeline-wizard-settings.component.html",
  styleUrls: ["./timeline-wizard-settings.component.scss"],
})
export class TimelineWizardSettingsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() widget: Timeline;
  @Input() boardIds: Array<string>;
  @Input() navigationItem: NavigationHistoryItem;

  public boardsDataSource: Array<Board>;
  public alertTypesDataSource: Array<AlertType>;

  readonly BoardType = BoardType;
  readonly Sizes = Sizes;

  constructor(
    private boardService: BoardService,
    public categoryService: CategoryService,
    public keycloakService: KeycloakService,
    readonly alertsService: AlertsService,
    readonly tagService: TagService
  ) {}

  ngOnInit(): void {
    if (this.keycloakService.isUserInRole("saas-alerts")) {
      this.alertsService.getTypes().subscribe((response: Array<AlertType>) => {
        this.alertTypesDataSource = response?.filter(
          (item: AlertType) =>
            item.label !== AlertTypes.RESEARCH &&
            item.label !== AlertTypes.SYSTEM
        );
        if (
          this.navigationItem.action !== WidgetActions.CREATE &&
          this.form.get("alertTypeId").value
        ) {
          this.alertsService.getAlertByTagId(this.widget.tagId).subscribe();
        }
      });

      this.form.addControl(
        "alertTypeId",
        new FormControl(this.widget.alertTypeId)
      );
    }
    this.form.addControl("boardIds", new FormControl([]));
    this.form.addControl(
      "sharedTag",
      new FormControl({
        value: this.widget.sharedTag || false,
        disabled:
          this.widget.sharedTag && this.widget.alertTypeId ? true : false,
      })
    );
    if (this.keycloakService.isUserInRole("super-admin")) {
      this.form.addControl(
        "categoryId",
        new FormControl(this.widget.categoryId)
      );
    }

    this.loadBoards();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.boardIds?.previousValue !== changes?.boardIds?.currentValue) {
      this.setBoards();
    }
  }

  private loadBoards(): void {
    this.boardService.getBoards().then((response: Array<Board>) => {
      this.boardsDataSource = response;
      if (this.boardIds?.length > 0) {
        this.setBoards();
      }
    });
  }

  private setBoards(): void {
    if (this.boardsDataSource?.length > 0) {
      const boards = this.boardIds.map((item: string) =>
        this.boardsDataSource?.find((board: Board) => board.id === item)
      );
      this.form.controls.boardIds.setValue(boards);
    }
  }

  public onChangeCategory(categoryId: string): void {
    this.widget.categoryId = categoryId;
    this.form.get("sharedTag").setValue(true);
  }

  public onChangeAlertType(): void {
    this.tagService.changeAlertType$.next(this.form?.get("alertTypeId")?.value);
  }

  public onChangeSharedTag(event: MatSlideToggleChange): void {
    this.tagService.toggleSharedTag$.next(event.checked);
  }
}
