import { Injectable } from "@angular/core";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import { TimelineDTO } from "../interfaces/tags-dtos";

@Injectable({
  providedIn: "root",
})
export class TimelineService {
  public timelines: Array<TimelineDTO> = [];

  constructor(private apiRequestService: ApiRequestService) {}

  list(): Promise<Array<TimelineDTO>> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .get("/timelines", {}, new DTOTypeConverter<Array<TimelineDTO>>())
        .then((timelines: Array<TimelineDTO>) => {
          timelines = timelines.filter((t: TimelineDTO) => t.name.length > 0);
          this.timelines = timelines;
          resolve(timelines);
        })
        .catch(reject);
    });
  }
}
