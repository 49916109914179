import { cloneDeep } from "lodash";
import { Pipe, PipeTransform } from "@angular/core";
import { ListItem } from "../models/list-item";

@Pipe({
  name: "filterQuery",
})
export class FilterQueryPipe implements PipeTransform {
  transform(data: Array<ListItem>, query: string): Array<ListItem> {
    if (!query) return data;
    return cloneDeep(data).filter(
      (item: ListItem) =>
        item.name.toLowerCase().trim().indexOf(query.toLowerCase().trim()) > -1
    );
  }
}
