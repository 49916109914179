import { WIDGET_DIMENSIONS } from "../../const/widget.const";
import {
  IPlaceholder,
  IPlaceholderSize,
} from "../../interfaces/widget/placeholder.interface";

export class Placeholder implements IPlaceholder {
  constructor(
    public className: string,
    public width: IPlaceholderSize,
    public height: IPlaceholderSize,
    public container: Element
  ) {}

  public create(): HTMLDivElement {
    const placeholder = document.createElement("div");
    placeholder.className = this.className;
    placeholder.style.width = `${this.width.value}px`;
    placeholder.style.height = `${this.height.value}px`;
    placeholder.style.border = "3px dashed gray";
    placeholder.style.position = "absolute";
    placeholder.style.zIndex = "1";

    return placeholder;
  }

  public increaseWidth(): void {
    const resizerElement = document.querySelector(
      `.${this.className}`
    ) as HTMLElement;

    if (
      WIDGET_DIMENSIONS.width + this.width.end + WIDGET_DIMENSIONS.gutter <=
      this.container.clientWidth
    ) {
      this.width = {
        ...this.width,
        ...{
          start:
            WIDGET_DIMENSIONS.width +
            this.width.start +
            WIDGET_DIMENSIONS.gutter,
          end:
            WIDGET_DIMENSIONS.width + this.width.end + WIDGET_DIMENSIONS.gutter,
        },
      };
      resizerElement.style.width = `${this.width.end}px`;
    }
  }

  public descreaseWidth(): void {
    const resizerElement = document.querySelector(
      `.${this.className}`
    ) as HTMLElement;
    this.width = {
      ...this.width,
      ...{
        start:
          this.width.start - WIDGET_DIMENSIONS.width - WIDGET_DIMENSIONS.gutter,
        end:
          this.width.end - WIDGET_DIMENSIONS.width - WIDGET_DIMENSIONS.gutter,
      },
    };
    resizerElement.style.width = `${this.width.end}px`;
  }

  public increaseHeight(): void {
    const resizerElement = document.querySelector(
      `.${this.className}`
    ) as HTMLElement;

    if (
      WIDGET_DIMENSIONS.height + this.height.end + WIDGET_DIMENSIONS.gutter <=
      this.container.clientHeight
    ) {
      this.height = {
        ...this.height,
        ...{
          start:
            WIDGET_DIMENSIONS.height +
            this.height.start +
            WIDGET_DIMENSIONS.gutter,
          end:
            WIDGET_DIMENSIONS.height +
            this.height.end +
            WIDGET_DIMENSIONS.gutter,
        },
      };
      resizerElement.style.height = `${this.height.end}px`;
    }
  }

  public decreaseHeight(): void {
    const resizerElement = document.querySelector(
      `.${this.className}`
    ) as HTMLElement;
    this.height = {
      value:
        this.height.end - WIDGET_DIMENSIONS.height - WIDGET_DIMENSIONS.gutter,
      start:
        this.height.start - WIDGET_DIMENSIONS.height - WIDGET_DIMENSIONS.gutter,
      end:
        this.height.end - WIDGET_DIMENSIONS.height - WIDGET_DIMENSIONS.gutter,
    };
    resizerElement.style.height = `${this.height.end}px`;
  }

  public getRuleValue(): string {
    return "";
  }
}
