import { Component, OnInit } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatDateRangePicker } from "@angular/material/datepicker";
import { Align, DateRangeService, IPreset, Sizes } from "@intorqa-ui/core";
import { DateRange } from "../../date-range.model";
import { DateQueryType } from "./../../date-range.enum";

@Component({
  selector: "itq-date-range-presets",
  templateUrl: "./date-range-presets.component.html",
  styleUrls: ["./date-range-presets.component.scss"],
})
export class DateRangePresetsComponent<D> implements OnInit {
  readonly customPresets: Array<DateQueryType>;
  readonly Sizes = Sizes;
  readonly Align = Align;

  public dateRange: IPreset;

  constructor(
    readonly dateRangeService: DateRangeService,
    private picker: MatDateRangePicker<D>,
    private dateAdapter: DateAdapter<D>
  ) {
    this.customPresets = DateRange.getDateLabels();
    this.dateRange = this.dateRangeService.preset;
  }

  ngOnInit(): void {}

  selectRange(label: DateQueryType): void {
    this.dateRangeService.preset = { ...this.dateRangeService.preset, label };
    this.dateRange = DateRange.findPresetByLabel(label);
    const start = this.dateAdapter.getValidDateOrNull(this.dateRange.start);
    const end = this.dateAdapter.getValidDateOrNull(this.dateRange.end);
    this.picker.select(start);
    this.picker.select(end);
    if (label !== DateQueryType.Custom) {
      this.picker.close();
    }
  }
}
