import { TagCategory } from "@intorqa-ui/core";
import { LinkTag } from "../../models/profiles/profile-tags";

export interface ILinkTag {
  id: string;
  description: string;
  type: TagCategory;
  isDefault: boolean;
  tagName: string;
     updatedDate: number;
     updatedBy: string;
  tagId?: string;
}

export interface ILinksData {
  items: Array<LinkTag>;
  totalCount: number;
}

export interface ILoadLink {
  link: LinkTag,
  data: ILinksData
}
