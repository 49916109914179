import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ComponentType, Sizes } from "@intorqa-ui/core";
import {
  GetMetadataFieldPipe,
  MetadataFields,
  PricingModel,
  Profile,
  ProfileService,
  ProfileTypeMetadata,
} from "@intorqa-ui/shared";
import { Subscription } from "rxjs";

@Component({
  selector: "itq-metadata-container",
  templateUrl: "./metadata-container.component.html",
  styleUrls: ["./metadata-container.component.scss"],
})
export class MetadataContainerComponent implements OnInit {
  @Input() formControlName: string;
  @Input() form: FormGroup;
  @Input() profileMetadata: ProfileTypeMetadata;
  @Input() dataSource: Array<any>;
  @Input() profile: Profile;

  @Output() changeValue = new EventEmitter<string>();

  readonly ComponentType = ComponentType;
  readonly Sizes = Sizes;
  readonly MetadataFields = MetadataFields;
  readonly PricingModel = PricingModel;

  private changeMetadataControlValueSubscription: Subscription;

  constructor(
    public profileService: ProfileService,
    private getMetadataField: GetMetadataFieldPipe
  ) {}

  ngOnInit() {
    if (
      this.profileMetadata.name === MetadataFields.PRICING_CURRENCY ||
      this.profileMetadata.name === MetadataFields.PRICE_PER_MONTH
    ) {
      this.changeMetadataControlValueSubscription =
        this.profileService.changeMetadataControlValue$.subscribe(
          (response: PricingModel) => {
            this.form
              .get(`metadata.${this.formControlName}`)
              ?.setValue(undefined);
            if (!response || response === PricingModel.FREE) {
              this.form.get(`metadata.${this.formControlName}`)?.disable();
              if (
                this.profileMetadata.name === MetadataFields.PRICE_PER_MONTH
              ) {
                this.form
                  .get(`metadata.${this.formControlName}`)
                  ?.setValue("Free");
              }

              this.profileMetadata.required = false;
              this.form
                .get(`metadata.${this.formControlName}`)
                ?.removeValidators([Validators.required]);
            } else {
              this.form.get(`metadata.${this.formControlName}`)?.enable();
              this.form.get(`metadata.${this.formControlName}`)?.setValue(null);
              this.form
                .get(`metadata.${this.formControlName}`)
                ?.setValidators([Validators.required]);
              this.profileMetadata.required = true;
            }
          }
        );
    }
    const metadataGroup = this.form.get("metadata") as FormGroup;
    const metadata = this.profile?.metadata?.find(
      (item: ProfileTypeMetadata) => item.id === this.profileMetadata.id
    );
    const value = metadata?.values ? JSON.parse(metadata?.values) : undefined;
    let disabled = false;

    if (
      this.profileMetadata.name === MetadataFields.PRICING_CURRENCY ||
      this.profileMetadata.name === MetadataFields.PRICE_PER_MONTH
    ) {
      const pricingModel = this.getMetadataField.transform(
        this.profile.metadata,
        MetadataFields.PRICING_MODEL
      );
      if (pricingModel) {
        this.profileMetadata.required = true;
        if (pricingModel.values) {
          const parsedPricingModel = JSON.parse(pricingModel.values);
          this.profileService.pricingModel = parsedPricingModel;
          if (parsedPricingModel === PricingModel.ONE_OFF_PAYMENT) {
            this.profileMetadata.required = true;
          }
        }
      } else {
        disabled = true;
      }
    }
    metadataGroup.addControl(
      this.formControlName,
      new FormControl(
        { value, disabled },
        this.profileMetadata.required ? [Validators.required] : undefined
      )
    );
  }

  ngOnDestroy(): void {
    this.changeMetadataControlValueSubscription?.unsubscribe();
  }

  public onMetadataChange(params: string): void {
    if (this.profileMetadata.name === MetadataFields.PRICING_MODEL) {
      this.profileService.pricingModel = params as PricingModel;
      this.profileService.changeMetadataControlValue$.next(
        params as PricingModel
      );
    }
  }
}
