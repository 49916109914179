import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  PillType,
  QueryFilters,
  Sections,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from "@intorqa-ui/core";
import { ICustomTag, ITagMetadata, Query } from "@intorqa-ui/shared";
import { Subscription } from "rxjs";
import { ListSearch } from "./list-search.model";

@Component({
  selector: "itq-list-search",
  templateUrl: "./list-search.component.html",
  styleUrls: ["./list-search.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ListSearchComponent,
    },
  ],
})
export class ListSearchComponent implements OnInit, OnDestroy {
  @Input() initialState: QueryFilters;
  @Input() query: Query;
  @Input() category: TagCategory;
  @Input() filter: string;
  @Input() dataSource: Array<ICustomTag>;
  @Input() disabled = false;
  @Input() section: Sections;
  @Input() showSelectionsLabel = true;

  @Output() search = new EventEmitter<QueryFilters>();
  @Output() changeValue = new EventEmitter<ITagMetadata>();

  @ViewChild("listSearch") listSearch: any;

  readonly Sizes = Sizes;
  readonly PillType = PillType;

  public list: ListSearch;
  public showLoader = false;
  private scrollTop = 0;
  private dataBoundSubscription: Subscription;
  private touched = false;
  private value: Array<ITagMetadata>;
  private scrollSubscription: Subscription;

  constructor(private virtualScrollService: VirtualScrollService) {}

  ngOnInit(): void {
    this.dataBoundSubscription =
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.listSearch?._elementRef?.nativeElement?.scrollTo(0, 0);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.showLoader = false;
    }
    if (changes?.category?.previousValue !== changes?.category?.currentValue) {
      this.filter = undefined;
      this.onSearch();
    }
  }

  ngOnDestroy(): void {
    this.dataBoundSubscription.unsubscribe();
    this.scrollSubscription?.unsubscribe();
  }

  onChange = () => {};

  onTouched = () => {};

  writeValue(items: Array<ITagMetadata>): void {
    this.value = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public onSearch(): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    this.initialState.query = this.filter;
    this.showLoader = true;
    this.search.emit(this.initialState);
  }

  public onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.listSearch._elementRef.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          undefined,
          this.initialState.page
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.search.emit(this.initialState);
          }
        });
    }
  }

  public onChangeValue(value: ITagMetadata): void {
    this.changeValue.emit(value);
  }
}
