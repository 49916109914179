import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: "root",
})
export class ErrorResolver implements Resolve<void> {
  constructor(private errorService: ErrorService) {}

  resolve(): void {
    this.errorService.error = undefined;
  }
}
