import { ErrorMessages } from "./error.enum";

export const Errors = [
  {
    error: ErrorMessages.BOARD_NOT_FOUND,
    title: undefined,
    description:
      "The board you are trying to rename has been deleted by another user!",
  },
  {
    error: ErrorMessages.UPDATE_TREE_ERROR,
    title: undefined,
    description:
      "There was an error while trying to update the tree. Please refresh and try again!",
  },
  {
    error: ErrorMessages.CANNOT_DELETE_DEFAULT_BOARD,
    title: undefined,
    description:
      "The group can't be deleted because it contains the default board!",
  },
  {
    error: ErrorMessages.DUPLICATE_TAG_NAME,
    title: "Tag name already exists!",
    description: "Please change the tag name and try again!",
  },
  {
    error: ErrorMessages.TAG_API_PREFIX_NOT_FOUND,
    title: "Api key prefix not found!",
    description: undefined,
  },
  {
    error: ErrorMessages.CYCLIC_TAG_DEPENDENCY,
    title: undefined,
    description:
      "There is already a tag in this query that refers back to this tag.",
  },
  {
    error: ErrorMessages.INVALID_DEPENDENCIES_UPDATE,
    title: undefined,
    description:
      "You cannot unlink this tag, as then the linked Tag would have no query.",
  },
  {
    error: ErrorMessages.TAG_NOT_FOUND,
    title: undefined,
    description:
      "This tag does not exists. Please check if has it been deleted.",
  },
  {
    error: ErrorMessages.PROFILE_NOT_FOUND,
    title: undefined,
    description:
      "This profile does not exists. Please check if has it been deleted.",
  },
];
