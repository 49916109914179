import { cloneDeep } from "lodash";
import { Pipe, PipeTransform } from "@angular/core";
import {
  ConnectionsService,
  Profile,
  ProfileConnection,
  WidgetActions,
  ConnectionType,
} from "@intorqa-ui/shared";

@Pipe({
  name: "filterProfiles",
})
export class FilterProfilesPipe implements PipeTransform {
  constructor(private connectionsService: ConnectionsService) {}

  transform(
    data: Array<Profile>,
    connection: ProfileConnection,
    action: WidgetActions
  ): Array<Profile> {
    if (!data || data?.length === 0) return data;

    const connections = this.connectionsService.connections.items?.filter(
      (item: ProfileConnection) => item.typeId === connection.typeId
    );
    const profileIds = connections.map(
      (item: ProfileConnection) => item.targetProfileId
    );
    if (action === WidgetActions.SETTINGS) {
      return data.filter(
        (item: Profile) =>
          !profileIds.includes(item.profileId) ||
          item.profileId === connection.targetProfileId
      );
    } else {
      return data.filter(
        (item: Profile) => !profileIds.includes(item.profileId)
      );
    }
  }
}

@Pipe({
  name: "filterDuplicates",
})
export class FilterDuplicatesPipe implements PipeTransform {
  constructor() {}

  transform(connectionType: Array<ConnectionType>): Array<ConnectionType> {
    const data = cloneDeep(connectionType);
    const attribute = "name";
    const uniqueEntries = {};
    const result = [];
    for (const item of data) {
      if (!uniqueEntries[item[attribute]]) {
        uniqueEntries[item[attribute]] = true;
        result.push(item);
      }
    }
    return result;
  }
}
