import { TableChart } from "./../models/charts/table-chart";
import { LineChart } from "./../models/charts/line-chart";
import {
  ChartOrientation,
  ChartType,
  LineMode,
  PieMode,
  WidgetOption,
} from "../enums/widget.enum";
import { Chart } from "../models/charts/chart";
import { HorizontalBarChart } from "../models/charts/horizontal-bar-chart";
import { VerticalBarChart } from "../models/charts/vertical-bar-chart";
import { AreaChart } from "../models/charts/area-chart";
import { PieChart } from "../models/charts/pie-chart";
import { DoughnutChart } from "../models/charts/doughnut-chart";
import { FoamtreeChart } from "../models/charts/foamtree-chart";
import { TimelineChart } from "../models/charts/timeline-chart";
import { NetworkChart } from "../models/charts/network-chart";
import { TagComparison } from "../models/widgets/tag-comparison";
import { TimeSeries } from "../models/widgets/time-series";
import { TagAnalysis } from "../models/widgets/tag-analysis";

export class ChartFactory {
  static createObject(widget: TagAnalysis | TimeSeries | TagComparison): Chart {
    if (widget.chartType === ChartType.BAR) {
      const orientation = widget.getOption(WidgetOption.ORIENTATION);
      if (orientation === ChartOrientation.VERTICAL) {
        return new VerticalBarChart(widget.chartType);
      } else {
        return new HorizontalBarChart(widget.chartType);
      }
    } else if (widget.chartType === ChartType.LINE) {
      const mode = widget.getOption(WidgetOption.MODE);
      if (mode === LineMode.LINE) {
        return new LineChart(widget.chartType);
      } else {
        return new AreaChart(widget.chartType);
      }
    } else if (widget.chartType === ChartType.PIE) {
      const mode = widget.getOption(WidgetOption.MODE);
      if (mode === PieMode.PIE) {
        return new PieChart(widget.chartType);
      } else {
        return new DoughnutChart(widget.chartType);
      }
    } else if (widget.chartType === ChartType.TABLE) {
      return new TableChart(widget.chartType);
    } else if (widget.chartType === ChartType.FOAMTREE) {
      return new FoamtreeChart(widget.chartType);
    } else if (widget.chartType === ChartType.TIMELINE) {
      return new TimelineChart(widget.chartType);
    } else if (widget.chartType === ChartType.NETWORK) {
      return new NetworkChart(widget.name, widget.chartType);
    }
  }
}
