import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DynamicPlaceholderDirective, IconType } from "@intorqa-ui/core";
import {
  ISegment,
  NoteType,
  NotesService,
  ProfileNote,
} from "@intorqa-ui/shared";
import { ViewNotesFactory } from "./view-notes.factory";

@Component({
  selector: "itq-view-notes",
  templateUrl: "./view-notes.component.html",
  styleUrls: ["./view-notes.component.scss"],
})
export class ViewNotesComponent implements OnInit {
  @Input() note: ProfileNote;
  @Input() segment: ISegment;

  readonly IconType = IconType;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  dynamicPlaceholder!: DynamicPlaceholderDirective;

  constructor(private notesService: NotesService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.note?.previousValue !== changes?.note?.currentValue) {
      this.dynamicPlaceholder?.viewContainerRef?.clear();
      const noteType = this.notesService.getTypeById(this.note.typeId);
      this.createComponent(noteType);
    }
  }

  private createComponent(item: NoteType): void {
    const viewContainerRef = this.dynamicPlaceholder.viewContainerRef;
    const component = ViewNotesFactory.getComponent(item);
    let componentRef = viewContainerRef.createComponent<any>(component);
    componentRef.instance.note = this.note;
  }

  public onClose(): void {
    this.notesService.showViewNotes$.next(false);
  }
}
