import { Type } from "@angular/core";
import { NoteType, NoteTypes } from "@intorqa-ui/shared";
import { ViewCodeSnippetComponent } from "./components/view-code-snippet/view-code-snippet.component";
import { ViewContactDetailsComponent } from "./components/view-contact-details/view-contact-details.component";
import { ViewDomainDetailsComponent } from "./components/view-domain-details/view-domain-details.component";
import { ViewFileHashComponent } from "./components/view-file-hash/view-file-hash.component";
import { ViewGamerTagComponent } from "./components/view-gamer-tag/view-gamer-tag.component";
import { ViewPaymentDetailsComponent } from "./components/view-payment-details/view-payment-details.component";
import { ViewRepoLinkComponent } from "./components/view-repo-link/view-repo-link.component";
import { ViewScreenshotComponent } from "./components/view-screenshot/view-screenshot.component";
import { ViewSocialChannelsComponent } from "./components/view-social-channels/view-social-channels.component";
import { ViewTextNoteComponent } from "./components/view-text-note/view-text-note.component";
import { ViewVideoLinkComponent } from "./components/view-video-link/view-video-link.component";
import { ViewBioLinkComponent } from "./components/view-bio-link/view-bio-link.component";
import { ViewAliasComponent } from "./components/view-alias/view-alias.component";
import { ViewForumProfileComponent } from "./components/view-forum-profile/view-forum-profile.component";
import { ViewStoreFrontComponent } from "./components/view-store-front/view-store-front.component";

export class ViewNotesFactory {
  static getComponent(
    item: NoteType
  ): Type<
    | ViewTextNoteComponent
    | ViewPaymentDetailsComponent
    | ViewGamerTagComponent
    | ViewSocialChannelsComponent
    | ViewRepoLinkComponent
    | ViewContactDetailsComponent
    | ViewDomainDetailsComponent
    | ViewCodeSnippetComponent
    | ViewFileHashComponent
    | ViewScreenshotComponent
    | ViewVideoLinkComponent
    | ViewBioLinkComponent
    | ViewAliasComponent
    | ViewForumProfileComponent
    | ViewStoreFrontComponent
  > {
    if (item.name === NoteTypes.PaymentDetails) {
      return ViewPaymentDetailsComponent;
    } else if (item.name === NoteTypes.SocialChannels) {
      return ViewSocialChannelsComponent;
    } else if (item.name === NoteTypes.Gamertag) {
      return ViewGamerTagComponent;
    } else if (item.name === NoteTypes.CodeRepoLink) {
      return ViewRepoLinkComponent;
    } else if (item.name === NoteTypes.ContactDetails) {
      return ViewContactDetailsComponent;
    } else if (item.name === NoteTypes.DomainDetails) {
      return ViewDomainDetailsComponent;
    } else if (item.name === NoteTypes.CodeSnippet) {
      return ViewCodeSnippetComponent;
    } else if (item.name === NoteTypes.FileHash) {
      return ViewFileHashComponent;
    } else if (item.name === NoteTypes.Screenshot) {
      return ViewScreenshotComponent;
    } else if (item.name === NoteTypes.VideoLink) {
      return ViewVideoLinkComponent;
    } else if (item.name === NoteTypes.BioLink) {
      return ViewBioLinkComponent;
    } else if (item.name === NoteTypes.Alias) {
      return ViewAliasComponent;
    } else if (item.name === NoteTypes.ProfileLink) {
      return ViewForumProfileComponent;
    } else if (item.name === NoteTypes.Storefront) {
      return ViewStoreFrontComponent;
    } else {
      return ViewTextNoteComponent;
    }
  }
}
