import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CustomOverlayRef,
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
  DynamicPlaceholderDirective,
  Ecosystem,
  IconType,
  PillType,
  QueryFilters,
  TableColumn,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  ChartOrientation,
  ChartType,
  DocumentItemService,
  DTOQuery,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  EcosystemsService,
  IDisplayType,
  IWidget,
  IWidgetData,
  IWidgetType,
  NavigationHistoryItem,
  PieMode,
  Query,
  QueryRule,
  TagAnalysis,
  TagComparison,
  TimeSeries,
  Widget,
  WidgetActions,
  WidgetFactory,
  WidgetOption,
  WidgetService,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { fromEvent, Subscription } from "rxjs";
import { WidgetSettingsFactory } from "../../../widget-settings.factory";

@Component({
  selector: "itq-chart-wizard",
  templateUrl: "./chart-wizard.component.html",
  styleUrls: ["./chart-wizard.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ChartWizardComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;

  public showLoader = false;
  public widgetType: IWidgetType;
  public widget: TagAnalysis | TagComparison | TimeSeries;
  public initialState: QueryFilters;
  public data: IWidgetData;
  public tableColumns: Array<TableColumn>;
  public expandedFilters = true;
  public infoForm: FormGroup;
  public metricsForm: FormGroup;
  private stepperClickSubscription: Subscription;
  private componentRef: ComponentRef<any>;
  private ecosystemSubscription: Subscription;

  readonly IconType = IconType;
  readonly WidgetActions = WidgetActions;
  readonly AnalysisTypes = AnalysisTypes;
  readonly ChartType = ChartType;
  readonly ChartOrientation = ChartOrientation;
  readonly WidgetOption = WidgetOption;
  readonly PieMode = PieMode;
  readonly PillType = PillType;

  @ViewChild("countTemplate") countTemplate: TemplateRef<unknown>;
  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  constructor(
    private widgetService: WidgetService,
    private snackBar: MatSnackBar,
    public customOverlayRef: CustomOverlayRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    public documentService: DocumentItemService,
    private customOverlayService: CustomOverlayService,
    private ecosystemService: EcosystemsService
  ) {}

  ngOnInit(): void {
    this.infoForm = new FormGroup({});
    this.metricsForm = new FormGroup({});
    this.widget = cloneDeep(this.navigationItem.item);
    this.initialState = this.navigationItem.initialState;
    this.ecosystemSubscription = this.ecosystemService.ecosystems$.subscribe(
      (response: Array<Ecosystem>) => {
        if (response?.length === 1) {
          this.widget.ecosystemId = response[0].id;
          this.createComponent();
        }
      }
    );
    if (
      this.navigationItem.action === WidgetActions.SETTINGS ||
      this.navigationItem.action === WidgetActions.CLONE
    ) {
      this.createComponent();
      this.getData(this.initialState);
    }
  }

  ngAfterViewInit(): void {
    const stepperClick = fromEvent(
      document.getElementsByTagName("mat-step-header"),
      "click"
    );
    this.stepperClickSubscription = stepperClick.subscribe(() => {
      this.infoForm.markAllAsTouched();
      this.infoForm.controls.name.markAsDirty();
    });
  }

  ngOnDestroy(): void {
    this.ecosystemSubscription.unsubscribe();
    this.stepperClickSubscription.unsubscribe();
  }

  public onSave(): void {
    this.showLoader = true;
    this.widgetService
      .createWidget(this.widget)
      .then((response: TimeSeries | TagComparison | TagAnalysis) => {
        this.snackBar.open(
          "Your widget has been created and is available for immediate use!",
          "Close",
          {
            horizontalPosition: "right",
            duration: 5000,
            verticalPosition: "top",
          }
        );
        this.customOverlayRef.close({ widget: response, refresh: true });
      });
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        WidgetSettingsFactory.getComponent(this.widget)
      );

    this.componentRef = viewContainerRef.createComponent(
      dynamicComponentFactory
    );
    this.componentRef.instance.widget = this.widget;
    this.componentRef.instance.form = this.metricsForm;
    this.componentRef.instance.action = this.navigationItem.action;
    this.componentRef.instance.initialState = this.initialState;
    this.componentRef.instance.ecosystemId = this.widget.ecosystemId;
    this.componentRef.instance.update.subscribe(
      (widget: TagAnalysis | TagComparison | TimeSeries) => {
        this.widget = widget;
        if (this.widget.hasOptions()) {
          this.showLoader = true;
          this.getData(this.initialState);
        }
      }
    );
    this.componentRef.instance.showLoader.subscribe((response: boolean) => {
      this.showLoader = response;
    });
  }

  public onChangeAnalysis(params: IDisplayType): void {
    this.metricsForm = new FormGroup({});
    this.widget = WidgetFactory.createObject(
      cloneDeep({
        widgetId: this.widget.widgetId,
        type: params.id,
        name: this.widget.name,
        description: this.widget.description,
        ecosystemId: this.widget.ecosystemId,
      } as IWidget)
    );
    if (this.widget.ecosystemId) {
      const viewContainerRef = this.placeholder.viewContainerRef;
      viewContainerRef.clear();
      this.createComponent();
    }
    this.data = undefined;
  }

  public onEdit(): void {
    this.widgetService.updateWidget(this.widget).then((response: Widget) => {
      this.snackBar.open("Your widget has been updated!", "Close", {
        horizontalPosition: "right",
        duration: 5000,
        verticalPosition: "top",
      });
      this.customOverlayRef.close({
        widget: response,
        refresh: true,
      });
    });
  }

  private getData(params: QueryFilters): void {
    if (this.widget.hasOptions()) {
      this.showLoader = true;

      const queryModel = new Query();
      this.initialState = params;
      let query: DTOQuery;
      if (this.initialState?.query) {
        queryModel.addRule(
          new QueryRule(
            DTOQueryFieldType.content,
            DTOQueryConditionOperator.contains,
            [this.initialState.query]
          )
        );
      }
      query = queryModel.modelToDTO();

      const state = {
        where: this.initialState?.where,
      };
      const widget = cloneDeep(this.widget);
      this.widgetService
        .getData(
          {
            widget,
            filters: query,
          },
          state
        )
        .then((response: IWidgetData) => {
          this.tableColumns = this.widget.getTableColumns(this.countTemplate);
          this.data = response;
          this.showLoader = false;
        });
    }
  }

  public onUpdate(params: { field: string; value: string }): void {
    this.widget[params.field] = params.value;
  }

  public onChangeEcosystem(): void {
    if (this.widget.hasMetrics()) {
      this.customOverlayService.openCustom(
        {
          title: "Change ecosystem?",
          message: `In order to change ecosystem you need to reset your metrics.<br><br>Do you wish to continue?`,
          icon: ["far", "question-circle"],
          size: "4x",
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.widget.resetMetrics();
            this.widget.ecosystemId = this.infoForm.controls.ecosystem.value;
            this.createComponent();
          } else {
            this.infoForm.controls.ecosystem.setValue(this.widget.ecosystemId);
          }
        }
      );
    } else {
      this.widget.ecosystemId = this.infoForm.controls.ecosystem.value;
      this.createComponent();
      this.widgetService.changeEcosystemObservable.next();
    }
  }
}
