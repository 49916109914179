// Angular modules
import { DatePipe, DecimalPipe } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { YouTubePlayerModule } from "@angular/youtube-player";
import {
  AngularModule,
  CoreModule,
  FAwesomeModule,
  FormatDatePipe,
  HighlightSearchPipe,
  MaterialModule,
} from "@intorqa-ui/core";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { QuillModule } from "ngx-quill";
import { DocumentItemDetailComponent } from "./components/document-item-detail/document-item-detail.component";
import { DocumentItemTileComponent } from "./components/document-item-tile/document-item-tile.component";

import { NgxEchartsModule } from "ngx-echarts";
import {} from "../widget-settings/components/navigation-history/navigation-history.component";
import { ChartComponent } from "./components/chart/chart.component";
import { SortTableDataPipe } from "./components/chart/chart.pipe";
import {
  CustomSortExtensionFieldsPipe,
  FilterEmptyExtensionFieldsPipe,
  GetExtensionFieldNamePipe,
  GetExtensionFieldValuesPipe,
} from "./components/document-item-detail/document-item-detail.pipe";
import { ShareEmailComponent } from "./components/share-email/share-email.component";
import { DefaultImage } from "./directives/image-default.directive";
import {
  DetectUrlPipe,
  MillisToDate,
  SafePipe,
  StripeImgPipe,
  StripHtmlPipe,
  TimeAgoPipe,
  UrlifyPipe,
} from "../../../../../shared/src/lib/pipes/shared.pipe";
import { TagsIncludeSelectionComponent } from "./components/tags-include-selection/tags-include-selection.component";

import { FilterByTypePipe } from "./pipes/board.pipe";
import { SearchResultsComponent } from "./components/search-results/search-results.component";
import {
  GetSearchTermPipe,
  IsDiscordNavigationPipe,
} from "./components/search-results/search-results.pipe";
import {
  TransformMetadataValuesPipe,
  GetProfileTypeIconPipe,
  GetTrendIconColor,
  GetTrendIconPipe,
} from "@intorqa-ui/shared";
import { AddNotesComponent } from "./components/add-notes/add-notes.component";
import { PostNotesWizardComponent } from "./components/post-notes-wizard/post-notes-wizard.component";

@NgModule({
  imports: [
    FAwesomeModule,
    AngularModule,
    MaterialModule,
    CoreModule,
    YouTubePlayerModule,
    IconSpriteModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    QuillModule.forRoot({
      customOptions: [
        {
          import: "formats/font",
          whitelist: [
            "mirza",
            "roboto",
            "aref",
            "serif",
            "sansserif",
            "monospace",
          ],
        },
      ],
    }),
    MillisToDate,
  ],
  declarations: [
    ShareEmailComponent,
    DocumentItemTileComponent,
    DocumentItemDetailComponent,
    ChartComponent,
    DefaultImage,
    SearchResultsComponent,
    TransformMetadataValuesPipe,
    FormatDatePipe,
    StripeImgPipe,
    TimeAgoPipe,
    SafePipe,
    DetectUrlPipe,
    HighlightSearchPipe,
    CustomSortExtensionFieldsPipe,
    FilterEmptyExtensionFieldsPipe,
    SortTableDataPipe,
    StripHtmlPipe,
    TagsIncludeSelectionComponent,
    FilterByTypePipe,
    GetExtensionFieldNamePipe,
    GetSearchTermPipe,
    GetExtensionFieldValuesPipe,
    GetProfileTypeIconPipe,
    AddNotesComponent,
    UrlifyPipe,
    GetTrendIconColor,
    PostNotesWizardComponent,
    IsDiscordNavigationPipe,
    GetTrendIconPipe,
  ],
  exports: [
    ShareEmailComponent,
    DocumentItemTileComponent,
    DocumentItemDetailComponent,
    ChartComponent,
    DefaultImage,
    FormatDatePipe,
    StripeImgPipe,
    TimeAgoPipe,
    SafePipe,
    TransformMetadataValuesPipe,
    GetTrendIconColor,
    DetectUrlPipe,
    HighlightSearchPipe,
    CustomSortExtensionFieldsPipe,
    FilterEmptyExtensionFieldsPipe,
    StripHtmlPipe,
    TagsIncludeSelectionComponent,
    SearchResultsComponent,
    GetSearchTermPipe,
    FilterByTypePipe,
    GetProfileTypeIconPipe,
    AddNotesComponent,
    PostNotesWizardComponent,
    UrlifyPipe,
    GetTrendIconPipe,
  ],
  providers: [DatePipe, DecimalPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor() {}
}
