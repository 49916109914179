import { BoardType } from "../enums/board.enum";

export interface ISyncFusionTreeNode {
  id: string;
  name: string;
  expanded?: boolean;
  child?: Array<ISyncFusionTreeNode>;
  icon?: string;
  hasChildren?: boolean;
  isSelected?: boolean;
  pid?: string;
  isGroup: boolean;
  boardType?: BoardType;
  defaultBoard: boolean;
}

export interface ISyncFusionTree {
  dataSource: Array<ISyncFusionTreeNode>;
  id: string;
  parentID: string;
  text: string;
  hasChildren: string;
  selected: string;
  iconCss: string;
  isGroup: string;
  type: string;
  boardType: string;
}
