import { ElementRef, Pipe, PipeTransform } from "@angular/core";
import { Board, BoardType } from "@intorqa-ui/shared";

@Pipe({
  name: "showToggle",
})
export class ShowTogglePipe implements PipeTransform {
  constructor() {}

  transform(
    scrollerRef: ElementRef<HTMLDivElement>,
    widgetsCount: number,
    containerWidth: number
  ): boolean {
    if (scrollerRef && widgetsCount > 0) {
      return (
        scrollerRef.nativeElement.clientWidth / widgetsCount < containerWidth
      );
    }
    return false;
  }
}

@Pipe({
  name: "filterByType",
})
export class FilterByTypePipe implements PipeTransform {
  constructor() {}

  transform(boards: Array<Board>, type: BoardType): Array<Board> | undefined {
    if (!boards || boards?.length === 0) return boards;
    return boards.filter((board) => board.type === type);
  }
}
