import {
  AfterViewInit,
  Component,
  ComponentRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  IToolbarAction,
  IconType,
  PillType,
  QueryFilters,
  TableColumn,
  TagCategory,
  ToolbarActions,
  VirtualScrollService,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  ChartOrientation,
  ChartType,
  ChartTypes,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DiscordNavigationItem,
  DocumentItemService,
  IAddConnection,
  IAddNote,
  IDataPoint,
  ISearchResults,
  ISegment,
  IWidget,
  IWidgetData,
  IWidgetType,
  LinkTag,
  NavigationHistoryItem,
  PieMode,
  Profile,
  ProfileDrildownScope,
  ProfileDrilldown,
  ProfilesNavigationItem,
  Query,
  QueryRule,
  TagAnalysis,
  TagComparison,
  TagService,
  TimeSeries,
  Timeline,
  Utils,
  Widget,
  WidgetActions,
  WidgetOption,
  WidgetService,
} from "@intorqa-ui/shared";
import { ECharts } from "echarts";
import html2canvas from "html2canvas";
import { cloneDeep } from "lodash";
import { SegmentScope } from "../../../../../../../../shared/src/lib/enums/widget-settings.enum";
import { AddConnectionsComponent } from "../../../../shared/components/add-connections/add-connections.component";
import { ChartComponent } from "../../../../shared/components/chart/chart.component";
import { PostNotesWizardComponent } from "../../../../shared/components/post-notes-wizard/post-notes-wizard.component";

@Component({
  selector: "itq-chart-explore",
  templateUrl: "./chart-explore.component.html",
  styleUrls: ["./chart-explore.component.scss"],
})
export class ChartExploreComponent implements OnInit, AfterViewInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() segment: any;
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  @ViewChild("countTemplate") countTemplate: TemplateRef<unknown>;
  @ViewChild("chart") chart: ComponentRef<ChartComponent>;
  @ViewChild("template")
  template: TemplateRef<unknown>;

  public showLoader = false;
  public widgetType: IWidgetType;
  public widget: TagAnalysis | TagComparison | TimeSeries;
  public initialState: QueryFilters;
  public initialStateCopy: QueryFilters;
  public ChartType = ChartType;
  public WidgetOption = WidgetOption;
  public PieMode = PieMode;
  public documentsData: ISearchResults;
  public data: IWidgetData;
  public toolbarActions: Array<IToolbarAction>;
  public expandedFilters = true;
  public chartInstance: ECharts;
  private copyNavigationItem: NavigationHistoryItem;
  public tableColumns: Array<TableColumn>;
  public docsTableColumns: Array<TableColumn>;

  readonly ChartTypes = ChartTypes;
  readonly IconType = IconType;
  readonly PillType = PillType;
  readonly AnalysisTypes = AnalysisTypes;
  readonly WidgetActions = WidgetActions;
  readonly ChartOrientation = ChartOrientation;

  constructor(
    private widgetService: WidgetService,
    private snackBar: MatSnackBar,
    public customOverlayRef: CustomOverlayRef,
    public documentService: DocumentItemService,
    private customOverlayService: CustomOverlayService,
    private virtualScrollService: VirtualScrollService,
    private tagService: TagService
  ) {}

  ngOnInit(): void {
    this.widget = cloneDeep(this.navigationItem.item);
    this.copyNavigationItem = cloneDeep(this.navigationItem);
    this.initialState = cloneDeep(this.navigationItem.initialState);
    this.initialStateCopy = cloneDeep(this.initialState);
    this.toolbarActions = [
      {
        action: ToolbarActions.RESET_FILTERS,
      },
      {
        action: ToolbarActions.EXPORT,
      },
      {
        action: ToolbarActions.SEARCH,
        expanded: this.initialState?.query ? true : false,
        data: this.initialState?.query,
      },
    ];
    if (
      this.widget.type === AnalysisTypes.TAG_ANALYSIS ||
      this.widget.type === AnalysisTypes.TAG_COMPARISON
    ) {
      this.toolbarActions = [
        ...this.toolbarActions,
        {
          action: ToolbarActions.DATE,
          expanded: this.initialState?.where ? true : false,
          data: {
            date: this.initialState?.where,
          },
        },
      ];
    }
  }

  ngAfterViewInit(): void {
    this.initColumns();
    this.loadData();
  }

  private initColumns(): void {
    this.docsTableColumns = [
      {
        name: undefined,
        dataKey: "result",
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
  }

  private loadData(): void {
    this.showLoader = true;
    Promise.all([this.getData(), this.getDocuments()]).then(
      () => (this.showLoader = false)
    );
  }

  public onSave(): void {
    this.showLoader = true;
    this.widgetService.createWidget(this.widget).then((response: IWidget) => {
      this.snackBar.open(
        "Your widget has been created and is available for immediate use!",
        "Close",
        {
          horizontalPosition: "right",
          duration: 5000,
          verticalPosition: "top",
        }
      );
      this.customOverlayRef.close({ id: response.widgetId, refresh: true });
    });
  }
  public onUpdateWidget(params: { prop: string; value: any }): void {
    this.widget[params.prop] = params.value;
  }

  public onEdit(): void {
    this.widget.name = this.form.controls.name.value;
    this.widgetService.updateWidget(this.widget).then((response: Widget) => {
      this.snackBar.open("Your widget has been updated!", "Close", {
        horizontalPosition: "right",
        duration: 5000,
        verticalPosition: "top",
      });
      this.customOverlayRef.close({
        widget: response,
        refresh: true,
      });
    });
  }

  public onDrilldownChart(segment: any): void {
    this.segment = segment;
    this.articleDetail = undefined;
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    this.getDocuments();
  }

  private getDocuments(): Promise<void> {
    return new Promise((resolve) => {
      if (this.segment) {
        const queryModel = new Query();
        if (this.initialState?.query) {
          queryModel.addRule(
            new QueryRule(
              DTOQueryFieldType.content,
              DTOQueryConditionOperator.contains,
              [this.initialState.query]
            )
          );
        }
        queryModel.addRule(
          new QueryRule(
            this.widget.getDataPointField(),
            DTOQueryConditionOperator.in,
            [this.widget.getDataPointValue(this.segment)]
          )
        );

        const query = queryModel.modelToDTO();

        const state = this.widget.generateGetDocsState(
          {
            action: WidgetActions.EXPLORE,
            initialState: this.initialState,
            dataValue: this.segment?.data?.tagId,
          },
          this.segment
        );

        this.widgetService
          .getDocuments(
            {
              widget: this.widget,
              filters: query,
            },
            state
          )
          .then((response: Array<ISearchResults>) => {
            this.navigationItem.form = cloneDeep(this.form);
            this.navigationItem.initialState = cloneDeep(this.initialState);
            response.forEach((item: ISearchResults) => {
              if (this.initialState.page > 1) {
                const copyData = cloneDeep(this.documentsData);
                copyData.items = [...copyData.items, ...item.items];
                this.documentsData = copyData;
              } else {
                this.documentsData = item;
              }
              resolve();
            });
          });
      } else {
        resolve();
      }
    });
  }

  private getData(): Promise<void> {
    return new Promise((resolve) => {
      const queryModel = new Query();
      if (this.initialState?.query) {
        queryModel.addRule(
          new QueryRule(
            DTOQueryFieldType.content,
            DTOQueryConditionOperator.contains,
            [this.initialState.query]
          )
        );
      }
      const query = queryModel.modelToDTO();
      const state = {
        where: this.initialState?.where,
      };
      const widget = cloneDeep(this.widget);
      this.widgetService
        .getData(
          {
            widget,
            filters: query,
          },
          state
        )
        .then((response: IWidgetData) => {
          this.tableColumns = this.widget.getTableColumns(this.countTemplate);
          this.data = response;
        });
      resolve();
    });
  }

  public onResetFilters(): void {
    this.segment = undefined;
    this.articleDetail = undefined;
    this.widget = cloneDeep(this.navigationItem.item);
    this.documentsData = undefined;
    this.initialState = cloneDeep(this.initialStateCopy);
    this.toolbarActions = this.toolbarActions.map((item: IToolbarAction) => {
      if (item.action === ToolbarActions.DATE) {
        return {
          action: ToolbarActions.DATE,
          expanded: this.initialState?.where ? true : false,
          data: {
            date: this.initialState?.where,
          },
        };
      }
      if (item.action === ToolbarActions.SEARCH) {
        return {
          action: ToolbarActions.SEARCH,
          expanded: this.initialState?.query ? true : false,
          data: this.initialState?.query,
        };
      }
      return item;
    });

    this.onDataBound(this.initialState);
  }

  public hasFiltersApplied(): boolean {
    if (
      this.initialState.query !== this.copyNavigationItem.initialState.query ||
      this.segment ||
      JSON.stringify(this.initialState.where) !==
        JSON.stringify(this.navigationItem.initialState.where)
    ) {
      return true;
    }

    return false;
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    this.initialState = params;
    this.loadData();
  }

  public onSearch(params: QueryFilters): void {
    this.initialState = params;
    this.getDocuments();
  }

  public onExport(): void {
    const exportImage = (dataUrl: string) => {
      const img = new Image();
      img.src = dataUrl;
      const element = document.createElement("a");
      element.setAttribute("href", img.src);
      element.setAttribute("download", this.widget.name + ".png");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };
    if (ChartTypes.ECHARTS.includes(this.widget.chartType)) {
      exportImage(
        this.chartInstance.getDataURL({
          pixelRatio: 2,
          backgroundColor: "transparent",
        })
      );
    } else {
      html2canvas(this.chart.instance.chartContainer.nativeElement).then(
        (canvas: any) => {
          exportImage(canvas.toDataURL("image/png"));
        }
      );
    }
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  public onLoadCount(row: IDataPoint): void {
    this.segment = {
      data: { tagId: row.tagId, name: row.category, value: row.value },
    };
    this.widget.name = row.category;
    this.initialState.page = 1;
    this.onDataBound(this.initialState);
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.scope === ProfileDrildownScope.TAG
        ? this.widget.name
        : profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      this.widget.ecosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "plus",
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory["My Tags"]
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.Actor
          : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`
      ),
      undefined,
      undefined,
      0
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.openArticleDetail(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment);
        break;
      case SegmentScope.REPLIES:
        this.drilldownReplies(segment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      "discord",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      segment,
      undefined,
      segment.context
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownReplies(segment: ISegment): void {
    const tagId = this.widget.getTagId(this.segment.seriesIndex);
    this.tagService.getTagById(tagId).then((response: Timeline) => {
      let widget = response;
      widget.name =
        segment.value.emitType === "Comment"
          ? `${segment.value.emitType} by ${segment.value.emitActor} `
          : `Replies of ${segment.value.emitHeadline}`;
      const value = `Field Filter:${segment.value.id}:ReplyTo`;
      const navigationItem = new NavigationHistoryItem(
        `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
        widget,
        WidgetActions.DRILLDOWN,
        [
          {
            field: DTOQueryFieldType.filter,
            operator: DTOQueryConditionOperator.in,
            value: [value],
          },
        ],
        new QueryFilters(30, 1, undefined, undefined, undefined),
        new FormGroup({}),
        "file",
        IconType.FONT_AWESOME,
        {
          id: "Timeline",
          type: ChartType.TIMELINE,
          svgIcon: "board",
          tooltip: "Timeline",
        },
        undefined,
        undefined
      );
      this.widgetService.drilldownObservable.next(navigationItem);
    });
  }

  private drilldownDiscord(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "discord",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "hashtag",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      Utils.generateUUID(),
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "user",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private openArticleDetail(segment: ISegment): void {
    this.widgetService.updateSegmentObservable.next(segment);
  }

  public onAddConnection(params: IAddConnection): void {
    this.customOverlayService.open({
      data: params,
      type: CustomOverlayType["slide-right"],
      component: AddConnectionsComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddNote(params: IAddNote): void {
    this.customOverlayService.open({
      data: params,
      size: "lg",
      type: CustomOverlayType["slide-right"],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });
  }
}
