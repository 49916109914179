import { FormGroup } from "@angular/forms";
import { QueryFilters, IconType } from "@intorqa-ui/core";
import { WidgetActions } from "../enums/widget.enum";
import { IDiscordNavigationItem } from "../interfaces/discord-navigation-item.interface";
import { ISegment } from "../interfaces/widget-settings.interface";
import { IDisplayType } from "../interfaces/widget/widget.interface";
import { NavigationHistoryItem } from "./navigation-history-item.model";
import { QueryRule } from "./query-model/query-rule";
import { Widget } from "./widgets/widget";
import { IContext } from '../interfaces/document-dtos';

export class DiscordNavigationItem
  extends NavigationHistoryItem
  implements IDiscordNavigationItem
{
  constructor(
    public id: string,
    public item: Widget,
    public action: WidgetActions,
    public rules: Array<QueryRule>,
    public initialState: QueryFilters,
    public form: FormGroup,
    public icon: string,
    public iconType: IconType,
    public selectedDisplayType: IDisplayType,
    public segment: ISegment,
    public boardId: string,
    public context: IContext
  ) {
    super(
      id,
      item,
      action,
      rules,
      initialState,
      form,
      icon,
      iconType,
      selectedDisplayType,
      segment,
      boardId
    );
  }
}
