export interface IEcosystem {
  id: string;
  name: string;
  description: string;
  communityIds: Array<string>;
}

export interface IEcosystemResult {
  ecosystems: Array<IEcosystem>;
  page: number;
  pageSize: number;
  totalCount: number;
}
