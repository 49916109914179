import { Sections, TagCategory } from "./../enums/category.enum";
import { ICategory } from "../interfaces/category";

export class Category implements ICategory {
  constructor(
    public id: string,
    public name: TagCategory,
    public description: string,
    public section: Sections,
    public order: number,
    public ecosystemId: string
  ) {}
}
