import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DateRange } from "../components/date-range/date-range.model";

@Pipe({
  name: "isArray",
})
export class IsArrayPipe implements PipeTransform {
  transform(value: string): boolean {
    if (!value) return false;
    return Array.isArray(JSON.parse(value));
  }
}

@Pipe({
  name: "jsonParse",
})
export class JsonParsePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) return value;
    try {
      return JSON.parse(value);
    } catch (err: any) {
      return value;
    }
  }
}

@Pipe({
  name: "convertFromEpochMillis",
})
export class ConvertFromEpochMillisPipe implements PipeTransform {
  transform(value: number): Date {
    return DateRange.convertFromEpochSeconds(value);
  }
}

@Pipe({
  name: "castFormGroup",
  standalone: true,
})
export class CastFormGroupPipe implements PipeTransform {
  transform(form: FormGroup, control: string): FormGroup {
    if (form?.get(control)) {
      return form?.get(control) as FormGroup;
    }
    return undefined;
  }
}

@Pipe({
  name: "formatNumber",
  standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
  constructor(readonly numberPipe: DecimalPipe) {}

  transform(value: number): string {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return this.numberPipe.transform(value).toString();
    }
  }
}
