import { IVideoLinkNote } from "../../interfaces/profiles/video-link.interface";
import { ProfileNote } from "./profile-note";

export class VideoLinkNote extends ProfileNote implements IVideoLinkNote {
  constructor(
    public id: string,
    public profileId: string,
    public typeId: string,
    public documentId: string,
    public updatedDate: number,
    public updatedBy: string,
    public subType: string,
    public value: string,
    public rawValue: string,
    public textNote: string,
    public rawTextNote: string
  ) {
    super(id, profileId, typeId, documentId, updatedDate, updatedBy);
  }

  public isValid(): boolean {
    return this.typeId && this.value && this.textNote ? true : false;
  }
}
