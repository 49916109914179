import { Injectable } from "@angular/core";
import { QueryFilters } from "@intorqa-ui/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WidgetSettingsService {
  public dataBoundObservable = new Subject<QueryFilters>();

  constructor() {}
}
