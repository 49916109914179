import { ErrorMessages } from "./error.enum";

export interface IError {
  error: ErrorMessages;
  code: number;
  title: string;
  description: string;
  stopPropagation: boolean;
  formControlName?: string;
}
