import { Injectable } from "@angular/core";
import {
  ApiRequestService,
  DTOCreation,
  DTOTypeConverter,
} from "@intorqa-ui/api";
import { IError, QueryFilters } from "@intorqa-ui/core";
import { ECharts } from "echarts";
import { Observable, Subject } from "rxjs";
import { WidgetFactory } from "../factories/widget";
import { DTOQuery } from "../interfaces/query-dtos";
import { ISegment } from "../interfaces/widget-settings.interface";
import { IWidget, IWidgetData } from "../interfaces/widget/widget.interface";
import { NavigationHistoryItem } from "../models/navigation-history-item.model";
import { ResponsivePanels } from "../models/widgets/responsive-panels";
import { TimeSeries } from "../models/widgets/time-series";
import { Timeline } from "../models/widgets/timeline";
import { Widget } from "../models/widgets/widget";
import { TagAnalysis } from "./../models/widgets/tag-analysis";
import { TagComparison } from "./../models/widgets/tag-comparison";
import { ITagTreeNode } from "../interfaces/tags-dtos";

@Injectable({
  providedIn: "root",
})
export class WidgetService {
  public updateSegmentObservable = new Subject<ISegment | undefined>();
  public responsivePanels$ = new Subject<void>();
  public updateApiKeyObservable = new Subject<string>();
  public drilldownObservable = new Subject<NavigationHistoryItem>();
  public chartResizeObservable = new Subject();
  public changeEcosystemObservable = new Subject<void>();
  public updateNavigationItem$ = new Subject<NavigationHistoryItem>();
  public changeWidgetDisplayObservable = new Subject<{
    params: QueryFilters;
    widget: Widget;
  }>();
  public timelineDataBoundObservable = new Subject<{
    widget: Widget;
    initialState: QueryFilters;
  }>();
  public drilldownDataBoundObservable = new Subject();
  public loaderObservable = new Subject<{
    widget: Widget | Timeline;
    showLoader: boolean;
  }>();

  private _responsivePanels: ResponsivePanels;

  public get responsivePanels(): ResponsivePanels {
    return this._responsivePanels;
  }

  public set responsivePanels(v: ResponsivePanels) {
    this._responsivePanels = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public getData(
    payload: {
      widget: TagComparison | TagAnalysis | TimeSeries;
      filters: DTOQuery;
    },
    state: any
  ): Promise<IWidgetData> {
    let pageQuery = "";
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += "&";
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };
    addQueryParams("dateFrom", state?.where?.start);
    addQueryParams("dateTo", state?.where?.end);

    return new Promise((resolve, reject) => {
      this.apiRequestService
        .post(
          `/widgets/data${pageQuery !== "" ? `?${pageQuery}` : ""}`,
          new DTOTypeConverter<IWidgetData>(),
          JSON.stringify(payload),
          undefined,
          "v2.0"
        )
        .then((response: IWidgetData) => {
          response.series = payload.widget.transformData(response.series);
          resolve(response);
        })
        .catch((error: IError) => reject(error));
    });
  }

  public getDocuments(
    payload: {
      widget: Widget;
      filters: DTOQuery;
    },
    state: any
  ): Promise<any> {
    let pageQuery = "";
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += "&";
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };

    addQueryParams("pageSize", state?.pageSize);
    addQueryParams("page", state?.page);
    addQueryParams("dateFrom", state?.where?.start);
    addQueryParams("dateTo", state?.where?.end);
    addQueryParams("dataValue", state?.dataValue);
    return this.apiRequestService.post(
      `/widgets/docs${pageQuery !== "" ? `?${pageQuery}` : ""}`,
      new DTOTypeConverter<DTOCreation>(),
      JSON.stringify(payload),
      undefined,
      "v2.0"
    );
  }

  public createWidget(
    widget: Widget
  ): Promise<TimeSeries | TagComparison | TagAnalysis> {
    return new Promise((resolve) => {
      this.apiRequestService
        .post(
          "/widgets",
          new DTOTypeConverter<IWidget>(),
          JSON.stringify(widget),
          undefined,
          "v2.0"
        )
        .then((response: IWidget) => {
          const widget = WidgetFactory.createObject(response);
          resolve(widget);
        });
    });
  }

  public updateWidget(widget: Widget): Promise<Widget> {
    return new Promise((resolve) => {
      this.apiRequestService
        .put(
          "/widgets/" + widget.widgetId,
          new DTOTypeConverter<Widget>(),
          JSON.stringify(widget),
          undefined,
          "v2.0"
        )
        .then((response: IWidget) => {
          resolve(WidgetFactory.createObject(response));
        });
    });
  }

  public exportChart(title: string, chartInstance: ECharts): void {
    const exportImage = (dataUrl: string) => {
      const img = new Image();
      img.src = dataUrl;
      const element = document.createElement("a");
      element.setAttribute("href", img.src);
      element.setAttribute("download", title + ".png");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    exportImage(
      chartInstance.getDataURL({
        pixelRatio: 2,
        backgroundColor: "white",
      })
    );
  }

  public getDependants(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/widgets/${tagId}/dependants`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      "v2.0"
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/widgets/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      "v2.0"
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/widgets/${unlinkId}/unlink/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      "v2.0"
    );
  }
}
