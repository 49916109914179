import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { ActivatedRoute } from "@angular/router";
import {
  DocumentItem,
  DocumentItemService,
  ISegment,
  SegmentScope,
  WidgetActions,
} from "@intorqa-ui/shared";
import { CoreModule } from "@intorqa-ui/core";
import { SharedModule } from "../shared/shared.module";

@Component({
  selector: "itq-page-document",
  templateUrl: "./page-document.component.html",
  styleUrls: ["./page-document.component.scss"],
  standalone: true,
  imports: [CoreModule, SharedModule],
})
export class PageDocumentComponent implements OnInit, OnDestroy {
  public segment: ISegment;
  private queryParamsSubscription: Subscription;
  private docId: string;

  readonly WidgetActions = WidgetActions;

  constructor(
    private docService: DocumentItemService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.queryParamsSubscription = this.route.params.subscribe((params) => {
      this.docId = params.id;
    });
    this.docService.getDocument(this.docId).then((response: DocumentItem) => {
      this.segment = { scope: SegmentScope.ARTICLE_DETAIL, value: response };
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }
}
