import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getHiddenSelections",
})
export class GetHiddenSelectionsPipe implements PipeTransform {
  constructor() {}

  transform(
    selections: Array<any>,
    maxNumberSelections: number,
    dataFields: { name: string; value: string }
  ): string {
    let htmlString = "";
    for (let i = maxNumberSelections; i < selections.length; i++) {
      htmlString += `<div>${
        selections[i][dataFields.name] || selections[i]
      }</div>`;
    }
    return htmlString;
  }
}
