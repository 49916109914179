import { ISocialChannelNote } from "../../interfaces/profiles/social-channel-note.interface";
import { ProfileNote } from "./profile-note";

export class SocialChannelNote extends ProfileNote implements ISocialChannelNote {
  constructor(
    public id: string,
    public profileId: string,
    public typeId: string,
    public documentId: string,
    public updatedDate: number,
    public updatedBy: string,
    public subType: string,
    public rawValue: string,
    public textNote: string,
    public rawTextNote: string
  ) {
    super(id, profileId, typeId,documentId, updatedDate, updatedBy);
  }

  public isValid(): boolean {
    return this.typeId && this.subType && this.rawValue && this.textNote
      ? true
      : false;
  }
}
