import { TemplatePortal } from "@angular/cdk/portal";
import posthog from "posthog-js";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import {
  CustomOverlayService,
  DropdownRequest,
  IconType,
  OpenStatus,
  PortalBridgeService,
  SharedService,
} from "@intorqa-ui/core";
import {
  AnalyticsService,
  BoardService,
  MaterialService,
  NotificationsService,
} from "@intorqa-ui/shared";
import { KeycloakService } from "keycloak-angular";
import { Observable, Subscription, fromEvent } from "rxjs";
import { environment } from "../environments/environment";
import { NavLink } from "./modules/shared/components/default-navigation/default-navigation.model";
import { ApiConfigService } from "@intorqa-ui/api";
import { KeycloakProfile } from "keycloak-js";
import * as jwt_decode from "jwt-decode";

@Component({
  selector: "itq-portal",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  portal: Observable<TemplatePortal>;
  open = false;
  dropdownSubscription: Subscription;
  dropdownPosition = { left: 0, top: 0 };
  public version: string = environment.version;
  sidebarSubscription: Subscription;
  private routerSubscription: Subscription;
  public showLoader = false;
  private tooltipSubscription: Subscription;
  public showTooltip: boolean;
  public tooltipPosition: { top: number; left: number };
  public tooltipContent: string;
  public navigationDataSource: Array<NavLink>;
  private debounceSubscription: Subscription;
  public showNotifications = false;
  public raisedAlertId: string;
  public researchAlertId: string;
  private subscriptions = new Subscription();

  @HostBinding("class") classes = "";

  @ViewChild("backdrop") backdrop: ElementRef;

  constructor(
    private portalBridge: PortalBridgeService,
    public sharedService: SharedService,
    private materialService: MaterialService,
    private router: Router,
    private boardService: BoardService,
    private analyticsService: AnalyticsService,
    private keycloakService: KeycloakService,
    readonly customOverlayService: CustomOverlayService,
    readonly notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    readonly activatedRoute: ActivatedRoute,
    @Inject(ApiConfigService) private config
  ) {
    this.navigationDataSource = [
      new NavLink(
        "Boards",
        "right",
        "board-square",
        IconType.SVG,
        "active",
        "/boards"
      ),
      new NavLink(
        "Tags",
        "right",
        "tags",
        IconType.FONT_AWESOME,
        "active",
        "/tags"
      ),
    ];
    this.materialService.addCustomIcons();
    this.analyticsService.loadProfile();
    if (this.keycloakService.isUserInRole("saas-profiles")) {
      this.navigationDataSource = [
        ...this.navigationDataSource,
        new NavLink(
          "Profiles",
          "right",
          "file-invoice",
          IconType.FONT_AWESOME,
          "active",
          "/profiles"
        ),
      ];
    }
  }

  ngOnInit(): void {
    this.bindHideNotificationsSubscription();
    if (posthog && this.config?.posthog?.apiKey) {
      posthog.init(this.config.posthog.apiKey, {
        api_host: this.config.posthog.apiHost,
      });
    }

    this.keycloakService.loadUserProfile().then((response: KeycloakProfile) => {
      if (posthog) {
        posthog.identify(response.id);
        this.keycloakService.getToken().then((token: string) => {
          const decodedToken = jwt_decode.jwtDecode(token) as any;
          const organisation = decodedToken.organisation;
          posthog.people.set({
            email: response.email,
            organisation,
          });
        });
      }
    });
    this.tooltipSubscription = this.portalBridge.tooltip$.subscribe(
      (response: {
        show: boolean;
        top: number;
        left: number;
        content: string;
      }) => {
        this.showTooltip = response.show;
        this.tooltipPosition = {
          left: response.left,
          top: response.top,
        };
        this.tooltipContent = response.content;
      }
    );
    this.routerSubscription = this.router.events.subscribe(
      (routerEvent: RouterEvent) => {
        this.checkRouteChange(routerEvent);
      }
    );

    this.sidebarSubscription =
      this.boardService.toggleSideBarObservable.subscribe((args: string) => {
        this.classes = args;
      });
    this.dropdownSubscription = this.portalBridge.dropdownObeservable.subscribe(
      (args: DropdownRequest) => {
        if (args.position) {
          this.dropdownPosition = {
            top: args.position.top - 10,
            left: args.position.left + 10,
          };
        }
        switch (args.action) {
          case OpenStatus.CLOSE:
            this.open = false;
            break;
          case OpenStatus.OPEN:
            this.open = true;
            break;
          default:
            this.open = !this.open;
            break;
        }
      }
    );
    this.subscriptions.add(
      this.notificationsService.openNotifications$.subscribe(
        (response: string) => {
          this.onLoadNotifications(undefined, response);
        }
      )
    );
    this.bindOpenResearchNotifications();
    this.portal = this.portalBridge._portal;
  }

  private bindOpenResearchNotifications(): void {
    this.subscriptions.add(
      this.notificationsService.openResearchNotifications$.subscribe(
        (response: string) => {
          this.researchAlertId = response;
          event?.stopPropagation();
          this.showNotifications = true;
          this.cdr.detectChanges();
          this.bindBackdropClick();
        }
      )
    );
  }

  bindHideNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.hideNotifications$.subscribe(() => {
        this.showNotifications = false;
      })
    );
  }

  bindBackdropClick(): void {
    const click = fromEvent(this.backdrop.nativeElement, "click");
    this.debounceSubscription = click.subscribe((response: PointerEvent) => {
      if (
        response.target instanceof HTMLElement &&
        response.target.classList.contains("notifications__backdrop")
      ) {
        this.showNotifications = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.sidebarSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.dropdownSubscription.unsubscribe();
    this.tooltipSubscription.unsubscribe();
    this.debounceSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  private checkRouteChange(routerEvent: RouterEvent): void {
    // if route change started
    if (routerEvent instanceof NavigationStart) {
      this.showLoader = true;
    }
    // if route change ended
    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.showLoader = false;
    }
  }

  public onLoadNotifications(event?: MouseEvent, raisedAlertId?: string): void {
    if (this.showNotifications) {
      this.showNotifications = false;
      this.raisedAlertId = undefined;
      this.debounceSubscription.unsubscribe();
    } else {
      this.raisedAlertId = raisedAlertId;
      event?.stopPropagation();
      this.showNotifications = true;
      this.cdr.detectChanges();
      this.bindBackdropClick();
    }
  }

  public onCloseNotifications(): void {
    this.showNotifications = false;
  }
}
