import { TemplateRef } from "@angular/core";
import { IDropdownItem } from "../components/dropdown/dropdown.interface";
import { SearchFieldType } from "../enums/query-filters.enum";
import { ComponentType } from "../enums/table.enum";

export interface TableColumn {
  name: string;
  dataKey: string;
  position?: "right" | "left" | "center";
  isSortable?: boolean;
  isFilterable?: boolean;
  searchFieldType?: SearchFieldType;
  linkUrl?: { url: string; field: string };
  customRender?: boolean;
  template?: TemplateRef<unknown>;
  templateData?: any;
  width?: string;
  sticky?: boolean;
  dataSource?: Array<IDropdownItem>;
  dataBound?(): any;
  searchValue?: string;
  httpBinding?: boolean;
  padding?: string;
  searchField?: string;
  searchComponent?: ComponentType;
  isMetadata?: boolean;
  searchComponentType?: "number" | "text";
  show?: boolean;
  cssClass?: string;
}
