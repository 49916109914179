import { Injectable } from "@angular/core";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import { ITagTreeNode, ITimelineTag } from "./../interfaces/tags-dtos";

import { FormGroup } from "@angular/forms";
import {
  Category,
  DateQueryType,
  IError,
  QueryFilters,
  SharedService,
  TagCategory,
} from "@intorqa-ui/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AnalysisTypes, ChartType } from "../enums/widget.enum";
import { DTOQuery } from "../interfaces/query-dtos";
import { ITagMetadata, Tag } from "../interfaces/tags-dtos";
import { Query } from "../models/query-model/query-model";
import { Timeline } from "../models/widgets/timeline";
import {
  IData,
  ISearchResults,
  IContext,
  IContextSearchResults,
} from "../interfaces/document-dtos";

interface ISaveTagDTO {
  name: string;
  description: string;
  categoryId: string;
  ecosystemId: string;
  query: DTOQuery;
  sharedTag: boolean;
  boardIds: Array<string>;
  _extras: string;
}

@Injectable({
  providedIn: "root",
})
export class TagService {
  public getFilters$ = new Subject<void>();
  public reloadFilters$ = new Subject<QueryFilters>();
  public resetQuery$ = new Subject<void>();
  public toggleSharedTag$ = new Subject<boolean>();
  public tags$ = new Subject<{
    items: Array<Timeline>;
    page: number;
    pageSize: number;
    totalCount: number;
  }>();

  public changeAlertType$ = new Subject<string>();
  public queryModel: Query;
  public getSearchResults$ = new Subject<void>();

  constructor(
    private apiRequestService: ApiRequestService,
    private sharedService: SharedService
  ) {}

  save(tag: ISaveTagDTO): Observable<Timeline> {
    return this.apiRequestService
      .postToObservable(
        "/tags",
        new DTOTypeConverter<ITimelineTag>(),
        tag,
        undefined,
        "v2.0"
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        map((response: ITimelineTag) => {
          const extras = new Query();
          extras.query = extras.dtoToModel(response.tag.query);
          extras.type = JSON.parse(response.tag._extras)?.type;
          return new Timeline(
            response.timelineId,
            response.tag.username,
            AnalysisTypes.TIMELINE,
            response.tag.name,
            response.tag.description,
            ChartType.TIMELINE,
            response.tag.tagId,
            response.tag.createdDate,
            response.tag.sharedTag,
            extras,
            response.tag.categoryId,
            response.tag.lastTaggingTime,
            response.tag.ecosystemId,
            response.tag.updatedDate,
            response.tag.alertTypeId
          );
        })
      );
  }

  update(attributes: { [key: string]: any }): Observable<Tag> {
    return this.apiRequestService.putToObservable(
      `/tags/${attributes.tagId}`,
      attributes,
      undefined,
      "v2.0"
    );
  }

  getTags(
    initialState: QueryFilters,
    form: FormGroup
  ): Observable<{
    items: Array<Timeline>;
    page: number;
    pageSize: number;
    totalCount: number;
  }> {
    this.sharedService.loader$.next(true);
    let pageQuery = `page=${initialState.page}`;
    pageQuery += `&size=${initialState.pageSize}`;
    if (form.controls.searchTerm.value) {
      pageQuery += `&search=${form.controls.searchTerm.value}`;
    }
    pageQuery += `&filter=${form.controls.type.value}`;
    pageQuery += `&sortField=${initialState.sort?.active}`;
    pageQuery += `&sortOrder=${initialState.sort?.direction}`;

    return this.apiRequestService
      .getToObservable(
        `/tags?${pageQuery}`,
        new DTOTypeConverter<{
          items: Array<Tag>;
          page: number;
          pageSize: number;
        }>(),
        undefined,
        "v2.0"
      )
      .pipe(
        map(
          (response: {
            items: Array<Tag>;
            page: number;
            pageSize: number;
            totalCount: number;
          }) => {
            const result = {
              items: response?.items.map((item: Tag) => {
                const extras = new Query();
                extras.query = extras.dtoToModel(item.query);
                extras.type = JSON.parse(item._extras)?.type;
                return new Timeline(
                  item.tagId,
                  item.username,
                  AnalysisTypes.TIMELINE,
                  item.name,
                  item.description,
                  ChartType.TIMELINE,
                  item.tagId,
                  item.createdDate,
                  item.sharedTag,
                  extras,
                  item.categoryId,
                  item.lastTaggingTime,
                  item.ecosystemId,
                  item.updatedDate,
                  item.alertTypeId
                );
              }),
              page: response.page,
              pageSize: response.pageSize,
              totalCount: response.totalCount,
            };
            this.tags$.next(result);
            this.sharedService.loader$.next(false);
            return result;
          }
        )
      );
  }

  getTagById(id: string): Promise<Timeline> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .get(`/tags/${id}`, {}, new DTOTypeConverter<Tag>(), undefined, "v2.0")
        .then((response: Tag) => {
          const extras = new Query();
          extras.query = extras.dtoToModel(response.query);
          extras.type = JSON.parse(response._extras)?.type;
          resolve(
            new Timeline(
              response.tagId,
              response.username,
              AnalysisTypes.TIMELINE,
              response.name,
              response.description,
              ChartType.TIMELINE,
              response.tagId,
              response.createdDate,
              response.sharedTag,
              extras,
              response.categoryId,
              response.lastTaggingTime,
              response.ecosystemId,
              response.updatedDate,
              response.alertTypeId
            )
          );
        })
        .catch(reject);
    });
  }

  delete(id: string): Promise<{ message: string }> {
    return this.apiRequestService.delete(`/tags/${id}`);
  }

  public getTagsMetadata(ids: Array<string>): Promise<Array<ITagMetadata>> {
    return this.apiRequestService.post(
      "/tags/metadata",
      new DTOTypeConverter<Array<ITagMetadata>>(),
      {
        tagIds: ids,
      },
      undefined,
      "v1.0"
    );
  }

  public getSelections(
    tagIds: { included: Array<string>; excluded: Array<string> },
    fieldFilterIds: { included: Array<string>; excluded: Array<string> },
    contentIds: { included: Array<string>; excluded: Array<string> },
    categories: Array<Category>
  ): Promise<Array<ITagMetadata>> {
    return new Promise((resolve) => {
      let result: Array<ITagMetadata> = [];
      if (contentIds) {
        let contentFieldFilterIds = [
          ...contentIds?.included,
          ...contentIds?.excluded,
        ];
        contentFieldFilterIds.map((item: string) => {
          result = [
            ...result,
            {
              section: undefined,
              categoryName: TagCategory.content,
              tagName: undefined,
              tagId: item,
              included: contentIds.included.includes(item),
              excluded: contentIds.excluded.includes(item),
            },
          ];
        });
      }
      let allFieldFilterIds = [
        ...fieldFilterIds?.included,
        ...fieldFilterIds?.excluded,
      ];
      allFieldFilterIds.map((item: string) => {
        const splittedValue = item.split(":");
        result = [
          ...result,
          {
            section: categories.find(
              (item: Category) => item.name === splittedValue[2]
            )?.section,
            categoryName: splittedValue[2] as TagCategory,
            tagName: splittedValue[1],
            tagId: item,
            included: fieldFilterIds.included.includes(item),
            excluded: fieldFilterIds.excluded.includes(item),
          },
        ];
      });
      let mergedTagIds = [...tagIds?.included, ...tagIds?.excluded];
      if (mergedTagIds?.length > 0) {
        this.getTagsMetadata(mergedTagIds).then(
          (response: Array<ITagMetadata>) => {
            response.forEach((elem: ITagMetadata) => {
              const metadata = response.find((item: ITagMetadata) => {
                return elem.tagId === item.tagId;
              });
              result = [
                ...result,
                {
                  section: metadata.section,
                  categoryName: metadata.categoryName,
                  tagName: metadata.tagName,
                  tagId: metadata.tagId,
                  included: tagIds.included.includes(metadata.tagId),
                  excluded: tagIds.excluded.includes(metadata.tagId),
                },
              ];
            });
            resolve(result);
          }
        );
      } else {
        resolve(result);
      }
    });
  }

  public getApiKeyPrefix(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .get(
          `/tags/${id}/apiKeyPrefix`,
          {},
          new DTOTypeConverter<string>(),
          undefined,
          "v2.0"
        )
        .then((response: string) => {
          resolve(response);
        })
        .catch((error: IError) => {
          reject(error);
        });
    });
  }

  public getDependants(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/tags/${tagId}/dependants`,
      new DTOTypeConverter<ITagTreeNode>(),
      "v1.0"
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/tags/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      "v1.0"
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/tags/${unlinkId}/unlink/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      "v1.0"
    );
  }
  public execute(
    state: QueryFilters,
    queryModel: Query,
    ecosystemId: string
  ): Promise<IData> {
    return new Promise((resolve) => {
      const query = queryModel.modelToDTO();
      const action = state?.page || 1;
      let pageQuery = `page=${action}`;

      if (state?.pageSize) {
        pageQuery += `&size=${state.pageSize}`;
      }
      if (state?.where) {
        pageQuery += `&dateFrom=${state.where?.start}`;
        if (state.where?.label === DateQueryType.Custom) {
          pageQuery += `&dateTo=${state.where?.end}`;
        }
      }
      if (ecosystemId) {
        pageQuery += `&ecosystemId=${ecosystemId}`;
      }
      return this.apiRequestService
        .post(
          `/tags/search?${pageQuery}`,
          new DTOTypeConverter<ISearchResults>(),
          JSON.stringify(query)
        )
        .then((response: ISearchResults) => {
          const data = {
            result: response?.items,
            count: response?.totalHits,
          } as IData;
          resolve(data);
        });
    });
  }

  public getContext(
    state: QueryFilters,
    queryModel: Query,
    ecosystemId: string,
    context: IContext
  ): Promise<IData> {
    return new Promise((resolve) => {
      const query = queryModel.modelToDTO();
      let pageQuery = `size=${state.pageSize}`;

      if (state?.where) {
        pageQuery += `&dateFrom=${state.where?.start}`;
        if (state.where?.label === DateQueryType.Custom) {
          pageQuery += `&dateTo=${state.where?.end}`;
        }
      }
      if (ecosystemId) {
        pageQuery += `&ecosystemId=${ecosystemId}`;
      }
      if (context) {
        pageQuery += `&context=${context.document.id}`;
        if (context.before || context.after) {
          pageQuery += `&page=${state.page}`;
        }
        if (context.before) {
          pageQuery += `&before=${context.before}`;
        }
        if (context.after) {
          pageQuery += `&after=${context.after}`;
        }
      }

      return this.apiRequestService
        .post(
          `/tags/search?${pageQuery}`,
          new DTOTypeConverter<ISearchResults>(),
          JSON.stringify(query)
        )
        .then((response: IContextSearchResults) => {
          resolve({
            count: response.totalHits,
            result: response.items,
            page: response.page,
          });
        });
    });
  }
}
