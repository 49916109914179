import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CoreModule, IconType, QueryFilters, Sizes } from "@intorqa-ui/core";
import {
  IDisplayType,
  Notification,
  NotificationsService,
  ResearchNotification,
} from "@intorqa-ui/shared";
import { Subscription } from "rxjs";
import { NotificationsHubComponent } from "./notifications-hub/notifications-hub.component";

@Component({
  selector: "itq-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
  standalone: true,
  imports: [
    CoreModule,
    NotificationsHubComponent,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class NotificationsComponent implements OnInit {
  @Output() close = new EventEmitter<void>();

  @Input() raisedAlertId: string;
  @Input() researchAlertId: string;

  public form: FormGroup;
  public buttonDataSource: Array<IDisplayType>;
  public unreadCount: number;
  public showLoader = false;
  private showLoaderSubscription: Subscription;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined
  );
  private getUnreadCountSubscription: Subscription;
  private loadNotificationsSubscription: Subscription;
  public notificationsCount: number;

  @ViewChild("unreadTemplate")
  unreadTemplate: TemplateRef<unknown>;

  readonly IconType = IconType;
  readonly Sizes = Sizes;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.loadNotificationsSubscription =
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          if (response.totalCount) {
            this.notificationsCount = response.totalCount;
          }
        }
      );
    this.getUnreadCountSubscription =
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount = response;
          this.buttonDataSource = this.buttonDataSource.map(
            (item: IDisplayType) => {
              if (item.id === "unread") {
                if (this.unreadCount === 0) {
                  item = { ...item, ...{ disabled: true } };
                } else {
                  delete item.disabled;
                }
                return item;
              } else {
                return item;
              }
            }
          );
        }
      );
    this.showLoaderSubscription = this.notificationsService.loader$.subscribe(
      (response: boolean) => {
        this.showLoader = response;
      }
    );
    this.form = new FormGroup({
      showAll: new FormControl("read"),
    });
  }

  ngAfterViewInit(): void {
    if (this.raisedAlertId) {
      this.notificationsService.loadOtherMatches$.next({
        filters: undefined,
        raisedAlertId: this.raisedAlertId,
      });
    }
    if (this.researchAlertId) {
      this.notificationsService
        .getNotificationById(this.researchAlertId)
        .subscribe((response: Notification) => {
          this.notificationsService.loadNotificationDetail$.next(
            response as ResearchNotification
          );
        });
    }

    this.buttonDataSource = [
      {
        id: "read",
        tooltip: "All",
      },
      {
        id: "unread",
        tooltip: "Unread",
        template: this.unreadTemplate,
      },
    ];
  }

  ngOnDestroy(): void {
    this.showLoaderSubscription.unsubscribe();
    this.getUnreadCountSubscription.unsubscribe();
    this.loadNotificationsSubscription.unsubscribe();
  }

  public onExplore(): void {
    this.notificationsService.loadOtherMatches$.next({
      filters: new QueryFilters(30, 1, undefined, undefined, undefined),
      raisedAlertId: undefined,
    });
  }

  public onDataBound(): void {
    this.notificationsService
      .getNotifications(
        this.initialState,
        this.form.get("showAll").value === "unread" ? false : undefined
      )
      .subscribe();
  }

  public onMarkAllRead(): void {
    this.showLoader = true;
    this.notificationsService.markAllAsRead().subscribe(() => {
      this.showLoader = false;
    });
  }
}
