import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomOverlayRef } from "@intorqa-ui/core";
import {
  Profile,
  ProfileEditAction,
  ProfileMetadata,
  ProfileMetadataGroups,
  ProfileService,
  ProfileTypeMetadata,
  ProfilesNavigationItem,
  WidgetActions,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() action: ProfileEditAction;
  @Input() closeOnUpdate = true;
  @Input() editMetadataAction: ProfileMetadataGroups;

  readonly ProfileEditAction = ProfileEditAction;

  constructor(
    private profileService: ProfileService,
    private snackBar: MatSnackBar,
    private customOverlayRef: CustomOverlayRef
  ) {}

  ngOnInit() {}

  public onCancel(): void {
    this.navigationItem.action = WidgetActions.EXPLORE;
  }

  public onUpdate(): void {
    const dirtyValues = this.getDirtyValues();
    if (Object.keys(dirtyValues).length > 0) {
      this.profileService
        .update(this.profile.profileId, dirtyValues)
        .subscribe((response: Profile) => {
          this.profile = response;
          this.snackBar.open(
            "Your tag has been updated, and is available for immediate use!",
            "Close",
            {
              horizontalPosition: "right",
              duration: 5000,
              verticalPosition: "top",
            }
          );
          if (this.closeOnUpdate) {
            this.customOverlayRef.close({ refresh: true });
          } else {
            this.navigationItem.action = WidgetActions.EXPLORE;
          }
        });
    } else {
      this.navigationItem.action = WidgetActions.EXPLORE;
    }
  }

  private getDirtyValues(): { [key: string]: any } {
    let dirtyValues = {};

    const detailsGroup = this.form.get("details") as FormGroup;
    Object.keys(detailsGroup.controls).forEach((key) => {
      let currentControl = detailsGroup.controls[key];

      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });
    let metadata = this.getMetadata();
    dirtyValues["metadata"] = [...(dirtyValues["metadata"] || []), ...metadata];

    return dirtyValues;
  }

  private getMetadata(): Array<ProfileMetadata> {
    const metadataGroup = this.form.get("metadata") as FormGroup;
    let result: Array<ProfileMetadata> = [];
    Object.keys(metadataGroup.controls).forEach((item: string) => {
      if (metadataGroup.controls[item].value) {
        const typeMetadata = this.profileService.typeMetadata.find(
          (typeMetadata: ProfileTypeMetadata) => {
            return item === typeMetadata.id;
          }
        );
        result.push(
          new ProfileMetadata(
            typeMetadata.id,
            typeMetadata.name,
            JSON.stringify(metadataGroup.controls[item].value),
            typeMetadata.component,
            typeMetadata.groupName
          )
        );
      }
    });
    return result;
  }
}
