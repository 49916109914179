export enum Sizes {
  NONE = "none",
  SM = "sm",
  MD = "md",
  "X-SM" = "x-sm",
  BLOCK = "block",
  "XX-LG" = "xx-lg",
}

export enum Directions {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export enum Align {
  START = "start",
  END = "end",
  CENTER = "center",
}
