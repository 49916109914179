export interface IPlaceholder {
  className: string;
  width: IPlaceholderSize;
  height: IPlaceholderSize;
  container: Element;
}

export interface IPlaceholderSize {
  value: number;
  start: number;
  end: number;
}
