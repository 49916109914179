import {
  DTOQueryConditionOperator,
  DTOQueryFieldType,
} from "../../enums/query.enum";
import { DTOQueryCondition } from "../../interfaces/query-dtos";

export class QueryRule implements DTOQueryCondition {
  constructor(
    public field: DTOQueryFieldType,
    public operator: DTOQueryConditionOperator,
    public value: Array<any> | string
  ) {}
}
