import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Sizes } from "../../enums/shared.enum";

@Component({
  selector: "itq-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent
  implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit
{
  @Input() placeholder = "";
  @Input() type = "text";
  @Input() submit = false;
  @Input() disabled: boolean;
  @Input() autoFocus = false;
  @Input() submitTooltip: string;
  @Input() showIcon = true;
  @Input() submitOnEnter = false;
  @Input() padding = Sizes.SM;
  @Input() required: boolean;
  @Input() width: number;

  @ViewChild("input") input: ElementRef;

  @Output() search = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();
  @Output() focus = new EventEmitter();
  @Output() blurEvent = new EventEmitter();
  @Output() keyUpEvent = new EventEmitter<string>();

  public searchTerm: string | number;
  private debounceSubscription: Subscription;
  private searchTermCopy: string | number;
  public touched = false;
  public dirty = false;

  @HostBinding("style.width") styleWidth: string;

  readonly Sizes = Sizes;

  constructor() {}

  ngOnInit(): void {
    if (this.width) {
      this.styleWidth = this.width + "px";
    }
  }

  ngAfterViewInit(): void {
    if (!this.submit) {
      const change = fromEvent(this.input.nativeElement, "keyup");
      const result = change.pipe(debounceTime(400));
      this.debounceSubscription = result.subscribe((response: any) => {
        if (this.type === "text") {
          this.onChange(this.searchTerm?.toString());
        } else {
          this.onChange(Number(this.searchTerm));
        }
        if (
          this.type !== "number" ||
          !(this.type === "number" && isNaN(parseInt(response.target.value)))
        ) {
          this.keyUpEvent.emit(response.target.value);
          if (!this.submit && !this.submitOnEnter) {
            this.search.emit();
          }
        }
      });
    }
    if (this.autoFocus) {
      this.input.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    if (!this.submit) {
      this.debounceSubscription?.unsubscribe();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (text: string | number) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  onDirty = (value: boolean) => {
    this.dirty = value;
  };

  writeValue(value: string): void {
    this.searchTerm = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  onClear(e: MouseEvent): void {
    e.stopImmediatePropagation();
    this.searchTerm = undefined;
    if (this.type === "text") {
      this.onChange(this.searchTerm?.toString());
    } else {
      this.onChange(Number(this.searchTerm));
    }
    this.clear.emit();
  }

  changeValue(event: Event): void {
    event.stopPropagation();
    if (this.type === "text") {
      this.onChange(this.searchTerm?.toString());
    } else {
      this.onChange(Number(this.searchTerm));
    }
    this.searchTermCopy = this.searchTerm;
    this.search.emit();
  }

  onSubmit(event: Event): void {
    event.stopImmediatePropagation();
    if (this.type === "text") {
      this.onChange(this.searchTerm?.toString());
    } else {
      this.onChange(Number(this.searchTerm));
    }
    this.search.emit();
    this.input.nativeElement.blur();
    this.searchTermCopy = this.searchTerm;
  }

  public onFocus(): void {
    this.focus.emit();
    this.onTouched(true);
    this.touched = true;
  }

  public onBlur(): void {
    if (this.searchTermCopy !== this.searchTerm) {
      this.searchTermCopy = this.searchTerm;
      this.blurEvent.emit();
    }
  }
}
