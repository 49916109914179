import { IFileHashNote } from "../../interfaces/profiles/file-hash.interface";
import { ProfileNote } from "./profile-note";

export class FileHashNote extends ProfileNote implements IFileHashNote {
  constructor(
    public id: string,
    public profileId: string,
    public typeId: string,
    public documentId: string,
    public updatedDate: number,
    public updatedBy: string,
    public rawValue: string,
    public textNote: string,
    public rawTextNote: string
  ) {
    super(id, profileId, typeId, documentId,updatedDate, updatedBy);
  }

  public isValid(): boolean {
    return this.typeId && this.rawValue && this.textNote ? true : false;
  }
}
