import { AnalysisTypes } from "./../enums/widget.enum";
import { ViewMode } from "@intorqa-ui/core";
import { QueryType } from "../enums/timeline-query.enum";
import { ChartType } from "../enums/widget.enum";
import { IDisplayType } from "../interfaces/widget/widget.interface";

export const TimelineDisplayTypes: Array<IDisplayType> = [
  {
    id: "Timeline",
    type: ChartType.TIMELINE,
    icon: ["far", "table"],
    tooltip: "Table",
    viewMode: ViewMode.TABLE,
  },
  {
    id: "Foamtree",
    type: ChartType.FOAMTREE,
    svgIcon: "ripl",
    tooltip: "Foamtree",
    viewMode: ViewMode.RIPL,
  },
  {
    id: "Dashboard",
    type: ChartType.TABLE,
    icon: ["far", "users"],
    tooltip: "Community Dashboard",
    viewMode: ViewMode.DASHBOARD,
  },
];

export const SEARCH_TYPES: Array<IDisplayType> = [
  {
    id: QueryType.QUICK_SEARCH,
    icon: ["far", "tags"],
    tooltip: "Search everything",
  },
  {
    id: QueryType.ACTOR,
    icon: ["far", "user"],
    tooltip: "Find an actor",
  },
  {
    id: QueryType.CHANNEL,
    icon: ["far", "hashtag"],
    tooltip: "Find a channel",
  },
  {
    id: QueryType.QUERY_BUILDER,
    icon: ["fab", "searchengin"],
    tooltip: "Query builder",
  },
];

export const ANALYSIS_TYPES: Array<IDisplayType> = [
  {
    id: AnalysisTypes.TAG_ANALYSIS,
    svgIcon: AnalysisTypes.TAG_ANALYSIS,
    tooltip: "Tag Analysis",
  },
  {
    id: AnalysisTypes.TIME_SERIES,
    svgIcon: AnalysisTypes.TIME_SERIES,
    tooltip: "Tag Trends",
  },
  {
    id: AnalysisTypes.TAG_COMPARISON,
    svgIcon: AnalysisTypes.TAG_COMPARISON,
    tooltip: "Tag Comparison",
  },
];
