export const environment = {
  production: false,
  apiBase: "https://saas.intorqa-dev.com/api/",
  evBusUrl: "https://saas.intorqa-dev.com/eventbus",
  externalApiBase: "https://api.intorqa-dev.com",
  apiRequestTimeout: 5000,
  gTranslateApi: {
    token: "AIzaSyBByIqIserkELS3_v6_NlHWZo6dKVMv6e8",
    translateUrl:
      "https://translation.googleapis.com/language/translate/v2?key=",
    detectLanguage:
      "https://translation.googleapis.com/language/translate/v2/detect?key=",
  },
  slackConfig: {
    clientId: "302272051877.3132677901217",
    clientSecret: "eba5d9a4d485913c6cd3b1e0d373ae0e",
  },
  authConfig: {
    enabled: true,
    config: {
      url: "https://auth2.intorqa-dev.com/",
      realm: "intorqa",
      clientId: "intorqa-saas",
    },
    initOptions: {
      onLoad: "login-required",
      checkLoginIframe: false,
    },
    enableBearerInterceptor: true,
    loadUserProfileAtStartUp: true,
    bearerExcludedUrls: ["/assets", "/clients/public"],
  },
  version: require("../../../../package.json").version,
};
