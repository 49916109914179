import { TemplatePortal } from "@angular/cdk/portal";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { Utils } from "utils/utils";
import { OpenDirection, OpenStatus } from "../../enums/dropdown.enum";
import { Sizes } from "../../enums/shared.enum";
import { QueryFilters } from "../../models/query-model/query-filters";
import { DropdownService } from "../../services/dropdown.service";
import { PortalBridgeService } from "../../services/portal-bridge.service";
import { MatButtonComponent } from "../mat-button/mat-button.component";
import { PillType } from "../pill/pill.enum";
import { GetHiddenSelectionsPipe } from "./multiple-dropdown.pipe";

@Component({
  selector: "itq-multiple-dropdown",
  templateUrl: "./multiple-dropdown.component.html",
  styleUrls: ["./multiple-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultipleDropdownComponent,
    },
  ],
})
export class MultipleDropdownComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @ViewChild("portalContent") portalContent: TemplateRef<unknown>;
  @ViewChild("button") button: MatButtonComponent;
  @ViewChild("buttonWrapper") buttonWrapper: ElementRef;
  @ViewChild("dropdownResults") dropdownResults: ElementRef;

  @Input() disabled = false;
  @Input() direction = "down";
  @Input() width: number;
  @Input() dataSource: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() placeholder = "Please select an item...";
  @Input() materialStyle = "stroked";
  @Input() padding = Sizes["X-SM"];
  @Input() text = "";
  @Input() textTransform: string;
  @Input() color: string;
  @Input() query: string;
  @Input() pageSize: number;
  @Input() emptyDataSourceMessage: string;
  @Input() invalid: boolean;
  @Input() httpBinding = false;

  @Output() addOnEmpty = new EventEmitter<string>();
  @Output() changeValue = new EventEmitter<any>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  private pageX = 0;
  private pageY = 0;
  public dropdownWidth: number;
  private dropdownSubscription: Subscription;
  private selectionsSubscription: Subscription;
  private touched = false;
  public open = false;
  public selections: Array<any> = [];
  public showLoader: boolean;
  public showTooltip = false;
  public leftPosition: number;
  public topPosition: number;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined
  );
  public maxNumberSelections: number;

  readonly OpenDirection = OpenDirection;
  readonly Sizes = Sizes;
  readonly PillType = PillType;

  @HostListener("document:click", ["$event"])
  click(): void {
    this.toggleDropdown(false);
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.toggleDropdown(false);
  }

  constructor(
    private dropdownService: DropdownService,
    private viewContainerRef: ViewContainerRef,
    private portalBridgeService: PortalBridgeService,
    private getHiddenSelectionsPipe: GetHiddenSelectionsPipe
  ) {}

  onChange = (items: any) => {};

  onTouched = () => {};

  writeValue(items: Array<any> | undefined): void {
    this.selections = items;
    this.maxNumberSelections = this.getMaxNumberSelections();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnInit(): void {
    this.dropdownSubscription =
      this.dropdownService.dropdownObservable.subscribe(() => {
        this.open = false;
      });
  }

  ngAfterViewInit(): void {
    this.maxNumberSelections = this.getMaxNumberSelections();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.showLoader = false;
    }
  }

  ngOnDestroy(): void {
    this.toggleDropdown(false);
    this.dropdownSubscription?.unsubscribe();
    this.selectionsSubscription?.unsubscribe();
  }

  private getMaxNumberSelections(): number {
    if (!this.selections) return 0;
    if (!this.buttonWrapper?.nativeElement?.offsetWidth) return 0;
    let textWidth = 0;
    let count = 0;
    const containerWrapperWidth =
      this.buttonWrapper?.nativeElement?.offsetWidth - 24;
    this.selections.forEach((selection: any) => {
      textWidth +=
        Utils.getTextWidthFromElement(
          selection[this.dataFields.name] || selection,
          "Roboto",
          "12px"
        ) +
        24 +
        Utils.remToPx(1.25);
      if (containerWrapperWidth > textWidth) {
        count += 1;
      }
    });

    return count;
  }

  loadPortal(): void {
    const portal = new TemplatePortal(
      this.portalContent,
      this.viewContainerRef
    );
    this.portalBridgeService.portal = portal;
  }

  setDropdownPosition(): void {
    const dropdownBoundingRect =
      this.button?.dropdownButton?._elementRef?.nativeElement?.getBoundingClientRect();
    if (window.innerHeight - dropdownBoundingRect?.y < 400) {
      this.direction = "up";
    }
    if (this.width) {
      this.pageX = dropdownBoundingRect?.left - this.dropdownWidth - 10;
      this.pageY = dropdownBoundingRect?.bottom + 20;
    } else {
      this.pageX = dropdownBoundingRect?.right - this.dropdownWidth - 10;
      this.pageY = dropdownBoundingRect?.bottom + 20;
    }
  }

  onClick(): void {
    this.toggleDropdown(!this.open);
  }

  toggleDropdown(action: boolean): void {
    if (action) {
      this.setDropdownWidth();
      this.setDropdownPosition();
      this.loadPortal();
    }
    this.open = action;
    if (this.open) {
      if (this.httpBinding) {
        this.showLoader = true;
        this.dataBound.emit(this.initialState);
      }
      this.portalBridgeService.toggle(OpenStatus.OPEN, {
        left: this.pageX,
        top: this.pageY,
      });
    } else {
      this.portalBridgeService.toggle(OpenStatus.CLOSE);
    }
  }

  setDropdownWidth(): void {
    this.dropdownWidth =
      this.width ||
      this.button?.dropdownButton?._elementRef?.nativeElement?.clientWidth;
  }

  public onDataBound(params: QueryFilters): void {
    if (this.initialState) {
      this.showLoader = true;
      this.dataBound.emit(params);
    }
  }

  public onChangeValue(value: any): void {
    const selectionExists = this.selections?.find((item: any) => {
      return item[this.dataFields.value] === value[this.dataFields.value];
    });
    if (selectionExists) {
      this.selections = cloneDeep(this.selections).filter((item: any) => {
        return item[this.dataFields.value] !== value[this.dataFields.value];
      });
    } else {
      this.selections = [...(this.selections || []), value];
    }
    this.maxNumberSelections = this.getMaxNumberSelections();
    this.onChange(this.selections?.length > 0 ? this.selections : undefined);
    this.changeValue.emit(value);
  }

  public getSelection(): any {
    if (this.selections) {
      const selection = this.dataSource?.find((item: any) => {
        return item[this.dataFields.value] === this.selections;
      });
      if (selection) {
        return selection[this.dataFields.name];
      }
      return undefined;
    }
    return undefined;
  }

  onToggle(event: any, show: boolean): void {
    this.loadPortal();
    const htmlContent = this.getHiddenSelectionsPipe.transform(
      this.selections,
      this.maxNumberSelections,
      this.dataFields
    );
    const target = event.currentTarget.getBoundingClientRect();
    this.portalBridgeService.toggleTooltip({
      show: show,
      top: target.top,
      left: target.left,
      content: htmlContent,
    });
  }
}
