import { Pipe, PipeTransform } from "@angular/core";
import { Sections, TableColumn, TagCategory } from "@intorqa-ui/core";
import { ITagMetadata } from "@intorqa-ui/shared";

@Pipe({
  name: "getSelectionsSections",
})
export class GetSelectionsSectionsPipe implements PipeTransform {
  constructor() {}

  transform(selections: Array<ITagMetadata>): Array<Sections> {
    return [...new Set(selections.map((item: ITagMetadata) => item.section))];
  }
}

@Pipe({
  name: "getSelectionsSectionTags",
})
export class GetSelectionsSectionTagsPipe implements PipeTransform {
  constructor() {}

  transform(
    selections: Array<ITagMetadata>,
    section: Sections
  ): Array<ITagMetadata> {
    return selections.filter((item: ITagMetadata) => item.section === section);
  }
}

@Pipe({
  name: "filterSelectionsColumns",
})
export class FilterSelectionsColumnsPipe implements PipeTransform {
  constructor() {}

  transform(
    columns: Array<TableColumn>,
    section: Sections
  ): Array<TableColumn> {
    if (section === Sections["My Tags"]) {
      return columns.filter((item: TableColumn) => item.name !== "Category");
    }
    return columns;
  }
}

@Pipe({
  name: "filterSelectionsByCategory",
})
export class FilterSelectionsByCategoryPipe implements PipeTransform {
  constructor() {}

  transform(
    selections: Array<ITagMetadata>,
    category: TagCategory
  ): Array<ITagMetadata> {
    return selections.filter(
      (item: ITagMetadata) => item.categoryName !== category
    );
  }
}

@Pipe({
  name: "getSelectionsByCategory",
})
export class GetSelectionsByCategoryPipe implements PipeTransform {
  constructor() {}

  transform(
    selections: Array<ITagMetadata>,
    category: TagCategory
  ): Array<ITagMetadata> {
    return selections.filter(
      (item: ITagMetadata) => item.categoryName === category
    );
  }
}
