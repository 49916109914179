import { catchError } from "rxjs/operators";
import { CommonModule } from "@angular/common";
import { Component, HostBinding, Input } from "@angular/core";
import { ProfilesNavigationItem } from "./../../../../../../shared/src/lib/models/profiles-navigation-item.model";

import { FormGroup } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router } from "@angular/router";
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  FAwesomeModule,
  IError,
  IconType,
  QueryFilters,
  Sizes,
  ToolbarActions,
} from "@intorqa-ui/core";
import {
  AlertTypesIcons,
  ChartType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  IDisplayType,
  MillisToDate,
  NavigationHistoryItem,
  NotificationsService,
  PriorityColor,
  PriorityLabel,
  Profile,
  ProfileService,
  ProfileTypeIcon,
  QueryRule,
  TagService,
  Timeline,
  TimelineFeedDisplayTypes,
  TransactionNotificationType,
  TransactionNotificationTypeText,
  TransactionalNotification,
  WidgetActions,
} from "@intorqa-ui/shared";
import { SharedModule } from "../../shared/shared.module";
import { WidgetSettingsComponent } from "../../widget-settings/widget-settings.component";
import { GetActionPipe } from "./notifications-transactional.pipe";

@Component({
  selector: "itq-notifications-transactional",
  templateUrl: "./notifications-transactional.component.html",
  styleUrls: ["./notifications-transactional.component.scss"],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    MillisToDate,
    GetActionPipe,
  ],
})
export class NotificationsTransactionalComponent {
  @Input() notification: TransactionalNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly TransactionNotificationTypeText = TransactionNotificationTypeText;
  readonly TransactionNotificationType = TransactionNotificationType;

  @HostBinding("class") class: string;
  @HostBinding("style.borderRight") borderRight: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly profileService: ProfileService,
    readonly router: Router
  ) {}

  ngOnInit(): void {
    this.class = !this.notification.read ? "unread" : "";
    this.borderRight = "10px solid #98b5cc";
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: TransactionalNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? "unread" : "";
        this.notificationService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get("priority")?.value
          )
          .subscribe();
      });
  }

  public onLoadAction(): void {
    const { triggerMetadata } = this.notification;

    switch (triggerMetadata.action) {
      case TransactionNotificationType.TAG_CREATED:
        this.handleCreateTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.TAG_EDITED:
        this.handleEditTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.PROFILE_UPDATED:
      case TransactionNotificationType.PROFILE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_DELETED:
      case TransactionNotificationType.PROFILE_NOTE_UPDATED:
        this.handleLoadProfileAction(triggerMetadata);
        break;
      default:
        break;
    }
  }

  private handleEditTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService
      .getTagById(id)
      .then((timeline: Timeline) => {
        const navigationItem = new NavigationHistoryItem(
          `${WidgetActions.SETTINGS}_${timeline.tagId}`,
          timeline,
          WidgetActions.SETTINGS,
          undefined,
          new QueryFilters(30, 1, undefined, undefined, undefined),
          new FormGroup({}),
          "pencil",
          IconType.FONT_AWESOME,
          undefined,
          undefined,
          undefined
        );

        const actions = [
          { action: ToolbarActions.RESET_FILTERS },
          { action: ToolbarActions.DATE, expanded: false },
          { action: ToolbarActions.REFRESH },
        ];

        this.openCustomOverlay({ navigationItem, actions });
      })
      .catch((errorResponse: IError) => {
        if (errorResponse) {
          this.customOverlayService.openCustom(
            {
              title: "Oooops!",
              data: errorResponse.description,
              message: errorResponse.description,
              icon: ["far", "exclamation-circle"],
              size: "4x",
              dialog: {
                type: DialogTypes.ALERT,
              },
            },
            DialogComponent
          );
        }
      });
  }

  private handleCreateTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService
      .getTagById(id)
      .then((timeline: Timeline) => {
        const navigationItem = new NavigationHistoryItem(
          `${timeline.widgetId}_${WidgetActions.EXPLORE}`,
          timeline,
          WidgetActions.EXPLORE,
          [
            new QueryRule(DTOQueryFieldType.tag, DTOQueryConditionOperator.in, [
              timeline.tagId,
            ]),
          ],
          new QueryFilters(30, 1, undefined, undefined, undefined),
          new FormGroup({}),
          "magnifying-glass",
          IconType.FONT_AWESOME,
          TimelineFeedDisplayTypes.find(
            (item: IDisplayType) => item.type === ChartType.TIMELINE
          ),
          undefined,
          undefined
        );

        this.openCustomOverlay({ navigationItem });
      })
      .catch((errorResponse: IError) => {
        if (errorResponse) {
          this.customOverlayService.openCustom(
            {
              title: "Oooops!",
              data: errorResponse.description,
              message: errorResponse.description,
              icon: ["far", "exclamation-circle"],
              size: "4x",
              dialog: {
                type: DialogTypes.ALERT,
              },
            },
            DialogComponent
          );
        }
      });
  }

  private handleLoadProfileAction(triggerMetadata: {
    [key: string]: any;
  }): void {
    const { id } = triggerMetadata;
    this.profileService.getProfileById(id).subscribe(
      (profile: Profile) => {
        let navigationItem: any;
        const queryFilters = new QueryFilters(30, 1, undefined, undefined, {
          direction: "desc",
          active: "updatedDate",
        });
        const profileTypeIcon = ProfileTypeIcon[profile?.profileTypeName];
        const timelineChart = {
          id: "Timeline",
          type: ChartType.TIMELINE,
          svgIcon: "board",
          tooltip: "Timeline",
        };

        if (
          triggerMetadata.action ===
            TransactionNotificationType.PROFILE_NOTE_CREATED ||
          triggerMetadata.action ===
            TransactionNotificationType.PROFILE_NOTE_DELETED ||
          triggerMetadata.action ===
            TransactionNotificationType.PROFILE_NOTE_UPDATED
        ) {
          navigationItem = new ProfilesNavigationItem(
            `${WidgetActions.EXPLORE}_${profile.profileId}`,
            profile,
            WidgetActions.EXPLORE,
            undefined,
            queryFilters,
            new FormGroup({}),
            profileTypeIcon,
            IconType.FONT_AWESOME,
            timelineChart,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            2
          );
        } else {
          navigationItem = new NavigationHistoryItem(
            `${WidgetActions.EXPLORE}_${profile.profileId}`,
            profile,
            WidgetActions.EXPLORE,
            undefined,
            queryFilters,
            new FormGroup({}),
            profileTypeIcon,
            IconType.FONT_AWESOME,
            timelineChart,
            undefined,
            undefined
          );
        }

        this.openCustomOverlay({ navigationItem });
      },
      (errorResponse: IError) => {
        if (errorResponse) {
          this.customOverlayService.openCustom(
            {
              title: "Oooops!",
              data: errorResponse.description,
              message: errorResponse.description,
              icon: ["far", "exclamation-circle"],
              size: "4x",
              dialog: {
                type: DialogTypes.ALERT,
              },
            },
            DialogComponent
          );
        }
      }
    );
  }

  private openCustomOverlay(data: any): void {
    this.customOverlayService.open({
      data,
      closeBtnStyle: "basic",
      type: CustomOverlayType["almost-full"],
      size: "lg",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}
