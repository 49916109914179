import { Injectable } from "@angular/core";
import { DataLayerCustomDimensions } from "../enums/data-layer-custom-dimensions.enum";
import { FullCustomDimensionsSet } from "../types/full-custom-dimensions-set";

// This is a type that can be used for a partial version of the dimensions set,
// Where there are gaps and some dimensions are empty
export type PartialCustomDimensionsSet = { [key: string]: string };

@Injectable({
  providedIn: "root",
})
export class DataLayerCustomDimensionsService {
  private _CURRENT_SET: FullCustomDimensionsSet;
  private _CONSTANT_DIMENSIONS: FullCustomDimensionsSet;

  constructor() {
    this._generateEmptyDimensionsSet();
  }

  set dimensions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions)
      .map((key) => DataLayerCustomDimensions[key])
      .forEach((key) => {
        this._CURRENT_SET[key] =
          someDimensions[key] || this._CONSTANT_DIMENSIONS[key];
      });
  }

  trigger(): void {
    const w = window as any;
    if (w && w.dataLayer) {
      w.dataLayer.push(this._CURRENT_SET);
    }
  }

  private _generateEmptyDimensionsSet(): void {
    this._CURRENT_SET = {
      [DataLayerCustomDimensions.uuid]: undefined,
      [DataLayerCustomDimensions.organisationKey]: undefined,
      [DataLayerCustomDimensions.organisation]: undefined,
    };
  }
}
