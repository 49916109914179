import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownItem } from "@intorqa-ui/core";
import {
  ANALYSIS_TYPES,
  EcosystemsService,
  IDisplayType,
  NavigationHistoryItem,
  Widget,
  WidgetActions,
} from "@intorqa-ui/shared";

@Component({
  selector: "itq-chart-wizard-details",
  templateUrl: "./chart-wizard-details.component.html",
  styleUrls: ["./chart-wizard-details.component.scss"],
})
export class ChartWizardDetailsComponent implements OnInit {
  @Input() widget: Widget;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() form: FormGroup;

  @Output() changeQueryType = new EventEmitter<IDisplayType>();
  @Output() changeEcosystem = new EventEmitter<void>();
  @Output() update = new EventEmitter<{ field: string; value: string }>();

  public ecosystemDataSource: Array<IDropdownItem> = [];

  readonly displayTypes = ANALYSIS_TYPES;
  readonly WidgetActions = WidgetActions;

  constructor(private ecosystemsService: EcosystemsService) {}

  ngOnInit(): void {
    this.createForm();
    this.getEcosystems();
  }

  private createForm(): void {
    this.form.addControl(
      "name",
      new FormControl(this.widget?.name, [Validators.required])
    );
    this.form.addControl(
      "description",
      new FormControl(this.widget?.description)
    );
    if (this.navigationItem.action === WidgetActions.CREATE) {
      this.form.addControl(
        "analysisType",
        new FormControl(this.widget?.type || this.displayTypes[0].id, [
          Validators.required,
        ])
      );
    }
  }

  private getEcosystems(): void {
    this.ecosystemsService
      .getEcosystems()
      .subscribe((response: Array<{ id: string; name: string }>) => {
        this.ecosystemDataSource = response.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        if (this.ecosystemDataSource?.length > 1) {
          this.form.addControl(
            "ecosystem",
            new FormControl(this.widget?.ecosystemId, [Validators.required])
          );
        }
      });
  }

  public onChangeDisplayType(params: IDisplayType): void {
    this.changeQueryType.emit(params);
  }

  public onUpdate(params: { field: string; value: string }): void {
    this.update.emit(params);
  }

  public onChangeEcosystem(): void {
    this.changeEcosystem.emit();
  }
}
