import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Category,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  FoamtreeService,
  IDropdownItem,
  IError,
  IRiplResults,
  IToolbarAction,
  IconType,
  PillType,
  QueryFilters,
  Sections,
  Sizes,
  TagCategory,
  ToolbarActions,
  ViewMode,
  VirtualScrollService,
} from "@intorqa-ui/core";
import {
  AnalysisTypes,
  CategoryService,
  ChartType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DiscordNavigationItem,
  DocumentItem,
  DocumentItemService,
  EcosystemsService,
  IData,
  IDataPoint,
  IDisplayType,
  ISegment,
  IWidgetData,
  NavigationHistoryItem,
  Query,
  QueryRule,
  QueryType,
  SEARCH_TYPES,
  SegmentScope,
  SettingsActions,
  TagAnalysis,
  TagService,
  Timeline,
  TimelineFeedDisplayTypes,
  TimelineNavigationItem,
  Widget,
  WidgetActions,
  WidgetService,
} from "@intorqa-ui/shared";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { ApiSettingsComponent } from "../../../components/api-settings/api-settings.component";

@Component({
  selector: "itq-timeline-explore",
  templateUrl: "./timeline-explore.component.html",
  styleUrls: ["./timeline-explore.component.scss"],
})
export class TimelineExploreComponent implements OnInit, OnDestroy, OnChanges {
  @Input() navigationItem: TimelineNavigationItem;
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  public showLoader = false;
  public widget: Timeline;
  public initialState: QueryFilters;
  public data: Array<IDataPoint>;
  public name: string;
  public widgetData: IWidgetData;
  public timelineData: IData;
  public foamtreeData: IRiplResults;
  public error: IError;
  public expandedFilters = true;
  public selectedCluster: any;
  public queryModel = new Query();
  public viewMode = ViewMode.TABLE;
  public tagAnalysisWidget: TagAnalysis;
  public toolbarActions: Array<IToolbarAction>;
  public displayTypes: Array<IDisplayType>;
  private scrollSubscription: Subscription;
  private foamtreeRef: ElementRef;
  public ecosystemDataSource: Array<IDropdownItem> = [];
  public categoriesDataSource: Array<Category>;
  private collapseHistorySubscription: Subscription;
  private page: number;
  private scrollDirection = "down";

  readonly searchTypes = SEARCH_TYPES;
  readonly SegmentScope = SegmentScope;
  readonly Sizes = Sizes;
  readonly AnalysisTypes = AnalysisTypes;
  readonly ViewMode = ViewMode;
  readonly SettingsActions = SettingsActions;
  readonly QueryType = QueryType;
  readonly IconType = IconType;
  readonly WidgetActions = WidgetActions;
  readonly PillType = PillType;
  readonly ChartType = ChartType;

  constructor(
    public customOverlayRef: CustomOverlayRef,
    public documentService: DocumentItemService,
    public tagService: TagService,
    private foamtreeService: FoamtreeService,
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    private categoryService: CategoryService,
    private ecosystemsService: EcosystemsService,
    private virtualScrollService: VirtualScrollService,
    readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.collapseHistorySubscription =
      this.widgetService.responsivePanels$.subscribe(() => {
        const responsivePanels = this.widgetService.responsivePanels;
        if (
          responsivePanels.foamtree &&
          responsivePanels.articleDetail &&
          (responsivePanels.filters || responsivePanels.navigationHistory)
        ) {
          this.widgetService.responsivePanels.filters = false;
          this.expandedFilters = false;
        }
      });

    this.toolbarActions = [
      {
        action: ToolbarActions.TOGGLE_VIEWS,
        data: [ViewMode.RIPL, ViewMode.TABLE],
      },
      {
        action: ToolbarActions.DATE,
        expanded: this.initialState?.where ? true : false,
        data: {
          date: this.initialState?.where,
        },
      },
      {
        action: ToolbarActions.REFRESH,
      },
      {
        action: ToolbarActions.ADD,
      },
      {
        action: ToolbarActions.API,
      },
    ];
    this.getEcosystems();
    this.setDrilldownFilters();
    this.scrollSubscription = this.virtualScrollService.scroll$.subscribe(
      (response: { query: QueryFilters; direction: string }) => {
        this.scrollDirection = response.direction;
        this.initialState.page = cloneDeep(response.query.page);
        this.showLoader = true;
        this.getData().then((response: IData) => {
          this.getDataCallback(response);
          this.showLoader = false;
        });
      }
    );
    this.form.addControl(
      "query",
      new FormControl(undefined, [Validators.required])
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.navigationItem?.previousValue !==
      changes?.navigationItem?.currentValue
    ) {
      this.viewMode = ViewMode.TABLE;
      this.widget = changes?.navigationItem?.currentValue?.item as Timeline;
      if (
        this.navigationItem?.selectedDisplayType?.type !== ChartType.TIMELINE
      ) {
        this.tagAnalysisWidget = new TagAnalysis(
          this.widget.widgetId,
          this.widget.username,
          AnalysisTypes.TAG_ANALYSIS,
          this.widget.name,
          this.widget.description,
          undefined,
          undefined,
          undefined,
          undefined,
          this.navigationItem.selectedDisplayType?.options,
          [this.widget.tagId],
          [this.navigationItem.selectedDisplayType?.id as TagCategory],
          25,
          ChartType.TABLE,
          this.widget.ecosystemId
        );
      }
      this.initialState = cloneDeep(
        changes?.navigationItem?.currentValue?.initialState
      );

      this.form.addControl(
        "searchType",
        new FormControl(QueryType.QUICK_SEARCH)
      );
      this.displayTypes = TimelineFeedDisplayTypes.filter(
        (item: IDisplayType) => {
          return item.id !== this.navigationItem.selectedDisplayType.id;
        }
      );
      if (
        this.queryModel.hasRules() ||
        this.navigationItem?.rules?.length > 0
      ) {
        this.showLoader = true;
        this.getData().then((response: IData | IWidgetData) => {
          this.getDataCallback(response);
          this.showLoader = false;
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
    this.collapseHistorySubscription.unsubscribe();
  }

  public onChangeEcosystem(): void {
    if (this.queryModel.hasRules()) {
      this.customOverlayService.openCustom(
        {
          title: "Change ecosystem?",
          message: `In order to change ecosystem you need to reset your query.<br><br>Do you wish to continue?`,
          icon: ["far", "question-circle"],
          size: "4x",
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.initialState.resetPagination().then(() => {
              this.virtualScrollService.dataBoundObservable.next();
            });
            this.widget.ecosystemId = this.form.controls.ecosystemId.value;
            this.clearQuery();
            this.getFilters();
          } else {
            this.form.controls.ecosystemId.setValue(this.widget.ecosystemId);
          }
        }
      );
    } else {
      this.widget.ecosystemId = cloneDeep(this.form.controls.ecosystemId.value);
      this.getFilters();
    }
  }

  private getFilters(): void {
    if (this.widget.ecosystemId) {
      this.categoryService
        .getCategories(this.widget.ecosystemId)
        .then((response: Array<Category>) => {
          this.clearQuery();
          this.categoriesDataSource = response;

          this.cdr.detectChanges();
          this.tagService.getSearchResults$.next();
        });
    }
  }

  private getEcosystems(): void {
    this.ecosystemsService
      .getEcosystems()
      .subscribe((response: Array<{ id: string; name: string }>) => {
        this.ecosystemDataSource = response.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        if (this.ecosystemDataSource?.length > 1) {
          this.form.addControl(
            "ecosystemId",
            new FormControl({ value: this.widget?.ecosystemId, disabled: true })
          );
        }
        this.getFilters();
      });
  }

  private setDrilldownFilters(): void {
    if (this.initialState?.query) {
      this.queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [this.initialState.query]
        )
      );
    }
  }

  private updateNavigationItem(): void {
    const query = new Query();
    let rules = this.queryModel.getRules();
    if (this.navigationItem?.rules?.length > 0) {
      rules = this.queryModel.getRules().filter((rule: QueryRule) => {
        return rule.value !== this.navigationItem?.rules[0]?.value;
      });
    }
    rules.forEach((item: QueryRule) => {
      query.addRule(item);
    });
    this.onUpdateWidget({ prop: "_extras", value: query });
  }

  public onUpdateWidget(params: { prop: string; value: any }): void {
    this.widget[params.prop] = params.value;
    this.navigationItem.form = cloneDeep(this.form);
    this.navigationItem.initialState = cloneDeep(this.initialState);
    this.navigationItem.item[params.prop] = params.value;
  }

  public onToggleFilters(): void {
    this.expandedFilters = !this.expandedFilters;
    this.widgetService.updateSegmentObservable.next(undefined);
    this.widgetService.responsivePanels.filters = this.expandedFilters;
    this.widgetService.responsivePanels$.next();
  }

  public onUpdateFoamtreeRef(ref: ElementRef): void {
    this.foamtreeRef = ref;
  }

  public onExportFoamtree(): void {
    this.foamtreeService.exportImage(this.widget.name, this.foamtreeRef);
  }

  public getData(): Promise<IData | IWidgetData> {
    if (this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE) {
      return this.getTimelineData();
    } else {
      return this.getWidgetData(this.initialState);
    }
  }

  private getTimelineData(): Promise<IData> {
    const queryModel = this.queryModel.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    if (this.navigationItem instanceof DiscordNavigationItem) {
      if (this.initialState.page) {
        if (this.page < this.initialState.page) {
          this.navigationItem.segment.context = {
            document: this.navigationItem.context.document,
            before:
              this.timelineData.result[this.timelineData.result.length - 1]
                .emitDocumentDate,
          };
        } else {
          this.navigationItem.segment.context = {
            document: this.navigationItem.context.document,
            after: this.timelineData.result[0].emitDocumentDate,
          };
        }
      } else {
        this.navigationItem.segment.context = {
          document: this.navigationItem.context.document,
        };
      }
      return this.tagService.getContext(
        this.initialState,
        queryModel,
        this.widget.ecosystemId,
        this.navigationItem.segment.context
      );
    } else {
      return this.tagService.execute(
        this.initialState,
        queryModel,
        this.widget.ecosystemId
      );
    }
  }

  private getWidgetData(params: QueryFilters): Promise<IWidgetData> {
    const queryModel = this.queryModel.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    return this.widgetService.getData(
      {
        widget: this.tagAnalysisWidget,
        filters: queryModel.modelToDTO(),
      },
      {
        where: params?.where,
      }
    );
  }

  private getDataCallback(response: IData | IWidgetData): void {
    this.updateNavigationItem();
    if (this.navigationItem.selectedDisplayType.type !== ChartType.TIMELINE) {
      this.widgetData = response as IWidgetData;
      return;
    }

    const timelineData = response as IData;
    const existingResults =
      this.timelineData?.result?.length > 0 ? this.timelineData.result : [];
    const newResults = timelineData.result || [];

    if (timelineData.count) {
      if (this.scrollDirection === "up") {
        this.timelineData = {
          result: [...newResults, ...existingResults],
          count: timelineData.count,
        };
      } else {
        this.timelineData = {
          result:
            this.initialState.page === 1
              ? newResults
              : [...existingResults, ...newResults],
          count: timelineData.count,
        };
      }

      if (timelineData.page) {
        this.initialState.page = timelineData.page;
      }
    } else {
      this.timelineData = timelineData;
      this.page = this.initialState.page;
    }
  }

  public onDataBound(query: Query, params: QueryFilters): void {
    this.articleDetail = undefined;
    this.queryModel = query;
    if (this.queryModel.hasRules()) {
      this.form.controls.query.setValue(this.queryModel);
    } else {
      this.form.controls.query.setValue(undefined);
    }
    this.initialState = params;
    this.tagService.reloadFilters$.next(this.initialState);
    if (this.viewMode === ViewMode.RIPL) {
      this.showLoader = true;
      const getTimelineDataPromise = this.getData().then(
        (response: IData | IWidgetData) => {
          this.getDataCallback(response);
        }
      );
      const getFoamtreePromise = this.getFoamtreeData();
      Promise.all([getTimelineDataPromise, getFoamtreePromise]).then(() => {
        this.showLoader = false;
      });
    } else if (this.viewMode === ViewMode.TABLE) {
      this.showLoader = true;
      this.getData().then((response: IData | IWidgetData) => {
        this.getDataCallback(response);
        this.showLoader = false;
      });
    }
  }

  public onToggleView(viewMode: ViewMode): void {
    this.expandedFilters = true;
    this.widgetService.responsivePanels.foamtree = viewMode === ViewMode.RIPL;
    this.widgetService.responsivePanels$.next();
    if (viewMode === ViewMode.RIPL) {
      if (viewMode === this.viewMode) {
        viewMode = ViewMode.TABLE;
      } else {
        this.showLoader = true;
        this.getFoamtreeData().then(() => {
          this.showLoader = false;
        });
      }
    }
    this.viewMode = viewMode;
  }

  public onDrilldownFoamtree(cluster: any): void {
    this.showLoader = true;
    this.selectedCluster = cluster;
    const documentIds = this.foamtreeService.getClusterDocumentIds([cluster]);
    this.foamtreeService
      .filterCluster({ documentIds })
      .then((response: IData) => {
        this.timelineData = response;
        this.showLoader = false;
      });
  }

  private getFoamtreeData(): Promise<void> {
    return new Promise((resolve) => {
      this.foamtreeData = undefined;
      const queryModel = this.queryModel.cloneDeep();
      this.navigationItem.rules?.forEach((rule: QueryRule) => {
        queryModel.addRule(rule);
      });
      const query = queryModel.modelToDTO();
      this.foamtreeService
        .getFoamtree(this.initialState, query, this.widget.ecosystemId)
        .then((response: IRiplResults) => {
          this.foamtreeData = response;
          resolve();
        })
        .catch(() => {
          this.showLoader = false;
        });
    });
  }

  public onAdd(): void {
    const widget = cloneDeep(this.navigationItem?.item) as Timeline;
    widget.name = undefined;
    widget.categoryId = undefined;
    widget._extras.query.rules = [
      ...widget._extras.query.rules,
      this.navigationItem.rules[0],
    ];

    const rule = [
      new QueryRule(
        DTOQueryFieldType.filter,
        DTOQueryConditionOperator.in,
        this.navigationItem.rules[0].value
      ),
    ];
    const navHitoryItem = new NavigationHistoryItem(
      `${WidgetActions.CREATE}_${this.navigationItem.rules[0].value[0]}`,
      widget,
      WidgetActions.CREATE,
      rule,
      this.initialState,
      new FormGroup({}),
      "plus",
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      this.navigationItem.boardId
    );
    this.widgetService.drilldownObservable.next(navHitoryItem);
  }

  public onChangeQueryType(): void {
    if (this.queryModel.hasRules()) {
      this.customOverlayService.openCustom(
        {
          title: "Change search type",
          message:
            "In order to change your search type, you need to reset your query.<br><br>Do you wish to continue?",
          icon: ["far", "question-circle"],
          size: "4x",
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.clearQuery();
            this.onDataBound(this.queryModel, this.initialState);
          } else {
            this.form.controls.searchType.setValue(this.queryModel.type);
          }
        }
      );
    } else {
      this.clearQuery();
      this.onDataBound(this.queryModel, this.initialState);
    }
  }

  private clearQuery(): void {
    if (this.form.controls.searchType.value === QueryType.QUICK_SEARCH) {
      this.queryModel = new Query([], this.form.controls.searchType.value);
      this.form.controls.searchTerm?.setValue("");
      this.form.controls.section?.setValue(Sections["My Tags"]);
    }
    if (this.form.controls.searchType.value === QueryType.QUERY_BUILDER) {
      this.queryModel = new Query(
        [
          new QueryRule(
            DTOQueryFieldType.content,
            DTOQueryConditionOperator.contains,
            []
          ),
        ],
        this.form.controls.searchType.value
      );
    } else {
      this.queryModel = new Query([], this.form.controls.searchType.value);
    }
    this.form.controls.query.setValue(this.queryModel);
  }

  public onShowApi(): void {
    this.customOverlayService.open({
      data: {
        widget: this.widget,
      },
      type: CustomOverlayType["slide-right"],
      component: ApiSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment);
        break;
      case SegmentScope.REPLIES:
        this.drilldownReplies(segment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      "discord",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      segment,
      undefined,
      segment.context
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownReplies(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitType === "Comment"
        ? `${segment.value.emitType} by ${segment.value.emitActor} `
        : `Replies of ${segment.value.emitHeadline}`,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.id}:ReplyTo`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "file",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownDiscord(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "discord",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "hashtag",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      undefined,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      this.widget.ecosystemId,
      undefined,
      undefined
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      "user",
      IconType.FONT_AWESOME,
      {
        id: "Timeline",
        type: ChartType.TIMELINE,
        svgIcon: "board",
        tooltip: "Timeline",
      },
      this.articleDetail,
      undefined
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onChangeDisplayType(params: IDisplayType): void {
    this.articleDetail = undefined;
    this.displayTypes?.push(cloneDeep(this.navigationItem.selectedDisplayType));
    this.navigationItem.selectedDisplayType = params;
    if (this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE) {
      this.initialState.page = 1;
    } else {
      this.viewMode = ViewMode.TABLE;
      this.tagAnalysisWidget = new TagAnalysis(
        this.widget.widgetId,
        this.widget.username,
        AnalysisTypes.TAG_ANALYSIS,
        this.widget.name,
        this.widget.description,
        undefined,
        undefined,
        undefined,
        undefined,
        params.options,
        [this.widget.tagId],
        [params.id as TagCategory],
        25,
        ChartType.TABLE,
        this.widget.ecosystemId
      );
    }
    this.showLoader = true;
    this.getData().then((response: IData | IWidgetData) => {
      this.getDataCallback(response);
      this.showLoader = false;
    });
    this.displayTypes = this.displayTypes.filter((item: IDisplayType) => {
      return item.id !== this.navigationItem.selectedDisplayType.id;
    });
  }
}
