import { EChartsOption } from "echarts";
import { ChartType } from "../../enums/widget.enum";
import { IDisplayType, ISerie } from "../../interfaces/widget/widget.interface";
import { Widget } from "../widgets/widget";
import { Chart } from "./chart";

export class TimelineChart extends Chart {
  constructor(public name: ChartType) {
    super(name);
  }

  public getOptions(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any }
  ): EChartsOption {
    let options: EChartsOption;
    return options;
  }

  public getDisplayTypes(
    displayTypes: Array<IDisplayType>,
    widget: Widget
  ): Array<IDisplayType> {
    const selectedDisplay = this.getSelectedType(displayTypes, widget);
    const types = displayTypes?.filter((item: IDisplayType) => {
      return item.id !== selectedDisplay.id;
    });

    return types;
  }

  public getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: Widget
  ): IDisplayType {
    return displayTypes.find((item: IDisplayType) => {
      return item.type === ChartType.TIMELINE;
    });
  }
}
