import { Component, Input, OnInit } from "@angular/core";
import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { IconType } from "./icon.enum";

@Component({
  selector: "itq-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent implements OnInit {
  @Input() iconName: IconName | string;
  @Input() iconType: IconType = IconType.FONT_AWESOME;
  @Input() iconSize: SizeProp | string;

  constructor() {}

  ngOnInit(): void {}
}
