import { FormGroup } from "@angular/forms";
import { NavigationHistoryItem } from "../../models/navigation-history-item.model";
import { ProfileConnection } from "../../models/profiles/profile-connection";
import { WidgetActions } from "../../enums/widget.enum";
import { Profile } from "../../models/widgets/profile";

export interface IProfileConnection {
  id?: string;
  typeId: string;
  typeName: string;
  originProfileId: string;
  originProfileName?: string;
  originProfileTypeName?: string;
  targetProfileId: string;
  targetProfileName?: string;
  targetProfileTypeName?: string;
  description?: string;
  updatedDate?: string;
  updatedBy?: string;
}

export interface IProfileConnectionsResults {
  items: Array<IProfileConnection>;
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface IConnectionsData {
  items: Array<ProfileConnection>;
  totalCount: number;
}

export interface IAddConnection {
  connection: ProfileConnection;
  profile: Profile;
  navigationItem: NavigationHistoryItem;
  form: FormGroup;
  action: WidgetActions;
}
