import { Pipe, PipeTransform } from "@angular/core";
import { Delays_DataSource } from "@intorqa-ui/shared";

@Pipe({
  name: "getPeriodLabel",
  standalone: true
})
export class GetPeriodLabelPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    return Delays_DataSource.find(
      (item: { name: string; value: number }) => item.value === value
    )?.name;
  }
}
