import { Pipe, PipeTransform } from "@angular/core";
import { Board } from "@intorqa-ui/shared";

@Pipe({
  name: "filterBoards",
})
export class FilterBoardsPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<Board>, selections: Array<Board>): Array<Board> {
    if (!selections || !data) return data;
    const ids = selections.map((item: Board) => item.id);
    return data?.filter((item: Board) => !ids.includes(item.id));
  }
}
