import { TemplateRef } from "@angular/core";
import { TableColumn, TagCategory } from "@intorqa-ui/core";
import { EChartsOption } from "echarts";
import { cloneDeep } from "lodash";
import { TagComparisonDisplayTypes } from "../../const/tag-comparison.const";
import { FilterTypes } from "../../const/tag.const";
import { DTOQueryFieldType } from "../../enums/query.enum";
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
  WidgetOption,
} from "../../enums/widget.enum";
import { ChartFactory } from "../../factories/chart";
import {
  IDisplayType,
  ISerie,
  ITagComparison,
} from "../../interfaces/widget/widget.interface";
import { Widget } from "./widget";

export class TagComparison extends Widget implements ITagComparison {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public options: object,
    public dataSource: Array<string>,
    public dataValues: Array<string>,
    public dataType: TagCategory,
    public chartType: ChartType,
    public ecosystemId: string
  ) {
    super(widgetId, username, type, name, description, chartType, ecosystemId);
  }

  public hasOptions(): boolean {
    return this.dataValues?.length > 0 &&
      this.dataType?.length > 0 &&
      this.chartType
      ? true
      : false;
  }

  public hasMetrics(): boolean {
    return this.dataValues?.length > 0 || this.dataType?.length > 0
      ? true
      : false;
  }

  public resetMetrics(): void {
    this.dataValues = undefined;
    this.dataSource = undefined;
    this.dataType = undefined;
  }

  public generateGetDocsState(params: { [key: string]: any }): any {
    return {
      pageSize: 30,
      page: params.initialState?.page || 1,
      where: params.initialState?.where,
      dataValue: params.dataValue,
    };
  }

  public getDataPointField(): DTOQueryFieldType {
    if (
      this.dataType === TagCategory.Channel ||
      this.dataType === TagCategory.Actor ||
      this.dataType === TagCategory.Source ||
      this.dataType === TagCategory["Post Type"] ||
      this.dataType === TagCategory.Community
    ) {
      return DTOQueryFieldType.filter;
    }
    return DTOQueryFieldType.tag;
  }

  public canLoadDocuments(action: WidgetActions, segment: any): boolean {
    return action === WidgetActions.EXPLORE && segment;
  }

  public transformData(series: Array<ISerie>): Array<ISerie> {
    return (series = series.filter((item: ISerie) => {
      return item.data.length > 0;
    }));
  }

  public getDataPointValue(segment: any): string {
    return segment?.data?.tagId;
  }

  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [
      {
        name: "Data point",
        dataKey: "category",
        isSortable: true,
        width: "fit",
      },
      {
        name: "Count",
        dataKey: "count",
        position: "right",
        width: "80px",
        customRender: true,
        template: template,
      },
    ];
  }

  public getSelectedType(): IDisplayType {
    if (TagComparisonDisplayTypes?.length > 0) {
      const widget = cloneDeep(this);
      widget.chartType = this.chartType;
      const chart = ChartFactory.createObject(widget);
      return chart.getSelectedType(TagComparisonDisplayTypes, this);
    }
  }

  public getDisplayTypes(filter: boolean): Array<IDisplayType> {
    if (filter) {
      const widget = cloneDeep(this);
      widget.chartType = this.chartType;
      const chart = ChartFactory.createObject(widget);
      return chart.getDisplayTypes(TagComparisonDisplayTypes, this);
    }

    return TagComparisonDisplayTypes;
  }

  public getRuleValue(): Array<string> {
    return [];
  }

  public getTagId(index: number): string {
    return this.dataValues[index];
  }

  public getTagIds(): { included: Array<string>; excluded: Array<string> } {
    let result = { included: [], excluded: [] };
    this.dataValues?.forEach((item: string) => {
      if (item.indexOf(":") === -1) {
        result.included.push(item);
      }
    });
    return result;
  }

  public getFieldFilterTagIds(): {
    included: Array<string>;
    excluded: Array<string>;
  } {
    let result = { included: [], excluded: [] };
    this.dataValues?.forEach((item: string) => {
      if (item.indexOf(":") > -1) {
        const splittedValue = item.split(":");
        if (FilterTypes.includes(splittedValue[2])) {
          result.included.push(item);
        }
      }
    });
    return result;
  }

  public getOptions(
    series: Array<ISerie>,
    action: WidgetActions,
    segment?: any
  ): EChartsOption {
    let options: EChartsOption;
    const chart = ChartFactory.createObject(this);
    const mode = this.getOption(WidgetOption.MODE);
    const xAxis = this.getOption(WidgetOption.X_AXIS);
    const yAxis = this.getOption(WidgetOption.Y_AXIS);
    const dataZoom = this.getOption(WidgetOption.DATA_ZOOM) || { show: true };
    const seriesOptions = this.getOption(WidgetOption.SERIES);
    const tooltip = this.getOption(WidgetOption.TOOLTIP);
    options = chart.getOptions(series, segment, {
      widgetAction: action,
      width: this.width,
      mode,
      xAxis,
      yAxis,
      dataZoom,
      series: seriesOptions,
      tooltip,
    });
    return options;
  }

  public getOption(option: WidgetOption): any {
    let result: any;
    if (this.options) {
      Object.keys(this.options)?.forEach((key: WidgetOption) => {
        if (key === option) {
          result = this.options[key];
        }
      });
    }
    return result;
  }

  public cloneDeep(): TagComparison {
    return new TagComparison(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.options,
      this.dataSource,
      this.dataValues,
      this.dataType,
      this.chartType,
      this.ecosystemId
    );
  }
}
