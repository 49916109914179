import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  Align,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  IQueryColumn,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TagCategory,
} from "@intorqa-ui/core";
import {
  DocumentItem,
  DocumentItemService,
  IAddConnection,
  IAddNote,
  IProfileData,
  ISegment,
  LinkTag,
  LinkTagsService,
  NavigationHistoryItem,
  NotificationsService,
  Profile,
  ProfileConnection,
  ProfileDrildownScope,
  ProfileDrilldown,
  ProfileService,
  ProfileType,
  SegmentScope,
  SlackService,
  Utils,
  WidgetActions,
  WidgetService,
} from "@intorqa-ui/shared";
import { KeycloakService } from "keycloak-angular";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { ShareEmailComponent } from "../share-email/share-email.component";

@Component({
  selector: "itq-document-item-tile",
  templateUrl: "./document-item-tile.component.html",
  styleUrls: ["./document-item-tile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentItemTileComponent implements OnInit {
  @Input() item: DocumentItem;
  @Input() initialState: QueryFilters;
  @Input() segment: ISegment;
  @Input() query: string | IQueryColumn[];
  @Input() allowDrilldown = true;
  @Input() showReplies = false;
  @Input() ecosystemId: string;
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() shareByEmail = true;
  @Input() navigationItem: NavigationHistoryItem;

  @Output() drilldown = new EventEmitter<ISegment>();
  @Output() createProfile = new EventEmitter<ProfileDrilldown>();
  @Output() addNote = new EventEmitter<IAddNote>();
  @Output() addConnection = new EventEmitter<IAddConnection>();

  public customOverlay: CustomOverlayRef;
  public repliesCount: number;
  public expanded = false;
  public showNestedNavbar = false;
  public showListProfile = false;
  public profileTypesDataSource: Array<ProfileType> = [];
  public profilesDataSource: Array<Profile>;
  public profileInitialState: QueryFilters;
  public isLoadingProfiles = false;

  readonly SegmentScope = SegmentScope;
  readonly ProfileDrildownScope = ProfileDrildownScope;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly TagCategory = TagCategory;

  @ViewChild("itemdetailcontainer", { static: true }) container: ElementRef;

  constructor(
    public customOverlayService: CustomOverlayService,
    private documentService: DocumentItemService,
    public widgetService: WidgetService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    private linkTagsService: LinkTagsService,
    private snackBar: MatSnackBar,
    public keycloakService: KeycloakService,
    readonly slackService: SlackService,
    readonly notificationService: NotificationsService,
    readonly _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.showReplies) {
      this.getRepliesCount();
    }
  }

  public getRepliesCount(): void {
    this.documentService.getRepliesCount(this.item).then((response: number) => {
      this.repliesCount = response;
      this.cdr.detectChanges();
    });
  }

  public onShareEmail(event: MouseEvent, document: DocumentItem): void {
    event.stopImmediatePropagation();

    if (this.shareByEmail) {
      this.customOverlayService.open({
        data: {
          item: document,
        },
        type: CustomOverlayType["slide-right"],
        component: ShareEmailComponent,
        disposeOnNavigation: true,
      });
    }
  }

  shareSlack(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.customOverlayService.openCustom(
      {
        title: "Share to slack?",
        message: `Are you sure you want to share the document to slack?`,
        icon: ["far", "question-circle"],
        size: "4x",
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          window.localStorage.setItem("document", JSON.stringify(this.item));
          const attachment = this.slackService.generateAttachment(this.item);
          this.notificationService
            .postSlackMessage(attachment)
            .subscribe(() => {
              this._snackBar.open("Document shared succssefully!", "Close", {
                horizontalPosition: "right",
                duration: 5000,
                verticalPosition: "top",
              });
            });
        }
      }
    );
  }

  public getDocumentDate(dateItem: any): any {
    if (typeof dateItem === "number") {
      return new Date(dateItem);
    } else if (typeof dateItem === "string") {
      return new Date(dateItem);
    } else if (dateItem) {
      return dateItem;
    } else {
      return null;
    }
  }

  onDrilldown(scope: SegmentScope): void {
    this.drilldown.emit({ scope, value: this.item });
  }

  public onCreateProfile(
    scope: ProfileDrildownScope,
    profileType: ProfileType
  ): void {
    this.createProfile.emit(
      new ProfileDrilldown(
        scope,
        profileType,
        scope === ProfileDrildownScope.ACTOR
          ? this.item.emitActor
          : this.item.emitChannel
      )
    );
  }

  public onGetProfileTypes(): void {
    this.profileService
      .getProfileTypes(this.ecosystemId)
      .subscribe((response: Array<ProfileType>) => {
        this.profileTypesDataSource = response;
      });
  }

  public onLinkProfile(profile: Profile, scope: ProfileDrildownScope): void {
    const link = new LinkTag(
      undefined,
      undefined,
      scope === ProfileDrildownScope.ACTOR
        ? TagCategory.Actor
        : TagCategory.Channel,
      true,
      scope === ProfileDrildownScope.ACTOR
        ? this.item.emitActor
        : this.item.emitChannel,
      undefined,
      undefined,
      undefined
    );
    this.linkTagsService.linkTags(profile.profileId, [link]).subscribe(() => {
      this.snackBar.open(
        `${
          scope === ProfileDrildownScope.ACTOR
            ? this.item.emitActor
            : this.item.emitChannel
        } has been linked tp profile ${profile.name}!`,
        "Close",
        {
          horizontalPosition: "right",
          duration: 5000,
          verticalPosition: "top",
        }
      );
      this.profilesDataSource = this.profilesDataSource.filter(
        (item: Profile) => {
          return item.profileId !== profile.profileId;
        }
      );
    });
  }

  public onProfileDataBound(params: QueryFilters): void {
    this.onGetProfiles(params).subscribe();
  }

  public onGetProfiles(params: QueryFilters): Observable<IProfileData> {
    if (typeof params.query === "string") {
      params.addQueryColumn({
        searchValues: [params.query],
        searchField: "name",
        searchFieldType: SearchFieldType.TEXT,
      });
    }
    return this.profileService.getProfiles(params, this.ecosystemId).pipe(
      map((response: IProfileData) => {
        this.profilesDataSource = response.items;
        return response;
      })
    );
  }

  public onMouseOverLinkProfile(scope: TagCategory): void {
    if (!this.isLoadingProfiles) {
      this.isLoadingProfiles = true;
      this.profileInitialState = new QueryFilters(30, 1, undefined, undefined, {
        direction: "desc",
        active: "updatedDate",
      });
      forkJoin([
        this.getSelections(scope),
        this.onGetProfiles(this.profileInitialState),
      ]).subscribe((response: Array<any>) => {
        this.profilesDataSource = this.profilesDataSource.filter(
          (item: Profile) => !response[0].includes(item.profileId)
        );
        this.showListProfile = true;
        this.showNestedNavbar = false;
        this.isLoadingProfiles = false;
      });
    }
  }

  private getSelections(scope: TagCategory): Observable<Array<string>> {
    return this.linkTagsService.getTargetLinks(
      undefined,
      scope === TagCategory.Actor ? this.item.emitActor : this.item.emitChannel,
      scope
    );
  }

  public onAddConnection(event: MouseEvent): void {
    event.stopImmediatePropagation();
    const params = {
      connection: new ProfileConnection(
        Utils.generateUUID(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ),
      profile: this.profile,
      navigationItem: this.navigationItem,
      form: this.form,
      action: WidgetActions.CREATE,
    };

    this.addConnection.emit(params);
  }

  public onAddNote(event: MouseEvent): void {
    event.stopImmediatePropagation();
    const params = {
      segment: {
        segment: SegmentScope.ARTICLE_DETAIL,
        value: this.item,
      },
      ecosystemId: this.ecosystemId,
      profile: this.profile,
    };

    this.addNote.emit(params);
  }
}
