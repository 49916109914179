import { AnalysisTypes, ChartType } from "../../enums/widget.enum";
import { IProfileList } from "../../interfaces/profiles/profile.interface";
import { IWidgetData } from "../../interfaces/widget/widget.interface";
import { ProfileMetadata } from "./profile-metadata";
import { Profile } from "../widgets/profile";

export class ProfileList extends Profile implements IProfileList {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public ecosystemId: string,
    public profileTypeId: string,
    public profileTypeName: string,
    public profileId: string,
    public metadata: Array<ProfileMetadata>,
    public activity: number,
    public trend: number,
    public activityData: IWidgetData,
    public updatedBy: string,
    public updatedDate: number
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      profileTypeId,
      profileTypeName,
      profileId,
      metadata,
      trend,
      activity,
      updatedBy,
      updatedDate
    );
  }
}
