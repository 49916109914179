import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { Utils } from "@intorqa-ui/shared";

@Pipe({
  name: "formatDates",
})
export class FormatDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(data: Array<any>): Array<any> {
    return data?.map((item: any) => {
      return {
        ...item,
        ...{
          createdDate: this.datePipe.transform(item.createdDate, "yyyy MMM dd"),
          updatedDate: this.datePipe.transform(item.updatedDate, "yyyy MMM dd"),
        },
      };
    });
  }
}

@Pipe({
  name: "highlightSearch",
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: string): string {
    if (!args) {
      return value;
    }
    if (!value) {
      return value;
    }
    let searchTerm = args
      .replaceAll('"', " ")
      .replaceAll("|", " ")
      .replaceAll("*", " ")
      .replaceAll("+", " ")
      .replaceAll("(", " ")
      .replaceAll(")", " ")
      .replaceAll("-", " ");

    let regex = new RegExp("~[1-9]", "gi");
    searchTerm = searchTerm.replaceAll(regex, " ");

    const splittedSearchTerm = searchTerm.split(" ");
    if (splittedSearchTerm.length > 0) {
      let uniqueSearchTerms = splittedSearchTerm
        .filter(Utils.unique)
        .filter(Utils.empty);
      uniqueSearchTerms = uniqueSearchTerms.map(
        (item: string) => "\\b" + item + "\\b"
      );
      const pattern = new RegExp(`(${uniqueSearchTerms.join("|")})`, "gi");

      return value?.replace(
        pattern,
        (match) => `<span class='highlight'>${match}</span>`
      );
    }

    return value;
  }
}
