import { Injectable } from "@angular/core";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import {
  Category,
  DateQueryType,
  ICategory,
  IError,
  IQueryColumn,
  QueryFilters,
  TagCategory,
  TagCategoryId,
} from "@intorqa-ui/core";
import { FilterTypes } from "../const/tag.const";
import { DTOQuery } from "../interfaces/query-dtos";
import { ICustomTag, IExtTag, ITagResults } from "../interfaces/tags-dtos";
import { IContext } from "../interfaces/document-dtos";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  public categories: Array<Category> = [];

  constructor(private apiRequestService: ApiRequestService) {}

  public getCategories(ecosystemId: string): Promise<Array<Category>> {
    return new Promise((resolve) => {
      this.categories = [];
      let queryParams = "";
      if (ecosystemId) {
        queryParams = `?ecosystemId=${ecosystemId}`;
      }
      this.apiRequestService
        .get(
          `/tags/categories${queryParams}`,
          {},
          new DTOTypeConverter<Array<ICategory>>(),
          undefined,
          "v2.0"
        )
        .then((response: Array<ICategory>) => {
          this.categories = [];
          response?.forEach((item: ICategory) =>
            this.categories.push(
              new Category(
                item.id,
                item.name,
                item.description,
                item.section,
                item.order,
                item.ecosystemId
              )
            )
          );
          this.categories = this.categories.sort((a: Category, b: Category) =>
            a.order < b.order ? -1 : 1
          );
          resolve(this.categories);
        });
    });
  }

  public update(category: Category): Promise<void> {
    return new Promise((resolve) => {
      this.apiRequestService
        .put(
          `/tags/categories/${category?.id}`,
          new DTOTypeConverter<ICategory>(),
          JSON.stringify(category),
          undefined,
          "v1.0"
        )
        .then((response: ICategory) => {
          this.categories = this.categories.map((item: Category) => {
            if (item.id === response.id) {
              return new Category(
                response.id,
                response.name,
                response.description,
                response.section,
                response.order,
                response.ecosystemId
              );
            } else {
              return item;
            }
          });
          resolve();
        });
    });
  }

  public save(category: Category): Promise<void> {
    return new Promise((resolve) => {
      this.apiRequestService
        .post(
          "/tags/categories",
          new DTOTypeConverter<ICategory>(),
          JSON.stringify(category),
          undefined,
          "v1.0"
        )
        .then((response: ICategory) => {
          this.categories.push(
            new Category(
              response.id,
              response.name,
              response.description,
              response.section,
              response.order,
              response.ecosystemId
            )
          );
          resolve();
        });
    });
  }

  public delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .delete(`/tags/categories/${id}`, undefined, "v1.0")
        .then(() => {
          this.categories = this.categories.filter(
            (item: Category) => item.id !== id
          );
          resolve();
        })
        .catch((error: IError) => reject(error));
    });
  }

  public findCategoryByName(name: string): Category {
    return this.categories.find((category: Category) => category.name === name);
  }

  public findCategoryById(id: string): Category {
    return this.categories.find((category: Category) => category.id === id);
  }

  public getSections(): Array<string> {
    return [...new Set(this.categories.map((item: Category) => item.section))];
  }

  public getCategoriesBySection(section: string): Array<Category> {
    return this.categories.filter((item: Category) => item.section === section);
  }

  public getTags(
    query: string | Array<IQueryColumn>,
    initialState: QueryFilters,
    queryModel: DTOQuery,
    categoryName: TagCategory,
    ecosystemId: string,
    after: string,
    context?: IContext
  ): Promise<Array<ICustomTag>> {
    return new Promise((resolve) => {
      let pageQuery = `page=${initialState.page}`;
      pageQuery += `&pageSize=${initialState.pageSize}`;
      if (ecosystemId) {
        pageQuery += `&ecosystemId=${ecosystemId}`;
      }
      if (initialState.page > 1 && after) {
        pageQuery += `&after=${encodeURIComponent(after)}`;
      }

      if (initialState?.where) {
        pageQuery += `&dateFrom=${initialState.where?.start}`;
        if (initialState.where?.label === DateQueryType.Custom) {
          pageQuery += `&dateTo=${initialState.where?.end}`;
        }
      }
      if (query) {
        pageQuery += `&prefix=${query}`;
      }

      if (context?.document) {
        pageQuery += `&context=${context.document.id}`;
      }
      this.apiRequestService
        .post(
          `/tags/categories/${categoryName}/filters?${pageQuery}`,
          new DTOTypeConverter<any>(),
          JSON.stringify(queryModel),
          undefined,
          "v2.0"
        )
        .then((response: ITagResults) => {
          const result = response.items.map((tag: IExtTag) => ({
            name: !tag.value || tag.value === "" ? "[EMPTY]" : tag.value,
            id: FilterTypes.includes(categoryName)
              ? `Field Filter:${tag.value}:${TagCategoryId[categoryName]}`
              : tag.id,
            type: FilterTypes.includes(categoryName)
              ? "FieldFilterTag"
              : "UserTag",
            count: tag.count,
          }));
          resolve(result);
        });
    });
  }

  public filterCategories(categories: Array<TagCategory>): Array<Category> {
    const results = this.categories.filter((item: Category) => {
      return categories.includes(item.name) === false ? true : false;
    });
    return results;
  }
}
