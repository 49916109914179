import {
  BarMode,
  ChartOrientation,
  ChartType,
  LineMode,
} from "../enums/widget.enum";
import { IDisplayType } from "../interfaces/widget/widget.interface";

export const TimeSeriesDisplayTypes: Array<IDisplayType> = [
  {
    id: "StackedVerticalBar",
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
      mode: BarMode.STACK,
    },
    svgIcon: "stacked-vertical-bar",
    tooltip: "Stacked Bar",
  },
  {
    id: "GroupedVerticalBar",
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
      mode: BarMode.GROUP,
    },
    svgIcon: "grouped-vertical-bar",
    tooltip: "Grouped Bar",
  },
  {
    id: "Area",
    type: ChartType.LINE,
    options: {
      mode: LineMode.AREA,
    },
    svgIcon: "area",
    tooltip: "Area",
  },
  {
    id: "Line",
    type: ChartType.LINE,
    options: {
      mode: LineMode.LINE,
    },
    svgIcon: "line",
    tooltip: "Line",
  },
];
