import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";

import { ApiConfig } from "./interfaces/api-config.interface";
import { ApiConfigService } from "./services/api-config-service.service";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [],
})
export class ApiModule {
  static forRoot(config: ApiConfig): ModuleWithProviders<any> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfigService,
          useValue: config,
        },
      ],
    };
  }
}
