import { Pipe, PipeTransform } from "@angular/core";
import { DateQueryType } from "../../date-range.enum";
import { IPreset } from "../../date-range.interface";
import { DateRange } from "../../date-range.model";

@Pipe({
  name: "getSelected",
})
export class GetSelectedPipe implements PipeTransform {
  transform(item: DateQueryType, dateRange: IPreset): boolean {
    if (!dateRange) {
      return item === DateQueryType.Custom ? true : false;
    }
    return item === dateRange.label ? true : false;
  }
}
