import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
// Components
import { ContainerComponent } from "./components/container/container.component";
import { CustomOverlayComponent } from "./components/custom-overlay/custom-overlay.component";
import { EditableLabelComponent } from "./components/editable-label/editable-label.component";
import { FoamtreeComponent } from "./components/foamtree/foamtree.component";
import { FormElementComponent } from "./components/form/form-element/form-element.component";
import { FormRowComponent } from "./components/form/form-row/form-row.component";
import { FormComponent } from "./components/form/form.component";
import { HeadLineSimpleComponent } from "./components/headline-simple/headline-simple.component";
import { HorizontalScrollerComponent } from "./components/horizontal-scroller/horizontal-scroller.component";
import { InputComponent } from "./components/input/input.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { MatButtonComponent } from "./components/mat-button/mat-button.component";
import { ModalBodyComponent } from "./components/modal/modal-body/modal-body.component";
import { ModalFooterComponent } from "./components/modal/modal-footer/modal-footer.component";
import { ModalHeaderComponent } from "./components/modal/modal-header/modal-header.component";
import { NavButtonComponent } from "./components/nav-button/nav-button.component";
import { PanelInfoComponent } from "./components/panel-info/panel-info.component";
import { PillComponent } from "./components/pill/pill.component";
import { SimpleInfoPanelComponent } from "./components/simple-info-panel/simple-info-panel.component";
import { TableComponent } from "./components/table/table.component";
import {
  DataPropertyGetterPipe,
  FilterByQueryPipe,
} from "./components/table/table.pipe";
import { ToolbarActionComponent } from "./components/toolbar/toolbar-action/toolbar-action.component";
import { UserAccountComponent } from "./components/user-account/user-account.component";
import { VirtualTableComponent } from "./components/virtual-table/virtual-table.component";

import { DialogComponent } from "./components/dialog/dialog.component";
import { ErrorComponent } from "./components/error/error.component";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { AngularModule } from "./modules/angular.module";
import { FAwesomeModule } from "./modules/font-awesome.module";
import { MaterialModule } from "./modules/material.module";

import { RouterModule } from "@angular/router";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { ButtonThumbnailComponent } from "./components/button-thumbnail/button-thumbnail.component";
import { ButtonToggleComponent } from "./components/button-toggle/button-toggle.component";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { DateRangeHeaderComponent } from "./components/date-range/components/date-range-header/date-range-header.component";
import { DateRangePresetsComponent } from "./components/date-range/components/date-range-presets/date-range-presets.component";
import { GetSelectedPipe } from "./components/date-range/components/date-range-presets/date-range-presets.pipe";
import { DateRangeComponent } from "./components/date-range/date-range.component";
import { DropdownItemComponent } from "./components/dropdown/dropdown-item/dropdown-item.component";
import { DropdownResultsComponent } from "./components/dropdown/dropdown-results/dropdown-results.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import {
  GetLabelPipe,
  SetActiveItemClassPipe,
} from "./components/dropdown/dropdown.pipe";
import { HelpComponent } from "./components/help/help.component";
import { IconComponent } from "./components/icon/icon.component";
import {
  CastIconNamePipe,
  CastIconSizePipe,
} from "./components/icon/icon.pipe";
import { InputToggleComponent } from "./components/input-toggle/input-toggle.component";
import { ListComponent } from "./components/list/list.component";
import { MessageComponent } from "./components/message/message.component";
import { MultipleDropdownItemComponent } from "./components/multiple-dropdown/multiple-dropdown-item/multiple-dropdown-item.component";
import { MultipleDropdownResultsComponent } from "./components/multiple-dropdown/multiple-dropdown-results/multiple-dropdown-results.component";
import { IsSelectionCheckedPipe } from "./components/multiple-dropdown/multiple-dropdown-results/multiple-dropdown-results.pipe";
import { MultipleDropdownComponent } from "./components/multiple-dropdown/multiple-dropdown.component";
import { GetHiddenSelectionsPipe } from "./components/multiple-dropdown/multiple-dropdown.pipe";
import { PanelActionComponent } from "./components/panel-action/panel-action.component";
import { SelectionListComponent } from "./components/selection-list/selection-list.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TagsListComponent } from "./components/tags-list/tags-list.component";
import { FindActionPipe } from "./components/toolbar/toolbar.pipe";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { YoutubePlayerComponent } from "./components/youtube-player/youtube-player.component";
import { AutofocusDirective } from "./directives/auto-focus.directive";
import { DynamicPlaceholderDirective } from "./directives/dynamic-placeholder.directive";
import { GetChildrenPipe } from "./pipes/accordion";
import { GetSectionsPipe } from "./pipes/category";
import { ConvertToUDSPipe } from "./pipes/exchange-rate.pipe";
import { FilterQueryPipe } from "./pipes/list-item";
import {
  ConvertFromEpochMillisPipe,
  IsArrayPipe,
  JsonParsePipe,
} from "./pipes/shared.pipe";
import { IsTagEnabledPipe } from "./pipes/tag";
import { GetWidgetTooltipPipe, IsWidgetDisabledPipe } from "./pipes/widget";

@NgModule({
  imports: [
    MaterialModule,
    FAwesomeModule,
    AngularModule,
    RouterModule,
    IconSpriteModule,
    YouTubePlayerModule,
  ],
  declarations: [
    DialogComponent,
    EditableLabelComponent,
    HorizontalScrollerComponent,
    CustomOverlayComponent,
    FormComponent,
    FormRowComponent,
    FormElementComponent,
    DropdownComponent,
    DropdownItemComponent,
    DropdownResultsComponent,
    PanelActionComponent,
    PanelInfoComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    HeadLineSimpleComponent,
    LoaderComponent,
    CastIconNamePipe,
    InputComponent,
    PillComponent,
    MatButtonComponent,
    TableComponent,
    SimpleInfoPanelComponent,
    ContainerComponent,
    VirtualTableComponent,
    FoamtreeComponent,
    UserAccountComponent,
    NavButtonComponent,
    DynamicPlaceholderDirective,
    AutofocusDirective,
    DataPropertyGetterPipe,
    ErrorComponent,
    ToolbarComponent,
    ToolbarActionComponent,
    DateRangeComponent,
    IconComponent,
    MultipleDropdownComponent,
    MultipleDropdownResultsComponent,
    MultipleDropdownItemComponent,
    MessageComponent,
    SidebarComponent,
    TagsListComponent,
    ButtonThumbnailComponent,
    CastIconSizePipe,
    SetActiveItemClassPipe,
    DateRangePresetsComponent,
    DateRangeHeaderComponent,
    GetSelectedPipe,
    FindActionPipe,
    HelpComponent,
    FilterQueryPipe,
    ListComponent,
    SelectionListComponent,
    GetChildrenPipe,
    IsWidgetDisabledPipe,
    IsTagEnabledPipe,
    GetWidgetTooltipPipe,
    FilterByQueryPipe,
    GetSectionsPipe,
    InputToggleComponent,
    ButtonToggleComponent,
    IsSelectionCheckedPipe,
    GetHiddenSelectionsPipe,
    TooltipComponent,
    DatePickerComponent,
    GetLabelPipe,
    IsArrayPipe,
    JsonParsePipe,
    ConvertFromEpochMillisPipe,
    ConvertToUDSPipe,
    YoutubePlayerComponent,
  ],
  exports: [
    EditableLabelComponent,
    HorizontalScrollerComponent,
    FormComponent,
    FormRowComponent,
    FormElementComponent,
    DropdownComponent,
    DropdownResultsComponent,
    DropdownItemComponent,
    PanelInfoComponent,
    CastIconNamePipe,
    PanelActionComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    HeadLineSimpleComponent,
    CustomOverlayComponent,
    LoaderComponent,
    InputComponent,
    PillComponent,
    MatButtonComponent,
    TableComponent,
    SimpleInfoPanelComponent,
    ContainerComponent,
    VirtualTableComponent,
    FoamtreeComponent,
    UserAccountComponent,
    NavButtonComponent,
    DynamicPlaceholderDirective,
    AutofocusDirective,
    ErrorComponent,
    ToolbarComponent,
    ToolbarActionComponent,
    DateRangeComponent,
    IconComponent,
    MultipleDropdownComponent,
    MultipleDropdownResultsComponent,
    MultipleDropdownItemComponent,
    MessageComponent,
    SidebarComponent,
    TagsListComponent,
    ButtonThumbnailComponent,
    CastIconSizePipe,
    DateRangePresetsComponent,
    GetSelectedPipe,
    DateRangeHeaderComponent,
    FindActionPipe,
    HelpComponent,
    FilterQueryPipe,
    ListComponent,
    SelectionListComponent,
    GetChildrenPipe,
    IsWidgetDisabledPipe,
    IsTagEnabledPipe,
    GetWidgetTooltipPipe,
    FilterByQueryPipe,
    GetSectionsPipe,
    InputToggleComponent,
    ButtonToggleComponent,
    IsSelectionCheckedPipe,
    TooltipComponent,
    DatePickerComponent,
    IsArrayPipe,
    JsonParsePipe,
    ConvertFromEpochMillisPipe,
    ConvertToUDSPipe,
    YoutubePlayerComponent,
  ],
  providers: [
    FindActionPipe,
    IsTagEnabledPipe,
    GetHiddenSelectionsPipe,
    ConvertToUDSPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor() {}
}
