import { AnalysisTypes, ChartType } from "../../enums/widget.enum";
import { Widget } from "./widget";

export class NotificationWidget extends Widget {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public ecosystemId: string
  ) {
    super(widgetId, username, type, name, description, chartType, ecosystemId);
  }
}
