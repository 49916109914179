import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavLink } from "./default-navigation.model";
import { Subscription } from "rxjs";
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  HelpComponent,
  IconType,
  QueryFilters,
  SharedService,
  Sizes,
} from "@intorqa-ui/core";
import { NotificationsService } from "@intorqa-ui/shared";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: "itq-default-navigation",
  templateUrl: "./default-navigation.component.html",
  styleUrls: ["./default-navigation.component.scss"],
  standalone: true,
  imports: [
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    CommonModule,
    RouterModule,
    IconSpriteModule,
  ],
})
export class DefaultNavigationComponent implements OnInit {
  @Input() dataSource: Array<NavLink> = [];
  @Input() showNotifications: boolean;

  @Output() loadNotifications = new EventEmitter<MouseEvent>();
  @Output() closeNotifications = new EventEmitter<void>();

  readonly Sizes = Sizes;
  readonly IconType = IconType;

  public unreadCount: number;
  private subscriptions = new Subscription();

  constructor(
    public sharedService: SharedService,
    private customOverlayService: CustomOverlayService,
    readonly notificationsService: NotificationsService,
    readonly keycloakService: KeycloakService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount = response;
        }
      )
    );
    if (this.keycloakService.isUserInRole("saas-alerts")) {
      // this.notificationsService
      //   .getUnreadNotificationsCount(
      //     new QueryFilters(30, 1, undefined, undefined, undefined)
      //   )
      //   .subscribe((response: number) => {
      //     this.unreadCount = response;
      //   });
    }
  }

  ngOnDestroy(): void {
    console.log("destroyed");
    this.subscriptions.unsubscribe();
  }

  public onLoadHelp(): void {
    this.customOverlayService.open({
      closeBtnStyle: "basic",
      closeBtnClass: "help_close__btn",
      type: CustomOverlayType["almost-full"],
      size: "lg",
      component: HelpComponent,
      disposeOnNavigation: true,
    });
  }

  public isMobileDevice(): boolean {
    return this.sharedService.mobile || this.sharedService.tablet;
  }

  public onLoadNotifications(event: MouseEvent): void {
    this.loadNotifications.emit(event);
  }

  public onNavigationItemClick(): void {
    this.closeNotifications.next();
  }
}
