import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";

import { FormGroup } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  QueryFilters,
  Sizes,
} from "@intorqa-ui/core";
import {
  AlertTypesIcons,
  TagMatchNotification,
  IAddNote,
  ISegment,
  NotificationsService,
  PriorityColor,
  PriorityLabel,
  Profile,
  ProfileDrilldown,
  ProfileNote,
  ProfilesNavigationItem,
  WidgetActions,
} from "@intorqa-ui/shared";
import { PostNotesWizardComponent } from "../../shared/components/post-notes-wizard/post-notes-wizard.component";
import { SharedModule } from "../../shared/shared.module";
import { WidgetSettingsComponent } from "../../widget-settings/widget-settings.component";
import { Subscription } from 'rxjs';

@Component({
  selector: "itq-notifications-tag-match",
  templateUrl: "./notifications-tag-match.component.html",
  styleUrls: ["./notifications-tag-match.component.scss"],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
  ],
})
export class NotificationsTagMatchComponent implements OnInit {
  @Input() notification: TagMatchNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  @Output() drilldown = new EventEmitter<{
    segment?: ISegment;
    notification: TagMatchNotification;
  }>();

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;

  private markAsReadSubscription: Subscription;

  @HostBinding("style.borderRight") borderRight: string;
  @HostBinding("class") class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService
  ) {}

  ngOnInit() {
    this.markAsReadSubscription = this.notificationService.markAsRead$.subscribe(() => {
      this.class = '';
    })
    this.class = !this.notification.read ? "unread" : "";
    this.borderRight =
      "10px solid " + PriorityColor[this.notification.priority];
  }

  ngOnDestroy(): void {
    this.markAsReadSubscription?.unsubscribe();
  }

  public onViewOtherMatches(): void {
    this.notificationService.loadOtherMatches$.next({
      raisedAlertId: this.notification.raisedAlertId,
      filters: undefined,
    });
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: TagMatchNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? "unread" : "";
      });
  }

  public onAddNote(params: IAddNote): void {
    let customOverlay: CustomOverlayRef;
    customOverlay = this.customOverlayService.open({
      data: params,
      size: "lg",
      type: CustomOverlayType["slide-right"],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe(
      (response: { refresh: boolean; profile: Profile; note: ProfileNote }) => {
        if (response?.profile && response.note) {
          const navigationItem = new ProfilesNavigationItem(
            `${WidgetActions.CREATE}_addnote_profiles`,
            response.profile,
            WidgetActions.CREATE,
            undefined,
            undefined,
            this.form,
            "plus",
            IconType.FONT_AWESOME,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [response.note],
            0
          );
          customOverlay = this.customOverlayService.open({
            data: {
              navigationItem,
            },
            closeBtnStyle: "basic",
            type: CustomOverlayType["almost-full"],
            size: "md",
            component: WidgetSettingsComponent,
            disposeOnNavigation: true,
          });
        }
      }
    );
  }

  public onDrilldown(segment?: ISegment): void {
    this.onToggleRead();
    this.drilldown.emit({ segment, notification: this.notification });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    this.notificationService.createProfile$.next({
      profileDrilldown,
      notification: this.notification,
    });
  }
}
