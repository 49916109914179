import { TemplateRef } from "@angular/core";
import { IsTagEnabledPipe, TableColumn } from "@intorqa-ui/core";
import { cloneDeep } from "lodash";
import { TimelineFeedDisplayTypes } from "../../const/timeline-feed.const";
import { AnalysisTypes, ChartType } from "../../enums/widget.enum";
import { ChartFactory } from "../../factories/chart";
import { ITimeline } from "../../interfaces/tags-dtos";
import { IDisplayType } from "../../interfaces/widget/widget.interface";
import { Widget } from "./widget";

export class Timeline extends Widget implements ITimeline {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public tagId: string,
    public createdDate: number,
    public sharedTag: boolean,
    public _extras: any,
    public categoryId: string,
    public lastTaggingTime: number,
    public ecosystemId: string,
    public updatedDate: number,
    public alertTypeId: string
  ) {
    super(widgetId, username, type, name, description, chartType, ecosystemId);
  }

  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [
      {
        name: undefined,
        dataKey: "result",
        isSortable: true,
        customRender: true,
        template,
      },
    ];
  }

  public getDisplayTypes(filter: boolean): Array<IDisplayType> {
    if (filter) {
      const widget = cloneDeep(this);
      widget.chartType = this.chartType;
      const chart = ChartFactory.createObject(widget);
      return chart.getDisplayTypes(TimelineFeedDisplayTypes, this);
    }

    return TimelineFeedDisplayTypes;
  }

  public getSelectedType(): IDisplayType {
    if (TimelineFeedDisplayTypes?.length > 0) {
      const widget = cloneDeep(this);
      widget.chartType = this.chartType;
      const chart = ChartFactory.createObject(widget);
      return chart.getSelectedType(TimelineFeedDisplayTypes, this);
    }
  }

  public getRuleValue(): Array<string> {
    return [this.tagId || this.widgetId];
  }

  public isEnabled(isTagEnabledPipe: IsTagEnabledPipe): boolean {
    return isTagEnabledPipe.transform(this);
  }
}
