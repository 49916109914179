import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ListItem, QueryFilters } from "@intorqa-ui/core";
import { Subscription } from "rxjs";
import { VirtualScrollService } from "./../../services/virtual-scrolling.service";

@Component({
  selector: "itq-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit, OnDestroy {
  private scrollTop = 0;
  public query: string;
  private dataBoundSubscription: Subscription;
  private scrollSubscription: Subscription;

  @Input() label: string;
  @Input() dataSource: Array<ListItem>;
  @Input() template: TemplateRef<unknown>;
  @Input() allowDrag = false;
  @Input() initialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    undefined
  );

  @Output() dataBound = new EventEmitter<void>();
  @Output() add = new EventEmitter<string>();

  @ViewChild("selectionsList") selectionsList: any;

  constructor(private virtualScrollService: VirtualScrollService) {}

  ngOnInit(): void {
    this.dataBoundSubscription =
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.selectionsList?._element?.nativeElement?.scrollTo(0, 0);
      });
  }

  ngOnDestroy(): void {
    this.dataBoundSubscription.unsubscribe();
    this.scrollSubscription?.unsubscribe();
  }

  private onDataBound(): void {
    this.dataBound.emit();
  }

  public onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.selectionsList._element.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          undefined,
          this.initialState.page
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.onDataBound();
          }
        });
    }
  }

  public onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
  }
}
