import { Pipe, PipeTransform } from "@angular/core";
import { Timeline } from "@intorqa-ui/shared";
import { IsTagEnabledPipe } from "./tag";

@Pipe({
  name: "isWidgetDisabled",
})
export class IsWidgetDisabledPipe implements PipeTransform {
  constructor(private isTagEnabledPipe: IsTagEnabledPipe) {}

  transform(widget: Timeline): boolean {
    return !widget?.isEnabled(this.isTagEnabledPipe);
  }
}

@Pipe({
  name: "getWidgetTooltip",
})
export class GetWidgetTooltipPipe implements PipeTransform {
  constructor(private isTagEnabledPipe: IsTagEnabledPipe) {}

  transform(widget: Timeline): string {
    return !widget?.isEnabled(this.isTagEnabledPipe)
      ? "You don't have permissions to edit this widget"
      : undefined;
  }
}
