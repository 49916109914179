import { Injectable } from "@angular/core";
import {
  ApiRequestService,
  DTOCreation,
  DTOTypeConverter,
} from "@intorqa-ui/api";
import { DateQueryType, QueryFilters } from "@intorqa-ui/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AlertTypes } from "../enums/alerts.enum";
import { IMessage, ISearchResults } from "../interfaces/document-dtos";
import {
  IHubNotificationsResults,
  INotification,
  IResearchNotification,
  ITagMatchNotification,
  IThresholdHubNotification,
  ITransactionalNotification,
} from "../interfaces/notification.interface";
import {
  Notification,
  ResearchNotification,
  SlackNotification,
  TagMatchNotification,
  ThresholdNotification,
  TransactionalNotification,
} from "../models/notifications/notifications";
import { ProfileDrilldown } from "../models/profiles/profile-drilldown";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  public getUnreadCount$ = new Subject<number>();
  public openNotifications$ = new Subject<string>();
  public openResearchNotifications$ = new Subject<string>();
  public markAsRead$ = new Subject<void>();
  public hideNotifications$ = new Subject<void>();
  public loader$ = new Subject<boolean>();
  public loadNotificationDetail$ = new Subject<ResearchNotification>();

  public createProfile$ = new Subject<{
    profileDrilldown: ProfileDrilldown;
    notification: Notification;
  }>();
  public loadOtherMatches$ = new Subject<{
    filters?: QueryFilters;
    raisedAlertId?: string;
  }>();
  public loadNotifications$ = new Subject<{
    items: Notification[];
    totalCount: number;
  }>();
  private _notifications: Notification[] = [];

  public get notifications(): Notification[] {
    return this._notifications;
  }

  public set notifications(v: Notification[]) {
    this._notifications = v;
  }
  private _unreadCount: number;

  public get unreadCount(): number {
    return this._unreadCount;
  }

  public set unreadCount(v: number) {
    this._unreadCount = v;
  }

  constructor(readonly apiRequestService: ApiRequestService) {}

  public postSlackMessage(attachment: string): Observable<SlackNotification> {
    return this.apiRequestService
      .postToObservable(
        "/notifications/slack/message",
        new DTOTypeConverter<{
          attachment: string;
        }>(),
        { attachments: attachment }
      )
      .pipe(
        map((response: { attachment: string }) => {
          return new SlackNotification(response.attachment);
        })
      );
  }

  public shareEmail(message: IMessage): Promise<any> {
    return this.apiRequestService.post(
      "/notifications/email",
      new DTOTypeConverter<DTOCreation>(),
      JSON.stringify(message)
    );
  }

  public markAllAsRead(): Observable<void> {
    return this.apiRequestService
      .putToObservable("/notifications/hub/readAll")
      .pipe(
        map(() => {
          this.notifications = this.notifications.map(
            (
              item:
                | TagMatchNotification
                | ThresholdNotification
                | ResearchNotification
                | TransactionalNotification
            ) => {
              item.read = true;
              return item;
            }
          );
          this.unreadCount = 0;
          this.markAsRead$.next();
          this.getUnreadCount$.next(this.unreadCount);
        })
      );
  }

  /**
   * Retrieves notifications based on the specified parameters.
   *
   * @param params - The query filters for pagination and search.
   * @param read - Optional. Specifies if the notifications should be read or unread.
   * @param priority - Optional. Specifies if the notifications should have priority.
   * @param alertTypeId - Optional. The ID of the alert type.
   * @returns An Observable containing the notifications and the total count.
   */
  public getNotifications(
    params: QueryFilters,
    read?: boolean,
    priority?: boolean,
    alertTypeId?: string
  ): Observable<{
    items: Notification[];
    totalCount: number;
  }> {
    this.loader$.next(true);
    const pageQuery = this.buildPageQuery(params, read, priority, alertTypeId);
    return this.apiRequestService
      .getToObservable(
        `/notifications/hub?${pageQuery}`,
        new DTOTypeConverter<IHubNotificationsResults>()
      )
      .pipe(
        map((response: IHubNotificationsResults) => {
          const notifications = this.mapNotifications(response.items);
          this.updateNotifications(params.page, notifications);
          this.loadNotifications$.next({
            items: this.notifications,
            totalCount: response.totalCount,
          });
          this.loader$.next(false);
          return { items: this.notifications, totalCount: response.totalCount };
        })
      );
  }

  private buildPageQuery(
    params: QueryFilters,
    read?: boolean,
    priority?: boolean,
    alertTypeId?: string
  ): string {
    let pageQuery = `page=${params.page}&pageSize=${params.pageSize}`;
    if (params.query) {
      pageQuery += `&query=${params.query}`;
    }
    if (read !== undefined) {
      pageQuery += `&read=${read}`;
    }
    if (priority) {
      pageQuery += `&priority=${priority}`;
    }
    if (alertTypeId) {
      pageQuery += `&alertTypeId=${alertTypeId}`;
    }
    if (params.where) {
      pageQuery += `&dateFrom=${params.where.start}`;
      if (params.where.label === DateQueryType.Custom) {
        pageQuery += `&dateTo=${params.where.end}`;
      }
    }
    return pageQuery;
  }

  private mapNotifications(items: INotification[]): Notification[] {
    return items.map((item: INotification) => {
      if (item.alertTypeName === AlertTypes.TAG_MATCH) {
        const tagMatchNotification = item as ITagMatchNotification;
        return new TagMatchNotification(
          tagMatchNotification.raisedAlertId,
          tagMatchNotification.createdDate,
          tagMatchNotification.alertTypeName,
          tagMatchNotification.tagName,
          tagMatchNotification.priority,
          tagMatchNotification.message,
          tagMatchNotification.read,
          tagMatchNotification.ecosystemId,
          tagMatchNotification.document,
          tagMatchNotification.matches,
          tagMatchNotification.tagEdited
        );
      } else if (item.alertTypeName === AlertTypes.RESEARCH) {
        const researchNotification = item as IResearchNotification;
        return new ResearchNotification(
          researchNotification.raisedAlertId,
          researchNotification.createdDate,
          researchNotification.alertTypeName,
          researchNotification.priority,
          researchNotification.message,
          researchNotification.read,
          researchNotification.ecosystemId,
          researchNotification.headline,
          researchNotification.rawMessage
        );
      } else if (item.alertTypeName === AlertTypes.SYSTEM) {
        const researchNotification = item as ITransactionalNotification;
        return new TransactionalNotification(
          researchNotification.raisedAlertId,
          researchNotification.createdDate,
          researchNotification.alertTypeName,
          researchNotification.message,
          researchNotification.read,
          researchNotification.ecosystemId,
          researchNotification.headline,
          researchNotification.triggerMetadata
        );
      } else {
        const thresholdNotification = item as IThresholdHubNotification;
        return new ThresholdNotification(
          thresholdNotification.raisedAlertId,
          thresholdNotification.createdDate,
          thresholdNotification.alertTypeName,
          thresholdNotification.tagName,
          thresholdNotification.priority,
          thresholdNotification.message,
          thresholdNotification.read,
          thresholdNotification.ecosystemId,
          thresholdNotification.period,
          thresholdNotification.condition,
          thresholdNotification.count,
          thresholdNotification.tagId,
          thresholdNotification.tagEdited
        );
      }
    });
  }

  private updateNotifications(
    page: number,
    notifications: Notification[]
  ): void {
    if (page === 1) {
      this.notifications = notifications;
    } else {
      this.notifications = [...this.notifications, ...notifications];
    }
  }

  public findNotificationBy(id: string): Notification {
    return this.notifications.find(
      (item: Notification) => item.raisedAlertId === id
    );
  }

  public getUnreadNotificationsCount(
    params: QueryFilters,
    priority?: boolean
  ): Observable<number> {
    let pageQuery = "";
    if (params?.query) {
      pageQuery += `query=${params.query}`;
    }
    if (priority) {
      pageQuery += (pageQuery ? "&" : "") + `priority=${priority}`;
    }
    if (params?.where) {
      pageQuery += (pageQuery ? "&" : "") + `dateFrom=${params.where?.start}`;
      if (params.where?.label === DateQueryType.Custom) {
        pageQuery += `&dateTo=${params.where?.end}`;
      }
    }
    const query = pageQuery ? `?${pageQuery}` : "";
    return this.apiRequestService
      .getToObservable(
        `/notifications/hub/unreadcount${query}`,
        new DTOTypeConverter<number>()
      )
      .pipe(
        map((response: number) => {
          this.unreadCount = response;
          this.getUnreadCount$.next(response);
          return response;
        })
      );
  }

  public getMatches(
    params: QueryFilters,
    raisedAlertId: string
  ): Observable<ISearchResults> {
    let pageQuery = `page=${params.page}`;
    pageQuery += `&pageSize=${params.pageSize}`;
    return this.apiRequestService.getToObservable(
      `/notifications/hub/matches/${raisedAlertId}?${pageQuery}`,
      new DTOTypeConverter<ISearchResults>()
    );
  }

  public toggleReadState(
    raisedAlertId: string,
    state: boolean
  ): Observable<Notification> {
    return this.apiRequestService
      .putToObservable(`/notifications/hub/${raisedAlertId}/read`, {
        read: state,
      })
      .pipe(
        map((response: INotification) => {
          const notification = this.notifications?.find(
            (item: Notification) => item.raisedAlertId === raisedAlertId
          );
          notification.read = response.read;
          notification.read ? (this.unreadCount -= 1) : (this.unreadCount += 1);
          this.getUnreadCount$.next(this.unreadCount);
          return notification;
        })
      );
  }

  public findNotificationById(raisedAlertId: string): Notification {
    return this.notifications.find(
      (item: Notification) => item.raisedAlertId === raisedAlertId
    );
  }

  public getNotificationById(notificationId: string): Observable<Notification> {
    return this.apiRequestService
      .getToObservable(
        `/notifications/${notificationId}`,
        new DTOTypeConverter<Notification>()
      )
      .pipe(
        map((response: INotification) => {
          const notifications = this.mapNotifications([response]);
          return notifications[0];
        })
      );
  }
}
