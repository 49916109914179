import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FileHashNote, Profile } from "@intorqa-ui/shared";

@Component({
  selector: "itq-view-file-hash",
  templateUrl: "./view-file-hash.component.html",
  styleUrls: ["./view-file-hash.component.scss"],
})
export class ViewFileHashComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: FileHashNote;

  constructor() {}

  ngOnInit() {}
}
