import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";

@Component({
  selector: "itq-editable-label",
  templateUrl: "./editable-label.component.html",
  styleUrls: ["./editable-label.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EditableLabelComponent,
    },
  ],
})
export class EditableLabelComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() title: string;
  @Input() class: string;
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() allowEmptyValues = true;
  @Input() form: FormGroup;
  @Input() required = false;
  @Input() editable = true;
  @Input() asyncValidators: AsyncValidatorFn;
  @Input() tooltipClass: string;
  @Input() autoFocus = false;

  @Output() update = new EventEmitter<any>();

  private _editingName = false;
  private touched = false;
  private value: string;

  public get editingName(): boolean {
    return this._editingName;
  }

  public set editingName(value: boolean) {
    this._editingName = value;
    this.componentClass = this.editingName ? "edit__mode" : "";
  }

  @HostBinding("class") componentClass: string;

  @HostListener("document:click", ["$event"])
  click(): void {
    if (this.editingName && !this.autoFocus) {
      this.onUpdate(this.form.controls[this.controlName].value);
    } else {
      this.autoFocus = false;
    }
  }

  constructor() {}

  ngOnInit(): void {
    if (this.autoFocus) {
      this.form.addControl(
        this.controlName,
        new FormControl(
          this.title || "",
          this.required ? [Validators.required] : undefined
        )
      );
    }
  }

  ngAfterViewInit(): void {
    this.editingName = this.autoFocus ? true : false;
    // this.editingName = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.title?.previousValue !== changes?.title?.currentValue) {
      this.form?.controls[this.controlName]?.setValue(
        changes.title.currentValue || ""
      );
    }
  }

  onChange = (items: any) => {};

  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public onEdit(event: MouseEvent): void {
    event.stopImmediatePropagation();
    if (this.editable) {
      this.form.addControl(
        this.controlName,
        new FormControl(
          this.title || "",
          this.required ? [Validators.required] : undefined,
          this.asyncValidators
        )
      );

      this.editingName = !this.editingName;
    }
  }

  public onUpdate(value: string): void {
    this.title = value;
    if (this.allowEmptyValues) {
      this.update.emit(value);
      this.editingName = false;
      this.form.removeControl(this.controlName);
    } else {
      if (value?.trim() !== "") {
        this.update.emit(value);
        this.editingName = false;
        this.form.removeControl(this.controlName);
      }
    }
  }
}
