import { TemplateRef, Type } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TagCategory, ViewMode } from "@intorqa-ui/core";
import {
  AnalysisTypes,
  ChartType,
  DateInterval,
} from "../../enums/widget.enum";

export interface IWidgetType {
  name: string;
  type: AnalysisTypes;
  width: number;
  height: number;
  component: Type<unknown>;
}

export interface IWidget {
  readonly widgetId: string;
  readonly username: string;
  readonly type: AnalysisTypes;
  name: string;
  description: string;
  chartType: ChartType;
  ecosystemId: string;
}

export interface ITagAnalysis extends IWidget {
  width: number;
  height: number;
  x: number;
  y: number;
  options: object;
  dataSource: Array<string>;
  top: number;
  dataPoints: Array<TagCategory>;
}

export interface ITimeSeries extends IWidget {
  width: number;
  height: number;
  x: number;
  y: number;
  options: object;
  dataSource: Array<string>;
  dataType: TagCategory;
  dataValues: Array<string>;
  interval: DateInterval;
}

export interface ITagComparison extends IWidget {
  width: number;
  height: number;
  x: number;
  y: number;
  options: object;
  dataSource: Array<string>;
  dataType: TagCategory;
  dataValues: Array<string>;
}

export interface IDataPoint {
  category: string;
  count: number;
  tagId?: string;
  value: string;
}

export interface ISerie {
  tagId?: string;
  name?: string;
  data: Array<IDataPoint>;
}

export interface IWidgetData {
  series: Array<ISerie>;
  widgetId?: string;
  totalHits: number;
}
export interface IDisplayType {
  id: string;
  type?: ChartType;
  options?: { [key: string]: any };
  icon?: IconProp;
  svgIcon?: string;
  tooltip: string;
  viewMode?: ViewMode;
  template?: TemplateRef<unknown>;
  disabled?: boolean;
}
