import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { DataLayerCustomDimensions } from "../enums/data-layer-custom-dimensions.enum";
import {
  DataLayerCustomDimensionsService,
  PartialCustomDimensionsSet,
} from "./data-layer-custom-dimensions-service.service";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(
    private _DATA_LAYER_CUSTOM_DIMENSIONS: DataLayerCustomDimensionsService,
    private _ROUTER: Router,
    private keycloakService: KeycloakService
  ) {}

  pageView(
    someDimensions: PartialCustomDimensionsSet,
    overridePath?: string,
    overrideTitle?: string
  ): void {
    this._DATA_LAYER_CUSTOM_DIMENSIONS.dimensions = someDimensions;
    this._DATA_LAYER_CUSTOM_DIMENSIONS.trigger();
    const w = window as any;
    if (w && w.dataLayer) {
      w.dataLayer.push({
        event: "pageview",
        page: {
          path: overridePath || this._ROUTER.url,
          title: overrideTitle || document.title,
        },
      });
    }
  }

  customEvent(eventName: string): void {
    const w = window as any;
    if (w && w.dataLayer) {
      w.dataLayer.push({
        event: eventName,
      });
    }
  }

  public loadProfile(): void {
    this.keycloakService
      .getKeycloakInstance()
      .loadUserInfo()
      .then((profile: any) => {
        this.pageView({
          [DataLayerCustomDimensions.uuid]: profile.sub,
          [DataLayerCustomDimensions.organisation]: profile.organisation,
          [DataLayerCustomDimensions.organisationKey]: profile.organisationKey,
        });
        window["intorqaUser"] = {
          uuid: profile.sub,
          organisation: profile.organisation,
          organisationKey: profile.organisationKey,
          email: profile.email,
        };
        this.customEvent("userIdentifier");
        const getProfileEvent = new CustomEvent("_getProfile");
        window.dispatchEvent(getProfileEvent);
      });
  }
}
