import eventbus from "@vertx/eventbus-bridge-client.js";
import { Inject, Injectable } from "@angular/core";
import { ApiConfigService } from "@intorqa-ui/api";

@Injectable({
  providedIn: "root",
})
export class EventBusService {
  public eventBus: Promise<eventbus.EventBus>;

  constructor(@Inject(ApiConfigService) private config: any) {
    this.eventBus = this.connect();
  }

  async connect(): Promise<eventbus.EventBus> {
    console.log("Connecting to eventbus");

    const options = {
      vertxbus_reconnect_attempts_max: Infinity,
      vertxbus_reconnect_delay_min: 1000,
      vertxbus_reconnect_delay_max: 5000,
      vertxbus_reconnect_exponent: 2,
      vertxbus_randomization_factor: 0.5,
    };

    const eb = new eventbus(`${this.config.evBusUrl}`, options);
    eb.enableReconnect(true);
    eb.onopen = () => {
      console.log("Event bus connected...");
    };
    eb.onerror = () => console.log("Event bus error");
    eb.onclose = () => console.log("Event bus disconnected");

    return eb;
  }

  async send(address: string, message: any): Promise<void> {
    this.eventBus.then((eb: eventbus.EventBus) => {
      eb.send(address, message);
    });
  }

  registerEvent(address: string, handler: any): void {
    this.eventBus.then((eb: any) => {
      console.log("event registered");
      if (eb.state === 1) {
        eb.registerHandler(address, handler);
      } else {
        const interval = setInterval(() => {
          if (eb.state === 1) {
            eb.registerHandler(address, handler);
            clearInterval(interval);
          }
        }, 100);
      }
    });
  }

  unRegisterEvent(address: string, handler: any): void {
    this.eventBus.then((eb: eventbus.EventBus) => {
      eb.unregisterHandler(address, handler);
      console.log("event unregistered");
    });
  }
}
