export const Cheat_Reputation_Map = [
  {
    name: "Very Poor",
    value: 1,
  },
  {
    name: "Poor",
    value: 2,
  },
  {
    name: "Poor",
    value: 3,
  },
  {
    name: "Poor",
    value: 4,
  },
  {
    name: "Medium",
    value: 5,
  },
  {
    name: "Medium",
    value: 6,
  },
  {
    name: "Medium",
    value: 7,
  },
  {
    name: "High",
    value: 8,
  },
  {
    name: "High",
    value: 9,
  },
  {
    name: "Very High",
    value: 10,
  },
];
