import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class MaterialService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public addCustomIcons(): void {
    this.matIconRegistry.addSvgIcon(
      "board",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/board.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "website",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/website.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "discord",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/discord.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "reddit",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/reddit.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "vertical-bar-chart",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/vertical-bar-chart.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "youtube",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/youtube.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "telegram",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/telegram.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "ripl",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/ripl.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/logo-spiral.svg"
      )
    );
  }
}
