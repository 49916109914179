import { ProfileMetadata } from "../../models/profiles/profile-metadata";
import { ProfileList } from "../../models/profiles/profile-list";
import { IDataPoint, IWidgetData } from "../widget/widget.interface";
import { Profile } from "../../models/widgets/profile";
import { TimeSeries } from "../../models/widgets/time-series";

export interface IProfile {
  profileTypeId: string;
  profileId: string;
  profileTypeName: string;
  metadata: Array<ProfileMetadata>;
  updatedBy: string;
  name: string;
  ecosystemId: string;
  activity: number;
  trend: number;
  description: string;
  updatedDate: number;
}

export interface IProfileList {
  activityData: IWidgetData;
}

export interface IProfileDataSource {
  profileTypeId: string;
  profileId: string;
  updatedBy: string;
  name: string;
  ecosystemId: string;
  activity: number;
  description: string;
  updatedDate: string;
  widget: TimeSeries;
}

export interface IProfileData {
  items: Array<Profile>;
  totalCount: number;
}

export interface IProfileListData {
  items: Array<ProfileList>;
  totalCount: number;
}

export interface IProfileMetric {
  id: string;
  profileId: string;
  typeId: string;
  ecosystemId: string;
  organisationId: string;
  data: Array<IDataPoint>;
  trend?: boolean;
  totalHits: number;
  createdDate: number;
  updatedDate: number;
}
