import { ResearchAlert } from "../models/alerts/alert";
import { IDataPoint, ISerie } from "./widget/widget.interface";

export interface IAlert {
  id: string;
  message: string;
  priority: string;
  postSlack: boolean;
  emailMe: boolean;
}

export interface IResearchAlert extends IAlert {
  headline: number;
  createdDate: number;
  rawMessage: string;
  createdBy: string;
  sentBy: string;
  clientIds: { [key: string]: string };
  ecosystemId: string;
}

export interface ITagMatchAlert extends IAlert {
  delay: number;
  active: boolean;
}

export interface ITagThresholdAlert extends IAlert {
  active: boolean;
  dailyAverage: string;
  condition: string;
  count: number;
  period: number;
}

export interface IAlertType {
  id: string;
  label: string;
}

export interface ICreateTagMatchAlertDTO {
  tagId: string;
  typeId: string;
  message: string;
  delay: string;
  priority: string;
  postSlack: boolean;
  emailMe: boolean;
}

export interface ICreateTagThresholdAlertDTO {
  tagId: string;
  typeId: string;
  message: string;
  condition: string;
  period: string;
  count: number;
  priority: string;
  postSlack: boolean;
  emailMe: boolean;
  active: boolean;
}

export interface IAlertsTrendData {
  series: Array<ISerie>;
  widgetId: string;
  totalHits: number;
  dailyAverage: number;
}

export interface IAlertsTrend {
  data: Array<IDataPoint>;
  totalHits: number;
  dailyAverage: number;
}

export interface IAlertsData {
  items: Array<IResearchAlert>;
  totalCount: number;
}

export interface IAlertsResults {
  items: Array<ResearchAlert>;
  totalCount: number;
}
