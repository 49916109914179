import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { IDisplayType } from "@intorqa-ui/shared";
import { Align, Directions, Sizes } from "../../enums/shared.enum";

@Component({
  selector: "itq-button-thumbnail",
  templateUrl: "./button-thumbnail.component.html",
  styleUrls: ["./button-thumbnail.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ButtonThumbnailComponent),
    },
  ],
})
export class ButtonThumbnailComponent implements OnInit {
  @Input() displayTypes: Array<IDisplayType> = [];
  @Input() direction: Directions = Directions.HORIZONTAL;
  @Input() theme: string;

  @Output() change = new EventEmitter<IDisplayType>();

  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly Directions = Directions;

  public value: string;

  @HostBinding("class.flex-column") componentClass: string;
  @HostBinding("class") componentTheme: string;

  constructor() {}

  ngOnInit(): void {
    this.componentTheme = this.theme;
    this.componentClass =
      this.direction === Directions.VERTICAL ? "d-column" : "";
  }

  public onClick(item: IDisplayType): void {
    this.value = item.id;
    this.onChange(this.value);
    this.change.emit(item);
  }

  onChange = (text: string) => {};

  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
