import { Injectable } from "@angular/core";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IUserPreferences } from "../interfaces/user-preferences.interface";
import { UserPreferences } from "../models/user-preferences.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _userPreferences: UserPreferences;

  public get userPreferences(): UserPreferences {
    return this._userPreferences;
  }

  public set userPreferences(v: UserPreferences) {
    this._userPreferences = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public setDefaultEcosystem(ecosystemId: string): Observable<void> {
    return this.apiRequestService.postToObservable(
      "/user/preferences/ecosystem",
      new DTOTypeConverter<void>(),
      { ecosystemId }
    );
  }

  public getUserPreferences(): Observable<UserPreferences> {
    return this.apiRequestService
      .getToObservable(
        "/user/preferences",
        new DTOTypeConverter<IUserPreferences>()
      )
      .pipe(
        map((response: IUserPreferences) => {
          this.userPreferences = new UserPreferences(
            response.defaultEcosystemId,
            response.id,
            response.organisationId,
            response.username
          );
          return this.userPreferences;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public updateViewDate(profileId: string): Observable<void> {
    return this.apiRequestService.putToObservable(
      "/user/preferences/metrics/view",
      new DTOTypeConverter<void>(),
      { profileId }
    );
  }
}
