import { Pipe, PipeTransform } from "@angular/core";
import { DateQueryType, DateRange, QueryFilters } from "@intorqa-ui/core";
import moment from "moment";

@Pipe({
  name: "getDateRange",
})
export class GetDateRangePipe implements PipeTransform {
  constructor() {}

  transform(initialState: QueryFilters): string {
    if (initialState?.where.label === DateQueryType.Custom) {
      return `${moment(
        DateRange.convertFromEpochSeconds(initialState?.where?.start)
      ).format("DD-MM-YYYY")} - ${moment(
        DateRange.convertFromEpochSeconds(initialState?.where?.end)
      ).format("DD-MM-YYYY")}`;
    } else {
      return initialState?.where?.label;
    }
  }
}
