import { Injectable } from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import { IError, QueryFilters, SharedService } from "@intorqa-ui/core";
import {
  AnalysisTypes,
  IProfileType,
  LinkTag,
  PricingModel,
  ProfileTypeMetadata,
} from "@intorqa-ui/shared";
import { Observable, Subject, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ChartType } from "../enums/widget.enum";
import { IProfileTypeMetadata } from "../interfaces/profiles/profile-type-metadata.interface";
import {
  IProfile,
  IProfileData,
} from "../interfaces/profiles/profile.interface";
import { Profile } from "../models/widgets/profile";
import { ProfileType } from "./../models/profiles/profile-type";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  public metadata$ = new Subject<Array<ProfileTypeMetadata>>();
  public profiles$ = new Subject<IProfileData>();
  public initMetadataColumns$ = new Subject<void>();
  public getProfiles$ = new Subject<void>();
  public loadProfileTypes$ = new Subject<void>();
  public changeMetadataControlValue$ = new Subject<PricingModel>();

  private _typeMetadata: Array<ProfileTypeMetadata>;
  private _types: Array<ProfileType> = [];
  private _profiles: IProfileData = { items: [], totalCount: undefined };
  private _pricingModel: PricingModel;

  public set pricingModel(v: PricingModel) {
    this._pricingModel = v;
  }
  public get pricingModel(): PricingModel {
    return this._pricingModel;
  }

  public get profiles(): IProfileData {
    return this._profiles;
  }

  public set profiles(v: IProfileData) {
    this._profiles = v;
  }
  public get types(): Array<ProfileType> {
    return this._types;
  }

  public set types(v: Array<ProfileType>) {
    this._types = v;
  }

  public get typeMetadata(): Array<ProfileTypeMetadata> {
    return this._typeMetadata;
  }

  public set typeMetadata(v: Array<ProfileTypeMetadata>) {
    this._typeMetadata = v;
  }

  constructor(
    private apiRequestService: ApiRequestService,
    public sharedService: SharedService
  ) {}

  public getProfiles(
    params: QueryFilters,
    ecosystemId: string,
    showLoader = true
  ): Observable<IProfileData> {
    this.sharedService.loader$.next(showLoader);
    let pageQuery = `page=${params.page}`;
    pageQuery += `&pageSize=${params.pageSize}`;
    pageQuery += `&sortField=${params.sort.active}`;
    pageQuery += `&sortOrder=${params.sort.direction}`;
    if (params.sort.isMetadata) {
      pageQuery += `&sortIsMetadata=${params.sort.isMetadata}`;
    }
    pageQuery += `&ecosystemId=${ecosystemId}`;

    return this.apiRequestService
      .postToObservable(
        `/profiles/list?${pageQuery}`,
        new DTOTypeConverter<{
          items: Array<IProfile>;
          totalCount: number;
        }>(),
        params.query
      )
      .pipe(
        map((response: { items: Array<IProfile>; totalCount: number }) => {
          const items = response?.items.map(
            (item: any) =>
              new Profile(
                item.profileId,
                item.updatedBy,
                AnalysisTypes.PROFILE,
                item.name,
                item.description,
                ChartType.PROFILE,
                item.ecosystemId,
                item.profileTypeId,
                item.profileTypeName,
                item.profileId,
                item.metadata?.map((metadata: IProfileTypeMetadata) => ({
                  ...metadata,
                  ...{
                    values: metadata.values,
                  },
                })),
                item.trend,
                item.activity,
                item.updatedBy,
                item.updatedDate
              )
          );
          if (params.page === 1) {
            this.profiles = {
              items,
              totalCount: response.totalCount,
            };
          } else {
            this.profiles.items = [...this.profiles.items, ...items];
          }

          this.profiles$.next(this.profiles);
          this.sharedService.loader$.next(false);
          return this.profiles;
        })
      );
  }

  public getProfileTypes(ecosystemId: string): Observable<Array<ProfileType>> {
    this.sharedService.loader$.next(true);
    let pageQuery = `ecosystemId=${ecosystemId}`;

    return this.apiRequestService
      .getToObservable(
        `/profiles/types?${pageQuery}`,
        new DTOTypeConverter<Array<IProfileType>>()
      )
      .pipe(
        map((response: Array<IProfileType>) => {
          this.types = response.map(
            (item: IProfileType) => new ProfileType(item.id, item.name)
          );
          this.sharedService.loader$.next(false);
          return this.types;
        })
      );
  }

  public validateName(profile: Profile, linkTag: LinkTag): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<ValidationErrors | undefined> => {
      if (linkTag) {
        if (
          (profile.profileId && profile.name === control.value) ||
          !control.value ||
          control.value === ""
        ) {
          return of(undefined);
        }
      } else {
        if (
          (profile.profileId && profile.name === control.value) ||
          !control.value ||
          control.value === "" ||
          control.value === profile.name
        ) {
          return of(undefined);
        }
      }

      const pageQuery = `profileTypeId=${profile.profileTypeId}&ecosystemId=${profile.ecosystemId}&value=${control.value}`;
      return this.apiRequestService
        .getToObservable(
          `/profiles/validateName?${pageQuery}`,
          new DTOTypeConverter<boolean>()
        )
        .pipe(
          map((valid: boolean) =>
            valid ? undefined : { duplicateProfileName: true }
          )
        );
    };
  }

  public getMetadata(
    profileTypeId: string
  ): Observable<Array<ProfileTypeMetadata>> {
    return this.apiRequestService
      .getToObservable(
        `/profiles/metadata/${profileTypeId}`,
        new DTOTypeConverter<Array<IProfileTypeMetadata>>()
      )
      .pipe(
        map((response: Array<IProfileTypeMetadata>) => {
          this.typeMetadata = response?.map(
            (item: IProfileTypeMetadata) =>
              new ProfileTypeMetadata(
                item.id,
                item.name,
                item.component,
                item.values,
                item.required,
                item.groupName
              )
          );
          this.metadata$.next(this.typeMetadata);
          return this.typeMetadata;
        })
      );
  }

  public save(item: any): Observable<Profile> {
    this.sharedService.loader$.next(true);
    return this.apiRequestService
      .postToObservable("/profiles", new DTOTypeConverter<IProfile>(), item)
      .pipe(
        map((response: IProfile) => {
          this.sharedService.loader$.next(false);
          return new Profile(
            response.profileId,
            response.updatedBy,
            AnalysisTypes.PROFILE,
            response.name,
            response.description,
            ChartType.PROFILE,
            response.ecosystemId,
            response.profileTypeId,
            response.profileTypeName,
            response.profileId,
            response.metadata,
            response.trend,
            response.activity,
            response.updatedBy,
            response.updatedDate
          );
        })
      );
  }

  public update(id: string, item: { [key: string]: any }): Observable<Profile> {
    this.sharedService.loader$.next(true);
    return this.apiRequestService.putToObservable(`/profiles/${id}`, item).pipe(
      map((response: IProfile) => {
        const profile = new Profile(
          response.profileId,
          response.updatedBy,
          AnalysisTypes.PROFILE,
          response.name,
          response.description,
          ChartType.PROFILE,
          response.ecosystemId,
          response.profileTypeId,
          response.profileTypeName,
          response.profileId,
          response.metadata,
          response.trend,
          response.activity,
          response.updatedBy,
          response.updatedDate
        );
        this.profiles.items = this.profiles?.items?.map((item: Profile) => {
          if (item.profileId === response.profileId) {
            return profile;
          } else {
            return item;
          }
        });
        this.profiles$.next(this.profiles);
        this.sharedService.loader$.next(false);
        return profile;
      })
    );
  }

  public delete(id: string): Observable<boolean> {
    return this.apiRequestService.deleteToObserable(`/profiles/${id}`).pipe(
      map((response: boolean) => {
        this.profiles.items = this.profiles.items.filter(
          (item: Profile) => item.profileId !== id
        );
        return response;
      })
    );
  }

  public getProfileTypeById(profileTypeId: string): ProfileType {
    return this.types.find((item: ProfileType) => item.id === profileTypeId);
  }

  public getProfileTypeByName(profileTypeName: string): ProfileType {
    return this.types.find(
      (item: ProfileType) => item.name === profileTypeName
    );
  }

  public getUsers(): Observable<Array<string>> {
    return this.apiRequestService.getToObservable(
      "/profiles/users",
      new DTOTypeConverter<Array<string>>(),
      undefined
    );
  }

  public findProfileById(profileId: string): Profile {
    return this.profiles?.items?.find(
      (item: Profile) => item.profileId === profileId
    );
  }

  public getProfileById(profileId: string): Observable<Profile> {
    return this.apiRequestService
      .getToObservable(
        `/profiles/${profileId}`,
        new DTOTypeConverter<IProfile>()
      )
      .pipe(
        map((response: IProfile) => {
          return new Profile(
            response.profileId,
            undefined,
            AnalysisTypes.PROFILE,
            response.name,
            response.description,
            ChartType.PROFILE,
            response.ecosystemId,
            response.profileTypeId,
            response.profileTypeName,
            response.profileId,
            response.metadata,
            response.trend,
            response.activity,
            response.updatedBy,
            response.updatedDate
          );
        }),
        catchError((error: IError) => {
          return throwError(error);
        })
      );
  }
}
